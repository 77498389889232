import { IInterviewState } from '@redux/interview/interface';

import { jobRoleActions, interviewQuestionsActions, aiEvalutionActions, aiEvalutionHistoryActions, fetchAzureTokenActions, deleteEvaluationHistoryActions } from './action';
import { customText } from '@presentation/Interview/config';

export const initialState = {
    fetchJobRoles: [],
    fetchFilteredJobRoles: [],
    fetchAllJobRoles: [],
    interviewQuestions: {},
    submitEvaluation: null,
    fetchEvalautions: [],
    fetchAzureToken: null,
    validateJobRole: '',
    fetchJobRolesFromTags: [],
    deleteEvaluation: '',
    loaders: {
        fetchJobRoles: false,
        interviewQuestions: false,
        submitEvaluation: false,
        fetchEvalautions: false,
        fetchAzureToken: false,
        deleteEvaluation: false,
        validateJobRole: false,
        fetchAllJobRoles: false,
        fetchJobRolesFromTags: false
    },
    errors: {
        fetchJobRoles: '',
        interviewQuestions: '',
        submitEvaluation: '',
        fetchEvalautions: '',
        fetchAzureToken: '',
        deleteEvaluation: '',
        validateJobRole: '',
        fetchAllJobRoles: '',
        fetchJobRolesFromTags: ''
    },
};

export const prepareReducer = (
    state: IInterviewState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
    case jobRoleActions.FETCH_JOB_ROLE_LIST:
        return {
            ...state,
            loaders: { ...state.loaders, fetchJobRoles: true },
            errors: { ...state.errors, fetchJobRoles: '' },
        };
    case jobRoleActions.FETCH_JOB_ROLE_LIST_FROM_TAGS:
        return {
            ...state,
            loaders: { ...state.loaders, fetchJobRolesFromTags: true },
            errors: { ...state.errors, fetchJobRolesFromTags: '' },
        }; 
    case jobRoleActions.FETCH_JOB_ROLE_LIST_FROM_TAGS_SUCCESS:
        return {
            ...state,
            fetchJobRolesFromTags: action.payload,
            loaders: { ...state.loaders, fetchJobRolesFromTags: false },
        };
    case jobRoleActions.FETCH_JOB_ROLE_LIST_FROM_TAGS_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, fetchJobRolesFromTags: false },
            errors: { ...state.errors, fetchJobRolesFromTags: action.payload },
        };    
    case jobRoleActions.FETCH_FILTERED_JOB_ROLE_LIST_SUCCESS:
        return {
            ...state,
            fetchFilteredJobRoles: action.payload,
            loaders: { ...state.loaders, fetchJobRoles: false },
        };       
    case jobRoleActions.FETCH_JOB_ROLE_LIST_SUCCESS:
        return {
            ...state,
            fetchJobRoles: action.payload,
            loaders: { ...state.loaders, fetchJobRoles: false },
        };
    case jobRoleActions.FETCH_FILTERED_JOB_ROLE_LIST_SUCCESS:
        return {
            ...state,
            fetchFilteredJobRoles: action.payload,
            loaders: { ...state.loaders, fetchJobRoles: false },
        };

    case jobRoleActions.FETCH_ALL_JOB_ROLE_LIST:
        return {
            ...state,
            loaders: { ...state.loaders, fetchAllJobRoles: true },
            errors: { ...state.errors, fetchAllJobRoles: '' },
        };
    case jobRoleActions.FETCH_ALL_JOB_ROLE_LIST_SUCCESS:
        return {
            ...state,
            fetchAllJobRoles: action.payload,
            loaders: { ...state.loaders, fetchJobRoles: false },
        };
    case jobRoleActions.FETCH_JOB_ROLE_LIST_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, fetchJobRoles: false },
            errors: { ...state.errors, fetchJobRoles: action.payload },
        };
    case jobRoleActions.FETCH_ALL_JOB_ROLE_LIST_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, fetchJobRoles: false },
            errors: { ...state.errors, fetchJobRoles: action.payload },
        };
    case jobRoleActions.VALIDATE_JOB_ROLE:
        return {
            ...state,
            loaders: { ...state.loaders, validateJobRole: true },
            errors: { ...state.errors, validateJobRole: '' },
        };
    case jobRoleActions.VALIDATE_JOB_ROLE_SUCCESS:
        return {
            ...state,
            validateJobRole: action.payload,
            loaders: { ...state.loaders, validateJobRole: false },
        };
    case jobRoleActions.VALIDATE_JOB_ROLE_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, validateJobRole: false },
            errors: { ...state.errors, validateJobRole: action.payload },
        };

    case jobRoleActions.RESET_VALIDATE_JOB_ROLE:
        return {
            ...state,
            validateJobRole: undefined,
            loaders: { ...state.loaders, validateJobRole: false },
            errors: { ...state.errors, validateJobRole: '' }
        };
    case interviewQuestionsActions.FETCH_INTERVIEW_QUESTION_LIST:
        return {
            ...state,
            loaders: { ...state.loaders, interviewQuestions: true },
            errors: { ...state.errors, interviewQuestions: '' },
        };
    case interviewQuestionsActions.FETCH_INTERVIEW_QUESTION_LIST_SUCCESS:
        return {
            ...state,
            interviewQuestions: action.payload,
            loaders: { ...state.loaders, interviewQuestions: false },
        };
    case interviewQuestionsActions.FETCH_INTERVIEW_QUESTION_LIST_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, interviewQuestions: false },
            errors: { ...state.errors, interviewQuestions: action.payload },
        };

    case aiEvalutionActions.SUBMIT_EVALUATION:
        return {
            ...state,
            loaders: { ...state.loaders, submitEvaluation: true },
            errors: { ...state.errors, submitEvaluation: '' },
        };
    case aiEvalutionActions.SUBMIT_EVALUATION_SUCCESS:
        return {
            ...state,
            submitEvaluation: action.payload,
            loaders: { ...state.loaders, submitEvaluation: false },
        };
    case aiEvalutionActions.SUBMIT_EVALUATION_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, submitEvaluation: false },
            errors: { ...state.errors, submitEvaluation: action.payload },
        };

    case aiEvalutionActions.RESET_SUBMIT_EVALUATION:
        return {
            ...state,
            submitEvaluation: undefined,
            loaders: { ...state.loaders, submitEvaluation: false },
        };

    case aiEvalutionHistoryActions.FETCH_EVALUATION_LIST:
        return {
            ...state,
            loaders: { ...state.loaders, fetchEvalautions: true },
            errors: { ...state.errors, fetchEvalautions: '' },
        };
    case aiEvalutionHistoryActions.FETCH_EVALUATION_LIST_SUCCESS:
        return {
            ...state,
            fetchEvalautions: action.payload,
            loaders: { ...state.loaders, fetchEvalautions: false },
            errors: { ...state.errors, fetchEvalautions: '' },
        };
    case aiEvalutionHistoryActions.FETCH_EVALUATION_LIST_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, fetchEvalautions: false },
            errors: { ...state.errors, fetchEvalautions: action.payload },
        };
    case fetchAzureTokenActions.FETCH_AZURE_TOKEN:
        return {
            ...state,
            loaders: { ...state.loaders, fetchAzureToken: true },
            errors: { ...state.errors, fetchAzureToken: '' },
        };
    case fetchAzureTokenActions.FETCH_AZURE_TOKEN_SUCCESS:
        return {
            ...state,
            fetchAzureToken: action.payload,
            loaders: { ...state.loaders, fetchAzureToken: false },
            errors: { ...state.errors, fetchAzureToken: '' },
        };
    case fetchAzureTokenActions.FETCH_AZURE_TOKEN_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, fetchAzureToken: false },
            errors: { ...state.errors, fetchAzureToken: action.payload },
        };
    case deleteEvaluationHistoryActions.DELETE_EVALUATION:
        return {
            ...state,
            deleteEvaluation: customText.deleted,
            loaders: { ...state.loaders, deleteEvaluation: true },
            errors: { ...state.errors, deleteEvaluation: '' },
        };
    case deleteEvaluationHistoryActions.DELETE_EVALUATION_SUCCESS:
        return {
            ...state,
            loaders: { ...state.loaders, deleteEvaluation: false },
        };
    case deleteEvaluationHistoryActions.DELETE_EVALUATION_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, deleteEvaluation: false },
            errors: { ...state.errors, deleteEvaluation: action.payload },
        };
    case deleteEvaluationHistoryActions.RESET_DELETE_EVALUATION:
        return {
            ...state,
            deleteEvaluation: undefined,
            loaders: { ...state.loaders, deleteEvaluation: false },
        };
        
    default:
        return state;
    }
};
