import React, { ReactNode } from 'react';

import {WFImg} from '@components/index'

import './style.less';

interface IProps {
  value: string | ReactNode;
  onClick: (e?: any) => void;
  className?: any;
  icon?: any;
};

export default function (props: IProps) {
    const { className, onClick, value, icon } = props;

    const style = ['wf_link_button', className].join(' ');

    return (
        <div className={style} onClick={onClick}>
            {icon && <WFImg src={icon} className="btn-icon"></WFImg>}<span className="link__color">{value}</span>
        </div>
    );
};
