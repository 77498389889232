import { getLangPath } from '@translations/AppLanguage';

import { Routes } from '@navigator/Routes';
import { getCourseKeyUrl } from '@utils/UserUtils';

import qs from 'qs';

export enum URLs {
  // Default
  guest = 'guest',

  // Auth
  home = 'home',
  login = 'login',
  forgotPassword = 'forgotPassword',
  createProfile = 'createProfile',
  register = 'register',
  changePassword = 'changePassword',
  enrollCohort = 'enrollCohort',
  userVerification = 'userVerification',
  interview = 'interview',
  jobPrepare = 'job-prepare',

  // messages
  messages = 'messages',
  messageThread = 'messageThread',

  // Batches
  batches = 'batches',
  batchesStudents = 'batchstudents',
  allBatches = 'allBatches',
  editBatches = 'editBatches',
  manageStudents = 'manageStudents',
  inviteStudents = 'inviteStudents',

  // cohort
  cohorts = 'cohorts',
  cohortDetails = 'cohortDetails',
  cohortMembers = 'cohortMembers',
  createBatch = 'createBatch',
  courseReport = "courseReport",
  batchReport = 'batchReport',

  // calendar
  calendar = 'calendar',

  // Courses
  courses = 'courses',
  courseDetails = 'courseDetails',
  guestCourseDetail = 'guestCourseDetail',
  courseLesson = 'courseLesson',
  courseLessonMobile = 'courseLessonMobile',

  // Forums
  forums = 'forums',

  // logout
  logout = 'logout',

  // event
  meeting = 'meeting',
  meetingDetail = 'meetingDetail',

  // profile
  profile = 'profile',


  // reports
  reports = 'reports',
  batchReports = 'batchReports',

  // dashboard
  dashboard = 'dashboard',
   // library
   library = 'library',
   contentListingPage = 'contentListingPage',
   contentDetailsPage = 'contentDetailsPage',
   contentSearchPage = 'contentSearchPage',
     // typography
  typography = 'typography',

  support = 'support',
  // User Setting
  settings = "settings",
  userSettingsSyncOption = "userSettingsSyncOption",
  settingsMenu = "settingsMenu",
  refreshCookies = 'refreshCookies',

  // Courses Catalog
  courseCatalog = "courseCatalog",
  authenticate = "authenticate",
  // career
  career = "careerGuidance",
  eventDetail = "eventDetail",

  // program
  programDetail = "programDetail",
  updateProfileData = "updateProfileData",

  // mentors
  mentors = 'mentors',
  network = 'network',
  mentorListingPage = 'mentorListingPage',
  mentorDetails = 'mentorDetails',
}

export interface IRouteParams {

  batchId?: number;

  ventureId?: string;
  profileId?: string;
  type?: string;
  conversationId?: string;
  meetingId?: string;
  courseId?: number | string;
  userId?: string;
  code?: string;
  value?: string;
  contentId?:string;
  searchTerm?: string;
  id?:string;
  tagType?: string;
  content?: {};
  subMenu?: string;
  syncOptionKey?: string;
  lessonId?: string;
  activityId?: string;
  tabName?: {};
  courseKey?: string;
}

const generate = (url?: URLs, params?: IRouteParams) => {
  switch (url) {

    case URLs.guest:
      return getLangPath(Routes.Auth.guest);

    /**
     *  Auth URLs
     */
    case URLs.jobPrepare:
      return getLangPath(Routes.JobPrepare.jobPrepare);
    case URLs.interview:
      return getLangPath(Routes.Interview.interview);
    case URLs.home:
      return getLangPath(Routes.Home.home);

    case URLs.dashboard:
      return getLangPath(Routes.Dashboard.dashboard);

    case URLs.login:
      return getLangPath(getCourseKeyUrl(Routes.Auth.login, params));

    case URLs.forgotPassword:
      return getLangPath(Routes.Auth.forgotPassword);

    case URLs.createProfile:
      return getLangPath(getCourseKeyUrl(Routes.Profile.createProfile, params));

    case URLs.register:
      return getLangPath(getCourseKeyUrl(Routes.Auth.register, params));

    case URLs.changePassword:
      return getLangPath(getCourseKeyUrl(Routes.Auth.changePassword, params));

    case URLs.enrollCohort:
      return getLangPath(Routes.Auth.enrollCohort);

    // Message URLs
    case URLs.messages:
      return getLangPath(Routes.Messages.messages);

    case URLs.messageThread:
      return getLangPath(`${Routes.Messages.messages}/${params.profileId}/${params.type}/${params.conversationId}`);


    case URLs.createBatch:
      return getLangPath(`${Routes.Batch.createBatch}`);

    // Batches
    case URLs.batches:
      return getLangPath(Routes.Batch.batches);

    case URLs.batchesStudents:
      return getLangPath(Routes.Batch.students);

    case URLs.cohortDetails:
      return getLangPath(`${Routes.Batch.cohortDetail}/${params.batchId}`);

    case URLs.cohortMembers:
      return getLangPath(`${Routes.Batch.cohortDetail}/${params.batchId}${Routes.Batch.members}`);

    case URLs.courseReport:
      return getLangPath(`${Routes.Batch.courseReports}/${params.userId}/course/${params.courseId}`);

    case URLs.batchReport:
      return getLangPath(`${Routes.Batch.batchReports}/${params.batchId}`);

    // Calendar
    case URLs.calendar:
      return getLangPath(Routes.Calendar.calendar);

    // Courses
    case URLs.courses:
      return getLangPath(`${Routes.Course.courses}`);

    case URLs.courseDetails:
      return getLangPath(`${Routes.Course.courses}/${params.courseId}`);

    case URLs.logout:
      return getLangPath(`${Routes.Auth.logout}`);

    // Event
    case URLs.meetingDetail:
      return getLangPath(`${Routes.Meeting.meeting}/${params.meetingId}`);

    // Profile
    case URLs.profile:
      return getLangPath(`${Routes.Profile.profile}/${params.userId}`);


    // reports
    case URLs.reports:
      return getLangPath(Routes.Batch.reports);

    case URLs.batchReports:
      return getLangPath(Routes.Batch.batchReports);

    case URLs.cohortDetails:
      return getLangPath(`${Routes.Batch.cohortDetail}/${params.batchId}`);

    case URLs.allBatches:
      return getLangPath(`${Routes.Batch.allBatches}`);

    case URLs.editBatches:
      return getLangPath(`${Routes.Batch.edit}/${params.batchId}`);

    case URLs.userVerification:
      return getLangPath(`${Routes.Auth.userVerification}/${params.type}/${params.code}/${params.value}`);

    case URLs.manageStudents:
      return getLangPath(`${Routes.Batch.manageStudent}/${params.batchId}`);

    case URLs.inviteStudents:
      return getLangPath(`${Routes.Batch.inviteStudent}/${params.batchId}`);

   case URLs.typography:
        return getLangPath(`${Routes.Auth.typography}  Typography`);

    case URLs.support:
        return getLangPath(Routes.Dashboard.support);
    // User Settings
    case URLs.settingsMenu:
      return getLangPath(`${Routes.Settings.settings}/${(params.subMenu)}`);

    case URLs.userSettingsSyncOption:
      return getLangPath(`${Routes.Settings.settings}/${(params.subMenu)}/${(params.syncOptionKey)}`);

    case URLs.refreshCookies:
        return getLangPath(Routes.General.refreshCookies);
    case URLs.userVerification:
      return getLangPath(`${Routes.Auth.userVerification}/${params.type}/${params.code}/${params.value}`);
   // library
   case URLs.contentSearchPage:
        return getLangPath(`${Routes.Library.library}/search?${qs.stringify(params.content)}`);
    case URLs.contentDetailsPage:
        return getLangPath(`${Routes.Library.library}/${params.id}`);
    case URLs.library:
      return getLangPath(`${Routes.Library.library}`);
    case URLs.contentListingPage:
        return getLangPath(`${Routes.Library.library}/list?${qs.stringify(params.content)}`);


    case URLs.authenticate:
        return getLangPath(Routes.Auth.authenticate);

    case URLs.forums:
        return getLangPath(Routes.Forums.forums);

    case URLs.career:
        return getLangPath(Routes.Career.career);

    case URLs.eventDetail:
        return getLangPath(`${Routes.Event.event}/${params.id}`);

    case URLs.programDetail:
      return getLangPath(`${Routes.Program.program}/${params.id}`);

    case URLs.updateProfileData:
      return getLangPath(Routes.Auth.updateProfile);
    case URLs.courseLesson:
        return getLangPath(`${Routes.Course.courseDetail}/${params.courseId}/course-lesson/${params.lessonId}/${params.activityId}`);
    case URLs.courseLessonMobile:
      return getLangPath(`${Routes.Course.courseDetailMobile}/${params.courseId}/course-lesson/${params.lessonId}/${params.activityId}`);
     // mentors
    case URLs.mentors:
      return getLangPath(`${Routes.Mentor.mentors}?${qs.stringify(params?.content)}`);
    case URLs.network:
      return getLangPath(`${Routes.Mentor.network}?${qs.stringify(params?.tabName)}`);
    case URLs.mentorListingPage:
      return getLangPath(`${Routes.Mentor.mentors}/${params.ventureId}/${params.type}`)
    case URLs.mentorDetails:
      return getLangPath(`${Routes.Mentor.mentor}/${params.userId}`);
    default:
      return getLangPath(Routes.Home.default);

  }

};


export const NavigationUrl = {
  generate,
};
