import { forumActions } from "./actions";

export interface IForumState {
    forumListByUserId: any,
    forumDetailsbyForumIdMap: {},
    recommendedForumList: any,
    loaders: {
        forumListByUserId: boolean,
        forumDetailsbyForumIdMap: {},
        recommendedForumList: boolean,
    };
    errors: {
        forumListByUserId: string,
        forumDetailsbyForumIdMap: {},
        recommendedForumList: string
    };
}

export const initialState = {
    forumListByUserId: [],
    forumDetailsbyForumIdMap: {},
    recommendedForumList: [],
    loaders: {
        forumListByUserId: false,
        forumDetailsbyForumIdMap: {},
        recommendedForumList: false,
    },
    errors: {
        forumListByUserId: "",
        forumDetailsbyForumIdMap: {},
        recommendedForumList: ""
    },
};

export const forumReducer = (
    state: IForumState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
        case forumActions.FETCH_FORUM_LIST_BY_USERID:
            return {
                ...state,
                loaders: { ...state.loaders, forumListByUserId: true },
                errors: { ...state.errors, forumListByUserId: "" },
            };
        case forumActions.FETCH_FORUM_LIST_BY_USERID_SUCCESS:
            return {
                ...state,
                forumListByUserId: action.payload,
                loaders: { ...state.loaders, forumListByUserId: false },
            };
        case forumActions.FETCH_FORUM_LIST_BY_USERID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, forumListByUserId: false },
                errors: { ...state.errors, forumListByUserId: action.payload },
            };
        case forumActions.FETCH_FORUM_DETAILS_BY_FORUMID:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    forumDetailsbyForumIdMap: {
                        ...state.loaders.forumDetailsbyForumIdMap,
                        [action.payload.forumId]: true
                    }
                },
                errors: {
                    ...state.errors,
                    forumDetailsbyForumIdMap: {
                        ...state.errors.forumDetailsbyForumIdMap,
                        [action.payload.forumId]: ""
                    }
                }
            }

        case forumActions.FETCH_FORUM_DETAILS_BY_FORUMID_SUCCESS:
            return {
                ...state,
                forumDetailsbyForumIdMap: { ...state.forumDetailsbyForumIdMap, [action?.payload?.forumId]: action.payload.response },
                loaders: {
                    ...state.loaders,
                    forumDetailsbyForumIdMap: {
                        ...state.loaders.forumDetailsbyForumIdMap,
                        [action.payload.forumId]: false
                    }
                }
            }

        case forumActions.FETCH_FORUM_DETAILS_BY_FORUMID_FAIL:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    forumDetailsbyForumIdMap: {
                        ...state.loaders.forumDetailsbyForumIdMap,
                        [action.payload.forumId]: false
                    }
                },
                errors: {
                    ...state.errors,
                    forumDetailsbyForumIdMap: {
                        ...state.errors.forumDetailsbyForumIdMap,
                        [action.payload.forumId]: action.payload.error
                    }
                }
            }
        case forumActions.FETCH_RECOMMENDED_FORUM_LIST:
            return {
                ...state,
                loaders: { ...state.loaders, recommendedForumList: true },
                errors: { ...state.errors, recommendedForumList: "" },
            };
        case forumActions.FETCH_RECOMMENDED_FORUM_LIST_SUCCESS:
            return {
                ...state,
                recommendedForumList: action.payload,
                loaders: { ...state.loaders, recommendedForumList: false },
            };
        case forumActions.FETCH_RECOMMENDED_FORUM_LIST_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, recommendedForumList: false },
                errors: { ...state.errors, recommendedForumList: action.payload },
            };
        default:
            return state;
    }
};
