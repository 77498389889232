const actionTypePrefix = "SKILLS_SCORE";

export const skillScoresActions = {
  FETCH_SKILLS_SCORES: `${actionTypePrefix}/FETCH_SKILLS_SCORES`,
  FETCH_SKILLS_SCORES_SUCCESS: `${actionTypePrefix}/FETCH_SKILLS_SCORES_SUCCESS`,
  FETCH_SKILLS_SCORES_FAIL: `${actionTypePrefix}/FETCH_SKILLS_SCORES_FAIL`,
};

export interface IFetchSkillsScoreAction {
  type: string;
  payload: {
    userId: string;
    courseId: string;
    courseLmsId: number;  };
}

export const fetchSkillsScoresData = (
  userId: string,
  courseId: string,
  courseLmsId: number,
): IFetchSkillsScoreAction => {
  return {
    type: skillScoresActions.FETCH_SKILLS_SCORES,
    payload: {
      userId,
      courseId,
      courseLmsId
    },
  };
};

export const fetchSkillsScoresSuccess = (skillScoresData: any[]) => {
  return {
    type: skillScoresActions.FETCH_SKILLS_SCORES_SUCCESS,
    payload: skillScoresData,
  };
};

export const fetchSkillsScoresFailure = (error: string) => {
  return {
    type: skillScoresActions.FETCH_SKILLS_SCORES_FAIL,
    payload: error,
  };
};
