import React from 'react';

import Text, { FontFamilyVariant, TextType } from '@components/Text';

import './styles.less';

interface IProps {
    title: string;
    description?: string;
    className?: string;
}

const TitleAndDescription = (props: IProps) => {
    const { title, description, className = '' } = props;
    return (
        <div className={`title_desc_container ${className}`}>
            <Text
                fontFamilyVariant={FontFamilyVariant.bold}
                text={title}
                textType={TextType.h4}
            />
            {description && (
                <Text
                    fontFamilyVariant={FontFamilyVariant.regular1}
                    text={description}
                    textType={TextType.h8}
                />
            )}
        </div>
    );
};

export default TitleAndDescription;
