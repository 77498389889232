import { LocationModel } from "@common/domain/models/LocationModel";

const actionTypePrefix = "LOCATION";

export const locationActions = {
  FETCH_REGIONS: `${actionTypePrefix}/FETCH_REGIONS`,
  FETCH_REGIONS_SUCCESS: `${actionTypePrefix}/FETCH_REGIONS_SUCCESS`,
  FETCH_REGIONS_FAIL: `${actionTypePrefix}/FETCH_REGIONS_FAIL`,

  FETCH_COUNTRIES: `${actionTypePrefix}/FETCH_COUNTRIES`,
  FETCH_COUNTRIES_SUCCESS: `${actionTypePrefix}/FETCH_COUNTRIES_SUCCESS`,
  FETCH_COUNTRIES_FAIL: `${actionTypePrefix}/FETCH_COUNTRIES_FAIL`,

  FETCH_STATES: `${actionTypePrefix}/FETCH_STATES`,
  FETCH_STATES_SUCCESS: `${actionTypePrefix}/FETCH_STATES_SUCCESS`,
  FETCH_STATES_FAIL: `${actionTypePrefix}/FETCH_STATES_FAIL`,

  FETCH_CITIES: `${actionTypePrefix}/FETCH_CITIES`,
  FETCH_CITIES_SUCCESS: `${actionTypePrefix}/FETCH_CITIES_SUCCESS`,
  FETCH_CITIES_FAIL: `${actionTypePrefix}/FETCH_CITIES_FAIL`,

  FETCH_LOCATION: `${actionTypePrefix}/FETCH_LOCATION`,
  FETCH_LOCATION_SUCCESS: `${actionTypePrefix}/FETCH_LOCATION_SUCCESS`,
  FETCH_LOCATION_FAIL: `${actionTypePrefix}/FETCH_LOCATION_FAIL`,

  CLEAR_LOCATION_LIST: `${actionTypePrefix}/CLEAR_LOCATION_LIST`,
  CLEAR_COUNTRIES_LIST: `${actionTypePrefix}/CLEAR_COUNTRIES_LIST`
};

export interface IFetchStates {
  type: string;
  payload: {
    countryId?: string
  };
}

export interface IFetchCities {
  type: string;
  payload: {
    stateId?: string
  };
}

export interface IFtechLocationPayload {
  search?: string,
  limit?: number,
  pageNumber?: number,
  city?: string,
  district?: string,
  state?: string,
  country?: string
}
export interface IFetchLocation {
  type: string,
  payload: IFtechLocationPayload
}

export interface IFetchCountryPayload {
  search?: string
}

export interface IFetchCountryAction {
  type: string,
  payload: IFetchCountryPayload
}

export interface ICountryList {
  country: string;
  dialCode: string;
  iso2: string;
}

export const fetchRegions = () => {

  return {
    type: locationActions.FETCH_REGIONS,
  }
}

export const fetchRegionsSuccess = (regions: any[])  => {

  return {
    type: locationActions.FETCH_REGIONS_SUCCESS,
    payload: regions
  }
}

export const fetchRegionsFailure = (error: string) => {

  return {
    type: locationActions.FETCH_REGIONS_FAIL,
    payload: error,
  }
}



export const fetchCountries = (payload:IFetchCountryPayload) : IFetchCountryAction => {

  return {
    type: locationActions.FETCH_COUNTRIES,
    payload
  }
}

export const fetchCountriesSuccess = (countries: ICountryList[])  => {

  return {
    type: locationActions.FETCH_COUNTRIES_SUCCESS,
    payload: countries
  }
}

export const fetchCountriesFailure = (error: string) => {

  return {
    type: locationActions.FETCH_COUNTRIES_FAIL,
    payload: error,
  }
}


export const fetchStates = (countryId: string) : IFetchStates => {

  return {
    type: locationActions.FETCH_STATES,
    payload: {
      countryId
    }
  }
}

export const fetchStatesSuccess = (states: any[])  => {

  return {
    type: locationActions.FETCH_STATES_SUCCESS,
    payload: states
  }
}

export const fetchStatesFailure = (error: string) => {

  return {
    type: locationActions.FETCH_STATES_FAIL,
    payload: error,
  }
}


export const fetchCities = (stateId: string) : IFetchCities => {

  return {
    type: locationActions.FETCH_CITIES,
    payload: {
      stateId
    },
  }
}

export const fetchCitiesSuccess = (cities: any[])  => {

  return {
    type: locationActions.FETCH_CITIES_SUCCESS,
    payload: cities
  }
}

export const fetchCitiesFailure = (error: string) => {

  return {
    type: locationActions.FETCH_CITIES_FAIL,
    payload: error,
  }
}

export const fetchLocation = (payload: IFtechLocationPayload): IFetchLocation => {

  return {
    type: locationActions.FETCH_LOCATION,
    payload
  }
}

export const fetchLocationSuccess = (locationList: LocationModel[],pageNumber?:number,totalCount?:number) => {

  return {
    type: locationActions.FETCH_LOCATION_SUCCESS,
    payload: {
      locationList, pageNumber,totalCount
    }
  }
}

export const fetchLocationFailure = (error: string) => {

  return {
    type: locationActions.FETCH_LOCATION_FAIL,
    payload: error,
  }
}

export const clearLocationList = () =>{
  return{
    type:locationActions.CLEAR_LOCATION_LIST
  }
}

export const clearCountriesList = () => {

  return {
    type: locationActions.CLEAR_COUNTRIES_LIST,
  }
}
