import { JsonProperty } from "json-object-mapper";

export interface ILinkedAccounts {
  zoomSetting?: ILinkedAccountsItem,
  google?: ILinkedAccountsItem
}

export interface ILinkedAccountsItem {
  syncToggle: boolean
}

export interface IAccount {
  meetingSection?: ILinkedAccounts
}

export interface INotificationSetting {
  whatsAppOptIn: {
    general: boolean
  }
}

export interface IUserSettings {
  userId: string,
  account?: IAccount,
  notificationSettings?: INotificationSetting
}



export class SettingsModel {

  @JsonProperty({ name: "userId", required: false })
  public userId: string = "";

  @JsonProperty({ name: "account", required: false })
  public account: IAccount = {};

  @JsonProperty({ name: "createdAt", required: false })
  public createdAt: string = "";

  @JsonProperty({ name: "updatedAt", required: false })
  public updatedAt: string = "";

  @JsonProperty({ name: "id", required: false })
  public id: string = "";

  @JsonProperty({ name: "notificationSettings", required: false })
  public notificationSettings: INotificationSetting = {} as INotificationSetting;

  public getUserId(): string {
    return this.userId;
  }

  public getAccount(): IAccount {
    return this.account;
  }

  public getNotificationSettings(): INotificationSetting {
    return this.notificationSettings;
  }

  public getCreatedAt(): string {
    return this.createdAt
  }

  public getUpdatedAt(): string {
    return this.updatedAt
  }

  public getId(): string {
    return this.id
  }
}
