import { IFluxStandardAction } from "@store/webInterfaces";
import { IEngagementReportPayload } from "./interface";

const actionTypePrefix = "FACULTY_REPORT";

export const facultyReportActions = {
  FETCH_REPORTS: `${actionTypePrefix}/FETCH_REPORTS`,
  FETCH_REPORTS_SUCCESS: `${actionTypePrefix}/FETCH_REPORTS_SUCCESS`,
  FETCH_REPORTS_FAIL: `${actionTypePrefix}/FETCH_REPORTS_FAIL`,

  FETCH_QUIZ_REPORTS: `${actionTypePrefix}/FETCH_QUIZ_REPORTS`,
  FETCH_QUIZ_REPORTS_SUCCESS: `${actionTypePrefix}/FETCH_QUIZ_REPORTS_SUCCESS`,
  FETCH_QUIZ_REPORTS_FAIL: `${actionTypePrefix}/FETCH_QUIZ_REPORTS_FAIL`,

  FETCH_STUDENTS_LIST: `${actionTypePrefix}/FETCH_STUDENTS_LIST`,
  FETCH_STUDENTS_LIST_SUCCESS: `${actionTypePrefix}/FETCH_STUDENTS_LIST_SUCCESS`,
  FETCH_STUDENTS_LIST_FAIL: `${actionTypePrefix}/FETCH_STUDENTS_LIST_FAIL`,

  FETCH_ENGAGEMENT_REPORTS: `${actionTypePrefix}/FETCH_ENGAGEMENT_REPORTS`,
  FETCH_ENGAGEMENT_REPORTS_SUCCESS: `${actionTypePrefix}/FETCH_ENGAGEMENT_REPORTS_SUCCESS`,
  FETCH_ENGAGEMENT_REPORTS_FAIL: `${actionTypePrefix}/FETCH_ENGAGEMENT_REPORTS_FAIL`,

  FETCH_MULTIPLE_BATCHES_REPORT: `${actionTypePrefix}/FETCH_MULTIPLE_BATCHES_REPORT`,
  FETCH_MULTIPLE_BATCHES_REPORT_SUCCESS: `${actionTypePrefix}/FETCH_MULTIPLE_BATCHES_REPORT_SUCCESS`,
  FETCH_MULTIPLE_BATCHES_REPORT_FAIL: `${actionTypePrefix}/FETCH_MULTIPLE_BATCHES_REPORT_FAIL`,
};

export interface IFetchFacultyReportAction {
  type: string;
  payload: {
    batchId: string;
  };
}

export const fetchFacultyReports = (
  batchId: string
): IFetchFacultyReportAction => {
  return {
    type: facultyReportActions.FETCH_REPORTS,
    payload: {
      batchId,
    },
  };
};

export const fetchFacultyReportsSuccess = (data: any[]) => {
  return {
    type: facultyReportActions.FETCH_REPORTS_SUCCESS,
    payload: data,
  };
};

export const fetchFacultyReportsFailure = (error: string) => {
  return {
    type: facultyReportActions.FETCH_REPORTS_FAIL,
    payload: error,
  };
};

export const fetchFacultyQuizReports = (batchId: string) => {
  return {
    type: facultyReportActions.FETCH_QUIZ_REPORTS,
    payload: {
      batchId,
    },
  };
};

export const fetchFacultyQuizReportsSuccess = (data: any[]) => {
  return {
    type: facultyReportActions.FETCH_QUIZ_REPORTS_SUCCESS,
    payload: data,
  };
};

export const fetchFacultyQuizReportsFailure = (error: string) => {
  return {
    type: facultyReportActions.FETCH_QUIZ_REPORTS_FAIL,
    payload: error,
  };
};

export const fetchStudentsList = (id: number): IFluxStandardAction<number> => {
  return {
    type: facultyReportActions.FETCH_STUDENTS_LIST,
    payload: id,
  };
};

// TODO: Have to put the proper type of the data.
export const fetchStudentsListSuccess = (
  data: any[]
): IFluxStandardAction<any> => {
  return {
    type: facultyReportActions.FETCH_STUDENTS_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchStudentsListFailure = (
  error: string
): IFluxStandardAction<any> => {
  return {
    type: facultyReportActions.FETCH_STUDENTS_LIST_FAIL,
    payload: error,
  };
};

export const fetchEngagementReports = (
  id: number
): { type: string; payload: number } => {
  return {
    type: facultyReportActions.FETCH_ENGAGEMENT_REPORTS,
    payload: id,
  };
};

export const fetchEngagementReportsSuccess = (
  data: IEngagementReportPayload[]
): { type: string; payload: IEngagementReportPayload[] } => {
  return {
    type: facultyReportActions.FETCH_ENGAGEMENT_REPORTS_SUCCESS,
    payload: data,
  };
};

export const fetchEngagementReportsFailure = (
  error: string
): { type: string; payload: string } => {
  return {
    type: facultyReportActions.FETCH_ENGAGEMENT_REPORTS_FAIL,
    payload: error,
  };
};

export interface IFetchBatchReportsAction {
  type: string;
  payload: {
    courseId: string;
    batchIds: string[];
  };
}

export const fetchMultipleBatchReports = (
  courseId: string,
  batchIds: string[]
): IFetchBatchReportsAction => {
  return {
    type: facultyReportActions.FETCH_MULTIPLE_BATCHES_REPORT,
    payload: { courseId, batchIds },
  };
};

export const fetchMultipleBatchReportsSuccess = (reportsData: any[]) => {
  return {
    type: facultyReportActions.FETCH_MULTIPLE_BATCHES_REPORT_SUCCESS,
    payload: reportsData,
  };
};

export const fetchMultipleBatchReportsFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: facultyReportActions.FETCH_MULTIPLE_BATCHES_REPORT_FAIL,
    payload: error,
  };
};
