import { JsonProperty } from 'json-object-mapper';

export class BatchDeliveryModel {
    @JsonProperty({name: 'id', required: true})
    private id: number = 0;

    @JsonProperty({name: 'modelName', required: true})
    private modelName = '';

    public getId(): number {
        return this.id;
    }

    public getModelName(): string {
        return this.modelName;
    }
}
