import { StudentCourseActivity } from "@common/domain/models/StudentCourseActivity";
import { StudentQuizReportModel } from "@common/domain/models/StudentQuizReport";

import { reportActions } from "./actions";


export interface IStudentReportState {
  reports: StudentQuizReportModel;
  studentQuizReportsByCourseId: any; // [key: string] : [value: StudentQuizReportModel]

  courseReports: string[];
  courseReportsMap: {};

  byId: {};
  studentCourseActivityReport: StudentCourseActivity | {};
  courseActivityReportByCourseId: any;
  loaders: {
    fetchQuizData: boolean;
    fetchMultipleCourseReports: boolean;
    fetchCourseActivityReport: {};
  };
  errors: {
    fetchQuizData: string;
    fetchMultipleCourseReports: string;
    fetchCourseActivityReport: string;
  };
}

export const initialState = {
  reports: {} as StudentQuizReportModel,
  studentQuizReportsByCourseId: {},

  courseReports: [],
  courseReportsMap: {},

  byId: {},
  studentCourseActivityReport: {},
  courseActivityReportByCourseId: {},
  loaders: {
    fetchQuizData: false,
    fetchMultipleCourseReports: false,
    fetchCourseActivityReport: {},
  },
  errors: {
    fetchQuizData: "",
    fetchMultipleCourseReports: "",
    fetchCourseActivityReport: ""
  },
};

export const studentReportReducer = (
  state: IStudentReportState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case reportActions.FETCH_REPORTS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchQuizData: true },
        errors: { ...state.errors, fetchQuizData: "" },
      };
    case reportActions.FETCH_REPORTS_SUCCESS:
      const quizData = action.payload.quizData;

      updateMapByCourseId(quizData, action.payload.courseId, state.studentQuizReportsByCourseId)
      return {
        ...state,
        reports: quizData,
        loaders: { ...state.loaders, fetchQuizData: false },
      };
    case reportActions.FETCH_REPORTS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchQuizData: false },
        errors: { ...state.errors, fetchQuizData: action.payload },
      };




    case reportActions.FETCH_MULTIPLE_COURSES_REPORT:
      return {
        ...state,
        loaders: { ...state.loaders, fetch: true },
        errors: { ...state.errors, fetchMultipleCourseReports: "" },
      };
    case reportActions.FETCH_MULTIPLE_COURSES_REPORT_SUCCESS:
      const courseReports = action.payload;
      updateCourseReportsMap(courseReports, state.courseReportsMap);
      const courseReportIds = courseReports.map((report) => report.course.courseId);
      return {
        ...state,
        courseReports: courseReportIds,
        loaders: { ...state.loaders, fetchMultipleCourseReports: false },
      };
    case reportActions.FETCH_MULTIPLE_COURSES_REPORT_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchMultipleCourseReports: false },
        errors: { ...state.errors, fetchMultipleCourseReports: action.payload },
      };
    case reportActions.FETCH_COURSE_ACTIVITY_REPORT:
      return {
        ...state,
        loaders: { ...state.loaders, fetchCourseActivityReport: { [action.payload.courseId]: true }},
        errors: { ...state.errors, fetchCourseActivityReport: "" },
      };
    case reportActions.FETCH_COURSE_ACTIVITY_REPORT_SUCCESS:
      const activityReport = action.payload.activityReport;
      updateMapByCourseId(activityReport, action.payload.courseId, state.courseActivityReportByCourseId)
      return {
        ...state,
        studentCourseActivityReport: activityReport,
        loaders: { ...state.loaders, fetchCourseActivityReport: { [action.payload.courseId]: false }},
      };
    case reportActions.FETCH_COURSE_ACTIVITY_REPORT_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchCourseActivityReport: { [action.payload.courseId]: false }},
        errors: { ...state.errors, fetchCourseActivityReport: action.payload },
      };
    default:
      return state;
  }
};

export const updateCourseReportsMap = (newReports: any[], existingMap: any) => {
  newReports.forEach((report) => {
    existingMap[report.course.courseId] = report;
  });
};

export const updateMapByCourseId = (newActivityReport: any, courseId: string, existingMap: any) => {
  existingMap[courseId] = newActivityReport;
}

