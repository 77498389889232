
import Img from '../atoms/Image/Img';
import ProfilePic from './ProfilePic';

export interface Options {
    root?: HTMLElement | null;
    rootMargin?: string;
    threshold?: number;
}

export interface Retry {
    count?: number;
    delay?: number;
    acc?: '+' | '*' | boolean;
}

export {  Img, ProfilePic };
