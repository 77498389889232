import { call, delay, ForkEffect, put, StrictEffect, takeLatest } from "redux-saga/effects";

import { BatchStudent } from "@common/domain/models/BatchStudent";
import { ConnectionRepository } from "@common/domain/repositories/ConnectionRepository";

import { fetchStudentFacultyOnBatchIdFailure, fetchStudentFacultyOnBatchIdSuccess, SearchActions } from "@redux/connections/actions";

import { IFluxStandardAction } from "@store/webInterfaces";
import { IFetchStudentFacultyPayload } from "./interface";

export function* fetchStudentFacultyOnBatchIdSaga(action: IFluxStandardAction<IFetchStudentFacultyPayload>): Generator<StrictEffect, void, BatchStudent[]> {
    // debounce of 0.5 second.
    yield delay(500);
    const { search, batchIds, isVentureAddMember, type } = action.payload;
    try {
        if (isVentureAddMember) {
            const fetchBatchStudentByBatchIdResponse: BatchStudent[] = yield call(ConnectionRepository.fetchBatchStudentByBatchId, { search, batchIds, type });
            yield put(fetchStudentFacultyOnBatchIdSuccess(fetchBatchStudentByBatchIdResponse));
        } else {
            const fetchBatchStudentByBatchIdResponse: BatchStudent[] = yield call(ConnectionRepository.fetchBatchStudentByBatchId, action.payload);
            const fetchBatchFacultiesByBatchIdResponse: BatchStudent[] = yield call(ConnectionRepository.fetchBatchFacultiesByBatchId, action.payload);
            const fetchBatchRMByBatchIdResponse: BatchStudent[] = yield call(ConnectionRepository.fetchBatchRMByBatchId, action.payload);
            yield put(fetchStudentFacultyOnBatchIdSuccess([...fetchBatchStudentByBatchIdResponse, ...fetchBatchFacultiesByBatchIdResponse, ...fetchBatchRMByBatchIdResponse]));
        }
    } catch (e) {
        yield put(fetchStudentFacultyOnBatchIdFailure(e.message));
    }
}

export function* watchConnection(): Generator<ForkEffect> {
    yield takeLatest(SearchActions.FETCH_STUDENT_FACULTY_ON_BATCH_ID, fetchStudentFacultyOnBatchIdSaga);
}


