import { JsonProperty } from "json-object-mapper";

import { IUserSettings, SettingsModel } from "./Settings";

export interface ISubGroupValues {
    name: string;
    key: string;
    description: string;
    type?: string;
}

export interface IConfig {
    name: string,
    key: string,
    values: IValues[]
    sections?:any;
}

export interface IValues {
    name: string;
    key: string;
    description: string;
    type?: string;
    isSubGrouped?: boolean
    subGroupValues?: ISubGroupValues[];
}

export interface ISettingsConfig {
    id: string,
    createdAt: string,
    updatedAt: string,
    config: IConfig[],
}

export interface IUpdateSettingPayload {
    settingsData: IUserSettings,
    isCreateSettingData: boolean
  }

export interface IUpdateSettingsPayload {
    type: string;
    payload: IUpdateSettingPayload;
}


export class SettingsConfigModel {
    @JsonProperty({ name: "name", required: false })
    public name: string = "";

    @JsonProperty({ name: "key", required: false })
    public key: string = "";

    @JsonProperty({ name: "config", required: true })
    public config: IConfig[] = [];

    @JsonProperty({ name: "values", required: false })
    public values: IValues[] = [];

    @JsonProperty({ name: "id", required: true })
    public id: string = "";

    @JsonProperty({ name: "createdAt", required: true })
    public createdAt: string = "";

    @JsonProperty({ name: "updatedAt", required: true })
    public updatedAt: string = "";

    public getName(): string {
        return this.name;
    }

    public getKey(): string {
        return this.key;
    }

    public getConfig(): IConfig[] {
        return this.config;
    }

    public getValues(): IValues[] {
        return this.values;
    }

    public getId(): string {
        return this.id;
    }

}
