import { NotificationModal } from '@common/domain/models/Notification';
import { IState } from '@store/webInterfaces';

export const getNotificationsLoading = (state: IState): boolean => {
    if (state.notification === undefined || state.notification.loaders === undefined
        || state.notification.loaders.getNotifications === undefined) {
        return false;
    }
    return state.notification.loaders.getNotifications;
};

export const getNotificationsCount = (state: IState): number => {
    if (state.notification === undefined) {
        return 0;
    }
    return state.notification.notificationCount;
};

export const getNotificationList = (state: IState): NotificationModal[] => {
    if (state.notification === undefined || state.notification.notifications.length === 0) {
        return [];
    }
    return state.notification.notifications;
};

export const getTotalNotificationCount = (state: IState): number => {
    if (state.notification === undefined || state.notification.totalNotificationCount === 0) {
        return null;
    }
    return state.notification.totalNotificationCount;
};

export const getReadNotificationLoader = (state:IState):boolean => state?.notification?.loaders?.readNotificationById || false;

export const getReadNotificationError = (state:IState):string => state?.notification?.error?.readNotificationById || '';
