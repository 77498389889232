import {
  call,
  ForkEffect,
  put,
  StrictEffect,
  takeLatest,
} from "redux-saga/effects";

import { IFluxStandardAction } from "@store/webInterfaces";
import { FacultyReportRepository } from "../../domain/repositories/FacultyReportRepository";
import {
  facultyReportActions,
  fetchEngagementReportsFailure,
  fetchEngagementReportsSuccess,
  fetchFacultyQuizReportsFailure,
  fetchFacultyQuizReportsSuccess,
  fetchFacultyReportsFailure,
  fetchFacultyReportsSuccess,
  fetchMultipleBatchReportsFailure,
  fetchMultipleBatchReportsSuccess,
  fetchStudentsList,
  fetchStudentsListFailure,
  fetchStudentsListSuccess,
  IFetchBatchReportsAction,
  IFetchFacultyReportAction,
} from "./actions";

export function* fetchReportsSaga(action: IFetchFacultyReportAction): any {
  try {
    const { batchId } = action.payload;
    const states: any[] = yield call(
      FacultyReportRepository.fetchFacultyReports,
      batchId
    );
    yield put(fetchFacultyReportsSuccess(states));
  } catch (e) {
    yield put(fetchFacultyReportsFailure(e.message));
  }
}

export function* fetchQuizReportsSaga(action: IFetchFacultyReportAction): any {
  try {
    const { batchId } = action.payload;
    const states: any[] = yield call(
      FacultyReportRepository.fetchFacultyQuizReports,
      batchId
    );
    yield put(fetchFacultyQuizReportsSuccess(states));
  } catch (e) {
    yield put(fetchFacultyQuizReportsFailure(e.message));
  }
}

export function* fetchStudentsListSaga(
  action: IFluxStandardAction
): Generator<StrictEffect, void, any> {
  try {
    const id = action.payload;
    const studentsReport = yield call(
      FacultyReportRepository.fetchStudentsList,
      id
    );
    yield put(fetchStudentsListSuccess(studentsReport));
  } catch (e) {
    yield put(fetchStudentsListFailure(e.message));
  }
}

export function* fetchEngagementReportsSaga(
  action: IFluxStandardAction
): Generator<StrictEffect, void, any> {
  try {
    const id = action.payload;
    const engagementReport: any[] = yield call(
      FacultyReportRepository.fetchEngagementReport,
      id
    );
    yield put(fetchEngagementReportsSuccess(engagementReport));
  } catch (e) {
    yield put(fetchEngagementReportsFailure(e.message));
  }
}

export function* fetchMultipleBatchReportsSaga(
  action: IFetchBatchReportsAction
) {
  try {
    const { courseId, batchIds } = action.payload;

    const batchReports = yield call(
      FacultyReportRepository.fetchMultipleBatchReports,
      courseId,
      batchIds
    );
    yield put(fetchMultipleBatchReportsSuccess(batchReports));
  } catch (error) {
    yield put(fetchMultipleBatchReportsFailure(error.message));
  }
}

export function* watchFacultyReport(): Generator<ForkEffect> {
  yield takeLatest(facultyReportActions.FETCH_REPORTS, fetchReportsSaga);
  yield takeLatest(
    facultyReportActions.FETCH_QUIZ_REPORTS,
    fetchQuizReportsSaga
  );
  yield takeLatest(
    facultyReportActions.FETCH_STUDENTS_LIST,
    fetchStudentsListSaga
  );
  yield takeLatest(
    facultyReportActions.FETCH_ENGAGEMENT_REPORTS,
    fetchEngagementReportsSaga
  );
  yield takeLatest(
    facultyReportActions.FETCH_MULTIPLE_BATCHES_REPORT,
    fetchMultipleBatchReportsSaga
  );
}
