import { JsonProperty } from "json-object-mapper";

export class BatchStudent {
    @JsonProperty({ name: 'role', required: false })
    public role: string[] = [];

    @JsonProperty({ name: 'deleted', required: false })
    public deleted: boolean = false;

    @JsonProperty({ name: 'email', required: false })
    public email: string = '';

    @JsonProperty({ name: 'firstName', required: false })
    public firstName: string = '';

    @JsonProperty({ name: 'lastName', required: false })
    public lastName: string = '';

    @JsonProperty({ name: 'countryCode', required: false })
    public countryCode: string = '';

    @JsonProperty({ name: 'countryId', required: false })
    public countryId: number = 0;

    @JsonProperty({ name: 'preferredLanguage', required: false })
    public preferredLanguage: string = '';

    @JsonProperty({ name: 'userId', required: false })
    public userId: string = '';

    @JsonProperty({ name: 'lmsUserId', required: false })
    public lmsUserId: string = '';

    @JsonProperty({ name: 'lmsVendor', required: false })
    public lmsVendor: string = '';

    @JsonProperty({ name: 'createdAt', required: false })
    public createdAt: string = '';

    @JsonProperty({ name: 'updatedAt', required: false })
    public updatedAt: string = '';

    @JsonProperty({ name: 'id', required: false })
    public id: string = '';

    @JsonProperty({ name: "emailId", required: false })
    public emailId: string = "";

    @JsonProperty({ name: "phoneNumber", required: false })
    private phoneNumber: string = "";

    @JsonProperty({ name: "status", required: false })
    private status: string = "";

    @JsonProperty({ name: "studentId", required: false })
    public studentId: string = "";

    @JsonProperty({ name: "enrolledAt", required: false })
    private enrolledAt: string | Date = "";

    @JsonProperty({ name: "releasedAt", required: false })
    private releasedAt: string | Date = "";

    @JsonProperty({ name: "courseCompletion", required: false })
    private courseCompletion: ICourseCompletionData = {} as ICourseCompletionData;

    @JsonProperty({ name: "reportData", required: false })
    private reportData: IReportData = {} as IReportData;

    @JsonProperty({ name: "profileImageUuid", required: false })
    private profileImageUuid = "";

    @JsonProperty({ name: "profileImageUrl", required: false })
    private profileImageUrl: string = "";

    public getFirstName = (): string => {
        return this.firstName;
    };

    public getLastName = (): string => {
        return this.lastName;
    };

    public getEmailId = (): string => {
        return this.emailId;
    };

    public getStatus = (): string => {
        return this.status;
    };

    public getStudentId = (): string => {
        return this.studentId;
    };

    public getCourseCompletion = (): ICourseCompletionData => {
        return this.courseCompletion;
    };

    public getReportData = (): IReportData => {
        return this.reportData;
    };


    public getRole(): string {
        return this.role[0];
    }

    public getDeleted(): boolean {
        return this.deleted;
    }

    public getEmail(): string {
        return this.email;
    }

    public getName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public getCountryCode(): string {
        return this.countryCode;
    }

    public getCountryId(): number {
        return this.countryId;
    }

    public getPreferredLanguage(): string {
        return this.preferredLanguage;
    }

    public getUserId(): string {
        return this.userId;
    }

    public getLmsUserId(): string {
        return this.lmsUserId;
    }

    public getLmsVendor(): string {
        return this.lmsVendor;
    }

    public getCreatedAt(): string {
        return this.createdAt;
    }

    public getUpdatedAt(): string {
        return this.updatedAt;
    }

    public getId(): string {
        return this.id;
    }

    public getProfileImageUuid(): string {
        return this.profileImageUuid;
    }

    public getProfilePicUrl(): string {
        return this.profileImageUrl;
    }

}

interface ICourseCompletionData {
    completedLessons: number,
    completionPercentage: number,
    totalLessons: number,
}

interface IReportData {
    averageScore: number,
    evaluated: number,
    submitted: number,
    total: number,
}

export interface IBatchStudent {
    firstName: string,
    lastName: string,
    emailId: string,
    phoneNumber: string,
    lmsUserId: string,
    status: string,
    studentId: string,
    enrolledAt: string | Date,
    releasedAt: string | Date,
    courseCompletion: ICourseCompletionData,
    reportData: IReportData,
}

