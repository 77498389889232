import { MeetingModel } from "@common/domain/models/Meeting";
import { ICreateEvent, ICreateEventAction, ICreateMeeingDraftData, IFetchEvent } from "@common/redux/meeting/interface";

import { IFluxStandardAction } from "@store/webInterfaces";

const actionTypePrefix = "MEETING";

export const meetingActions = {
    CREATE_EVENT: `${actionTypePrefix}/CREATE_EVENT`,
    CREATE_EVENT_SUCCESS: `${actionTypePrefix}/CREATE_EVENT_SUCCESS`,
    CREATE_EVENT_FAIL: `${actionTypePrefix}/CREATE_EVENT_FAIL`,

    FETCH_EVENT_LIST: `${actionTypePrefix}/FETCH_EVENT_LIST`,
    FETCH_EVENT_LIST_SUCCESS: `${actionTypePrefix}/FETCH_EVENT_LIST_SUCCESS`,
    FETCH_EVENT_LIST_FAIL: `${actionTypePrefix}/FETCH_EVENT_LIST_FAIL`,

    FETCH_EVENT_DETAIL: `${actionTypePrefix}/FETCH_EVENT_DETAIL`,
    FETCH_EVENT_DETAIL_SUCCESS: `${actionTypePrefix}/FETCH_EVENT_DETAIL_SUCCESS`,
    FETCH_EVENT_DETAIL_FAIL: `${actionTypePrefix}/FETCH_EVENT_DETAIL_FAIL`,

    DELETE_EVENT: `${actionTypePrefix}/DELETE_EVENT`,
    DELETE_EVENT_SUCCESS: `${actionTypePrefix}/DELETE_EVENT_SUCCESS`,
    DELETE_EVENT_FAIL: `${actionTypePrefix}/DELETE_EVENT_FAIL`,

    CLEAR_EVENT: `${actionTypePrefix}/CLEAR_EVENT`,

    CREATE_MEETING_NOTE: `${actionTypePrefix}/CREATE_MEETING_NOTE`,
    CREATE_MEETING_NOTE_SUCCESS: `${actionTypePrefix}/CREATE_MEETING_NOTE_SUCCESS`,
    CREATE_MEETING_NOTE_FAIL: `${actionTypePrefix}/CREATE_MEETING_NOTE_FAIL`,

    UPDATE_MEETING_DETAIL: `${actionTypePrefix}/UPDATE_MEETING_DETAIL`,
    UPDATE_MEETING_DETAIL_SUCCESS: `${actionTypePrefix}/UPDATE_MEETING_DETAIL_SUCCESS`,
    UPDATE_MEETING_DETAIL_FAIL: `${actionTypePrefix}/UPDATE_MEETING_DETAIL_FAIL`,

    CREATE_MEETING_DRAFT_DATA: `${actionTypePrefix}/CREATE_MEETING_DRAFT_DATA`,

    FETCH_COURSE_MEETING_LIST: `${actionTypePrefix}/FETCH_COURSE_MEETING_LIST`,
    FETCH_COURSE_MEETING_LIST_SUCCESS: `${actionTypePrefix}/FETCH_COURSE_MEETING_LIST_SUCCESS`,
    FETCH_COURSE_MEETING_LIST_FAIL: `${actionTypePrefix}/FETCH_COURSE_MEETING_LIST_FAIL`,

    ATTENDEE_MEETING_ENROLL: `${actionTypePrefix}/ATTENDEE_MEETING_ENROLL`,
    ATTENDEE_MEETING_ENROLL_SUCCESS: `${actionTypePrefix}/ATTENDEE_MEETING_ENROLL_SUCCESS`,
    ATTENDEE_MEETING_ENROLL_FAIL: `${actionTypePrefix}/ATTENDEE_MEETING_ENROLL_FAIL`,

    FETCH_LESSON_MEETING_LIST: `${actionTypePrefix}/FETCH_LESSON_MEETING_LIST`,
    FETCH_LESSON_MEETING_LIST_SUCCESS: `${actionTypePrefix}/FETCH_LESSON_MEETING_LIST_SUCCESS`,
    FETCH_LESSON_MEETING_LIST_FAIL: `${actionTypePrefix}/FETCH_LESSON_MEETING_LIST_FAIL`,

    CLEAR_MEETINGS_FOR_COURSE: `${actionTypePrefix}/CLEAR_MEETINGS_FOR_COURSE`,

    SLOT_BOOKING: `${actionTypePrefix}/SLOT_BOOKING`,
    SLOT_BOOKING_SUCCESS: `${actionTypePrefix}/SLOT_BOOKING_SUCCESS`,
    SLOT_BOOKING_FAIL: `${actionTypePrefix}/SLOT_BOOKING_FAIL`,
}

// create event
export const createEvent = (payload: ICreateEvent, limit?: number): ICreateEventAction => {
    return {
        type: meetingActions.CREATE_EVENT,
        payload,
        limit
    }
}

export const createEventSuccess = (payload: string): IFluxStandardAction<string> => {
    return {
        type: meetingActions.CREATE_EVENT_SUCCESS,
        payload
    }
}

export const createEventFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: meetingActions.CREATE_EVENT_FAIL,
        payload: error
    }
}

// event list
export const fetchEventList = (payload: IFetchEvent): IFluxStandardAction<IFetchEvent> => {
    return {
        type: meetingActions.FETCH_EVENT_LIST,
        payload
    }
}

export const fetchEventListSuccess = (payload: MeetingModel[]): IFluxStandardAction<MeetingModel[]> => {
    return {
        type: meetingActions.FETCH_EVENT_LIST_SUCCESS,
        payload
    }
}

export const fetchEventListFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: meetingActions.FETCH_EVENT_LIST_FAIL,
        payload: error
    }
}

// event detail
export const fetchEventDetail = (id: string): IFluxStandardAction<string> => {
    return {
        type: meetingActions.FETCH_EVENT_DETAIL,
        payload: id
    }
}

export const fetchEventDetailSuccess = (payload: MeetingModel): IFluxStandardAction<MeetingModel> => {
    return {
        type: meetingActions.FETCH_EVENT_DETAIL_SUCCESS,
        payload
    }
}

export const fetchEventDetailFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: meetingActions.FETCH_EVENT_DETAIL_FAIL,
        payload: error
    }
}

// delete event
export const deleteEvent = (id: string): IFluxStandardAction<string> => {
    return {
        type: meetingActions.DELETE_EVENT,
        payload: id
    }
}

export const deleteEventSuccess = (payload: string): IFluxStandardAction<string> => {
    return {
        type: meetingActions.DELETE_EVENT_SUCCESS,
        payload
    }
}

export const deleteEventFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: meetingActions.DELETE_EVENT_FAIL,
        payload: error
    }
}

export const clearEvent = (): IFluxStandardAction => {
    return {
        type: meetingActions.CLEAR_EVENT,
    }
}

export const updateMeetingDetail = (id: string, meeting: ICreateEvent) => ({
    type: meetingActions.UPDATE_MEETING_DETAIL,
    payload:{
        id,
        meeting
    }
});

export const updateMeetingDetailSuccess = (payload : MeetingModel): IFluxStandardAction<MeetingModel> => ({
    type: meetingActions.UPDATE_MEETING_DETAIL_SUCCESS,
    payload
});

export const updateMeetingDetailFail = (error: string): IFluxStandardAction<string> => ({
    type: meetingActions.UPDATE_MEETING_DETAIL_FAIL,
    payload: error
});

export const createMeetingDraftData=(data:ICreateMeeingDraftData)=>{
    return{
        type:meetingActions.CREATE_MEETING_DRAFT_DATA,
        payload:data
    }
}

// course meeting list
export const fetchCourseMeetingList = (payload: IFetchEvent): IFluxStandardAction<IFetchEvent> => {
    return {
        type: meetingActions.FETCH_COURSE_MEETING_LIST,
        payload
    }
}

export const fetchCourseMeetingListSuccess = (payload: MeetingModel[]): IFluxStandardAction<MeetingModel[]> => {
    return {
        type: meetingActions.FETCH_COURSE_MEETING_LIST_SUCCESS,
        payload
    }
}

export const fetchCourseMeetingListFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: meetingActions.FETCH_COURSE_MEETING_LIST_FAIL,
        payload: error
    }
}

// lesson meeting list
export const fetchLessonMeetingList = (payload: IFetchEvent): IFluxStandardAction<IFetchEvent> => {
    return {
        type: meetingActions.FETCH_LESSON_MEETING_LIST,
        payload
    }
}

export const fetchLessonMeetingListSuccess = (payload: MeetingModel[]): IFluxStandardAction<MeetingModel[]> => {
    return {
        type: meetingActions.FETCH_LESSON_MEETING_LIST_SUCCESS,
        payload
    }
}

export const fetchLessonMeetingListFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: meetingActions.FETCH_LESSON_MEETING_LIST_FAIL,
        payload: error
    }
}

// attendee meeting enroll
export const attendeeMeetingEnroll = (payload): IFluxStandardAction<IFetchEvent> => {
    return {
        type: meetingActions.ATTENDEE_MEETING_ENROLL,
        payload
    }
}

export const attendeeMeetingEnrollSuccess = (payload): IFluxStandardAction<MeetingModel> => {
    return {
        type: meetingActions.ATTENDEE_MEETING_ENROLL_SUCCESS,
        payload
    }
}

export const attendeeMeetingEnrollFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: meetingActions.ATTENDEE_MEETING_ENROLL_FAIL,
        payload: error
    }
}

export const clearMeetingsForCourse = (): IFluxStandardAction<IFetchEvent> => {
    return {
        type: meetingActions.CLEAR_MEETINGS_FOR_COURSE
    }
}

export const slotBooking = (payload) => {
    return {
        type: meetingActions.SLOT_BOOKING,
        payload
    }
};

export const slotBookingSuccess = (payload) => {
    return {
        type: meetingActions.SLOT_BOOKING_SUCCESS,
        payload
    }
};

export const slotBookingFail = (error) => {
    return {
        type: meetingActions.SLOT_BOOKING_FAIL,
        payload: error
    }
};