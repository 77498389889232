import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

interface IProps {
  children?: React.ReactNode;
  className?: any;
  code?: boolean;
  copyable?: boolean | { text: string; onCopy?: () => void };
  delete?: boolean;
  disabled?: boolean;
  editable?: boolean;
  ellipsis?: boolean;
  expandable?: boolean;
  suffix?: string;
  level?: number;
  mark?: boolean;
  underline?: boolean;
  onChange?: () => void;
  type?: 'secondary' | 'warning' | 'danger';
  style?: any;
}

export default function(props: IProps) {
  const { children, className, ...rest } = props;
  const style = ['wf_normal_input', className].join(' ');
  return <Text {...rest} className={style}>{children}</Text>;
};
