
import { APIv1, APIv2 } from "@common/network/constants";
import { deserializeJsonObjCollection } from '@utils/ObjectMapperUtil';
import { ProfileModel } from '@models/Profile';
import { IFetchLocation } from "@common/redux/mentor/actions"
import { ApiClient, wfcommonApiClient } from "@network/client";

const ENDPOINTS = {
  fetchMentorList: () => `${APIv1}/profileservice/experts`,
  fetchLocation: () => `${APIv1}/profileservice/userprofiles/locations`,
};

class MentorRepository {
  private apiClient: any;

  constructor(apiClient: any) {
    this.apiClient = apiClient;
  }

  public fetchMentorList = async (payload) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchMentorList(), { ...payload, isCalComRequired: true });
    return { mentors: deserializeJsonObjCollection(response.mentors, ProfileModel, 'fetchMentors') as ProfileModel[], total: response?.total };
  }

  public fetchLocation = async (params: IFetchLocation) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchLocation(), params, true);
    return response.locations
  }

}

const mentorRepository = new MentorRepository(ApiClient);

export { mentorRepository as MentorRepository };
