import {deserializeJsonObj, deserializeJsonObjCollection} from "@utils/ObjectMapperUtil";

import { ApiClient, wfcommonApiClient } from "@common/network/client";
import { APIBaseServices, clearCacheForService } from "@common/network/clientCacheHandler";
import { APIv1 } from "@common/network/constants";
import { IApiClient } from "@common/network/interfaces";
import { ICreateEvent, IFetchEvent } from "@common/redux/meeting/interface";

import { MeetingModel } from "../models/Meeting";

const ENDPOINTS = {
    createEvent: () => `${APIv1}/organizationservice/events`,
    fetchEventList: (userId: string) => `${APIv1}/organizationservice/events/users/${userId}`,
    menteesList: (userId: string) => `${APIv1}/organizationservice/events/${userId}/mentees`,
    event: (id: string) => `${APIv1}/organizationservice/events/${id}`,
    fetchAllMeetingList: () => `${APIv1}/schedulingservice/events`,
    slotBooking: () => `${APIv1}/schedulingservice/events/slot-booking`,
    attendeeMeetingEnroll: (meetingId: string) => `${APIv1}/schedulingservice/events/${meetingId}/attendee/enroll`
}

class MeetingRepository {
  private apiClient: IApiClient;
  private commonApiClient: any;
  constructor(apiClient: IApiClient, commonApiClient: IApiClient) {
    this.apiClient = apiClient;
    this.commonApiClient = commonApiClient;
  }

  public createEvent = async (payload: ICreateEvent): Promise<MeetingModel> => {
    clearCacheForService(APIBaseServices.ORGANISATION_SERVICE);
    const response = await this.apiClient.post(
      ENDPOINTS.createEvent(),
      payload
    );
    return deserializeJsonObj(
      response,
      MeetingModel,
      "createEvent"
    ) as MeetingModel;
  };

  public fetchEventList = async (
    payload: IFetchEvent
  ): Promise<MeetingModel[]> => {
    const { userId, value, key, startDateTime, endDate, limit } = payload;
    const response = await this.apiClient.get(
      ENDPOINTS.fetchEventList(userId),
      { value, key, startDateTime, limit, endDateTime: endDate }
    );
    return deserializeJsonObjCollection(
      response,
      MeetingModel,
      "fetchEventList"
    ) as MeetingModel[];
  };

  public fetchEventDetail = async (payload: string): Promise<MeetingModel> => {
    const response = await this.apiClient.get(ENDPOINTS.event(payload));
    return deserializeJsonObj(
      response,
      MeetingModel,
      "fetchEventDetail"
    ) as MeetingModel;
  };

  public deleteEvent = async (payload: string): Promise<{}> => {
    const response = await this.apiClient.delete(ENDPOINTS.event(payload));
    return response;
  };

  public updateMeetingDetail = async (
    id: string,
    payload: ICreateEvent
  ): Promise<MeetingModel> => {
    const response = await this.apiClient.put(ENDPOINTS.event(id), payload);
    return deserializeJsonObj(
      response,
      MeetingModel,
      "updateMeetingDetail"
    ) as MeetingModel;
  };

  public fetchAllMeetingList = async (payload): Promise<MeetingModel[]> => {
    const response = await this.apiClient.get(
      ENDPOINTS.fetchAllMeetingList(),
      payload
    );
    return deserializeJsonObjCollection(
      response,
      MeetingModel,
      "fetchAllMeetingList"
    ) as MeetingModel[];
  };

  public attendeeMeetingEnroll = async (payload): Promise<MeetingModel> => {
    const { eventId, userId, email } = payload;
    const response = await this.apiClient.patch(
      ENDPOINTS.attendeeMeetingEnroll(eventId),
      { userId, email }
    );
    return deserializeJsonObj(
      response,
      MeetingModel,
      "attendeeMeetingEnroll"
    ) as MeetingModel;
  };

  public slotBooking = async (payload) => {
    const response = await this.apiClient.post(
      ENDPOINTS.slotBooking(),
      payload
    );
    return response;
  };

  public fetchMenteesList = async (userId: string) => {
    const response = await this.apiClient.get(ENDPOINTS.menteesList(userId));
    return response.data;
  };
}

const meetingRepository = new MeetingRepository(ApiClient, wfcommonApiClient);

export { meetingRepository as MeetingRepository };


