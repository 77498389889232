import { AllowPost, DayType } from '@constants/config';
import { MessageModel } from '@models/Message';
import { ProfileModel } from "@models/Profile";
import { DataUtility } from '@utils/DataUtility';
import { DateHelper, DateTimeFormat } from '@utils/DateHelper';
import { JsonProperty } from 'json-object-mapper';

export interface IConversation {
  id: string;
  profileIds: string[];
  createdBy: string;
  lastMessage: MessageModel;
  messages?: MessageModel[];
  isActive?: boolean;
  metadata: MetaData;
}

export class MetaData {
    @JsonProperty({name: 'name', required: false})
    public name: string = '';

    @JsonProperty({name: 'description', required: false})
    private description: string = '';
}

export class ConversationModel {

  @JsonProperty({name: 'id', required: true})
  private id: string = '';

  @JsonProperty({name: 'profileIds', required: true})
  private profileIds: string[] = [];

  @JsonProperty({name: 'createdBy', required: true})
  private createdBy: string = '';

  @JsonProperty({name: 'lastMessage', required: false})
  private lastMessage: MessageModel | undefined = undefined;

  @JsonProperty({name: 'isActive', required: false})
  public isActive: boolean = false;

  @JsonProperty({name: 'lastReadInfo', required: false})
  private lastReadInfo: any = {};

  @JsonProperty({name: 'profile', required: false})
  private profile?: ProfileModel = undefined;

  @JsonProperty({name: 'metadata', required: false})
  private metadata?: MetaData = undefined;

  @JsonProperty({name: 'allowPost', required: false})
  private allowPost?: AllowPost = AllowPost.ALL;

  public getId = (): string => {
    if ( this.id ) {
      return this.id;
    }
    return '';
  }

  public getAllowPost = (): string => {
    if ( this.allowPost ) {
      return this.allowPost;
    }
    return '';
  }

  public getCreatedBy = (): string => {
    if (this.createdBy) {
      return this.createdBy;
    }
    return '';
  }

    public getProfile = (): ProfileModel | undefined => {
    return this.profile || undefined;
  }

  public setProfile = (profile: ProfileModel) => {
    this.profile = profile;
  }

   public getDateTime = (): string => {
    if ( this.lastMessage ) {
      // if no createdAt then set Today's date
      const createdAt = this.lastMessage.getCreatedAt() || new Date();
      if ( DateHelper.isToday(createdAt) === DayType.TODAY ) {
        return DateHelper.getHourFromTimeStamp(createdAt, true);
      } else {
        return DateHelper.getDateTime(createdAt, DateTimeFormat.D_M_YYYY);
      }
        return DateHelper.getDateTime(createdAt, DateTimeFormat.D_M_YYYY);

    }
    return '';
  }

  public getLastMessage = (): MessageModel | undefined => {
    return this.lastMessage;
  }

  public setLastMessage = (lastMessage: MessageModel) => {
    this.lastMessage = lastMessage;
  }

  public getProfileIds = (): string[] => {
    return this.profileIds || [];
  }

  public setProfileIds = (profileIds: string[]) => {
    this.profileIds = profileIds;
  }

  public getLastReadInfo = (): any => {
    return this.lastReadInfo;
  }

  public isUnread = (userProfileId: string): boolean => {
    const lastMessage = this.lastMessage;
    if ( !lastMessage ) {
      // if no last message consider as read
      return false;
    }
    const createdBy = lastMessage.getCreatedBy();
    // if last message is sent by user itself then dont set unread.
    if ( createdBy === userProfileId ) {
      return false;
    } else if ( !DataUtility.isEmpty(this.lastReadInfo) ) {
      const lastReadTime = this.lastReadInfo[userProfileId];
      const lastMessageDate = lastMessage.getCreatedAt();
      if ( lastReadTime && lastMessageDate ) {
        // if lastReadTime is less than lastMessage created time then set unread as true
        return DateHelper.isBeforeDate(lastReadTime, DateHelper.convertDateTimeToString(lastMessageDate));
      }
    }
    // if lastReadInfo not exists and other cases if any
    return true;
  }

  public setLastReadInfo = (lastReadInfo: any): any => {
    return this.lastReadInfo = lastReadInfo;
  }

  public setMetadata = (metadata: MetaData) => {
      this.metadata = metadata;
  }

  public getMetadata = (): MetaData => {
      if (this.metadata){
          return this.metadata;
      }
}
}
