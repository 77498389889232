import {
  deserializeJsonObjCollection,
  deserializeJsonObj,
} from "@utils/ObjectMapperUtil";

import { ApiClient, wfcommonApiClient } from "@common/network/client";
import { APIv1 } from "@common/network/constants";
import { IApiClient } from "@common/network/interfaces";
import { PrepareModel } from "../models/Prepare";
import { InterviewEvalModel } from "../models/InterviewEvalModel";
import { AzureTokenModel } from "../models/AzureTokenModel";
import { IJobRolePayload, IAllJobRolePayload } from '@common/redux/interview/interface';
import { customText } from "@presentation/Interview/config";

const ENDPOINTS = {
  fetchJobRoleList: () =>
    `${APIv1}/transformerservice/roles`,
  submitEvaluation: () => `${APIv1}/transformerservice/ai/evaluation`,
  fetchEvaluationHistory: (userId: string) =>
    `${APIv1}/transformerservice/ai/evaluation?userId=${userId}`,
  fetchAzureToken: () => `${APIv1}/transformerservice/speech/token`,
  deleteEvaluation: (id) => `${APIv1}/transformerservice/ai/evaluation/${id}`,
  validateJobRole: (payload) => `${APIv1}/transformerservice/roles/${payload.jobRole}/validate`,
  fetchJobRoleListFromTags: () =>
    `${APIv1}/tagsservice/tags?products=WO&scopes=interview-prep`
};

class InterviewRepository {
  private apiClient: any;
  private wfcommonApiClient: any;

  constructor(apiClient: any,wfcommonApiClient:any) {
    this.apiClient = apiClient;
    this.wfcommonApiClient = wfcommonApiClient;
  }


  public fetchJobRoleList = async (payload): Promise<String[]> => {
    const response = await this.apiClient.get(
      ENDPOINTS.fetchJobRoleList(), payload
    );
    return response;
  };

  public fetchJobRoleListFromTags = async (payload): Promise<PrepareModel[]> => {
    const response = await this.wfcommonApiClient.get(
      ENDPOINTS.fetchJobRoleListFromTags(), { keys: customText.jobRoles, languageCodes: payload.language }
    );
    return deserializeJsonObj(
      response,
      PrepareModel,
      "fetchJobRoleList"
    ) as PrepareModel[];
  };

  public validateJobRole = async (payload): Promise<String> => {
    const response = await this.apiClient.get(
      ENDPOINTS.validateJobRole(payload), { type: payload.type, language: payload.selectedLanguage, country: payload.selectedCountry, useCase: payload?.useCase }
    );
    return response;
  };

  public submitEvaluation = async (
    payload: any
  ): Promise<InterviewEvalModel> => {
    const response = await this.apiClient.post(
      ENDPOINTS.submitEvaluation(),
      payload
    );
    const evalModel = deserializeJsonObj(
      response,
      InterviewEvalModel,
      "submitEvaluation"
    ) as InterviewEvalModel;
    return evalModel;
  };

  public fetchEvaluationHistory = async (
    userId: string
  ): Promise<InterviewEvalModel[]> => {
    const response = await this.apiClient.get(
      ENDPOINTS.fetchEvaluationHistory(userId)
    );
    const evalHistory = deserializeJsonObjCollection(
      response.attempts,
      InterviewEvalModel,
      "fetchEvaluationHistory"
    ) as InterviewEvalModel[];
    return evalHistory;
  };

  public fetchAzureToken = async (): Promise<AzureTokenModel> => {
    const response = await this.apiClient.get(
      ENDPOINTS.fetchAzureToken()
    );
    const azureToken = deserializeJsonObj(
      response,
      AzureTokenModel,
      "fetchAzureToken"
    ) as AzureTokenModel;
    return azureToken;
  };

  public deleteEvaluation = async (id: string) => {
    const response = await this.apiClient.delete(
      ENDPOINTS.deleteEvaluation(id)
    );
    return response;
  };
}

const prepareRepository = new InterviewRepository(ApiClient,wfcommonApiClient);

export { prepareRepository as InterviewRepository };
