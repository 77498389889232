import { ObjectMapper } from 'json-object-mapper';
import { IApiClient } from '@network/interfaces';
import { ApiClient } from '@network/client';
import { NotificationModal, INotificationAlertsCount } from '@models/Notification';
import { INotificationRepository } from './interfaces';
import { APIv1 } from '@network/constants';
import { Logger } from '@utils/Logger';

const ENDPOINTS = {
    getNotifications: (userId: number, page: number, limit: number) =>
        `${APIv1}/users/${userId}/notifications?page=${page}&limit=${limit}`,
    getNotificationsCount: (userId: number) => `${APIv1}/users/${userId}/notifications/count`,
    seenAllnotification: (userId: number) => `${APIv1}/users/${userId}/notifications/seen`,
    readNotificationById: (notifId: string) => `${APIv1}/notifications/${notifId}/read`,
    getNotificationsAlertCount: (userId: number) => `${APIv1}/users/${userId}/notificationAlertCounts`,
    seenNotificationsAlertByCategory: (userId: number, category: string) => `${APIv1}/users/${userId}/notificationAlertCounts/seen/${category}`,
};

export default class NotificationRepository implements INotificationRepository {
    private apiClient: IApiClient;

    constructor(apiClient: IApiClient) {
        this.apiClient = apiClient;
    }

    public getNotifications = async (userId: number, page: number, limit: number):
        Promise<any> => {
        const response = await this.apiClient.get(ENDPOINTS.getNotifications(userId, page, limit));
        const responseArray = response.notifications.map((notification: NotificationModal) => {
            try {
                const inapp_notif = ObjectMapper.deserialize(NotificationModal, notification);
                if (inapp_notif.isValid()) {
                    return inapp_notif;
                }
            } catch (e) {
                Logger.warn('error message post notification deserialize', { error: e.message });
            }
        }).filter((item: NotificationModal) => item !== undefined);
        const finalData = {
            notificationData: responseArray,
            totalCount: response.total
        }
        return finalData
    };

    public getNotificationsCount = async (userId: number): Promise<number> => {
        const response = await this.apiClient.get(ENDPOINTS.getNotificationsCount(userId));
        return response.notificationCount;
    };

    public readNotificationById = async (notifId: string): Promise<any> => {
        const response = await this.apiClient.put(ENDPOINTS.readNotificationById(notifId));
        return response;
    };

    public seenAllNotifications = async (userId: number): Promise<any> => {
        const response = await this.apiClient.put(ENDPOINTS.seenAllnotification(userId));
        return response;
    };

    public getNotificationsAlertCount = async (userId: number): Promise<INotificationAlertsCount> => {
        const response = await this.apiClient.get(ENDPOINTS.getNotificationsAlertCount(userId));
        return response;
    };

    public seenNotificationsAlertByCategory = async (userId: number, category: string): Promise<any> => {
        const response = await this.apiClient.put(ENDPOINTS.seenNotificationsAlertByCategory(userId, category));
        return response;
    };

}

const notificationRepository = new NotificationRepository(ApiClient);

export { notificationRepository as NotificationRepository };
