import { JsonProperty } from 'json-object-mapper';

interface ICourseRoot {
    competency: string;
    weightage: string;
    courseRootId: string;
}
export class ProgramModel {
    @JsonProperty({name: 'countries', required: false})
    private countries: string[] = [];

    @JsonProperty({name: 'createdAt', required: false})
    private createdAt: string = "";

    @JsonProperty({name: 'deleted', required: false})
    private deleted: boolean = false;

    @JsonProperty({name: 'description', required: false})
    private description: string = "";

    @JsonProperty({name: '_id', required: false })
    private _id: string = "";

    @JsonProperty({name: 'id', required: false })
    private id: string = "";

    @JsonProperty({name: 'imageURL', required: false})
    private image: string = "";

    @JsonProperty({name: 'languageCode', required: false})
    private languageCode: string = "";

    @JsonProperty({name: 'title', required: false})
    private title: string = "";

    @JsonProperty({name: 'servingMode', required: false})
    private servingMode: string[] = [];

    @JsonProperty({name: 'status', required: true})
    private status: string = "";

    @JsonProperty({name: 'tags', required: false})
    private tags: any = {};

    @JsonProperty({name: 'updatedAt', required: false})
    private updatedAt: string = "";

    @JsonProperty({name: 'version', required: false})
    private version: string = "";

    @JsonProperty({name: 'metadata', required: false})
    private metadata: {} = null;

    @JsonProperty( { name: 'duration', required: false})
    private duration: string = "";

    @JsonProperty( { name: 'key', required: false})
    public key: string = "";

    @JsonProperty( { name: 'certifiedOn', required: false})
    public certifiedOn: string = "";

    @JsonProperty( { name: 'validTill', required: false})
    public validTill: string = "";

    @JsonProperty({ name: 'certificateTemplate', required: false })
    private certificateTemplate: string = "";

    @JsonProperty({ name: 'certificateName', required: false })
    private certificateName: string = "";

    @JsonProperty({ name: 'certificateThreshold', required: false })
    private certificateThreshold: string = "";

    @JsonProperty({name:"collectionRootId", required:false})
    private collectionRootId = "";

    @JsonProperty({name:"isAssessmentBasedSkillScoreCard", required:false})
    private isAssessmentBasedSkillScoreCard = false;

    @JsonProperty({name:"courseRoots", required:false})
    private courseRoots: any = null;

    @JsonProperty({name:"isUserEnrolled", required:false})
    public isUserEnrolled: boolean = false;

    @JsonProperty({name:"userProgramResponse", required:false})
    public userProgramResponse: any = null;

    @JsonProperty({ name: 'userStatus', required: false })
    public userStatus: string = '';

    public getCourseRoots() {
        return this.courseRoots;
    }

    public setIsUserEnrolled(isUserEnrolled) {
        this.isUserEnrolled = isUserEnrolled;
    }

    public setUserProgramResponse(userProgramResponse) {
        this.userProgramResponse = userProgramResponse;
    }

    public getUserProgramResponse() {
        return this.userProgramResponse;
    }

    public getIsUserEnrolled() {
        return this.isUserEnrolled;
    }


    public setCourseRoots(courseRootResponse) {
        return this.courseRoots = courseRootResponse;
    }

    public getCollectionRootId() {
        return this.collectionRootId;
    }

    public getCertificateTemplate() {
        return this.certificateTemplate;
    }

    public getCertificateName() {
        return this.certificateName;
    }

    public getCertificateThreshold() {
        return Number(this.certificateThreshold);
    }

    public getDuration(): string {
        return this.duration;
    }

    public setKey(_id: string) {
        this.key = _id;
    }

    public setCertifiedOn(date: string) {
        this.certifiedOn = date;
    }

    public getCertifiedOn() {
        return this.certifiedOn;
    }

    public getValidTill() {
        return this.validTill;
    }

    public setValidTill(date: string) {
        this.validTill = date;
    }

    public getMetadata(): {} {
        return this.metadata;
    }

    public getCountries(): string[] {
        return this.countries;
    }

    public getCreatedAt(): string {
        return this.createdAt;
    }

    public getDeleted(): boolean {
        return this.deleted;
    }

    public getDescription(): string {
        return this.description;
    }

    public getId(): string {
        this.setKey(this._id);
        return this._id;
    }

    public getImage(): string {
        return this.image;
    }

    public getLanguageCode(): string {
        return this.languageCode;
    }

    public getTitle(): string {
        return this.title;
    }

    public getServingMode(): string[] {
        return this.servingMode;
    }

    public getStatus(): string {
        return this.status;
    }

    public getTags(): any {
        return this.tags;
    }

    public getUpdatedAt(): string {
        return this.updatedAt;
    }

    public getVersion(): string {
        return this.version;
    } 
    
    public getIsAssessmentBasedSkillScoreCard(): boolean {
        return this.isAssessmentBasedSkillScoreCard
    }

    public getUserStatus(): string {
        return this.userStatus;
    }

    public getInstituteProgramId(): string {
        return this.id;
    }
}


