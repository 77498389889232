import { deserializeJsonObjCollection } from "@utils/ObjectMapperUtil";

import { APIv1 } from "@common/network/constants";
import { IFetchCommentsActionPayload } from "@common/redux/comments/actions";

import { CommentEntitiesKeys, CommentEntitiesKeysService, CommentModel, IComment } from "@models/Comment";

import { ApiClient } from "@network/client";

const ENDPOINTS = {
  fetchComments: (apiService: string, entityKey: CommentEntitiesKeys, entityValue: string) => `${APIv1}/${apiService}/${entityKey}/${entityValue}/comments`,
  createComment: (apiService: string, entityKey: CommentEntitiesKeys, entityValue: string) => `${APIv1}/${apiService}/${entityKey}/${entityValue}/comments`,
  deleteComment: (apiService: string, entityKey: CommentEntitiesKeys, entityValue: string, id: string,) => `${APIv1}/${apiService}/${entityKey}/${entityValue}/comments/${id}`,
};

class CommentsRepository {
  private apiClient: any;

  constructor(apiClient: any) {
    this.apiClient = apiClient;
  }

  public fetchComments = async (paylaod: IFetchCommentsActionPayload): Promise<{ comments: CommentModel[]; total: number; }> => {

    const {
      entityKey,
      entityValue
    } = paylaod;
    const response = await this.apiClient.get(ENDPOINTS.fetchComments(CommentEntitiesKeysService[entityKey], entityKey, entityValue), paylaod);
    const comments = deserializeJsonObjCollection(
      response.comments,
      CommentModel,
      "fetchComments"
    ) as CommentModel[];

    return {
      comments,
      total: response.totalCount
    };
  };


  public createComment = async (entityKey: CommentEntitiesKeys, entityValue: string, comment: IComment): Promise<CommentModel[]> => {

    const response = await this.apiClient.post(ENDPOINTS.createComment(CommentEntitiesKeysService[entityKey], entityKey, entityValue), comment);
    return deserializeJsonObjCollection(
      [response],
      CommentModel,
      "createComment"
    ) as CommentModel[];
  };


  public deleteComment = async (entityKey: CommentEntitiesKeys, entityValue: string, commentId: string,) => {

    const response = await this.apiClient.delete(ENDPOINTS.deleteComment(CommentEntitiesKeysService[entityKey], entityKey, entityValue, commentId), {});

    return response;
  };
}

const commentsRepository = new CommentsRepository(ApiClient);

export { commentsRepository as CommentsRepository };
