import { call, put, takeEvery, } from 'redux-saga/effects';
import { LocationRepository } from '@repositories/LocationRepository';
import {
  fetchCitiesFailure, fetchCitiesSuccess, fetchCountriesFailure, fetchCountriesSuccess,
  fetchLocationFailure, fetchLocationSuccess, fetchRegionsFailure, fetchRegionsSuccess,
  fetchStatesFailure, fetchStatesSuccess, IFetchCities, IFetchCountryAction, IFetchLocation, IFetchStates,
  locationActions
} from './actions';
import { LocationModel } from '@common/domain/models/LocationModel';


export function* fetchRegionsSaga() : any {
  try {
    const regions: any[] = yield call(LocationRepository.fetchRegions);
    yield put(fetchRegionsSuccess(regions));
    
  } catch (e) {
    yield put(fetchRegionsFailure(e.message));
  }
}

export function* fetchCountriesSaga(action: IFetchCountryAction) : any {
  try {
    const countries: any[] = yield call(LocationRepository.fetchCountries, action.payload);
    yield put(fetchCountriesSuccess(countries));
    
  } catch (e) {
    yield put(fetchCountriesFailure(e.message));
  }
}


export function* fetchStatesSaga(action: IFetchStates) : any {
  try {
    const {
      countryId
    } = action.payload
    const states: any[] = yield call(LocationRepository.fetchStates, countryId);
    yield put(fetchStatesSuccess(states));

  } catch (e) {
    yield put(fetchStatesFailure(e.message));
  }
}


export function* fetchCitiesSaga(action: IFetchCities) : any {
  try {
    const {
      stateId
    } = action.payload;
    const cities: any[] = yield call(LocationRepository.fetchCities, stateId);
    yield put(fetchCitiesSuccess(cities));
    
  } catch (e) {
    yield put(fetchCitiesFailure(e.message));
  }
}

export function* fetchLocationSaga(action: IFetchLocation): any {
  try {
    const location: { locationList: LocationModel[], total: number } = yield call(LocationRepository.fetchLocation, action.payload);
    yield put(fetchLocationSuccess(location.locationList, action.payload.pageNumber, location.total));
  } catch (e) {
    yield put(fetchLocationFailure(e.message));
  }
}



export function* watchLocations(): any {
  yield takeEvery(locationActions.FETCH_REGIONS, fetchRegionsSaga);
  yield takeEvery(locationActions.FETCH_COUNTRIES, fetchCountriesSaga);
  yield takeEvery(locationActions.FETCH_STATES, fetchStatesSaga);
  yield takeEvery(locationActions.FETCH_CITIES, fetchCitiesSaga);
  yield takeEvery(locationActions.FETCH_LOCATION, fetchLocationSaga)
}
