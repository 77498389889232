import { JsonProperty } from "json-object-mapper";

interface IOverallRating {
  feedback: string;
  rating: number;
}

interface ICategoryFeedback {
  category_name: string;
  feedback: {
    rating: number;
    Strengths: string;
    Weaknesses: string;
    Recommendations: string;
  };
}


export class InterviewEvalModel {
  @JsonProperty({ name: 'id', required: true })
  private id: string = "";

  @JsonProperty({ name: 'jobRole', required: true })
  private jobRole: string = "";

  @JsonProperty({ name: 'userId', required: true })
  private userId: string = "";

  @JsonProperty({ name: 'question', required: true })
  private question: string = "";

  @JsonProperty({ name: 'userTextResponse', required: true })
  private userTextResponse: string = "";

  @JsonProperty({ name: 'createdAt', required: true })
  private createdAt: string = "";

  @JsonProperty({ name: 'updatedAt', required: false })
  private updatedAt: string = "";

  @JsonProperty({ name: 'evaluationResponse', required: true })
  private evaluationResponse: string = null;

  @JsonProperty({ name: 'language', required: false })
  private language: string = "";

  public getId = (): string | undefined => {
    return !!this.id ? this.id : undefined;
  };

  public getJobRole = (): string | undefined => {
    return !!this.jobRole ? this.jobRole : "";
  };

  public getUserId = (): string | undefined => {
    return !!this.userId ? this.userId : undefined;
  };

  public getQuestion = (): string | undefined => {
    return !!this.question ? this.question : undefined;
  };

  public getUserTextResponse = (): string | undefined => {
    return !!this.userTextResponse ? this.userTextResponse : undefined;
  };

  public getCreatedAt = (): string | undefined => {
    return !!this.createdAt ? this.createdAt : undefined;
  };

  public getUpdatedAt = (): string | undefined => {
    return !!this.updatedAt ? this.updatedAt : undefined;
  };

  public getEvaluationResponse = (): string  => {
    return this.evaluationResponse;
  };

  public getLanguage = (): string  => {
    return this.language;
  };

}

