import { NotificationModal } from '@models/Notification';

const notificationPrefix = 'Notification/';

export const NotificationActions = {

    FETCH_NOTIFICATION: `${notificationPrefix}FETCH_NOTIFICATION`,
    FETCH_NOTIFICATION_SUCCESS: `${notificationPrefix}FETCH_NOTIFICATION_SUCCESS`,
    FETCH_NOTIFICATION_FAILURE: `${notificationPrefix}FETCH_NOTIFICATION_FAILURE`,

    GET_NOTIFICATION_COUNT: `${notificationPrefix}GET_NOTIFICATION_COUNT`,
    GET_NOTIFICATION_COUNT_SUCCESS: `${notificationPrefix}GET_NOTIFICATION_COUNT_SUCCESS`,
    GET_NOTIFICATION_COUNT_FAILURE: `${notificationPrefix}GET_NOTIFICATION_COUNT_FAILURE`,

    SEEN_NOTIFICATION_ALL: `${notificationPrefix}SEEN_NOTIFICATION_ALL`,
    SEEN_NOTIFICATION_ALL_SUCCESS: `${notificationPrefix}SEEN_NOTIFICATION_ALL_SUCCESS`,
    SEEN_NOTIFICATION_ALL_FAILURE: `${notificationPrefix}SEEN_NOTIFICATION_ALL_FAILURE`,

    READ_NOTIFICATION_BY_ID: `${notificationPrefix}READ_NOTIFICATION_BY_ID`,
    READ_NOTIFICATION_BY_ID_SUCCESS: `${notificationPrefix}READ_NOTIFICATION_BY_ID_SUCCESS`,
    READ_NOTIFICATION_BY_ID_FAILURE: `${notificationPrefix}READ_NOTIFICATION_BY_ID_FAILURE`,

    SET_TOTAL_NOTIFICATION_COUNT: `${notificationPrefix}SET_TOTAL_NOTIFICATION_COUNT`

};

export const getNotifications = (userId: string, page: number, limit: number): { type: string, payload: { userId: string, page: number, limit: number } } => ({
    type: NotificationActions.FETCH_NOTIFICATION,
    payload: { userId, page, limit },
});

export const getNotificationsSuccess = (page: number, limit: number, notifications: NotificationModal[]): { type: string, payload: { page: number, limit: number, notifications: NotificationModal[] } } => ({
    type: NotificationActions.FETCH_NOTIFICATION_SUCCESS,
    payload: { page, limit, notifications },
});

export const getNotificationsFailure = (error: string): { type: string, payload: string } => ({
    type: NotificationActions.FETCH_NOTIFICATION_FAILURE,
    payload: error,
});

export const getNotificationsCount = (userId: string): { type: string, payload: { userId: string } } => ({
    type: NotificationActions.GET_NOTIFICATION_COUNT,
    payload: { userId },
});

export const getNotificationsCountSuccess = (notificationCount: number): { type: string, payload: number } => ({
    type: NotificationActions.GET_NOTIFICATION_COUNT_SUCCESS,
    payload: notificationCount,
});

export const getNotificationsCountFailure = (error: string): { type: string, payload: string } => ({
    type: NotificationActions.GET_NOTIFICATION_COUNT_FAILURE,
    payload: error,
});

export const setAllNotificationSeen = (userId: string): { type: string, payload: { userId: string } } => ({
    type: NotificationActions.SEEN_NOTIFICATION_ALL,
    payload: { userId },
});

export const setAllNotificationSeenSuccess = (retval: boolean): { type: string, payload: boolean } => ({
    type: NotificationActions.SEEN_NOTIFICATION_ALL_SUCCESS,
    payload: retval,
});

export const setAllNotificationSeenFailure = (error: string): { type: string, payload: string } => ({
    type: NotificationActions.SEEN_NOTIFICATION_ALL_FAILURE,
    payload: error,
});

export const readNotificationById = (notifId: string): { type: string, payload: { notifId: string } } => ({
    type: NotificationActions.READ_NOTIFICATION_BY_ID,
    payload: { notifId },
});

export const readNotificationByIdSuccess = (notifId: string): { type: string, payload: string } => ({
    type: NotificationActions.READ_NOTIFICATION_BY_ID_SUCCESS,
    payload: notifId,
});

export const readNotificationByIdFailure = (error: string): { type: string, payload: string } => ({
    type: NotificationActions.READ_NOTIFICATION_BY_ID_FAILURE,
    payload: error,
});

export const setTotalNotificationCount = (count: number): { type: string, payload: number } => ({
    type: NotificationActions.SET_TOTAL_NOTIFICATION_COUNT,
    payload: count,
});
