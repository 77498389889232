import {
  deserializeJsonObj, deserializeJsonObjCollection,
} from "@utils/ObjectMapperUtil";

import { APIv1 } from "@common/network/constants";

import { StudentCourseActivity } from "@models/StudentCourseActivity";
import { StudentQuizReportModel } from "@models/StudentQuizReport";
import { ApiClient } from "@common/network/client";

const ENDPOINTS = {
  fetchReports: (userId: string) =>
    `${APIv1}/courseservice/reports/quiz/student/${userId}`,
  fetchMultipleCourseDetails: (userId: string) => `${APIv1}/organizationservice/reports/course/${userId}`,
  fetchCourseActivityReport: (courseId: string, studentId: string) => `${APIv1}/organizationservice/reports/activities/course/${courseId}/student/${studentId}`
};

class ReportRepository {
  private apiClient: any;

  constructor(apiClient: any) {
    this.apiClient = apiClient;
  }

  public fetchReports = async (userId: string, courseId: string) => {
    const requestPayload = {
      courseId,
    };
    const response = await this.apiClient.get(
      ENDPOINTS.fetchReports(userId),
      requestPayload,
      true
    );
    const reports = deserializeJsonObj(
      response,
      StudentQuizReportModel,
      "fetchReports"
    );
    return reports;
  };

  public fetchMultipleCourseReports = async (
    userId: string,
    courseIds: string[]
  ) => {
    const requestPayload = {
      courseIds,
    };

    const response = await this.apiClient.get(
      ENDPOINTS.fetchMultipleCourseDetails(userId),
      requestPayload
    );
    return response;
  };

  public fetchCourseActivityReport = async (
    courseId: string,
    studentId: string
  ) => {
    const response = await this.apiClient.get(
      ENDPOINTS.fetchCourseActivityReport(courseId, studentId)
    );

    const studentCourseActivityReport = deserializeJsonObj(
      response,
      StudentCourseActivity,
      "fetchCourseActivityReport"
    );
    return studentCourseActivityReport;
  };
}

const reportRepository = new ReportRepository(ApiClient);

export { reportRepository as ReportRepository };
