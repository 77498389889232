import { JsonProperty } from "json-object-mapper";

export interface ICourseQuiz {
  id: string;
  name: string;
}

export interface IQuizData {
  quizName: string;
  quizId: number;
  courseId: number;
  status: string;
  attemptStartDate: string;
  attemptFinishDate: string;
  maxScore: number;
  userScore: number;
  percentageScore: string;
  batchAverage: string;
}

export class StudentQuizReportModel {
  @JsonProperty({ name: "courseQuizzes", required: true })
  private courseQuizzes: ICourseQuiz[] = [];

  @JsonProperty({ name: "quizData", required: true })
  private quizData: IQuizData[] = [];

  public getCourseQuizzes(): ICourseQuiz[] {
    return this.courseQuizzes;
  }

  public getQuizData(): IQuizData[] {
    return this.quizData;
  }
}
