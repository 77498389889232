import React from 'react';
import Spin from 'antd/lib/spin';

interface IProps {
    prefixCls?: string;
    className?: string;
    spinning?: boolean;
    style?: any;
    showFullPage?: boolean;
    size?: any;
    tip?: string;
    delay?: number;
    wrapperClassName?: string;
    indicator?: any;
}

export default function Spinner(props: IProps) {
    const { prefixCls, className, spinning, style, showFullPage, size, tip, delay, wrapperClassName, indicator } = props;
    return showFullPage ? (
        <div className="wf_spinner">
            <Spin
                className={className}
                indicator={indicator}
                prefixCls={prefixCls}
                size={size || 'large'}
                spinning={spinning}
                style={style}
                tip={tip}
                wrapperClassName={wrapperClassName}
            />
        </div>
    )
        : (
            <Spin
                className={className}
                delay={delay}
                indicator={indicator}
                prefixCls={prefixCls}
                size={size}
                spinning={spinning}
                style={style}
                tip={tip}
                wrapperClassName={wrapperClassName}
            />
        );
}
