import { mentorActions, IFetchLocationFilterData } from "./actions";
import { MENTOR_TYPE } from "@presentation/Mentors/constant"
import { ProfileModel } from "@common/domain/models/Profile";
export interface IMentorState {
    mentorList: any;
    filterDataMap: { [heading: string]: any };
    filteredMentorList: any;
    menteesList:ProfileModel[]
    filterLocation: IFetchLocationFilterData;
    loaders: {
        mentorList: any;
        filteredMentorList: any;
        filterLocation: boolean;
        menteesList:boolean;
    };
    errors: {
        mentorList: any;
        filteredMentorList: any;
        filterLocation: string;
        menteesList:string;
    };
}

export const initialState = {
    mentorList: {
        [MENTOR_TYPE.GENERAL_MENTOR]: {
            mentors: []
        },
        [MENTOR_TYPE.INDUSTRY_MENTOR]: {
            mentors: []
        },
        [MENTOR_TYPE.JOB_ROLE_MENTOR]: {
            mentors: []
        }
    },
    filteredMentorList: {
        [MENTOR_TYPE.GENERAL_MENTOR]: {
            mentors: []
        },
        [MENTOR_TYPE.INDUSTRY_MENTOR]: {
            mentors: []
        },
        [MENTOR_TYPE.JOB_ROLE_MENTOR]: {
            mentors: []
        }
    },
    menteesList:[],
    filterLocation: {},
    filterDataMap: {},
    loaders: {
        mentorList: {},
        filteredMentorList: {},
        filterLocation: false,
        menteesList:false,
    },
    errors: {
        mentorList: {},
        filteredMentorList: {},
        filterLocation: "",
        menteesList:""
    },
};

export const updateFilterdata = (heading: string, data: any, existingData: {}) => {
    if (!existingData[heading] && data) {
        existingData[heading] = data
    } else if (existingData[heading] && !data) {
        delete existingData[heading]
    } else if (existingData[heading] && data) {
        existingData[heading] = data
    }
};


export const mentorReducer = (
    state: IMentorState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
        case mentorActions.FETCH_MENTOR_LIST_START:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    mentorList: { ...state.loaders.mentorList, [action.payload.expertType]: true }
                },
            };
        case mentorActions.FETCH_MENTOR_LIST_SUCCESS:
            return {
                ...state,
                mentorList: {
                    ...state.mentorList, [action.payload.type]: {
                        mentors: action.payload.page==1 ?
                            action.payload.res?.mentors :
                            [...state.mentorList?.[action.payload.type]?.mentors, ...action.payload.res?.mentors],
                        total: action.payload.res?.total,
                        pageNumber: action.payload.page
                    }
                },
                loaders: {
                    ...state.loaders,
                    mentorList: { ...state.loaders.mentorList, [action.payload.type]: false }
                },
            };
        case mentorActions.FETCH_MENTOR_LIST_FAIL:
            return {
                ...state, loaders: {
                    ...state.loaders,
                    mentorList: { ...state.loaders.mentorList, [action.payload.type]: false }
                },
                errors: {
                    ...state.errors,
                    mentorList: { ...state.errors.mentorList, [action.payload.expertType]: action.payload }
                }
            };
        case mentorActions.SET_FILTER_DATA: {
            updateFilterdata(action.payload.heading, action.payload.data, state.filterDataMap)
            return {
                ...state,
                filterData: action.payload,
                loaders: {
                    ...state.loaders,
                    filteredMentorList: { ...state.loaders.filteredMentorList, [action.payload.expertType]: true }
                },
            }
        }
        case mentorActions.FETCH_FILTERED_MENTOR_LIST_SUCCESS:
            return {
                ...state,
                filteredMentorList: {
                    ...state.filteredMentorList, [action.payload.type]: {
                        mentors: action.payload.page == 1 ? action.payload.res?.mentors : [...state.filteredMentorList?.[action.payload.type]?.mentors, ...action.payload.res?.mentors],
                        total: action.payload.res?.total,
                        pageNumber: action.payload.page
                    }
                },
                loaders: {
                    ...state.loaders,
                    filteredMentorList: { ...state.loaders.filteredMentorList, [action.payload.type]: false }
                },
            };
        case mentorActions.FETCH_LOCATION: {
            return {
                ...state,
                loaders: { ...state.loaders, filterLocation: true }
            }
        }
        case mentorActions.FETCH_LOCATION_SUCCESS: {
            return {
                ...state,
                filterLocation: action.payload,
                loaders: { ...state.loaders, filterLocation: false }
            }
        }
        case mentorActions.FETCH_LOCATION_FAIL: {
            return {
                ...state,
                loaders: { ...state.loaders, filterLocation: false },
                errors: { ...state, filterLocation: action.payload }
            }
        }   
        case mentorActions.CLEAR_FILTER_DATA: {
            return {
              ...state,
              filterDataMap: {}
            }
        } 
        case mentorActions.FETCH_MENTEES_LIST_START:{
            return {
                ...state,
                loaders: { ...state.loaders, menteesList: true }
            }
        }

        case mentorActions.FETCH_MENTEES_LIST_SUCCESS:{
            return {
                ...state,
                menteesList: action.payload,
                loaders: { ...state.loaders, menteesList: false }
            }
        }

        case mentorActions.FETCH_MENTEES_LIST_FAIL:{
            return {
                ...state,
                loaders: { ...state.loaders, menteesList: false },
                errors: { ...state, menteesList: action.payload }
            }
        }
        default:
            return state;
    }
};
