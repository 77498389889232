import {
    call,
    fork,
    put,
    take,
    takeEvery,
    cancel,
    select,
    takeLatest,
  } from 'redux-saga/effects';
  import {
    ChatActions,
    getConversationFailure,
    getConversationSuccess,
    IGetConversation,
    SocketActionTypes,
    fetchMessagesSuccess,
    postMessageSuccess,
    fetchConversationsSuccess,
    fetchConversationsFailure,
    fetchMessagesFailure,
    postMessageFailure,
    IFetchConversationAction,
    IFetchMessagesAction,
    IPostMessageAction,
    updateSocketActivationStatus,
    IReceivedMessage,
    updateReceivedMessage,
    IReadReceiptAction,
    sendReadReceiptSuccess,
    fetchMessages,
    sendReadReceipt,
    fetchConversations,
    updateLoadEarlieMessagesFlag,
    startNewConvoSuccess,
    addActiveConvoOnTop,
    startSocket,
    getUnreadConversationCountSuccess,
    getUnreadConversationCountFail,
  } from '@redux/chat/actions';
  import { ChatRepository } from '@repositories/ChatRepository';
  import { SocketService } from '@services/SocketService';
  import { AuthenticationService } from '@services/AuthenticationService';
  import { ConversationModel } from '@models/Conversation';
  import {
    getActiveConversation,
    getChatData, 
    getConversationById,
    getMessagesOfConversation,
  } from '@redux/chat/selectors';
  import { CHAT_MESSAGES_LIMIT, CONVERSATIONS_PAGE_LIMIT, NEW_MESSAGES_LIMIT } from '@constants/config';
  import { DIRECTION, chatMessageType } from '@constants/config';
  import { DateHelper } from '@utils/DateHelper';
  import { MessageModel } from '@models/Message';
  import ChatDataMappingUtil from './ChatDataMappingUtil';
  import SocketConstants from '@redux/chat/SocketConstants';
  import { DataUtility } from '@utils/DataUtility';
  import { getConversation } from './actions';
  import { fetchProfilesByUserIdsSaga } from "@redux/profile/sagas";
  import { fetchProfilesByUserIds } from "@redux/profile/actions";
  import { getProfileByUserIdSelector } from "@redux/profile/selectors";
  import { getLoggedInUserId } from "@utils/DataMappingUtils";
import { getErrorCodes } from '@utils/ErrorMessageUtils';
  
  export function* getConversationJob(action: {
    type: string;
    payload: { userProfileId: string; data: IGetConversation, inSync?: boolean; fetchConvo?: boolean; };
  }): any {
    try {
      const data = action.payload.data;
      const { inSync, userProfileId, fetchConvo } = action.payload;
      const otherProfileId = data.conversation.profileIds[1];
      let conversation = yield select(getConversationById, data.conversation.conversationId);

      if (DataUtility.isEmpty(conversation)) {
        conversation = yield call(
          ChatRepository.getConversation,
          userProfileId,
          data,
        );
      }
      let otherProfile = yield select(getProfileByUserIdSelector, otherProfileId);
      if (otherProfile === null || DataUtility.isEmpty(otherProfile)) {
        yield call(
            fetchProfilesByUserIdsSaga,
            fetchProfilesByUserIds([otherProfileId]),
        );
      }

      yield call(getConversationSuccessJob, conversation, userProfileId, inSync, fetchConvo )
  
    } catch (e) {
      yield put(getConversationFailure(e.message));
    }
  }
  
  export function* getConversationByIdJob(action: {
    type: string;
    payload: { conversationId: string; userProfileId: string; inSync?: boolean; fetchConvo?: boolean; };
  }): any {
    try {
      const { conversationId, userProfileId, inSync, fetchConvo } = action.payload;
      let conversation = yield select(getConversationById, conversationId);
      if (DataUtility.isEmpty(conversation)) {
        conversation = yield call(
            ChatRepository.getConversationById,
            conversationId,
        );
      }
  
      let allProfileIds: string[] = conversation.getProfileIds();
      const ids = ChatDataMappingUtil.removeUserProfileId(conversation.getProfileIds());
      conversation.setProfileIds(ids);
      allProfileIds = [...allProfileIds, ...ids];
      allProfileIds = DataUtility.uniq(allProfileIds);
      const profilesLimit = 40;
      for (let i = 0; i <= allProfileIds.length; i = i + profilesLimit) {
        // to sequence another saga do yield call
        yield call(
            fetchProfilesByUserIdsSaga,
            fetchProfilesByUserIds(allProfileIds.slice(i, i + profilesLimit)),
        );
      }
      yield call(getConversationSuccessJob, conversation, userProfileId, inSync, fetchConvo )
  
    } catch (e) {
      yield put(getConversationFailure(e.message));
    }
  }
  
  export function* getConversationSuccessJob(conversation: ConversationModel,
                                             userProfileId: string, inSync?: boolean,
                                             fetchConvo?: boolean) {

    conversation.setProfileIds(ChatDataMappingUtil.removeUserProfileId(conversation.getProfileIds()));
    yield put(getConversationSuccess(conversation));
    if (fetchConvo) {
      yield call(
          fetchConversationsJob,
          fetchConversations(1, 10, true)
      );
    }
    const conversationMsgs: MessageModel[] = yield select(getMessagesOfConversation, conversation?.getId());
    let messagesSpecification = {
      conversationId: conversation?.getId(),
      cursorMessageId: '',
      direction: DIRECTION.before,
      limit: CHAT_MESSAGES_LIMIT,
    };
    if (!DataUtility.isEmpty(conversationMsgs)) {
      messagesSpecification = {
        ...messagesSpecification,
        cursorMessageId: conversationMsgs[0].getId(),
        direction: DIRECTION.after,
        // max limit to get latest message in Chat screen
        limit: NEW_MESSAGES_LIMIT,
      };
      if (inSync) {
        yield call(
            fetchChatMessages,
            fetchMessages(messagesSpecification, DataUtility.isEmpty(conversationMsgs), DIRECTION.after),
        )
      } else {
        yield put(fetchMessages(messagesSpecification, DataUtility.isEmpty(conversationMsgs), DIRECTION.after));
      }
    } if (inSync) {
      yield call(
          fetchChatMessages,
          fetchMessages(messagesSpecification, DataUtility.isEmpty(conversationMsgs), DIRECTION.before),
      )
    } else {
      yield put(fetchMessages(messagesSpecification, DataUtility.isEmpty(conversationMsgs), DIRECTION.before));
    }
    if (inSync) {
      yield call(
          sendReadReceiptJob,
          sendReadReceipt(conversation?.getId(), DateHelper.getCurrentDateTime(), userProfileId),
      );
    } else {
      yield put(sendReadReceipt(conversation?.getId(), DateHelper.getCurrentDateTime(), userProfileId));
  
    }
  }
  
  export function* fetchConversationsJob(action: IFetchConversationAction) {
    try {
      const { page, limit } = action.payload;
      const conversations = yield call(
        SocketService.getInstance().getConversations,
        page,
        limit,
      );
      let allProfileIds: string[] = [];
      conversations.forEach((conversation: ConversationModel) => {
        const ids = ChatDataMappingUtil.removeUserProfileId(conversation.getProfileIds());
        conversation.setProfileIds(ids);
        allProfileIds = [...allProfileIds, ...ids];
      });
  
      allProfileIds = DataUtility.uniq(allProfileIds);
      const profilesLimit = 40;
      for (let i = 0; i <= allProfileIds.length; i = i + profilesLimit) {
        // to sequence another saga do yield call
        yield call(
            fetchProfilesByUserIdsSaga,
            fetchProfilesByUserIds(allProfileIds.slice(i, i + profilesLimit)),
        );
      }
      yield put(fetchConversationsSuccess(conversations, page, action.payload.reset));
    } catch (error) {
      yield put(fetchConversationsFailure(error));
    }
  }
  
  export function* fetchChatMessages(action: IFetchMessagesAction) {
    try {
      const { reset, direction, messagesSpecification } = action.payload;
      const messages = yield call(SocketService.getInstance().getMessages, messagesSpecification);
      yield put(fetchMessagesSuccess(
        messagesSpecification.conversationId, messages, reset, direction));
      if (direction === DIRECTION.before) {
        yield put(updateLoadEarlieMessagesFlag(messagesSpecification.conversationId,
          !(messages.length < CHAT_MESSAGES_LIMIT)));
      }
    } catch (error) {
      yield put(fetchMessagesFailure(error));
    }
  }
  
  export function* postChatMessage(action: IPostMessageAction) {
    try {
      const payload: MessageModel = action.payload.message;
      const msg = {
        clientMessageId: payload.getClientMessageId(),
        conversationId: payload.getConversationId(),
        type: payload.getType(),

        content: payload.getType() === chatMessageType.Text ? {
          text: payload.getMessageText(),
          }
          : 
          payload.getMessageText().length > 0 ? 
          { 
            text: payload.getMessageText(),
            file: payload.getMessageFile(),
          }
          : 
          { file: payload.getMessageFile() },
        replyOf: payload.getReplyOf()
      };
      const message = yield call(SocketService.getInstance().postMessage, msg);
      yield put(postMessageSuccess(message));
    } catch (error) {
      yield put(postMessageFailure(error.message));
    }
  }
  
  export function* receivedMessageJob(action: IReceivedMessage) {
    const activeConversation = yield select(getActiveConversation);
    const receivedMessage = action.payload.message;
  
    const chatData = yield select(getChatData);
    if (chatData && chatData.conversations) {
      const conversation = DataUtility.find(chatData.conversations, (item: ConversationModel) => {
        return item?.getId() === receivedMessage.getConversationId();
      });
      // refresh existing list of conversations, if received msg's conversation data is missing
      if (!conversation) {
        yield call(fetchConversationsJob, fetchConversations(1, CONVERSATIONS_PAGE_LIMIT, true));
      }
    }
  
    yield put(updateReceivedMessage(receivedMessage));
    // trigger readReceipt event on receiving of new message if its of active conversation
    if (!DataUtility.isEmpty(activeConversation) && activeConversation?.getId() === receivedMessage.getConversationId()) {
      yield put(sendReadReceipt(receivedMessage.getConversationId(),
        DateHelper.getCurrentDateTime(), SocketService.userProfileId));
    }
  }
  
  export function* sendReadReceiptJob(action: IReadReceiptAction) {
    try {
      const conversation = yield call(
        SocketService.getInstance().readReceipt,
        action.payload,
      );
      yield put(sendReadReceiptSuccess(conversation));
    } catch (error) {
      // Do nothing
    }
  }
  
  export function* startNewConvoJob(action: {
    type: string;
    payload: { userProfileId: string; data: IGetConversation };
  }): any {
  
    const { userProfileId, data } = action.payload;
    yield call(
      getConversationJob,
      getConversation(userProfileId, data, true),
    );
    yield put(addActiveConvoOnTop());
    yield put(startNewConvoSuccess());
  }
  
  export function* refreshTokenSocketStartJob (action: any) {
    yield AuthenticationService.refreshTokens();
    yield put(startSocket());
  }

  export function* getUnreadConversationCountSaga(action) {
    const userId = action.payload;
    try {
      const response = yield call(ChatRepository.getUnreadConversationCount, userId);
      yield put(getUnreadConversationCountSuccess(response.unreadConversationCount));
    } catch (e) {
      const error = getErrorCodes(e);
      yield put(getUnreadConversationCountFail(error));
    }
  } 
  
  export function* watchChatActions() {
    yield takeEvery(ChatActions.GET_CONVERSATION, getConversationJob);
    yield takeEvery(ChatActions.GET_CONVERSATION_BY_ID, getConversationByIdJob);
    yield takeEvery(ChatActions.FETCH_MESSAGES, fetchChatMessages);
    yield takeEvery(ChatActions.POST_MESSAGE, postChatMessage);
    yield takeEvery(ChatActions.FETCH_CONVERSATIONS, fetchConversationsJob);
    yield takeEvery(ChatActions.RECEIVED_MESSAGE, receivedMessageJob);
    yield takeEvery(ChatActions.MESSAGE_READ_RECEIPT, sendReadReceiptJob);
  
    yield takeEvery(ChatActions.START_NEW_CONVO, startNewConvoJob);
  
    yield takeEvery(ChatActions.REFRESH_TOKEN, refreshTokenSocketStartJob)
    yield takeLatest(ChatActions.GET_UNREAD_CONVERSATION_COUNT, getUnreadConversationCountSaga);
    while (true) {
      yield take(SocketActionTypes.WEBSOCKET_START);
      const userToken = yield call(AuthenticationService.getAccessToken);
      SocketService.setUserToken(userToken);
      //TODO: update this once user calls are ready, currently getting from token decode
      const userProfileId = yield call(getLoggedInUserId);
      SocketService.setUserProfileId(userProfileId);
      yield fork(socketListenerSaga);
    }
  }
  
  export function* socketListenerSaga() {
    const socket = yield call(SocketService.getInstance().connectSocket);
    yield put(updateSocketActivationStatus(true));
    // starts the task in the background
    const socketTask = yield fork(prepareSocket, socket);
  
    // Wait for Kill call!
    while (true) {
      yield take(SocketActionTypes.WEBSOCKET_STOP);
      yield call(SocketService.getInstance().disconnectSocket);
      yield cancel(socketTask);
      yield put(updateSocketActivationStatus(false));
    }
  }
  
  export function* prepareSocket(socket: any) {
    const socketChannel = yield call(
      SocketService.getInstance().createSocketChannel,
      socket,
    );
    while (true) {
      yield call(receiveMessages, socketChannel);
    }
  }
  
  export function compareSocketActionTypes(
    action: { type: string; payload: any },
    actionType: string,
  ) {
    return action && action.type && action.type === actionType;
  }
  
  export function* receiveMessages(socketChannel: any) {
    // Wait for connection success event
    const value = yield take(socketChannel);
    // for doing any relevant work before dispatching actions
    yield put(value);
    // The value will never be from another `namespace` because socketChannel is unique
    // for this namespace.
    if (
      !compareSocketActionTypes(
        value,
        SocketConstants.SOCKETIO_CONNECT,
      )
    ) {
      return;
    }
  
    while (true) {
      const value = yield take(socketChannel);
      // a message has been received, dispatch an action with the message payload
      yield put(value);
      // Certain values from socket channel have special meaning.
      if (
        compareSocketActionTypes(
          value,
          SocketConstants.SOCKETIO_CONNECT_ERROR,
        )
      ) {
        break;
      }
    }
  }
  
  
  