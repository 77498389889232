import {
    call,
    ForkEffect,
    put,
    StrictEffect,
    takeEvery,
    takeLatest,
} from "redux-saga/effects";
import {
    fetchMentorListSuccess, fetchMentorListFail, IMentorPayload, IFetchLocationAction, fetchLocationFail,
    mentorActions, fetchFilteredMentorListSuccess, fetchLocationSuccess,fetchMenteesListSuccess,
    fetchMenteesListError
} from "./actions";
import { MentorRepository } from "@common/domain/repositories/MentorRepository"
import {MeetingRepository} from "@common/domain/repositories/MeetingRepository"
import {ProfileRepository} from "@common/domain/repositories/ProfileRepository"
import { IFluxStandardAction } from "@store/webInterfaces";
import { ProfileModel } from "@common/domain/models/Profile";
import { getErrorCodes } from "@utils/ErrorMessageUtils";


export function* fetchMentorList(action: IFluxStandardAction<IMentorPayload>): Generator<StrictEffect, void, ProfileModel> {
    try {
        const response: ProfileModel = yield call(MentorRepository.fetchMentorList, action.payload);
        if (action.type === mentorActions.FETCH_FILTERED_MENTOR_LIST) {
            yield put(fetchFilteredMentorListSuccess({ res: response, type: action.payload.expertType, page: action.payload?.page }))
        } else {
            yield put(fetchMentorListSuccess({ res: response, type: action.payload.expertType, page: action.payload?.page }));
        }
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchMentorListFail(error));
    }
}

export function* fetchLocationSaga(action: IFetchLocationAction) {
    try {
        const result = yield call(MentorRepository.fetchLocation, action.payload);
        yield put(fetchLocationSuccess(result));
    } catch (error) {
        yield put(fetchLocationFail(error))
    }
}

export function* fetchMenteesList(action: IFluxStandardAction<string>): Generator<StrictEffect, void, ProfileModel[]> {
    try {
        const response:any = yield call(MeetingRepository.fetchMenteesList, action.payload);
        const profiles = yield call(
            ProfileRepository.fetchProfilesByUserIds,
            response
        );
        yield put(fetchMenteesListSuccess(profiles))
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchMenteesListError(error));
    }
}

export function* watchMentor(): Generator<ForkEffect> {
    yield takeEvery(mentorActions.FETCH_MENTOR_LIST_START, fetchMentorList);
    yield takeEvery(mentorActions.FETCH_FILTERED_MENTOR_LIST, fetchMentorList);
    yield takeLatest(mentorActions.FETCH_LOCATION, fetchLocationSaga);
    yield takeLatest(mentorActions.FETCH_MENTEES_LIST_START, fetchMenteesList);
}
