import MicroCertificate from "@presentation/CourseDetails/Components/MicroCertificate";

export const instituteAdminMessages = {
    lastSixMonths: {
        key: "instituteAdmin.labelsAndTitles.LAST_SIX_MONTHS"
    },
    lastthreeMonths: {
        key: "instituteAdmin.labelsAndTitles.LAST_Three_MONTHS"
    },
    lastOneMonth: {
        key: "instituteAdmin.labelsAndTitles.LAST_ONE_MONTH"
    },
    lastOneYear: {
        key: "instituteAdmin.labelsAndTitles.LAST_ONE_YEAR"
    },
    currentMonth: {
        key: "instituteAdmin.labelsAndTitles.CURRENT_MONTH"
    },
    customDate: {
        key: "instituteAdmin.labelsAndTitles.CUSTOM_DATE"
    },
    netEnrolled: {
        key: "instituteAdmin.labelsAndTitles.NET_ENROLLED"
    },
    completed: {
        key: "instituteAdmin.labelsAndTitles.COMPLETED"
    },
    scoreAboveThershold: {
        key: "instituteAdmin.labelsAndTitles.SCORE_ABOVE_THERSHOLD"
    },
    downloadReport: {
        key: "instituteAdmin.labelsAndTitles.DOWNLOAD_REPORT"
    },
    studentJourney: {
        key: "instituteAdmin.labelsAndTitles.STUDENT_JOURNEY"
    },
    assessmentPerformance: {
        key: "instituteAdmin.labelsAndTitles.ASSESSMENT_PERFORMANCE"
    },
    assessmentPerformanceTwo: {
        key: "instituteAdmin.labelsAndTitles.ASSESSMENT_PERFORMANCE_TWO"
    },
    assessmentPerformanceThree: {
        key: "instituteAdmin.labelsAndTitles.ASSESSMENT_PERFORMANCE_THREE"
    },
    note: {
        key: "instituteAdmin.labelsAndTitles.NOTE"
    },
    scoreDistribution: {
        key: "instituteAdmin.labelsAndTitles.SCORE_DISTRIBUTION"
    },
    lastRefresh: {
        key: "instituteAdmin.labelsAndTitles.LAST_REFRESH"
    },
    batchStatus: {
        key: "instituteAdmin.labelsAndTitles.BATCH_STATUS"
    },
    startDateFrom: {
        key: "instituteAdmin.labelsAndTitles.START_DATE_FROM"
    },
    startDateTill: {
        key: "instituteAdmin.labelsAndTitles.START_DATE_TILL"
    },
    startDateFromPlaceholder: {
        key: "instituteAdmin.labelsAndTitles.START_DATE_FROM_PLACEHOLDER"
    },
    startDateTillPlaceholder: {
        key: "instituteAdmin.labelsAndTitles.START_DATE_TILL_PLACEHOLDER"
    },
    customDateRange: {
        key: "instituteAdmin.labelsAndTitles.CUSTOM_DATE_RANGE"
    },
    students: {
        key: "instituteAdmin.labelsAndTitles.STUDENTS"
    },
    scoreDistributionExplaination: {
        key: "instituteAdmin.labelsAndTitles.SCORE_DISTRIBUTION_EXPLAINATION"
    },
    downloadBatchInformation: {
        key: "instituteAdmin.labelsAndTitles.DOWNLOAD_BATCH_INFORMATION"
    },
    apply: {
        key: "instituteAdmin.labelsAndTitles.APPLY"
    },
    batchWiseCompletion: {
        key: "instituteAdmin.labelsAndTitles.BATCH_WISE_COMPLETION"
    },
    batchStartDate: {
        key: "instituteAdmin.labelsAndTitles.BATCH_START_DATE"
    },
    batchStartDateRange: {
        key: "instituteAdmin.labelsAndTitles.BATCH_START_DATE_RANGE"
    },
    selectBatchStartDate: {
        key: "instituteAdmin.labelsAndTitles.SELECT_BATCH_START_DATE"
    },
    all: {
        key: "instituteAdmin.labelsAndTitles.ALL"
    },
    hour: {
        key: "instituteAdmin.labelsAndTitles.HOUR"
    },
    minute: {
        key: "instituteAdmin.labelsAndTitles.MINUTE"
    },
    day: {
        key: "instituteAdmin.labelsAndTitles.DAY"
    },
    second: {
        key: "instituteAdmin.labelsAndTitles.SECOND"
    },
    MonthDateRange: {
        key: "instituteAdmin.labelsAndTitles.Month_DATE_RANGE"
    },
    reportType: {
        key: "instituteAdmin.labelsAndTitles.REPORT_TYPE"
    },
    courseOverview: {
        key: "instituteAdmin.labelsAndTitles.COURSE_OVERVIEW"
    },
    courseOverviewDescription: {
        key: "instituteAdmin.labelsAndTitles.COURSE_OVERVIEW_DESCRIPTION"
    },
    courseOverviewDashboardDescription: {
        key: "instituteAdmin.labelsAndTitles.COURSE_OVERVIEW_DASHBOARD_DESCRIPTION"
    },
    courseOverviewNote: {
        key: "instituteAdmin.labelsAndTitles.COURSE_OVERVIEW_NOTE"
    },
    totalUsersEnrolled: {
        key: "instituteAdmin.labelsAndTitles.TOTAL_USERS_ENROLLED"
    },
    totalUsersCompleted: {
        key: "instituteAdmin.labelsAndTitles.TOTAL_USERS_COMPLETED"
    },
    microCertificateGenerated: {
        key: "instituteAdmin.labelsAndTitles.MICRO_CERTIFICATE_GENERATED"
    },
    detailedCourseInformation: {
        key: "instituteAdmin.labelsAndTitles.DETAILED_COURSE_INFORMATION"
    },
    netEnrolledCourse: {
        key: "instituteAdmin.labelsAndTitles.NET_ENROLLED_COURSE"
    },
    selectedCourseScoreDistributionExplaination: {
        key: "instituteAdmin.labelsAndTitles.SELECTED_COURSE_SCORE_DISTRIBUTION_EXPLAINATION"
    },
    downloadStudentReport: {
        key: "instituteAdmin.labelsAndTitles.DOWNLOAD_STUDENT_REPORT"
    },
    totalCountExceeds: {
        key: "instituteAdmin.labelsAndTitles.TOTAL_COUNT_EXCEEDS"
    },
    clearFilter: {
        key: "instituteAdmin.labelsAndTitles.CLEAR_FILTER"
    },
    noOfStudents: {
        key: "instituteAdmin.labelsAndTitles.NO_OF_STUDENTS"
    },
    scoreRange: {
        key: "instituteAdmin.labelsAndTitles.SCORE_RANGE"
    },
    noDataFound: {
        key: "instituteAdmin.labelsAndTitles.NO_DATA_FOUND"
    },
    allFaculty: {
        key: "instituteAdmin.labelsAndTitles.ALL_FACULTY"
    },
    batchMode: {
        key: "instituteAdmin.labelsAndTitles.BATCH_MODE"
    },
    selfServe: {
        key: "instituteAdmin.labelsAndTitles.SELF_SERVE"
    },
    batchModeText: {
        key: "instituteAdmin.labelsAndTitles.BATCH_MODE_DESCRIPTION"
    }
}