import React, { ReactNode } from 'react';
import Modal, { ModalProps } from 'antd/lib/modal';

interface IProps extends ModalProps {
  showModal: boolean;
  onCancel: () => void;
  style?: Object;
  closable?: boolean;
  destroyOnClose?: boolean;
  maskClosable?: boolean;
  okText?: string | ReactNode;
  title?: string | ReactNode;
  wrapClassName?: string;
  width?: string | number;
  cancelText?: string | ReactNode;
  onOk?: () => void;
  footer?: ReactNode | null;
  centered?: boolean;
}

export class WFModal extends React.Component<IProps> {
  public render() {
    const { showModal, onCancel, style, footer = null, centered, ...rest } = this.props;

    return (
      <Modal
        style={style}
        centered={centered === false ? centered : true}
        open={showModal}
        footer={footer}
        onCancel={onCancel}
        {...rest}
      >
        {this.props.children}
      </Modal>
    );
  }
}
