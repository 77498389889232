import {deserializeJsonObjCollection} from "@utils/ObjectMapperUtil";

import { APIv1 } from "@common/network/constants";
import { IApiClient } from "@common/network/interfaces";
import { IFetchStudentFacultyPayload } from "@common/redux/connections/interface";

import { BatchStudent } from "@models/BatchStudent";

import { ApiClient } from "@network/client";

const ENDPOINTS = {
    fetchBatchStudentByBatchId: `${APIv1}/organizationservice/batches/students`,
    fetchBatchFacultiesByBatchId: `${APIv1}/organizationservice/batches/faculties`,
    fetchBatchRMByBatchId: `${APIv1}/organizationservice/batches/rms`
}

class ConnectionRepository {
    private apiClient: IApiClient;
    constructor(apiClient: IApiClient) {
        this.apiClient = apiClient;
    }

    public fetchBatchStudentByBatchId = async (payload: IFetchStudentFacultyPayload): Promise<BatchStudent[]> => {
        const response = await this.apiClient.get(ENDPOINTS.fetchBatchStudentByBatchId, payload);
        if (response && response.length === 0) 
            return response;
        return deserializeJsonObjCollection(response.batchStudents, BatchStudent, 'fetchBatchStudentByBatchId') as BatchStudent[];
    }

    public fetchBatchFacultiesByBatchId = async (payload: IFetchStudentFacultyPayload): Promise<BatchStudent[]> => {
        const response = await this.apiClient.get(ENDPOINTS.fetchBatchFacultiesByBatchId, payload);
        if (response && response.length === 0) 
            return response;
        return deserializeJsonObjCollection(response.batchFaculties, BatchStudent, 'fetchBatchFacultiesByBatchId') as BatchStudent[];
    }

    public fetchBatchRMByBatchId = async (payload: IFetchStudentFacultyPayload): Promise<BatchStudent[]> => {
        const response = await this.apiClient.get(ENDPOINTS.fetchBatchRMByBatchId, payload);
        return deserializeJsonObjCollection(response.batchRms, BatchStudent, 'fetchBatchRMByBatchId') as BatchStudent[];
    }
}

const connectionRepository = new ConnectionRepository(ApiClient);

export { connectionRepository as ConnectionRepository };
