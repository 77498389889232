import { getErrorCodes } from "@utils/ErrorMessageUtils";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { ReportRepository } from "../../domain/repositories/ReportRepository";
import {
  reportActions,
  fetchReportsSuccess,
  fetchReportsFailure,
  IFetchReportsAction,
  IFetchCourseReportsAction,
  fetchMultipleCourseReportsSuccess,
  fetchMultipleCourseReportsFailure,
  fetchCourseActivityReportSuccess,
  fetchCourseActivityReportFailure,
} from "./actions";

export function* fetchReportsSaga(action: IFetchReportsAction): any {
  try {
    const { userId, courseId } = action.payload;
    const response = yield call(
      ReportRepository.fetchReports,
      userId,
      courseId,
    );
    yield put(fetchReportsSuccess(courseId, response));
  } catch (e) {
    yield put(fetchReportsFailure(e.message));
  }
}


export function* fetchMultipleCourseReportsSaga(action: IFetchCourseReportsAction) {
  try {
    const { userId, courseIds } = action.payload;

    const courseReports = yield call(
      ReportRepository.fetchMultipleCourseReports,
      userId, courseIds
    );
    yield put(fetchMultipleCourseReportsSuccess(courseReports));
  } catch (error) {
    yield put(fetchMultipleCourseReportsFailure(error.message));
  }
}

export function* fetchCourseActivityReportSaga(action): any {
  try {
    const { courseId, studentId, courseLmsId } = action.payload;
    const response = yield call(
      ReportRepository.fetchCourseActivityReport,
      courseLmsId,
      studentId
    );
    yield put(fetchCourseActivityReportSuccess(courseId, response));
  } catch (e) {
    const error = getErrorCodes(e);
    yield put(fetchCourseActivityReportFailure(error));
  }
}

export function* watchStudentReport(): any {
  yield takeEvery(reportActions.FETCH_REPORTS, fetchReportsSaga);
  yield takeLatest(
    reportActions.FETCH_MULTIPLE_COURSES_REPORT,
    fetchMultipleCourseReportsSaga
  );
  yield takeEvery(reportActions.FETCH_COURSE_ACTIVITY_REPORT,
    fetchCourseActivityReportSaga);
}

