import { IState } from "@store/webInterfaces";

export const getProgramDetailSelector = (state: IState) => state?.program.programDetail || null;
export const getProgramByIdMapSelector = (state: IState) => state?.program?.byId || null;
export const getProgramDetailLoaderSelector = (state: IState) => state?.program?.loaders?.programDetail || false;
export const getProgramDetailErrorSelector = (state: IState) => state?.program?.errors?.programDetail || "";

export const fetchProgramListSelector = (state: IState) => state?.program?.programList || [];
export const fetchProgramListLoaderSelector = (state: IState) => state?.program?.loaders?.programList || false;
export const fetchProgramListErrorSelector = (state: IState) => state?.program?.errors?.programList || "";

export const fetchExploreProgramListSelector = (state: IState) => state?.program?.exploreProgramList || [];
export const fetchExploreProgramListLoaderSelector = (state: IState) => state?.program?.loaders?.exploreProgramList || false;
export const fetchExploreProgramListErrorSelector = (state: IState) => state?.program?.errors?.exploreProgramList || "";

export const enrollProgramLoaderSelector = (state: IState) => state?.program?.loaders?.enrollProgram || false;
export const enrollProgramErrorSelector = (state: IState) => state?.program?.errors?.enrollProgram || "";

export const enrolledProgramListSelector = (state: IState) => state?.program?.enrolledProgramList || [];
export const enrolledProgramListLoaderSelector = (state: IState) => state?.program?.loaders?.enrolledProgramList || false;
export const enrolledProgramListErrorSelector = (state: IState) => state?.program?.errors?.enrolledProgramList || "";

export const fetchCourseProgramListSelector = (state: IState) => state?.program?.courseProgramList || [];
export const fetchCourseProgramListLoaderSelector = (state: IState) => state?.program?.loaders?.courseProgramList || false;
export const fetchCourseProgramListErrorSelector = (state: IState) => state?.program?.errors?.courseProgramList || "";

// Institute program List
export const fetchInstituteProgramListSelector = (state: IState) =>
    state?.program?.instituteProgramList || [];
  export const fetchInstituteProgramListLoaderSelector = (state: IState) =>
    state?.program?.loaders?.instituteProgramList || false;
  export const fetchInstituteProgramListErrorSelector = (state: IState) =>
    state?.program?.errors?.instituteProgramList || '';
  export const instituteProgramTotalCountSelector = (state: IState) =>
    state?.program?.instituteProgramCount || 0;
  
  // Wadhwani program List
  export const fetchWadhwaniProgramListSelector = (state: IState) =>
    state?.program?.wadhwaniProgramList || [];
  export const fetchWadhwaniProgramListLoaderSelector = (state: IState) =>
    state?.program?.loaders?.wadhwaniProgramList || false;
  export const fetchWadhwaniProgramListErrorSelector = (state: IState) =>
    state?.program?.errors?.wadhwaniProgramList || '';
  export const wadhwaniProgramTotalCountSelector = (state: IState) =>
    state?.program?.wadhwaniProgramCount || 0;


  