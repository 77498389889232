let parentRef: any;

class BrowserScrollService {
  public init(pRef: any) {
    parentRef = pRef;
  }

  public getScrollParent(): any {
    return parentRef;
  }

  public scrollToSection = (sectionRef) => {
    const heightOfHeader = 100;
    sectionRef?.current?.scrollIntoView(true);
    const scrolledY = window.scrollY;
    if (scrolledY) {
      window.scroll(0, scrolledY - heightOfHeader);
    }
  }
}

const browserScrollService = new BrowserScrollService();
export { browserScrollService as BrowserScrollService };
