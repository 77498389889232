import { APIv1 } from "@common/network/constants";
import { IApiClient } from "@common/network/interfaces";

import { ApiClient } from "@network/client";

const ENDPOINTS = {
  fetchFacultyReports: () =>
    `${APIv1}/organizationservice/reports/quizScores/batch`,
  fetchFacultyQuizReports: (batchId: string) =>
    `${APIv1}/organizationservice/reports/quiz/batch/${batchId}`,
  fetchStudentsList: (id: number) =>
    `${APIv1}/courseservice/reports/competency/batch/${id}`,
  fetchEngagementReport: (id: number) =>
    `${APIv1}/organizationservice/reports/competencyStatus/batch/${id}`,
  fetchMultipleBatchReports: (courseId: string) =>
    `${APIv1}/courseservice/reports/quizzes/course/${courseId}/batches`,
};

class FacultyReportRepository {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  public fetchFacultyReports = async (batchId: string) => {
    const requestPayload = {
      batchId: batchId,
    };
    const response = await this.apiClient.get(
      ENDPOINTS.fetchFacultyReports(),
      requestPayload
    );
    return response;
  };

  public fetchFacultyQuizReports = async (batchId: string) => {
    const response = await this.apiClient.get(
      ENDPOINTS.fetchFacultyQuizReports(batchId)
    );
    return response;
  };

  public fetchStudentsList = async (id: number) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchStudentsList(id));
    return response;
  };

  public fetchEngagementReport = async (id: number) => {
    const response = await this.apiClient.get(
      ENDPOINTS.fetchEngagementReport(id)
    );
    return response;
  };

  public fetchMultipleBatchReports = async (
    courseId: string,
    batchIds: string[]
  ) => {
    const requestPayload = {
      batchIds,
    };

    // Skipped deserialization since the key values are not fixed
    const response = await this.apiClient.get(
      ENDPOINTS.fetchMultipleBatchReports(courseId),
      requestPayload
    );
    return response.quizData;
  };
}

const facultyReportRepository = new FacultyReportRepository(ApiClient);

export { facultyReportRepository as FacultyReportRepository };
