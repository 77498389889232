import { ProgramModel } from "@models/Program";

import { IFluxStandardAction } from "@store/webInterfaces";
import { IEnrollProgram, IFetchInstituteProgram } from "./interface";

const actionTypePrefix = "PROGRAM";

export const programActions = {
    PROGRAM_DETAIL: `${actionTypePrefix}/PROGRAM_DETAIL`,
    PROGRAM_DETAIL_SUCCESS: `${actionTypePrefix}/PROGRAM_DETAIL_SUCCESS`,
    PROGRAM_DETAIL_FAIL: `${actionTypePrefix}/PROGRAM_DETAIL_FAIL`,

    PROGRAM_LIST: `${actionTypePrefix}/PROGRAM_LIST`,
    PROGRAM_LIST_SUCCESS: `${actionTypePrefix}/PROGRAM_LIST_SUCCESS`,
    PROGRAM_LIST_FAIL: `${actionTypePrefix}/PROGRAM_LIST_FAIL`,

    ENROLL_PROGRAM: `${actionTypePrefix}/ENROLL_PROGRAM`,
    ENROLL_PROGRAM_SUCCESS: `${actionTypePrefix}/ENROLL_PROGRAM_SUCCESS`,
    ENROLL_PROGRAM_FAIL: `${actionTypePrefix}/ENROLL_PROGRAM_FAIL`,

    ENROLLED_PROGRAM_LIST: `${actionTypePrefix}/ENROLLED_PROGRAM_LIST`,
    ENROLLED_PROGRAM_LIST_SUCCESS: `${actionTypePrefix}/ENROLLED_PROGRAM_LIST_SUCCESS`,
    ENROLLED_PROGRAM_LIST_FAIL: `${actionTypePrefix}/ENROLLED_PROGRAM_LIST_FAIL`,

    EXPLORE_PROGRAM_LIST: `${actionTypePrefix}/EXPLORE_PROGRAM_LIST`,
    EXPLORE_PROGRAM_LIST_SUCCESS: `${actionTypePrefix}/EXPLORE_PROGRAM_LIST_SUCCESS`,
    EXPLORE_PROGRAM_LIST_FAIL: `${actionTypePrefix}/EXPLORE_PROGRAM_LIST_FAIL`,

    COURSE_PROGRAM_LIST: `${actionTypePrefix}/COURSE_PROGRAM_LIST`,
    COURSE_PROGRAM_LIST_SUCCESS: `${actionTypePrefix}/COURSE_PROGRAM_LIST_SUCCESS`,
    COURSE_PROGRAM_LIST_FAIL: `${actionTypePrefix}/COURSE_PROGRAM_LIST_FAIL`,

    INSTITUTE_PROGRAM_LIST: `${actionTypePrefix}/INSTITUTE_PROGRAM_LIST`,
    INSTITUTE_PROGRAM_LIST_SUCCESS: `${actionTypePrefix}/INSTITUTE_PROGRAM_LIST_SUCCESS`,
    INSTITUTE_PROGRAM_LIST_FAIL: `${actionTypePrefix}/INSTITUTE_PROGRAM_LIST_FAIL`,
  
    WADHWANI_PROGRAM_LIST: `${actionTypePrefix}/WADHWANI_PROGRAM_LIST`,
    WADHWANI_PROGRAM_LIST_SUCCESS: `${actionTypePrefix}/WADHWANI_PROGRAM_LIST_SUCCESS`,
    WADHWANI_PROGRAM_LIST_FAIL: `${actionTypePrefix}/WADHWANI_PROGRAM_LIST_FAIL`,
}


export interface IFetchInstituteProgramAction {
    type: string;
    payload: IFetchInstituteProgram;
}

// course collection detail
export const fetchProgramDetail = (id: string): IFluxStandardAction<string> => {
    return {
        type: programActions.PROGRAM_DETAIL,
        payload: id
    }
}

export const fetchProgramDetailSuccess = (payload: ProgramModel): IFluxStandardAction<ProgramModel> => {
    return {
        type: programActions.PROGRAM_DETAIL_SUCCESS,
        payload
    }
}

export const fetchProgramDetailFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: programActions.PROGRAM_DETAIL_FAIL,
        payload: error
    }
}

// program list
export const fetchProgramList = (payload): IFluxStandardAction<string> => {
    return {
        type: programActions.PROGRAM_LIST,
        payload
    }
}

export const fetchProgramListSuccess = (payload): IFluxStandardAction<ProgramModel[]> => {
    return {
        type: programActions.PROGRAM_LIST_SUCCESS,
        payload
    }
}

export const fetchProgramListFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: programActions.PROGRAM_LIST_FAIL,
        payload: error
    }
}

// enroll program
export const enrollProgram = (payload: IEnrollProgram): IFluxStandardAction<IEnrollProgram> => {
    return {
        type: programActions.ENROLL_PROGRAM,
        payload
    }
}

export const enrollProgramSuccess = (payload): IFluxStandardAction<string> => {
    return {
        type: programActions.ENROLL_PROGRAM_SUCCESS,
        payload
    }
}

export const enrollProgramFail = (error): IFluxStandardAction<string> => {
    return {
        type: programActions.ENROLL_PROGRAM_FAIL,
        payload: error
    }
}

// enroll program list
export const enrolledProgramList = (payload: IEnrollProgram): IFluxStandardAction<IEnrollProgram> => {
    return {
        type: programActions.ENROLLED_PROGRAM_LIST,
        payload
    }
}

export const enrolledProgramListSuccess = (payload): IFluxStandardAction<string> => {
    return {
        type: programActions.ENROLLED_PROGRAM_LIST_SUCCESS,
        payload
    }
}

export const enrolledProgramListFail = (error): IFluxStandardAction<string> => {
    return {
        type: programActions.ENROLLED_PROGRAM_LIST_FAIL,
        payload: error
    }
}

//explore program list
export const fetchExploreProgramList = (payload): IFluxStandardAction<string> => {
    return {
        type: programActions.EXPLORE_PROGRAM_LIST,
        payload
    }
}

export const fetchExploreProgramListSuccess = (payload): IFluxStandardAction<ProgramModel[]> => {
    return {
        type: programActions.EXPLORE_PROGRAM_LIST_SUCCESS,
        payload
    }
}

export const fetchExploreProgramListFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: programActions.EXPLORE_PROGRAM_LIST_FAIL,
        payload: error
    }
}

// course program list
export const fetchCourseProgramList = (payload): IFluxStandardAction<string> => {
    return {
        type: programActions.COURSE_PROGRAM_LIST,
        payload
    }
}

export const fetchCourseProgramListSuccess = (payload): IFluxStandardAction<ProgramModel[]> => {
    return {
        type: programActions.COURSE_PROGRAM_LIST_SUCCESS,
        payload
    }
}

export const fetchCourseProgramListFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: programActions.COURSE_PROGRAM_LIST_FAIL,
        payload: error
    }
}

export const fetchInstituteProgramList = (
    payload: IFetchInstituteProgram,
  ): IFluxStandardAction<IFetchInstituteProgram> => {
    return {
      type: programActions.INSTITUTE_PROGRAM_LIST,
      payload,
    };
  };

export const fetchInstituteProgramListSuccess = (
    payload,
  ): IFluxStandardAction<ProgramModel[]> => {
    return {
      type: programActions.INSTITUTE_PROGRAM_LIST_SUCCESS,
      payload,
    };
  };
  
  export const fetchInstituteProgramListFail = (
    error: string,
  ): IFluxStandardAction<string> => {
    return {
      type: programActions.INSTITUTE_PROGRAM_LIST_FAIL,
      payload: error,
    };
  };
  
  export const fetchWadhwaniProgramList = (
    payload: IFetchInstituteProgram,
  ): IFluxStandardAction<IFetchInstituteProgram> => {
    return {
      type: programActions.WADHWANI_PROGRAM_LIST,
      payload,
    };
  };
  
  export const fetchWadhwaniProgramListSuccess = (
    payload,
  ): IFluxStandardAction<ProgramModel[]> => {
    return {
      type: programActions.WADHWANI_PROGRAM_LIST_SUCCESS,
      payload,
    };
  };
  
  export const fetchWadhwaniProgramListFail = (
    error: string,
  ): IFluxStandardAction<string> => {
    return {
      type: programActions.WADHWANI_PROGRAM_LIST_FAIL,
      payload: error,
    };
  };
