import { uploadActions } from "./actions";

export interface IUploadState {
  fileUploadLoaders: {}; // fileId = true 
  fileUploadErrors: {};  // fileId = error

  imageUploadLoaders: {}; // imageId = true
  imageUploadErrors: {}; // imageId = error
}

export const initialState: IUploadState = {
  fileUploadLoaders: {},
  fileUploadErrors: {},

  imageUploadLoaders: {},
  imageUploadErrors: {}

};

export const uploadReducer = (
  state: IUploadState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {

    case uploadActions.UPLOAD_FILE:
      return {
        ...state,
        fileUploadLoaders: {
          ...state.fileUploadLoaders,
          [action.payload.fileId]: true,
        },
        fileUploadErrors: {
          ...state.fileUploadErrors,
          [action.payload.fileId]: undefined,
        },
      };

    case uploadActions.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        fileUploadLoaders: {
          ...state.fileUploadLoaders,
          [action.payload.fileId]: undefined,
        },
      };

    case uploadActions.UPLOAD_FILE_FAIL:
      return {
        ...state,
        fileUploadLoaders: {
          ...state.fileUploadLoaders,
          [action.payload.fileId]: undefined,
        },
        fileUploadErrors: {
          ...state.fileUploadErrors,
          [action.payload.fileId]: action.payload,
        },
      };

    case uploadActions.UPLOAD_IMAGE:
      return {
        ...state,
        imageUploadLoaders: {
          ...state.imageUploadLoaders,
          [action.payload.imageId]: true,
        },
        imageUploadErrors: {
          ...state.imageUploadErrors,
          [action.payload.imageId]: undefined,
        },
      };
    case uploadActions.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        imageUploadLoaders: {
          ...state.imageUploadLoaders,
          [action.payload.imageId]: undefined,
        },
      };
    case uploadActions.UPLOAD_IMAGE_FAIL:
      return {
        ...state,
        imageUploadLoaders: {
          ...state.imageUploadLoaders,
          [action.payload.imageId]: undefined,
        },
        imageUploadErrors: {
          ...state.imageUploadErrors,
          [action.payload.imageId]: action.payload,
        },
      };


    default:
      return state;
  }
};
