export const rootMessages = {
    underMaintenence: {
        key: 'global.messages.UNDER_MAINTENANCE'
    },
    backOn: {
        key: 'global.messages.BACK_ON'
    },
    withNewFeatures: {
        key: 'global.messages.WITH_NEW_FEATURES'
    },
    tryAgain: {
        key: 'global.actions.TRY_AGAIN'
    },
    updateChrome: {
        key: 'global.messages.UPDATE_CHROME'
    },
    updateChromeMessage: {
        key: 'global.messages.UPDATE_CHROME_MESSAGE'
    },
    updateWebView: {
        key: 'global.messages.UPDATE_WEBVIEW'
    },
    updateWebViewMessage: {
        key: 'global.messages.UPDATE_WEBVIEW_MESSAGE'
    }
}



