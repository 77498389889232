import { JsonProperty } from "json-object-mapper";

import { userRoles } from "@common/redux/constants";

import { BatchStudent, IBatchStudent } from "@models/BatchStudent";

import { ProfileModel } from "./Profile";
import { CourseModel } from "./Course";
import { Institute } from "./Institute";

export interface IBatch {
  batchCloseTiming: string;
  batchKey: string;
  batchName: string;
  comment: string;
  courseId: number;
  courseName: string;
  createdAt: string;
  deletedAt: string;
  deliveryModel: number;
  description: string;
  endDate: string;
  facultyId: string;
  id: number;
  instituteId: number;
  isBatchClosed: number;
  isModuleClosed: boolean;
  maxStudentsAllowed: number;
  numberOfStudents: number;
  rating: number;
  startDate: string;
  updatedAt: string;

  batchStudents?: IBatchStudent[];
}

export interface IInvitedStudent {
  key: string,
  batchKey: string,
  userEmail: string,
  status: string,
}
export interface IResendOTPResponse {
  successInvites: [{emailIds: string}],
  failureInvites: string[]
}

export interface IInviteSingleResponse {
  total: number,
  successCount: number,
  failureCount: number,
  successInvites: string[],
  failureInvites: string[],
}
export class BatchModel {
  @JsonProperty({ name: "batchCloseTiming", required: false })
  private batchCloseTiming?: string = "";

  @JsonProperty({ name: "batchKey", required: false })
  private batchKey?: string = "";

  @JsonProperty({ name: "batchName", required: false })
  private batchName?: string = "";

  @JsonProperty({ name: "comment", required: false })
  private comment?: string = "";

  @JsonProperty({ name: "courseId", required: false })
  private courseId?: string = "";

  @JsonProperty({ name: "courseName", required: false })
  private courseName?: string = "";

  @JsonProperty({ name: "createdAt", required: false })
  private createdAt?: string = "";

  @JsonProperty({ name: "deletedAt", required: false })
  private deletedAt?: string = "";

  @JsonProperty({ name: "deliveryModel", required: false })
  private deliveryModel?: number = 0;

  @JsonProperty({ name: "description", required: false })
  private description?: string = "";

  @JsonProperty({ name: "endDate", required: false })
  private endDate?: string = "";

  @JsonProperty({ name: "facultyId", required: false })
  private facultyId?: string = "";

  @JsonProperty({ name: "id", required: true })
  private id: number = 0;

  @JsonProperty({ name: "instituteId", required: false })
  private instituteId?: number = 0;

  @JsonProperty({ name: "isBatchClosed", required: false })
  private isBatchClosed?: number = 0;

  @JsonProperty({ name: "isModuleClosed", required: false })
  private isModuleClosed?: boolean = false;

  @JsonProperty({ name: "maxStudentsAllowed", required: false })
  private maxStudentsAllowed?: number = 0;

  @JsonProperty({ name: "numberOfStudents", required: false })
  private numberOfStudents?: number = 0;

  @JsonProperty({ name: "rating", required: false })
  private rating?: string = "";

  @JsonProperty({ name: "startDate", required: false })
  private startDate?: string = "";

  @JsonProperty({ name: "updatedAt", required: false })
  private updatedAt?: string = "";

  @JsonProperty({ name: "batchStudents", required: false })
  private batchStudents?: BatchStudent[] = [];

  @JsonProperty({ name: "announcementId", required: false })
  private announcementId?: string = "";

  @JsonProperty({ name: "conversationId", required: false })
  private conversationId?: string = "";

  @JsonProperty({ name: "courseDetail", required: false })
  private courseDetail?: CourseModel = null;

  @JsonProperty({ name: "instituteDetail", required: false })
  private instituteDetail?: Institute = null;

  public getMaxStudentsAllowed(): number {
    return this.maxStudentsAllowed;
  }

  public getDeliveryModel(): number {
    return this.deliveryModel;
  }

  public setInstituteDetail(institute: Institute) {
    this.instituteDetail = institute;
  }

  public getInstituteDetail(): Institute {
    return this.instituteDetail;
  }

  public setCourseDetail = (course: CourseModel) => {
    this.courseDetail = course;
  }

  public getCourseDetail = () => {
    return this.courseDetail;
  }

  public getInstituteId = () => {
    return this.instituteId;
  }

  private batchMembersCount = {
    [userRoles.student]: null,
    [userRoles.faculty]: null,
  };

  public setBatchMembersCount = (count: number, role: userRoles) => {
    this.batchMembersCount[role] = count;
  }

  public getBatchMembersCount = (role?: userRoles | string): number => {
    if (!role)
      return Number(this.batchMembersCount[userRoles.student]) + Number(this.batchMembersCount[userRoles.faculty]);
    return this.batchMembersCount[role];
  }

  private batchMembers = {
    [userRoles.student]: [],
    [userRoles.faculty]: [],
    [userRoles.regionalManager]: [],
  };

  public setBatchMembers = (batchMembers: ProfileModel[], role: userRoles) => {
    this.batchMembers[role] = batchMembers;
  }

  public getBatchMembers = (role: userRoles): ProfileModel[] => {
    return this.batchMembers[role];
  }

  public getAnnouncementId = () => {
    return this.announcementId;
  };

  public getConversationId = () => {
    return this.conversationId;
  };

  public setId = (id: number) => {
    this.id = id;
  };

  public getId = (): number | undefined => {
    return !!this.id ? this.id : undefined;
  };

  public getCourseId = (): string => {
    return !!this.courseId ? this.courseId : "";
  };

  public getBatchStudents = (): BatchStudent[] => {
    return this.batchStudents;
  };

  public setBatchStudents = (batchStudents) => {
    this.batchStudents = batchStudents;
  };

  public getBatchName = (): string => {
    return this.batchName;
  };

  public getBatchId = (): number => {
    return this.id;
  };

  public getNumberOfStudents = (): number => {
    return this.numberOfStudents;
  };

  public getFacultyId = (): string => {
    return this.facultyId;
  };

  public getStartDate = (): string => {
    return this.startDate;
  };

  public getEndDate = (): string => {
    return this.endDate;
  };

  public getCourseName = (): string => {
    return this.courseName;
  };

  public getBatchUUID = (): string => {
    return this.batchKey;
  };

  public getCreatedAt = (): string => {
    return this.createdAt;
  };

  public getIsBatchClosed = (): number => {
    return this.isBatchClosed;
  }

  public getBatchCloseTiming = (): string => {
    return this.batchCloseTiming;
  }
}
