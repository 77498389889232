import { CommentModel } from "@common/domain/models/Comment";

import { commentsActions } from "./actions";


export type CommentsMapType = {
  [commentId: string]: CommentModel
};
export interface ICommentsState {
  commentIds: string[];
  totalComments: number;
  commentsMap: CommentsMapType,
  loaders: {
    fetchComments: boolean;
    deleteComment: boolean;
    createComment: boolean;
  };
  errors: {
    fetchComments: string;
    deleteComment: string;
    createComment: string;
  };
}

export const initialState: ICommentsState = {
  commentIds: [],
  totalComments: 0,
  commentsMap: {},
  loaders: {
    fetchComments: false,
    deleteComment: false,
    createComment: false,
  },
  errors: {
    fetchComments: "",
    deleteComment: "",
    createComment: ""
  },
};

export const commentsReducer = (
  state: ICommentsState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case commentsActions.FETCH_COMMENTS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchComments: true },
        errors: { ...state.errors, fetchComments: "" },
      };
    case commentsActions.FETCH_COMMENTS_SUCCESS:
      const commentIds = action.payload.comments.map((comment: CommentModel) => comment.getId());
      updateCommentsMap(state.commentsMap, action.payload.comments);
      const updatedCommentIds = (action.payload.page === 1) ? commentIds : [...state.commentIds, ...commentIds];
      return {
        ...state,
        commentIds: updatedCommentIds,
        totalComments: action.payload.total,
        loaders: { ...state.loaders, fetchComments: false },
      };
    case commentsActions.FETCH_COMMENTS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchComments: false },
        errors: { ...state.errors, fetchComments: action.payload },
      };

    case commentsActions.CREATE_COMMENT:
      return {
        ...state,
        loaders: { ...state.loaders, createComment: true },
        errors: { ...state.errors, createComment: "" },
      };
    case commentsActions.CREATE_COMMENT_SUCCESS:
      const createdComment: CommentModel = action.payload;
      updateCommentsMap(state.commentsMap,  [createdComment]);
      return {
        ...state,
        commentIds: [createdComment.getId(), ...state.commentIds],
        loaders: { ...state.loaders, createComment: false },
      };
    case commentsActions.CREATE_COMMENT_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, createComment: false },
        errors: { ...state.errors, createComment: action.payload },
      };

    case commentsActions.DELETE_COMMENTS:
      return {
        ...state,
        loaders: { ...state.loaders, deleteComment: true },
        errors: { ...state.errors, deleteComment: "" },
      };
    case commentsActions.DELETE_COMMENTS_SUCCESS:
      const deleteCommentId = action.payload.commentId;
      const exisitngMap = state.commentsMap;
      exisitngMap[deleteCommentId] = undefined;

      const existingCommentIdsList = state.commentIds;
      const udpatedCommentIdsList = existingCommentIdsList.filter(id => id !== deleteCommentId)

      return {
        ...state,
        commentIds: udpatedCommentIdsList,
        loaders: { ...state.loaders, deleteComment: false },
      };
    case commentsActions.DELETE_COMMENTS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, deleteComment: false },
        errors: { ...state.errors, deleteComment: action.payload },
      };
    default:
      return state;
  }
};


const updateCommentsMap = (existingMap: CommentsMapType, newComments: CommentModel[]) => {
  newComments.forEach((comment: CommentModel) => {
    const existingComment: CommentModel = existingMap[comment.getId()];
    if(existingComment)
      existingMap[comment.getId()] = Object.assign(existingComment, comment);
    else 
      existingMap[comment.getId()] = comment;
  });
}

