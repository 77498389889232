import React from "react";
import Spin from 'antd/lib/spin';

interface IProps {
    prefixCls?: string;
    className?: string;
    spinning?: boolean;
    style?: React.CSSProperties;
    size?: "small" | "large" | "default";
    tip?: string;
    delay?: number;
    wrapperClassName?: string;
    indicator?: React.ReactElement<HTMLElement>;
    showFullPage?: boolean;
}

export default function Spinner(props: IProps) {
    const { prefixCls, className, spinning, style, showFullPage, size, tip, delay, wrapperClassName, indicator } = props;
    return showFullPage ? (
        <div className="wf_spinner">
            <Spin
                size={size ? size : 'large'}
                prefixCls={prefixCls}
                className={className}
                spinning={spinning}
                style={style}
                tip={tip}
                delay={delay}
                wrapperClassName={wrapperClassName}
                indicator={indicator}
            />
        </div>
    ) :
        (
            <Spin
                size={size}
                prefixCls={prefixCls}
                className={className}
                spinning={spinning}
                style={style}
                tip={tip}
                delay={delay}
                wrapperClassName={wrapperClassName}
                indicator={indicator}
            />
        )
}
