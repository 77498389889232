import { NotificationActions } from '@redux/notifications/actions';
import { NotificationModal } from '@models/Notification';
import { DateHelper } from '@utils/DateHelper';

export interface INotificationState {
    notifications: NotificationModal[];
    notificationCount: number;
    totalNotificationCount: number;
    loaders: {
        getNotifications: false,
        getNotificationsCount: false,
        setAllNotificationSeen: false,
        readNotificationById: false,
    };
    error: {
        getNotifications: string,
        getNotificationsCount: string,
        setAllNotificationSeen: string,
        readNotificationById: string,
    };
}

interface IAction {
    type: string;
    payload: any;
}

const initialState: INotificationState = {
    notifications: [],
    notificationCount: 0,
    totalNotificationCount: 0,
    loaders: {
        getNotifications: false,
        getNotificationsCount: false,
        setAllNotificationSeen: false,
        readNotificationById: false,
    },
    error: {
        getNotifications: '',
        getNotificationsCount: '',
        setAllNotificationSeen: '',
        readNotificationById: '',

    },
};

export const notificationReducer = (state: INotificationState = initialState, action: IAction) => {
    switch (action.type) {
        case NotificationActions.FETCH_NOTIFICATION:
            return {
                ...state,
                error: { ...state.error, getNotifications: '' },
                loaders: { ...state.loaders, getNotifications: true },
            };
        case NotificationActions.FETCH_NOTIFICATION_SUCCESS:
            const { notifications, page } = action.payload;
            let outNotifications: NotificationModal[];
            let isLastPage = false;
            if (notifications && notifications.length === 0) {
                isLastPage = true;
            }
            if (page > 1) {
                outNotifications = [...state.notifications, ...notifications];
            } else {
                outNotifications = notifications;
            }
            return {
                ...state,
                notifications: outNotifications,
                isLastPage,
                loaders: { ...state.loaders, getNotifications: false },
            };
        case NotificationActions.FETCH_NOTIFICATION_FAILURE:
            return {
                ...state,
                error: { ...state.error, getNotifications: action.payload },
                loaders: { ...state.loaders, getNotifications: false },
            };

        case NotificationActions.GET_NOTIFICATION_COUNT:
            return {
                ...state,
                error: { ...state.error, getNotificationsCount: '' },
                loaders: { ...state.loaders, getNotificationsCount: true },
            };
        case NotificationActions.GET_NOTIFICATION_COUNT_SUCCESS:
            return {
                ...state,
                notificationCount: action.payload,
                loaders: { ...state.loaders, getNotificationsCount: false },
            };
        case NotificationActions.GET_NOTIFICATION_COUNT_FAILURE:
            return {
                ...state,
                error: { ...state.error, getNotificationsCount: action.payload },
                loaders: { ...state.loaders, getNotificationsCount: false },
            };

        case NotificationActions.SEEN_NOTIFICATION_ALL:
            return {
                ...state,
                error: { ...state.error, setAllNotificationSeen: '' },
                loaders: { ...state.loaders, setAllNotificationSeen: true },
            };
        case NotificationActions.SEEN_NOTIFICATION_ALL_SUCCESS:
            return {
                ...state,
                notificationCount: 0,
                loaders: { ...state.loaders, setAllNotificationSeen: false },
            };
        case NotificationActions.SEEN_NOTIFICATION_ALL_FAILURE:
            return {
                ...state,
                error: { ...state.error, setAllNotificationSeen: action.payload },
                loaders: { ...state.loaders, setAllNotificationSeen: false },
            };

        case NotificationActions.READ_NOTIFICATION_BY_ID:
            return {
                ...state,
                error: { ...state.error, readNotificationById: '' },
                loaders: { ...state.loaders, readNotificationById: true },
            };
        case NotificationActions.READ_NOTIFICATION_BY_ID_SUCCESS:
            const notifId = action.payload;
            const notificationsArray = [...state.notifications];
            let readInst: NotificationModal | undefined = notificationsArray.find(x => x.getId() === notifId);
            readInst!.setReadAt(DateHelper.getCurrentDateTime());

            return {
                ...state,
                notifications: notificationsArray,
                loaders: { ...state.loaders, readNotificationById: false },
            };
        case NotificationActions.READ_NOTIFICATION_BY_ID_FAILURE:
            return {
                ...state,
                error: { ...state.error, readNotificationById: action.payload },
                loaders: { ...state.loaders, readNotificationById: false },
            };
        case NotificationActions.SET_TOTAL_NOTIFICATION_COUNT:
            return {
                ...state,
                totalNotificationCount: action.payload
            }
        default:
            return state;
    }
};
