import {
  Conversation,
  DefaultPrompts,
  IAIFeedback,
  PromptMetaData,
} from "@models/AICoachModel";

import { aiCoachActions, SendPropmtPayload } from "./actions";
export interface IAICoachState {
  defaultPromps: DefaultPrompts[];
  conversation: Conversation[];
  conversationCount: number;
  promptMetadata: {};
  promptsList: PromptMetaData[];
  jobRole: any[];
  userFeedback: {};
  loaders: {
    defaultPromps: boolean;
    conversation: boolean;
    sendPrompt: boolean;
    deleteCoversation: boolean;
    promptMetadata: boolean;
    jobRole: boolean;
    userFeedbackLoader: boolean;
  };
  errors: {
    defaultPromps: string;
    conversation: string;
    sendPrompt: string;
    deleteCoversation: string;
    promptMetadata: string;
    jobRole: string;
    userFeedback: string;
  };
}

export const initialState = {
  defaultPromps: [],
  conversation: [],
  conversationCount: 0,
  promptMetadata: {},
  promptsList: [],
  jobRole: [],
  userFeedback: {},
  loaders: {
    defaultPromps: false,
    conversation: false,
    deleteCoversation: false,
    sendPrompt: false,
    promptMetadata: false,
    jobRole: false,
    userFeedbackLoader: false,
  },

  errors: {
    defaultPromps: "",
    conversation: "",
    sendPrompt: "",
    deleteCoversation: "",
    promptMetadata: "",
    jobRole: "",
    userFeedback: "",
  },
};

export const aiCoachReducer = (
  state: IAICoachState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case aiCoachActions.FETCH_PROMPTS:
      return {
        ...state,
        loaders: { ...state.loaders, defaultPromps: true },
        errors: { ...state.errors, defaultPromps: "" },
      };
    case aiCoachActions.FETCH_PROMPTS_SUCCESS:
      return {
        ...state,
        defaultPromps: action.payload,
        loaders: { ...state.loaders, defaultPromps: false },
      };
    case aiCoachActions.FETCH_PROMPTS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, defaultPromps: false },
        errors: { ...state.errors, defaultPromps: action.payload },
      };
    case aiCoachActions.FETCH_CONVERSATION:
      return {
        ...state,
        loaders: { ...state.loaders, conversation: true },
        errors: { ...state.errors, conversation: "" },
      };
    case aiCoachActions.FETCH_CONVERSATION_SUCCESS:
      const { conversation, page, totalCount } = action.payload;
      let conversationList: Conversation[];
      let isLastPage = false;
      if (conversation && conversation.length === 0) {
        isLastPage = true;
      }
      if (page > 1) {
        conversationList = [...conversation, ...state.conversation];
      } else {
        conversationList = conversation;
      }
      return {
        ...state,
        conversation: conversationList,
        conversationCount: totalCount,
        loaders: { ...state.loaders, conversation: false },
      };
    case aiCoachActions.FETCH_CONVERSATION_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, conversation: false },
        errors: { ...state.errors, conversation: action.payload },
      };
    case aiCoachActions.RESET_CONVERSATION:
        return {
          ...state,
          conversation: [],
          conversationCount: 0,
        };
    case aiCoachActions.DELETE_CONVERSATION:
      return {
        ...state,
        loaders: { ...state.loaders, deleteCoversation: true },
        errors: { ...state.errors, deleteCoversation: "" },
      };
    case aiCoachActions.DELETE_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversation: action.payload,
        loaders: { ...state.loaders, deleteCoversation: false },
      };
    case aiCoachActions.DELETE_CONVERSATION_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, deleteCoversation: false },
        errors: { ...state.errors, deleteCoversation: action.payload },
      };
    case aiCoachActions.GET_PROMPT_METADATA:
      return {
        ...state,
        loaders: { ...state.loaders, promptMetadata: true },
        errors: { ...state.errors, promptMetadata: "" },
      };
    case aiCoachActions.GET_PROMPT_METADATA_SUCCESS:
      const prmpts = action.payload.shortcuts;
      updatePromptsMap(prmpts, state.promptMetadata);
      return {
        ...state,
        promptsList: action.payload.shortcuts,
        loaders: { ...state.loaders, promptMetadata: false },
      };
    case aiCoachActions.GET_PROMPT_METADATA_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, promptMetadata: false },
        errors: { ...state.errors, promptMetadata: action.payload },
      };
    case aiCoachActions.FETCH_JOB_ROLE:
      return {
        ...state,
        loaders: { ...state.loaders, jobRole: true },
        errors: { ...state.errors, jobRole: "" },
      };
    case aiCoachActions.FETCH_JOB_ROLE_SUCCESS:
      return {
        ...state,
        jobRole: action.payload,
        loaders: { ...state.loaders, jobRole: false },
      };
    case aiCoachActions.FETCH_JOB_ROLE_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, jobRole: false },
        errors: { ...state.errors, jobRole: action.payload },
      };
  case aiCoachActions.USER_FEEDBACK:
      return {
        ...state,
        loaders: { ...state.loaders, userFeedbackLoader: true },
        errors: { ...state.errors, userfeedback: "" },
      };
    case aiCoachActions.USER_FEEDBACK_SUCCESS:
        return {
          ...state,
          userFeedback: action.payload,
          loaders: { ...state.loaders, userFeedbackLoader: false },
          errors: { ...state.errors, userfeedback: '' },
        };
    case aiCoachActions.USER_FEEDBACK_FAIL:
        return {
          ...state,
          loaders: { ...state.loaders, userFeedbackLoader: false },
          errors: { ...state.errors, userfeedback: action.payload },
        };
    default:
      return state;
  }
};

export const updatePromptsMap = (
  newPrompts: any[] | PromptMetaData[],
  existingMap: any
) => {
  newPrompts.forEach((prompt) => {
    try {
      if (prompt) existingMap[prompt.key] = prompt;
      else existingMap[prompt.key] = prompt;
    } catch (error) {}
  });
};

// may need this
export const updateuserFeedbackMap = (
  newFeedback: any[] | IAIFeedback[],
  existingMap: any
) => {
  newFeedback.forEach((feedback) => {
    try {
      if (feedback) existingMap[feedback.id] = feedback;
      else existingMap[feedback.id] = feedback;
    } catch (error) {}
  });
};

