import React from 'react';
import { Divider } from "antd";

interface IProps {
    className?: any;
    dashed?: boolean;
    orientation?: 'left' | 'right' | 'center';
    style?: Object;
    type?: 'horizontal' | 'vertical' | 'horizontal';
    text?: string;
}
export default function(props: IProps) {
    const { ...rest } = props;
    return <Divider className="wf_divider" {...rest}>{props.text}</Divider>;
};
