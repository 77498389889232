import Config from '@config/index';
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
import firebase from "firebase/app";

export const enum SCREEN_NAME {
  HOME = "HomeScreen",
  CALENDAR = "CalendarScreen",
  CHAT = "ChatScreen",
  LOGIN = "LoginScreen",
  REGISTER = "RegisterScreen",
  CREATE_PROFILE = "CreateProfileScreen",
  FORGOT_PASSWORD = "ForgotPasswordScreen",
  CREATE_PASSWORD = "CreatePasswordScreen",
  GUEST_HOME = "GuestHomeScreen",
  CREATE_BATCH = "CreateBatchScreen",
  USER_VERIFICATION = "UserVerificationScreen",
  USER_SUPPORT = "UserSupportScreen",
  ALL_BATCHES = "AllBatchesScreen",
  USER_PROFILE = "UserProfileScreen",
  EDIT_BATCH = "EditBatchScreen",
  MEETING_DETAIL = "MeetingDetailScreen",
  RESET_PASSWORD = "ResetPasswordScreen",
  INVITE_STUDENT = "InviteStudentScreen",
  MANAGE_STUDENT = "ManageStudentScreen",
  BATCH_DETAIL = "BatchDetailScreen",
  REPORT = "ReportScreen",
  BATCH_LIST = "BatchListScreen",
  COURSE_DETAIL = "CourseDetailScreen",
  TOOLKIT = "toolkit",
  PROGRAM_DETAIL = "ProgramDetailScreen",
  EVENT_DETAIL = "EventDetailScreen"
}
export class AnalyticsService {

  public static init() {
    firebase.initializeApp(Config.FIREBASE_WEB_CONFIG);
    firebase.analytics().setAnalyticsCollectionEnabled(true);
  }

  public static pageViewAnalytics = (screenName: string) => {
    firebase.analytics().setCurrentScreen(screenName);
  }

  public static setUserId = (uuid: string) => {
    firebase.analytics().setUserId(uuid);
  }

  public static setUserProperties = (propsDict: object) => {
    firebase.analytics().setUserProperties(propsDict);
  }

  public static logEvent = (eventName: string, params?: any) => {
    firebase.analytics().logEvent(eventName, params);
  }

  public static getPageViewEvent = () => {
    return firebase.analytics.EventName.PAGE_VIEW;
  }

}
