import React from 'react';
import { Button } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ButtonShape, ButtonType } from 'antd/lib/button';

interface IProps {
  className?: any;
  htmlType: 'button' | 'reset' | 'submit' | undefined;
  type?: ButtonType;
  icon?: React.ReactNode;
  shape?: ButtonShape;
  size?: SizeType;
  loading?:
    | boolean
    | {
        delay?: number;
      };
  ghost?: boolean;
  danger?: boolean;
  block?: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  href?: string;
  id?: string;
}

export default function(props: IProps) {
  const { children, ...rest } = props;
  return <Button {...rest} type={'link'}>{children}</Button>;
};
