// eslint-disable-next-line import/prefer-default-export
export const LOCAL_STORAGE_KEYS = Object.freeze({
    ACCESS_TOKEN: '@accessToken',
    REFRESH_TOKEN: '@refreshToken',
    IS_PROFILE_COMPLETED: '@isProfileCompleted',
    IS_EMAIL_VERIFIED: '@isEmailVerified',
    TEMP_PASS_GENERATED: '@tempPasswordGenerated',
    PREFERRED_LANGUAGE: '@preferredLanguage',
    ROLE: '@role',
    EMAIL: '@email',
    // PHONE: 'phoneNo'
});
