export const globalMessages = {
    uploadButtonText: {
        key: 'global.actions.UPLOAD'
    },

    changeButtonText: {
        key: 'global.actions.CHANGE'
    },

    replaceButtonText: {
        key: 'global.actions.REPLACE'
    },
    update: {
        key: 'errorBoundary.actions.update'
    },
    goToHomepage: {
        key: 'errorBoundary.actions.goToHomepage'
    },
    chunkError: {
        key: 'errorBoundary.chunkError'
    },
    chunkErrorReloadMessage: {
        key: 'errorBoundary.chunkErrorReloadMessage'
    },
    appError: {
        key: 'errorBoundary.appError'
    },
    wontShow: {
        key: 'messages.WONT_SHOW_PUBLICLY',
    },
    viewAll: {
        key: 'global.actions.VIEW_ALL'
    },
    published: {
        key: 'global.labelsAndTitles.PUBLISHED'
    },

    cancel: {
        key: 'global.actions.CANCEL'
    },

    crop: {
        key: 'global.actions.CROP'
    },
    image: {
        key: 'global.labelsAndTitles.IMAGE'
    },
    document: {
        key: 'global.labelsAndTitles.DOCUMENT'
    },
    closed: {
        key: 'global.labelsAndTitles.CLOSED'
    },
    active: {
        key: 'global.labelsAndTitles.ACTIVE'
    }

};
