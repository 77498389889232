import React, { ReactNode } from "react";
import "./styles.less";
import { FontFamilyVariant } from "../../utils"

interface IProps {
  variant: FontFamilyVariant;
  text: string | ReactNode;
  className?: string;
}

const BannerText = (props: IProps) => {
  const { text, variant, className } = props;

  const customStyle = className ? `${variant} ${className}` : variant;

  switch (variant) {
    case FontFamilyVariant.banner3XLarge:
      return <h1 className={`typography-text ${customStyle}`}>{text}</h1>;
    case FontFamilyVariant.banner2XLarge:
      return <h1 className={`typography-text ${customStyle}`}>{text}</h1>;
    case FontFamilyVariant.bannerXLarge:
      return <h1 className={`typography-text ${customStyle}`}>{text}</h1>;
    case FontFamilyVariant.bannerMedium:
      return <h1 className={`typography-text ${customStyle}`}>{text}</h1>;
    case FontFamilyVariant.bannerRegular:
      return <h1 className={`typography-text ${customStyle}`}>{text}</h1>;
    case FontFamilyVariant.bannerSmall:
      return <h1 className={`typography-text ${customStyle}`}>{text}</h1>;
    case FontFamilyVariant.bannerXSmall:
      return <h2 className={`typography-text ${customStyle}`}>{text}</h2>;
    case FontFamilyVariant.banner2XSmall:
      return <h3 className={`typography-text ${customStyle}`}>{text}</h3>;
    case FontFamilyVariant.banner3XSmall:
      return <h5 className={`typography-text ${customStyle}`}>{text}</h5>;

  }
}

export default BannerText;
