import {
  IDepartment,
  IProfile,
  IUserEducationQualification,
  ProfileModel,
} from "@models/Profile";
import { IState } from "@store/webInterfaces";
import { ObjectMapper } from "json-object-mapper";

const isProfileStateUndefined = (state: IState) => state.profile === undefined;

const isProfileErrorsUndefined = (state: IState) =>
  state.profile.errors === undefined;

const isProfileLoaderUndefined = (state: IState) =>
  state.profile.loaders === undefined;

export const getProfileMap = (state: IState) => {
  if (isProfileStateUndefined(state) || !state.profile.byId) return null;

  return state.profile.byId;
};

export const getProfileByIdSelector = (
  state: IState,
  profileId: string
): ProfileModel => {
  if (isProfileStateUndefined(state) || !state.profile.byId) return null;
  let profile = null;
  if (profileId) {
    profile = state.profile.byId[profileId];
    if (profile && !(profile instanceof ProfileModel)) {
      profile = ObjectMapper.deserialize(ProfileModel, profile);
    }
  }
  return profile;
};

export const getProfileByUserIdSelector = (
  state: IState,
  userId: string
): ProfileModel => {
  if (isProfileStateUndefined(state) || !state.profile.byId) return null;

  return getProfileByIdSelector(state, state.profile.byId[userId]);
};

export const getFetchProfileLoaderSelector = (state: IState) => {
  if (
    isProfileStateUndefined(state) ||
    isProfileLoaderUndefined(state) ||
    !state.profile.loaders.fetchProfiles
  )
    return false;
  return state.profile.loaders.fetchProfiles;
};

export const getFetchProfileErrorSelector = (state: IState) => {
  if (
    isProfileStateUndefined(state) ||
    isProfileErrorsUndefined(state) ||
    !state.profile.errors.fetchProfiles
  )
    return false;
  return state.profile.errors.fetchProfiles;
};

export const fetchProfilesSelector = (state: any): IProfile[] => {
  if (
    isProfileStateUndefined(state) ||
    isProfileErrorsUndefined(state) ||
    !state.profile.profiles
  )
    return [];
  return state.profile.profiles;
};

export const fetchProfileUserDetailsSelector = (state: any): ProfileModel => {
  if (
    isProfileStateUndefined(state) ||
    isProfileErrorsUndefined(state) ||
    !state.profile.fetchLoggedInUser
  )
    return null;

  let profile = state.profile.fetchLoggedInUser;
  if (Object.keys(profile).length && !(profile instanceof ProfileModel)) {
    profile = ObjectMapper.deserialize(ProfileModel, profile);
  }
  return profile;
};

export const fetchProfileUserDetailsErrorSelector = (state: IState): string => {
  if (
    isProfileStateUndefined(state) ||
    isProfileErrorsUndefined(state) ||
    !state.profile.errors.fetchLoggedInUser
  )
    return "";
  return state.profile.errors.fetchLoggedInUser;
};

export const fetchProfileUserDetailsLoaderSelector = (
  state: IState
): boolean => {
  if (
    isProfileStateUndefined(state) ||
    isProfileLoaderUndefined(state) ||
    !state.profile.loaders.fetchLoggedInUser
  )
    return false;
  return state.profile.loaders.fetchLoggedInUser;
};

// Register User
export const createProfileSelector = (state: IState) => {
  if (
    isProfileStateUndefined(state) ||
    isProfileErrorsUndefined(state) ||
    !state.profile.createUser
  )
    return null;
  return state.profile.createUser;
};

export const createProfileErrorSelector = (state: IState): string => {
  if (
    isProfileStateUndefined(state) ||
    isProfileErrorsUndefined(state) ||
    !state.profile.errors.createUser
  )
    return "";
  return state.profile.errors.createUser;
};

export const createProfileLoaderSelector = (state: IState): boolean => {
  if (
    isProfileStateUndefined(state) ||
    isProfileLoaderUndefined(state) ||
    !state.profile.loaders.createUser
  )
    return false;
  return state.profile.loaders.createUser;
};

export const updateProfileErrorSelector = (state: IState): string => {
  if (
    isProfileStateUndefined(state) ||
    isProfileErrorsUndefined(state) ||
    !state.profile.errors.updateProfile
  )
    return "";
  return state.profile.errors.updateProfile;
};

export const updateProfileLoaderSelector = (state: IState): boolean => {
  if (
    isProfileStateUndefined(state) ||
    isProfileLoaderUndefined(state) ||
    !state.profile.loaders.updateProfile
  )
    return false;
  return state.profile.loaders.updateProfile;
};

export const getEducationQualificationsSelector = (
  state: IState
): IUserEducationQualification[] => {
  if (
    isProfileStateUndefined(state) ||
    isProfileLoaderUndefined(state) ||
    !state.profile.educationQualifications
  )
    return [];
  return state.profile.educationQualifications;
};

export const fetchUserIdLoaderSelector = (state: IState): boolean => {
  if (isProfileStateUndefined(state) || isProfileLoaderUndefined(state) || !state.profile.loaders.profilebyEmail)
    return false;

  return state.profile.loaders.profilebyEmail;
};

export const fetchUserIdErrorSelector = (state: IState): string => {
  if (isProfileStateUndefined(state) || isProfileErrorsUndefined(state) || !state.profile.errors.profilebyEmail)
    return "";

  return state.profile.errors.profilebyEmail
}

export const fetchProfileByEmailSelector = (state: IState): ProfileModel => {
  if (isProfileStateUndefined(state) || isProfileErrorsUndefined(state) || !state.profile.profilebyEmail)
    return {} as ProfileModel;

  return state.profile.profilebyEmail;
}

export const getDepartmentsSelector = (
  state: IState
): IDepartment[] => {
  if (
    isProfileStateUndefined(state) ||
    isProfileLoaderUndefined(state) ||
    !state.profile.departments
  )
    return [];
  return state.profile.departments;
};
export const getProfileMetaData = (state: IState, keys: string): any => {
  return state?.profile?.profileMetaData?.[keys] || {};
}
export const isLoggedUserProfileIncompleteSelector = (state: IState): boolean => {
  return state?.profile?.isLoggedInUserProfileIncomplete || false;
}
