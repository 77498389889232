import React from 'react';
import MDReactComponent from 'react-markdown';

import { FormHelper } from '@utils/FormHelper';

import './style.less';
interface IProps {
  className?: string;
  children: any;
}

const MarkdownLink = (props: { title: string; href: string, children: any }) => {

  const { href, title, children } = props;
  let updatedHref = href;
  if (FormHelper.validateEmail(href)) {
    updatedHref = `mailto:${href}`;
  }
  return (
    <a href={updatedHref} target="_blank" className="wf_markdown_a">
      {title || getValue(children[0].props)}
    </a>
  );
};

const getValue = (data: any) => {
  if (data.value) {
    return data.value;
  }
  return getValue(data.children[0].props);
}

export default function Markdown(props: IProps) {
  const style = ['wf_markdown', props.className].join(' ');
  return (
    <MDReactComponent className={style} renderers={{ link: MarkdownLink }} escapeHtml={false}>
      {props.children}
    </MDReactComponent>
  );
}
