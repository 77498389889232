import { JsonProperty } from "json-object-mapper";

export class Institute {
    @JsonProperty({ name: 'id', required: true })
    private id: string = ""

    @JsonProperty({ name: 'name', required: true })
    private name: string = ""

    @JsonProperty({ name: 'createdAt', required: true })
    private createdAt: string = ""

    @JsonProperty({ name: 'updatedAt', required: true })
    private updatedAt: string = ""

    @JsonProperty({ name: 'deletedAt', required: false })
    private deletedAt: string = ""

    @JsonProperty({ name: 'phone', required: false })
    private phone: string = "";

    @JsonProperty({ name: 'email', required: false })
    private email: string = "";

    @JsonProperty({ name: 'timeZoneId', required: false })
    private timeZoneId: string = "";

    @JsonProperty({ name: 'instituteCode', required: true })
    private instituteCode: string = "";

    @JsonProperty({ name: 'status', required: true })
    private status: string = "";

    @JsonProperty({ name: 'address', required: false })
    private address: string = "";

    @JsonProperty({ name: 'cityId', required: false })
    private cityId: number = 0;

    @JsonProperty({ name: 'stateId', required: false })
    private stateId: number = 0;

    @JsonProperty({ name: 'countryId', required: false })
    private countryId: number = 0;

    @JsonProperty({ name: 'regionId', required: false })
    private regionId: number = 0;

    @JsonProperty({ name: 'regionalManagerId', required: false })
    private regionalManagerId: number = 0;

    @JsonProperty({ name: 'pincode', required: false })
    private pincode: string = "";

    @JsonProperty({ name: 'courseMappings', required: false })
    private courseMappings: string[] = [];

    @JsonProperty({ name: 'isSSOInstitute', required: false })
    private isSSOInstitute: boolean = false;

    @JsonProperty({ name: 'courseControlByPlatform', required: false })
    private courseControlByPlatform: boolean;

    @JsonProperty({ name: 'openCourseLanguageMappings', required: false })
    private openCourseLanguageMappings: any = [];

    @JsonProperty({ name: 'openCourseOptionsMapping', required: false })
    private openCourseOptionsMapping: string = '';
  
    public getOpenCourseOptionsMapping(): string {
      return this.openCourseOptionsMapping;
    }
    
    public getOpenCourseLanguageMappings(): any {
        return this.openCourseLanguageMappings;
    }

    public getCourseControlByPlatform(): boolean {
        return this.courseControlByPlatform;
    }

    public getIsSsoInstitute(): boolean {
        return this.isSSOInstitute;
    }

    public getCourseMappings(): string[] {
        return this.courseMappings;
    }

    public getPincode(): string {
        return this.pincode;
    }

    public getRegionalManagerId(): number {
        return this.regionalManagerId;
    }


    public getRegionId(): number {
        return this.regionId;
    }

    public getCountryId(): number {
        return this.countryId;
    }

    public getStateId(): number {
        return this.stateId;
    }

    public getCityId(): number {
        return this.cityId;
    }

    public getAddress(): string {
        return this.address;
    }  

    public getStatus(): string {
        return this.status;
    }

    public getInstituteCode(): string {
        return this.instituteCode;
    }

    public getTimeZoneId(): string {
        return this.timeZoneId;
    }

    public getEmail(): string {
        return this.email;
    }

    public getPhone(): string {
        return this.phone;
    }

    

    public getId(): string {
        return this.id;
    }

    public getName(): any {
        return this.name;
    }

    public getCreatedAt(): string {
        return this.createdAt;
    }

    public getUpdatedAt(): string {
        return this.updatedAt;
    }

    public getDeletedAt(): string {
        return this.deletedAt;
    }
}
