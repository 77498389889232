import React from 'react';
import { Typography } from 'antd';
const { Title } = Typography;

interface IProps {
  children?: React.ReactNode;
  className?: any;
  code?: boolean;
  copyable?: boolean | { text: string; onCopy: () => void };
  delete?: boolean;
  disabled?: boolean;
  editable?: boolean;
  ellipsis?: boolean;
  rows?: number;
  expandable?: boolean;
  suffix?: string;
  mark?: boolean;
  underline?: boolean;
  onChange?: () => void;
  type?: 'secondary' | 'warning' | 'danger';
}

export default function(props: IProps) {
    const { children, ...rest } = props;
  return <Title {...rest} level={2}>{children}</Title>
};
