import React from 'react';

interface IProps {
  content: string;
  icon?: any;
  className?: any;
  profilePicAvatar?: any;
}

export default function(props: IProps) {
  const { content, icon, className, profilePicAvatar } = props;
  const style = ['wf_rounded_button_container', className].join(' ');
  return (
    <div className={style}>
      <span className="rounded_button card_content">
        {!!profilePicAvatar ? (
          <span className="rounded_profile_pic">{profilePicAvatar}</span>
        ) : (
          <img alt="icon for rounded profile pic avatar" className="rounded_icon" src={icon} />
        )}
        <span className="rounded_button_content">{content}</span>
      </span>
    </div>
  );
}
