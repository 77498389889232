import { IState } from '@store/webInterfaces';

const isRootStateUndefined = (state: IState): boolean => state.root === undefined;

const isRootErrorsUndefined = (state: IState): boolean => state.root.errors === undefined;

const isRootLoaderUndefined = (state: IState): boolean => state.root.loaders === undefined;

export const getAppDataLoaderSelector = (state: IState): boolean => {
    if (
        isRootStateUndefined(state)
      || isRootLoaderUndefined(state)
    || !state.root.loaders.appData
    ) return false;
    return state.root.loaders.appData;
};

export const getAppDataErrorSelector = (state: IState): string => {
    if (
        isRootStateUndefined(state)
      || isRootErrorsUndefined(state)
    || !state.root.errors.appData
    ) return '';
    return state.root.errors.appData;
};
