import {
    call,
    ForkEffect,
    put,
    StrictEffect,
    takeEvery,
    takeLatest,
} from "redux-saga/effects";
import {
    fetchForumDetailsByForumIdFail, fetchForumDetailsByForumIdSuccess, fetchForumListByUserIdFail, fetchForumListByUserIdSuccess,
    fetchRecommendedForumListFail,
    fetchRecommendedForumListSuccess,
    forumActions
} from "./actions";
import { ForumRepository } from "@common/domain/repositories/ForumRepository";
import { fetchForumDetailsByForumIdAction, fetchForumListByUserIdAction, fetchRecommendedForumListAction } from "./interface";
import { getLoggedInUserId } from "@utils/UserUtils";


export function* fetchForumListByUserIdSaga(action: fetchForumListByUserIdAction): any {
    try {
        const { userId ,role} = action.payload;
        const responseData: any[] = yield call(ForumRepository.fetchForumListByUserId, userId,role);
        yield put(fetchForumListByUserIdSuccess(responseData));
    } catch (e) {
        yield put(fetchForumListByUserIdFail(e.message));
    }
}


export function* fetchForumDetailsByForumIdSaga(action: fetchForumDetailsByForumIdAction): any {
    try {
        const { forumId } = action.payload;
        const response = yield call(ForumRepository.fetchForumDetailsByForumId, forumId);
        yield put(fetchForumDetailsByForumIdSuccess(response[0], forumId));
    } catch (e) {
        yield put(fetchForumDetailsByForumIdFail(e.message, action.payload.forumId));
    }
}

export function* fetchRecommendedForumListSaga(action: fetchRecommendedForumListAction): any {
    try {
        const recommendedForum = yield call(ForumRepository.fetchRecommendedForumList, action.payload);
        const myForum = yield call(ForumRepository.fetchForumListByUserId, getLoggedInUserId());
        const myForumKeys = myForum?.forums?.map((item) => item?.forumId);
        const filteredRecommendedForum = [];
        recommendedForum?.forums?.map((item) => {
            if (!myForumKeys?.includes(item?.discourseCategorySlug)) {
                filteredRecommendedForum.push(item)
            }
        })
        yield put(fetchRecommendedForumListSuccess({ forums: filteredRecommendedForum, total: filteredRecommendedForum?.length }));
    } catch (e) {
        yield put(fetchRecommendedForumListFail(e.message));
    }
}

export function* watchForumSaga(): Generator<ForkEffect> {
    yield takeLatest(forumActions.FETCH_FORUM_LIST_BY_USERID, fetchForumListByUserIdSaga);
    yield takeEvery(forumActions.FETCH_FORUM_DETAILS_BY_FORUMID, fetchForumDetailsByForumIdSaga);
    yield takeLatest(forumActions.FETCH_RECOMMENDED_FORUM_LIST, fetchRecommendedForumListSaga)
}
