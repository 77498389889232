import { Institute } from "@common/domain/models/Institute";
import { ProfileModel } from "@common/domain/models/Profile";

import { IState } from "@store/webInterfaces";

const isInstitutesStateUndefined = (state: any) =>
  state.institute === undefined;

const isInstitutesLoaderUndefined = (state: any) =>
  state.institute.loaders === undefined;

const isInstitutesErrorsUndefined = (state: any) =>
  state.institute.errors === undefined;

export const fetchInstitutesLoaderSelector = (state: any): boolean => {
  if (
    isInstitutesStateUndefined(state) ||
    isInstitutesLoaderUndefined(state) ||
    !state.institute.loaders.fetchInstitutes
  )
    return false;
  return state.institute.loaders.fetchInstitutes;
};

export const fetchInstitutesErrorSelector = (state: any) => {
  if (
    isInstitutesStateUndefined(state) ||
    isInstitutesErrorsUndefined(state) ||
    !state.institute.errors.fetchInstitutes
  )
    return "";
  return state.institute.errors.fetchInstitutes;
};

export const fetchInstitutesSelector = (state: any) => {
  if (isInstitutesStateUndefined(state) || !state.institute.institutes)
    return null;

  return state.institute.institutes;
};

export const fetchFacultyCoursesLoaderSelector = (state: any): boolean => {
  if (
    isInstitutesStateUndefined(state) ||
    isInstitutesLoaderUndefined(state) ||
    !state.institute.loaders.fetchCourses
  )
    return false;
  return state.institute.loaders.fetchCourses;
};

export const fetchFacultyCoursesErrorSelector = (state: any) => {
  if (
    isInstitutesStateUndefined(state) ||
    isInstitutesErrorsUndefined(state) ||
    !state.institute.errors.fetchCourses
  )
    return "";
  return state.institute.errors.fetchCourses;
};

export const fetchFacultyCoursesSelector = (state: any) => {
  if (isInstitutesStateUndefined(state) || !state.institute.courses)
    return null;

  return state.institute.courses;
};

export const getInstituteByIdSelector = (
  state: IState,
  instituteId: number
): Institute | null => {
  if (isInstitutesStateUndefined(state) || !state.institute.instituteById) return null;

  return state.institute.instituteById[instituteId];
};

export const getInstituteMapSelector = (state: IState): Institute | {} => {
  if (isInstitutesStateUndefined(state) || !state.institute.instituteById)
    return {};

  return state.institute.instituteById;
};

export const fetchMultipleInstituteLoaderSelector = (state: IState): boolean => {
  if (
    isInstitutesStateUndefined(state) ||
    isInstitutesLoaderUndefined(state) ||
    !state.institute.loaders.fetchMutipleInstituteDetail
  )
    return false;
  return state.institute.loaders.fetchMutipleInstituteDetail;
};

export const fetchInstituteFacultiesLoaderSelector = (state: IState): boolean => {
  if (
    isInstitutesStateUndefined(state) ||
    isInstitutesLoaderUndefined(state) ||
    !state.institute.loaders.fetchInstituteFaculties
  )
    return false;
  return state.institute.loaders.fetchInstituteFaculties;
};

export const fetchInstituteFacultiesErrorSelector = (state: IState): string => {
  if (
    isInstitutesStateUndefined(state) ||
    isInstitutesErrorsUndefined(state) ||
    !state.institute.errors.fetchInstituteFaculties
  )
    return "";
  return state.institute.errors.fetchInstituteFaculties;
};

export const fetchInstituteFacultiesList = (state: IState): ProfileModel[] => {
  if (isInstitutesStateUndefined(state) || !state.institute.faculties) return null;

  return state.institute.faculties;
};

export const fetchCourseRootMapByIdSelector = (state: IState, courseRootId: string) => state?.institute?.byCourseRootId[courseRootId] || {};

export const fetchUserInstituteSelector = (state: IState) => state?.institute?.userInstitute || [];
export const fetchUserInstituteLoaderSelector = (state: IState) => state?.institute?.loaders?.userInstitute || false;
export const fetchUserInstituteErrorSelector = (state: IState) => state?.institute?.errors?.userInstitute || "";


