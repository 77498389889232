import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { getLoggedInUserId } from "@utils/UserUtils";

import { NavigationUrl, URLs } from "@navigator/NavigationUrl";

import Dashboard from "@layout/Dashboard";

interface IProps extends RouteProps{
  component: React.ComponentType<any>;
  location: any;
  isAuthenticated: boolean;
  exact?: boolean;
  to?: string;
  path: string | string[];
}

export class RoutePrivate extends React.Component<IProps, any> {
  private defaultPublicRoute = NavigationUrl.generate(URLs.guest);

  private renderDashboard = () => {
    return <Dashboard {...this.props} />;
  };

  private renderRedirect = (routeProps: any, to: any) => {
    return (
      <Redirect
        to={{
          pathname: to,
        }}
      />
    );
  };

  render() {
    const {
      component: Component,
      isAuthenticated,
      to = this.defaultPublicRoute,
      path,
      ...rest
    } = this.props;

    if (isAuthenticated && getLoggedInUserId()) {
      return <Route {...rest} render={this.renderDashboard} />;
    } else {
      return (
        <Route {...rest} render={(props) => this.renderRedirect(props, to)} />
      );
    }
  }
}
