import { APIv1 } from "@common/network/constants";
import { FileServiceBuckets, FileServiceIncludeTypes } from "@constants/config";
import { ApiClient } from "@network/client";

const ENDPOINTS = {
    uploadFile: (bucket: FileServiceBuckets) => `${APIv1}/files/${bucket}`,
};

class UploadRepository {
  private apiClient: any;

  constructor(apiClient: any) {
    this.apiClient = apiClient;
  }

  public uploadFile = async (file: any, bucket: FileServiceBuckets, include?: FileServiceIncludeTypes) => {

      const params = {
        include: include
      }

      const response = await this.apiClient.post(ENDPOINTS.uploadFile(bucket), file, params);
      return response;
  };

  public uploadImage = async (image: any, bucket: FileServiceBuckets, thumbnailAspectRatio?: number, include?: FileServiceIncludeTypes) => {

    const params = {
      include: include,
      thumbnailAspectRatio: thumbnailAspectRatio
    }
    
    const response = await this.apiClient.post(ENDPOINTS.uploadFile(bucket), image, params);
    return response;
};
}

const uploadRepository = new UploadRepository(ApiClient);

export { uploadRepository as UploadRepository };
