import { fetchForumListByUserIdPayload, fetchForumListByUserIdAction, fetchForumDetailsByForumIdPayload, fetchForumDetailsByForumIdAction, IFetchRecommendedForumListPayload, fetchRecommendedForumListAction } from "./interface";

const actionTypePrefix = "FORUM";

export const forumActions = {
    FETCH_FORUM_LIST_BY_USERID: `${actionTypePrefix}/FETCH_FORUM_LIST_BY_USERID`,
    FETCH_FORUM_LIST_BY_USERID_SUCCESS: `${actionTypePrefix}/FETCH_FORUM_LIST_BY_USERID_SUCCESS`,
    FETCH_FORUM_LIST_BY_USERID_FAIL: `${actionTypePrefix}/FETCH_FORUM_LIST_BY_USERID_FAIL`,

    FETCH_FORUM_DETAILS_BY_FORUMID: `${actionTypePrefix}/FETCH_FORUM_DETAILS_BY_FORUMID`,
    FETCH_FORUM_DETAILS_BY_FORUMID_SUCCESS: `${actionTypePrefix}/FETCH_FORUM_DETAILS_BY_FORUMID_SUCCESS`,
    FETCH_FORUM_DETAILS_BY_FORUMID_FAIL: `${actionTypePrefix}/FETCH_FORUM_DETAILS_BY_FORUMID_FAIL`,

    FETCH_RECOMMENDED_FORUM_LIST:  `${actionTypePrefix}/FETCH_RECOMMENDED_FORUM_LIST`,
    FETCH_RECOMMENDED_FORUM_LIST_SUCCESS:  `${actionTypePrefix}/FETCH_RECOMMENDED_FORUM_LIST_SUCCESS`,
    FETCH_RECOMMENDED_FORUM_LIST_FAIL:  `${actionTypePrefix}/FETCH_RECOMMENDED_FORUM_LIST_FAIL`,
};


export const fetchForumListByUserId = (payload: fetchForumListByUserIdPayload): fetchForumListByUserIdAction => {
    return {
        type: forumActions.FETCH_FORUM_LIST_BY_USERID,
        payload
    }
}

export const fetchForumListByUserIdSuccess = (response: any) => {
    return {
        type: forumActions.FETCH_FORUM_LIST_BY_USERID_SUCCESS,
        payload: response
    }
}

export const fetchForumListByUserIdFail = (error: string) => {
    return {
        type: forumActions.FETCH_FORUM_LIST_BY_USERID_FAIL,
        payload: error
    }
}

export const fetchForumDetailsByForumId = (payload: fetchForumDetailsByForumIdPayload): fetchForumDetailsByForumIdAction => {
    return {
        type: forumActions.FETCH_FORUM_DETAILS_BY_FORUMID,
        payload
    }
}

export const fetchForumDetailsByForumIdSuccess = (response:any,forumId:string) => {
    return {
        type: forumActions.FETCH_FORUM_DETAILS_BY_FORUMID_SUCCESS,
        payload: {response,forumId}
    }
}

export const fetchForumDetailsByForumIdFail = (error:string,forumId:string) => {
    return {
        type: forumActions.FETCH_FORUM_DETAILS_BY_FORUMID_FAIL,
        payload: {error,forumId}
    }
}

export const fetchRecommendedForumList = (payload: IFetchRecommendedForumListPayload): fetchRecommendedForumListAction => {
    return {
        type: forumActions.FETCH_RECOMMENDED_FORUM_LIST,
        payload
    }
}

export const fetchRecommendedForumListSuccess = (response: any) => {
    return {
        type: forumActions.FETCH_RECOMMENDED_FORUM_LIST_SUCCESS,
        payload: response
    }
}

export const fetchRecommendedForumListFail = (error: string) => {
    return {
        type: forumActions.FETCH_RECOMMENDED_FORUM_LIST_FAIL,
        payload: error
    }
}
