import React from 'react';
import { Menu } from "antd";

interface IProps {
    className?: any;
    disabled?: boolean;
    key: string;
    children?: any;
    icon?: React.ReactNode;
    onClick?: () => void;
}

export default function(props: IProps) {
    const { key, icon, className, children, ...rest } = props;
    return (
      <Menu.Item key={key} className={`${className}`} icon={icon} {...rest} tabIndex={0}>
        { children }
      </Menu.Item>
    );
};
