import { StudentCourseActivity } from "@common/domain/models/StudentCourseActivity";

const actionTypePrefix = "REPORT";

export const reportActions = {
  FETCH_REPORTS: `${actionTypePrefix}/FETCH_REPORTS`,
  FETCH_REPORTS_SUCCESS: `${actionTypePrefix}/FETCH_REPORTS_SUCCESS`,
  FETCH_REPORTS_FAIL: `${actionTypePrefix}/FETCH_REPORTS_FAIL`,


  FETCH_MULTIPLE_COURSES_REPORT: `${actionTypePrefix}/FETCH_MULTIPLE_COURSES_REPORT`,
  FETCH_MULTIPLE_COURSES_REPORT_SUCCESS: `${actionTypePrefix}/FETCH_MULTIPLE_COURSES_REPORT_SUCCESS`,
  FETCH_MULTIPLE_COURSES_REPORT_FAIL: `${actionTypePrefix}/FETCH_MULTIPLE_COURSES_REPORT_FAIL`,

  FETCH_COURSE_ACTIVITY_REPORT: `${actionTypePrefix}/FETCH_COURSE_ACTIVITY_REPORT`,
  FETCH_COURSE_ACTIVITY_REPORT_SUCCESS: `${actionTypePrefix}/FETCH_COURSE_ACTIVITY_REPORT_SUCCESS`,
  FETCH_COURSE_ACTIVITY_REPORT_FAIL: `${actionTypePrefix}/FETCH_COURSE_ACTIVITY_REPORT_FAIL`,

};

export interface IFetchReportsAction {
  type: string;
  payload: {
    userId: string;
    courseId: string;
  };
}

export const fetchReports = (userId: string, courseId: string): IFetchReportsAction => {
  return {
    type: reportActions.FETCH_REPORTS,
    payload: {
      userId,
      courseId,
    }
  };
};

export const fetchReportsSuccess = (courseId: string, quizData: any[]) => {
  return {
    type: reportActions.FETCH_REPORTS_SUCCESS,
    payload: {
      courseId,
      quizData
    },
  };
};

export const fetchReportsFailure = (error: string) => {
  return {
    type: reportActions.FETCH_REPORTS_FAIL,
    payload: error,
  };
};



export interface IFetchCourseReportsAction {
  type: string;
  payload: {
    userId: string,
    courseIds: string[]
  };
}

export const fetchMultipleCourseReports = (
  userId: string, courseIds: string[]
): IFetchCourseReportsAction => {
  return {
    type: reportActions.FETCH_MULTIPLE_COURSES_REPORT,
    payload: { userId, courseIds },
  };
};

export const fetchMultipleCourseReportsSuccess = (
  reportsData: any[]
) => {
  return {
    type: reportActions.FETCH_MULTIPLE_COURSES_REPORT_SUCCESS,
    payload: reportsData,
  };
};

export const fetchMultipleCourseReportsFailure = (error: string) => {
  return {
    type: reportActions.FETCH_MULTIPLE_COURSES_REPORT_FAIL,
    payload: error,
  };
};

export const fetchCourseActivityReport = (courseId: string, studentId: string, courseLmsId?: number) => ({
  type: reportActions.FETCH_COURSE_ACTIVITY_REPORT,
  payload: {
    courseId,
    courseLmsId,
    studentId
  }
});

export const fetchCourseActivityReportSuccess = (courseId: string, activityReport: StudentCourseActivity[]) => ({
  type: reportActions.FETCH_COURSE_ACTIVITY_REPORT_SUCCESS,
  payload: { courseId, activityReport },
});

export const fetchCourseActivityReportFailure = (error: string) => ({
  type: reportActions.FETCH_COURSE_ACTIVITY_REPORT_FAIL,
  payload: error,
});
