import { ObjectMapper } from 'json-object-mapper';

import { IChatRepository } from '@repositories/interfaces';

import { APIv1 } from '@common/network/constants';

import { IGetConversation } from '@redux/chat/actions';

import { ConversationModel } from '@models/Conversation';
import { MessageModel } from '@models/Message';

import { ApiClient } from '@network/client';
import { IApiClient } from '@network/interfaces';

const ENDPOINTS = {
  getConversation: (profileId: string) => `${APIv1}/organizationservice/profiles/${profileId}/conversations`,
  getConversationById : (conversationId: string) => `${APIv1}/organizationservice/conversations/${conversationId}`,
  getUnreadConversationCount: (userId: string) => `${APIv1}/chatservice/users/${userId}/conversations/unreadCount`
};

export default class ChatRepository implements IChatRepository {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  public getConversation = async (userProfileId: string, conversation: IGetConversation): Promise<ConversationModel> => {
    const response = await this.apiClient.put(ENDPOINTS.getConversation(userProfileId), conversation);
    const conversationModel = ObjectMapper.deserialize(ConversationModel, response.conversation);
    if ( response.conversation.lastMessage ) {
      const lastMessage = ObjectMapper.deserialize(MessageModel, response.conversation.lastMessage);
      conversationModel.setLastMessage(lastMessage);
    }
    return conversationModel;
  }

  public getConversationById = async (conversationId: string): Promise<ConversationModel> => {
    const response = await this.apiClient.get(ENDPOINTS.getConversationById(conversationId));
    const conversationModel = ObjectMapper.deserialize(ConversationModel, response.conversation);
    if ( response.conversation.lastMessage ) {
      const lastMessage = ObjectMapper.deserialize(MessageModel, response.conversation.lastMessage);
      conversationModel.setLastMessage(lastMessage);
    }
    return conversationModel;
  }

  public getUnreadConversationCount = async (userId: string): Promise<number> => {
    const response = await this.apiClient.get(ENDPOINTS.getUnreadConversationCount(userId));
    return response;
  }
}

const chatRepository = new ChatRepository(ApiClient);

export { chatRepository as ChatRepository };
