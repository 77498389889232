import { JsonProperty } from "json-object-mapper";

export class CourseCompetencyModel {
    @JsonProperty({ name: "id", required: true })
    private id: string = "";

    @JsonProperty({ name: "name", required: true })
    private name: string = "";

    public getId = (): string => {
        return this.id;
    };

    public getName = (): string => {
        return this.name;
    };

}
