import { ChatActions, SocketActionTypes } from '@redux/chat/actions';
import { ConversationModel } from '@models/Conversation';
import { MessageModel } from '@models/Message';
import { CONVERSATIONS_PAGE_LIMIT, DIRECTION } from '@constants/config';
import ChatDataMappingUtil from './ChatDataMappingUtil';
import { DataUtility } from '@utils/DataUtility';

export interface IChatState {
  isSocketActivated: boolean;
  socketStatus: {
    status: string;
    statusData: any;
  };

  loaders: {
    unreadConversationCount: boolean;
    getConversation: boolean;
    fetchConversations: boolean;
    fetchMessages: boolean;
    newChatProfileLoader: string;
  };
  errors: {
    getConversation: string;
    fetchConversations: string;
    fetchMessages: string;
    postMessageError: string;
    unreadConversationCount: string;
  };
  activeConversation: ConversationModel | null;
  conversations: ConversationModel[];
  isLastPage: boolean;
  pageNumber: number;
  messages: Map<string, MessageModel[]>;
  loadEarlierMessages: Map<string, boolean>;
  newChatProfileId: string;
  newConvoStarted: boolean;
  webChatStarted: boolean;
  unreadConversationCount: number;
}

export const PAGE_NUMBER = 1;
export const initialState: IChatState = {

  isSocketActivated: false,
  socketStatus: {
    status: '',
    statusData: {},
  },
  loaders: {
    getConversation: false,
    fetchConversations: false,
    fetchMessages: false,
    newChatProfileLoader: '',
    unreadConversationCount: false
  },
  errors: {
    getConversation: '',
    fetchConversations: '',
    fetchMessages: '',
    postMessageError: '',
    unreadConversationCount: ''
  },
  activeConversation: null,
  conversations: [],
  isLastPage: false,
  pageNumber: PAGE_NUMBER,
  messages: new Map<string, MessageModel[]>(),
  loadEarlierMessages: new Map<string, boolean>(),
  newChatProfileId: '',
  newConvoStarted: false,
  webChatStarted: false,
  unreadConversationCount: 0
};

export const chatReducer = (state = initialState, action: { type: string, payload: any }) => {

  switch (action.type) {

    case SocketActionTypes.WEBSOCKET_SOCKET_ACTIVATION_STATUS:

      let socketStatus = state.socketStatus;
      if (!action.payload.isSocketActivated) {
        socketStatus = {
          status: '',
          statusData: {},
        };
      }
      return {
        ...state,
        isSocketActivated: action.payload.isSocketActivated,
        socketStatus,
      };

    case SocketActionTypes.WEBSOCKET_UPDATE_STATUS:
      return {
        ...state,
        socketStatus: { ...action.payload },
      };

    case ChatActions.GET_CONVERSATION:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getConversation: true,
        },
        errors: {
          ...state.errors,
          getConversation: '',
        },
      };

    case ChatActions.GET_CONVERSATION_SUCCESS:
      const newConversation: ConversationModel = action.payload;
      let updatedConversations = state.conversations;
      if (!state.newChatProfileId && !state.newConvoStarted) {
        updatedConversations = ChatDataMappingUtil
          .addNewConversation(state.conversations, newConversation);
      }
      return {
        ...state,
        activeConversation: newConversation,
        conversations: updatedConversations,
        loaders: {
          ...state.loaders,
          getConversation: false,
        },
        errors: {
          ...state.errors,
          getConversation: '',
        },
      };

    case ChatActions.GET_CONVERSATION_FAILURE:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getConversation: false,
        },
        errors: {
          ...state.errors,
          getConversation: action.payload,
        },
      };

    case ChatActions.FETCH_CONVERSATIONS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          fetchConversations: true,
        },
        errors: {
          ...state.errors,
          fetchConversations: '',
        },
        pageNumber: action.payload.page,
      };

    case ChatActions.FETCH_CONVERSATIONS_SUCCESS:
      const conversations = action.payload.conversations;
      let updatedConversation = ChatDataMappingUtil.updateConversations(state.conversations,
        conversations, true, action.payload.reset);
      updatedConversation = ChatDataMappingUtil
        .addNewConversation(updatedConversation, state.activeConversation, true);

      return {
        ...state,
        loaders: {
          ...state.loaders,
          fetchConversations: false,
        },
        errors: {
          ...state.errors,
          fetchConversations: '',
        },
        conversations: updatedConversation,
        isLastPage: conversations.length < CONVERSATIONS_PAGE_LIMIT,
      };

    case ChatActions.FETCH_CONVERSATIONS_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          fetchConversations: false,
        },
        errors: {
          ...state.errors,
          fetchConversations: action.payload,
        },
      };

    case ChatActions.UPDATE_ACTIVE_CONVERSATION:
      return {
        ...state,
        activeConversation: action.payload.conversation,
      };

    case ChatActions.FETCH_MESSAGES:

      return {
        ...state,
        loaders: {
          ...state.loaders,
          fetchMessages: true,
        },
        errors: {
          ...state.errors,
          fetchMessages: '',
        },
      };
    case ChatActions.FETCH_MESSAGES_SUCCESS:
      const { conversationId, messages, reset } = action.payload;
      // reorder messages
      let updatedMsgs = ChatDataMappingUtil
        .updateChatMessage(state.messages.get(conversationId), messages, true, reset);
      // update isLoadMoreMessages flag if messages less than the limit set to false
      if (action.payload.direction !== DIRECTION.before) {
        // removing possible duplicates, probably duplicates by received msgs
        updatedMsgs = ChatDataMappingUtil.removeLatestDuplicateMessages(updatedMsgs, 20);
      }
      state.messages.set(conversationId, updatedMsgs);
      return {
        ...state,
        loaders: {
          ...state.loaders,
          fetchMessages: false,
        },
        errors: {
          ...state.errors,
          fetchMessages: '',
        },

      };

    case ChatActions.FETCH_MESSAGES_FAIL:

      return {
        ...state,
        loaders: {
          ...state.loaders,
          fetchMessages: false,
        },
        errors: {
          ...state.errors,
          fetchMessages: action.payload,
        },
      };

    case ChatActions.POST_MESSAGE:
      if (state.activeConversation) {
        const activeConId = state.activeConversation.getId();
        // reorder messages
        state.messages.set(activeConId,
          ChatDataMappingUtil
            .updateChatMessage([action.payload.message], state.messages.get(state.activeConversation.getId()),
              false, action.payload.reset));
      }
      return {
        ...state,
        errors: {
          ...state.errors,
          postMessageError: '',
        },
      };

    case ChatActions.POST_MESSAGE_SUCCESS:
      let latestConversations;
      if (state.activeConversation) {
        latestConversations = ChatDataMappingUtil
          .addNewConversation(state.conversations, state.activeConversation, true);
        state.activeConversation.setLastMessage(action.payload.message);
        // remove existing message and update sent state message
        const message2 = state.messages.get(state.activeConversation.getId())!.map((item: MessageModel) =>
          item.getClientMessageId() === action.payload.message.getClientMessageId() ? action.payload.message : item);
        // reorder and set
        state.messages.set(state.activeConversation.getId(),
          ChatDataMappingUtil.updateChatMessage(message2, [], false));
      }
      return {
        ...state,
        conversations: latestConversations ? latestConversations : state.conversations,
        errors: {
          ...state.errors,
          postMessageError: '',
        },
      };

    case ChatActions.POST_MESSAGE_FAIL:
      return {
        ...state,
        errors: {
          ...state.errors,
          postMessageError: action.payload,
        },
      };

    case ChatActions.UPDATE_RECEIVED_MESSAGE:
      const receivedMessage = action.payload.message;

      if (state.activeConversation && (state.activeConversation.getId() === receivedMessage.getConversationId())) {
        // update received messages & last message in the activeConversation
        state.activeConversation.setLastMessage(receivedMessage);
        // removing possible duplicated messages, considering last 20 messages
        let allMessages = ChatDataMappingUtil
          .updateChatMessage(state.messages.get(state.activeConversation.getId()), [receivedMessage], true);
        allMessages = ChatDataMappingUtil.removeLatestDuplicateMessages(allMessages, 20);
        // reorder messages
        state.messages.set(state.activeConversation.getId(), allMessages);
        return updateConversationOnMessageReceive(state, receivedMessage);
      } else {
        // update lasMessage in the matching conversation in the background conversations
        return updateConversationOnMessageReceive(state, receivedMessage);
      }
    case ChatActions.MESSAGE_READ_RECEIPT_SUCCESS:
      const conversationsData = [...state.conversations];
      const conversation = conversationsData.find((item) => { if (item) { return item.getId() === action.payload.getId() }});
      if (conversation) {
        conversation.setLastReadInfo(action.payload.getLastReadInfo());
      }
      return {
        ...state,
        conversations: conversationsData,
      };
    case ChatActions.UPDATE_LOAD_EARLIER_MESSAGES_FLAG:
      const { convId, loadEarlier } = action.payload;
      state.loadEarlierMessages.set(convId, loadEarlier);
      return {
        ...state,
      };
    case ChatActions.CLEAR_CHAT_CONVERSATIONS:
      return {
        ...state,
        conversations: [],
        newChatProfileId: '',
        messages: new Map<string, MessageModel[]>(),
      };
    case ChatActions.START_NEW_CHAT:
      return {
        ...state,
        newChatProfileId: action.payload.profileId,
      };
    case ChatActions.CLEAR_NEW_CHAT_PROFILE_ID:
      return {
        ...state,
        newChatProfileId: '',
        newConvoStarted: false,
      };
    case ChatActions.START_NEW_CONVO:
      const data = action.payload.data;
      const otherProfileId = data.conversation.profileIds[1];
      return {
        ...state,
        loaders: {
          ...state.loaders,
          newChatProfileLoader: otherProfileId,
        },
      };
    case ChatActions.START_NEW_CONVO_SUCCESS:
      return {
        ...state,
        newConvoStarted: true,
        loaders: {
          ...state.loaders,
          newChatProfileLoader: '',
        }
      };
    case ChatActions.ADD_CONVO_ON_TOP:
      return {
        ...state,
        newConvoStarted: true,
        conversations: ChatDataMappingUtil
          .addNewConversation(state.conversations, state.activeConversation, true),
      };
    case ChatActions.WEB_CHAT_STARTED:
      return {
        ...state,
        webChatStarted: true,
      };
    case ChatActions.WEB_CHAT_STOPPED:
      return {
        ...state,
        webChatStarted: false,
      }

    case ChatActions.GET_UNREAD_CONVERSATION_COUNT:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          unreadConversationCount: true
        },
        errors: {
          ...state.errors,
          unreadConversationCount: '',
        },
      }
    case ChatActions.GET_UNREAD_CONVERSATION_COUNT_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          unreadConversationCount: false
        },
        unreadConversationCount: action.payload
      }
    case ChatActions.GET_UNREAD_CONVERSATION_COUNT_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          unreadConversationCount: false
        },
        errors: {
          ...state.errors,
          unreadConversationCount: action.payload,
        },
      }
    case ChatActions.DECREMENT_UNREAD_CONVERSATION_COUNT:
      return {
        ...state,
        unreadConversationCount: state.unreadConversationCount - 1
      }
    default:
      return state;
  }
};

const updateConversationOnMessageReceive = (state: any, receivedMessage: any) => {
  const conversations = [...state.conversations];
  const conversationIndexForReceivedMessage = DataUtility.findIndex(conversations, (item: ConversationModel) => {
    return item?.getId() === receivedMessage.getConversationId();
  });

  if (conversationIndexForReceivedMessage === -1) {
    // this case never occurs as we updating conversations list in saga if not exists
    return state;
  } else {
    const conversationForReceivedMessage = conversations[conversationIndexForReceivedMessage];
    conversationForReceivedMessage.setLastMessage(receivedMessage);
    DataUtility.pullAt(conversations, [conversationIndexForReceivedMessage]);
    return {
      ...state,
      conversations: [conversationForReceivedMessage, ...conversations],
    };
  }
};
