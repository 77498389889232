import React, { ReactNode } from "react";
import "./styles.less";
import { FontFamilyVariant } from "../../utils"

interface IProps {
  variant: FontFamilyVariant;
  text: string | ReactNode;
  className?: string;
}

const CardText = (props: IProps) => {
  const { text, variant, className } = props;

  const customStyle = className ? `${variant} ${className}` : variant;

  switch (variant) {
    case FontFamilyVariant.cardTitleXLarge:
      return <h1 className={`typography-text ${customStyle}`}>{text}</h1>;
    case FontFamilyVariant.cardTitleLarge:
      return <h2 className={`typography-text ${customStyle}`}>{text}</h2>;
    case FontFamilyVariant.cardTitleMedium:
      return <h4 className={`typography-text ${customStyle}`}>{text}</h4>;
    case FontFamilyVariant.cardTitleSmall:
      return <h5 className={`typography-text ${customStyle}`}>{text}</h5>;
    case FontFamilyVariant.cardTitleXSmall:
      return <h6 className={`typography-text ${customStyle}`}>{text}</h6>;

  }
}

export default CardText;
