export { default as InboxOutlined } from '@ant-design/icons/InboxOutlined';
export { default as CloudUploadOutlined } from '@ant-design/icons/CloudUploadOutlined';
export { default as WechatOutlined } from '@ant-design/icons/WechatOutlined';
export { default as DownloadOutlined } from '@ant-design/icons/DownloadOutlined';
export { default as AudioOutlined } from '@ant-design/icons/AudioOutlined';
export { default as TeamOutlined } from '@ant-design/icons/TeamOutlined';
export { default as LogoutOutlined } from '@ant-design/icons/LogoutOutlined';
export { default as CalendarOutlined } from '@ant-design/icons/CalendarOutlined';
export { default as UserOutlined } from '@ant-design/icons/UserOutlined';
export { default as CloseOutlined } from '@ant-design/icons/CloseOutlined';
export { default as CheckCircleFilled } from '@ant-design/icons/CheckCircleFilled';
export { default as DatabaseOutlined } from '@ant-design/icons/DatabaseOutlined';
export { default as HddOutlined } from '@ant-design/icons/HddOutlined';
export { default as UsergroupAddOutlined } from '@ant-design/icons/UsergroupAddOutlined';
export { default as HomeOutlined } from '@ant-design/icons/HomeOutlined';
export { default as LeftOutlined } from '@ant-design/icons/LeftOutlined';
export { default as CopyOutlined } from '@ant-design/icons/CopyOutlined';
export { default as CaretDownFilled } from '@ant-design/icons/CaretDownFilled';
export { default as BarChartOutlined } from '@ant-design/icons/BarChartOutlined';
export { default as QuestionCircleOutlined } from '@ant-design/icons/QuestionCircleOutlined';
export { default as ReadOutlined } from '@ant-design/icons/ReadOutlined';
export { default as SearchOutlined } from '@ant-design/icons/SearchOutlined';
export { default as FileOutlined } from '@ant-design/icons/FileOutlined';
export { default as PaperClipOutlined } from '@ant-design/icons/PaperClipOutlined';
export { default as PictureOutlined } from '@ant-design/icons/PictureOutlined';
export { default as PlusSquareOutlined } from '@ant-design/icons/PlusSquareOutlined';
export { default as SettingOutlined } from '@ant-design/icons/SettingOutlined';
export { default as FullscreenExitOutlined} from "@ant-design/icons/FullscreenExitOutlined";
export { default as FullscreenOutlined} from "@ant-design/icons/FullscreenOutlined";
export { default as RightOutlined} from "@ant-design/icons/RightOutlined";
export { default as UpOutlined } from '@ant-design/icons/UpOutlined';
export { default as DownOutlined } from '@ant-design/icons/DownOutlined';
export { default as BookOutlined } from '@ant-design/icons/BookOutlined';
// WO-CODE-VARIANT
// export { default as InboxOutlined } from '@ant-design/icons/InboxOutlined';

// export { default as DownloadOutlined } from '@ant-design/icons/DownloadOutlined';
export { default as FormOutlined } from '@ant-design/icons/FormOutlined';
export { default as LocationOutlined } from '@ant-design/icons/EnvironmentOutlined';
export { default as DislikeOutlined } from "@ant-design/icons/DislikeOutlined";
export { default as LikeOutlined } from "@ant-design/icons/LikeOutlined";
export { default as UnorderedListOutlined } from "@ant-design/icons/UnorderedListOutlined";
export { default as CaretDownOutlined } from "@ant-design/icons/CaretDownOutlined";
