import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { AICoachRepository } from "@common/domain/repositories/AICoachRepository";

import {
  aiCoachActions,
  fetchConversationFail,
  fetchConversationSuccess,
  fetchPropmtsFail,
  fetchPropmtsSuccess,
  IFetchPropmtsAction,
  deleteConversationSuccess,
  deleteConversationFail,
  IFetchConversationAction,
  fetchPropmtsMetadataSuccess,
  fetchPropmtsMetadataFail,
  fetchJobRolesSuccess,
  fetchJobRolesFail,
  IDeleteConversationAction,
  IFetchPromptMetadataAction,
  IFetchJobRolesAction,
  sendUserFeedbackSuccess,
  sendUserFeedbackFail,
  IUserFeedbackAction
} from "./actions";
import { getLoggedInUserId } from "@utils/UserUtils";

export function* fetchPromptsSaga(action: IFetchPropmtsAction) {
  try {
    const response = yield call(AICoachRepository.fetchPrompts, action.payload);
    yield put(fetchPropmtsSuccess(response.tags[0].tags[0].groups));
  } catch (e) {
    yield put(fetchPropmtsFail(e.message));
  }
};

export function* fetchConversationSaga(action: IFetchConversationAction) {
  const { entityId, page, limit, tags } = action.payload;
  try {
    const response = yield call(AICoachRepository.fetchConversations, entityId, page, limit, tags);
    yield put(fetchConversationSuccess(page, response.conversations?.reverse(), response.totalCount));
  } catch (e) {
    yield put(fetchConversationFail(e.message));
  }
};


export function* deleteConversationSaga(action: IDeleteConversationAction) {
  const { entityId } = action.payload;
  try {
    const response = yield call(AICoachRepository.deleteConversations, entityId);
    yield put(deleteConversationSuccess(response.conversations));
  } catch (e) {
    yield put(deleteConversationFail(e.message));
  }
};

export function* fetchPrmptsMetadata(action: IFetchPromptMetadataAction) {
  try {
    const response = yield call(AICoachRepository.getPromptMetadata, action.payload);
    yield put(fetchPropmtsMetadataSuccess(response));
  } catch (e) {
    yield put(fetchPropmtsMetadataFail(e.message));
  }
};

export function* fetchJobRoleSaga(action: IFetchJobRolesAction) {
  try {
    const response = yield call(AICoachRepository.fetchJobRoles, action.payload);
    let jobRoles = [];
    if (response && response?.length > 0) {
      jobRoles = response.map((eachRole, index) => ({
        name: eachRole,
        id: eachRole + index
      }));
    }
    yield put(fetchJobRolesSuccess(jobRoles));
  } catch (e) {
    yield put(fetchJobRolesFail(e.message));
  }
};

export function* sendUserFeedbackSaga(action: IUserFeedbackAction) {
  try {
    const userId = getLoggedInUserId()
    const response = yield call(AICoachRepository.userFeedback, action.payload, userId);
    yield put(sendUserFeedbackSuccess(response));
  } catch (e) {
    yield put(sendUserFeedbackFail(e.message));
  }
}

export function* watchAICoach(): any {
  yield takeLatest(aiCoachActions.FETCH_PROMPTS, fetchPromptsSaga);
  yield takeEvery(aiCoachActions.FETCH_CONVERSATION, fetchConversationSaga);
  yield takeLatest(aiCoachActions.DELETE_CONVERSATION, deleteConversationSaga);
  yield takeEvery(aiCoachActions.GET_PROMPT_METADATA, fetchPrmptsMetadata);
  yield takeLatest(aiCoachActions.FETCH_JOB_ROLE, fetchJobRoleSaga);
  yield takeLatest(aiCoachActions.USER_FEEDBACK, sendUserFeedbackSaga);

};
