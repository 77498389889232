import { JsonProperty } from "json-object-mapper";

export interface ILangugage {
  id: number;
  name: string;
  languageCode: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export class Language {
  @JsonProperty({ name: "id", required: true })
  public id: number = 0;

  @JsonProperty({ name: "name", required: true })
  public name: string = "";

  @JsonProperty({ name: "languageCode", required: true })
  public languageCode: string = "";

  @JsonProperty({ name: "createdAt", required: true })
  public createdAt: string = "";

  @JsonProperty({ name: "updatedAt", required: true })
  public updatedAt: string = "";

  @JsonProperty({ name: "deletedAt", required: false })
  public deletedAt: string = "";

  public getId(): number {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }

  public getLanguageCode(): string {
    return this.languageCode;
  }

  public getCreatedAt(): string {
    return this.createdAt;
  }

  public getUpdatedAt(): string {
    return this.updatedAt;
  }

  public getDeletedAt(): string {
    return this.deletedAt;
  }
}
