import { JsonProperty } from "json-object-mapper";

export enum CommentEntitiesKeys {
  milestoneId = "milestone",
  eventId = "event"
}

export enum CommentEntitiesKeysTags {
  milestone = "milestoneId",
  event = "eventId"
}

export const CommentEntitiesKeysService  = {
  [CommentEntitiesKeys.milestoneId]: "profileservice",
  [CommentEntitiesKeys.eventId]: "organizationservice",
}

export interface ICommentTags {
  [CommentEntitiesKeys.milestoneId]?: string;
  [CommentEntitiesKeys.eventId]?: string;
};

export interface IComment {
    id?: string;
    comment: string;
    referTo: string[]; // profile Ids pf tagged users in the comment
    tags: ICommentTags; 
    isSystemGenerated?: boolean; 
    userId: string;  // comment creator. If userId is present then isSystemGenerated should be false
    createdAt?: string;
    updatedAt?: string;
};

export class CommentModel {

  @JsonProperty({name: 'id', required: true})
  private id: string = '';

  @JsonProperty({name: 'comment', required: false})
  private comment: string = '';

  @JsonProperty({name: 'referTo', required: false})
  private referTo: string[] = [];

  @JsonProperty({name: 'tags', required: false})
  private tags: ICommentTags = {} as ICommentTags;

  @JsonProperty({name: 'isSystemGenerated', required: false})
  private isSystemGenerated: boolean = false;

  @JsonProperty({name: 'userId', required: false})
  private userId: string = undefined;

  @JsonProperty({name: 'createdAt', required: false})
  private createdAt: string = undefined;

  @JsonProperty({name: 'updatedAt', required: false})
  private updatedAt: string = undefined;


  public getId() : string {
    return this.id;
  }

  public getComment() : string {
    return this.comment;
  }
  
  public getCommentCreator() : string {
    return this.userId;
  }

  public getCommentTaggedUserIds() : string[] {
    return this.referTo;
  }

  public getTags() : ICommentTags {
    return this.tags;
  }

  public getIsSystemGeneratedComment() : boolean {
    return this.isSystemGenerated;
  }

  public getCreatedAt() : string {
    return this.createdAt;
  }

  public getUpdatedAt() : string {
    return this.updatedAt;
  }

}

