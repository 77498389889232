import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";

import { CommentModel } from "@common/domain/models/Comment";
import { CommentsRepository } from "@common/domain/repositories/CommentsRepository";

import {
  commentsActions,
  createCommentFailure,
  createCommentSuccess,
  deleteCommentFailure,
  deleteCommentSuccess,
  fetchCommentsFailure,
  fetchCommentsSuccess,
  ICreateCommentsAction,
  IDeleteCommentsAction,
  IFetchCommentsAction,
} from "./actions";



export function* fetchCommentsSaga(action: IFetchCommentsAction) {
  try {
    const {
      page,
    } = action.payload;
    const result: { comments: CommentModel[], total: number } = yield call(CommentsRepository.fetchComments, action.payload);
    yield put(fetchCommentsSuccess(result.comments, result.total, page));
  } catch (e) {
    yield put(fetchCommentsFailure(e.message));
  }
}

export function* createCommentSaga(action: ICreateCommentsAction) {
  try {

    const {
      comment,
      entity,
      entityId
    } = action.payload;

    const comments: CommentModel[] = yield call(CommentsRepository.createComment, entity, entityId, comment);
    if (comments.length === 1)
      yield put(createCommentSuccess(comments[0]));
  } catch (e) {
    yield put(createCommentFailure(e.message));
  }
}


export function* deleteCommentSaga(action: IDeleteCommentsAction) {
  try {
    const {
      commentId,
      entity,
      entityId
    } = action.payload;
    const response = yield call(CommentsRepository.deleteComment, entity, entityId, commentId);
    yield put(deleteCommentSuccess(commentId));
  } catch (e) {
    yield put(deleteCommentFailure(e.message));
  }
}

export function* watchComments(): Generator<ForkEffect> {
  yield takeLatest(commentsActions.FETCH_COMMENTS, fetchCommentsSaga);
  yield takeLatest(commentsActions.CREATE_COMMENT, createCommentSaga);
  yield takeLatest(commentsActions.DELETE_COMMENTS, deleteCommentSaga);
}

