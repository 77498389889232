import { ConversationModel, IConversation } from '@models/Conversation';
import { MessageModel } from '@models/Message';

import { DIRECTION } from '@constants/config';

export interface IGetConversation {
  conversation: {
    profileIds: string[];
    conversationId?: string;
  };
}

export interface IFetchConversationAction {
  type: string;
  payload: {
    reset?: boolean;
    page: number,
    limit: number,
  };
}

export interface IFetchMessagesSpecification {
  conversationId: string;
  cursorMessageId: string;
  direction: string;
  limit: number;
}

export interface IFetchMessagesAction {
  type: string;
  payload: {
    messagesSpecification: IFetchMessagesSpecification;
    reset: boolean;
    direction: string;
  };
}

export interface IPostMessage {
  type: number;
  conversationId: string;
  clientMessageId: string;
  content: {
    text?: string;
    file?: string;
  };
  replyOf?: {};
}

export interface IPostMessageAction {
  type: string;
  payload: {
    message: MessageModel;
  };
}

export interface IReceivedMessage {
  type: string;
  payload: {
    message: MessageModel;
  };
}

export interface IUpdateActiveConversation {
  type: string;
  payload: {
    conversation: ConversationModel;
  };
}

export interface IRead {
  conversationId: string;
  timestamp: string;
  profileId: string;
}

export interface IReadReceiptAction {
  type: string;
  payload: IRead;
}

export interface IStartNewChatAction {
  type: string;
  payload: {
    profileId: string;
  }
}

const actionTypePrefix = 'CHAT/';
export const ChatActions = {
  GET_CONVERSATION: `${actionTypePrefix}GET_CONVERSATION`,
  GET_CONVERSATION_SUCCESS: `${actionTypePrefix}GET_CONVERSATION_SUCCESS`,
  GET_CONVERSATION_FAILURE: `${actionTypePrefix}GET_CONVERSATION_FAILURE`,

  GET_CONVERSATION_BY_ID: `${actionTypePrefix}GET_CONVERSATION_BY_ID`,

  FETCH_CONVERSATIONS: `${actionTypePrefix}FETCH_CONVERSATIONS`,
  FETCH_CONVERSATIONS_SUCCESS: `${actionTypePrefix}FETCH_CONVERSATIONS_SUCCESS`,
  FETCH_CONVERSATIONS_FAIL: `${actionTypePrefix}FETCH_CONVERSATIONS_FAIL`,

  FETCH_MESSAGES: `${actionTypePrefix}FETCH_MESSAGES`,
  FETCH_MESSAGES_SUCCESS: `${actionTypePrefix}FETCH_MESSAGES_SUCCESS`,
  FETCH_MESSAGES_FAIL: `${actionTypePrefix}FETCH_MESSAGES_FAIL`,

  POST_MESSAGE: `${actionTypePrefix}POST_MESSAGE`,
  POST_MESSAGE_SUCCESS: `${actionTypePrefix}POST_MESSAGE_SUCCESS`,
  POST_MESSAGE_FAIL: `${actionTypePrefix}POST_MESSAGE_FAIL`,

  RECEIVED_MESSAGE: `${actionTypePrefix}RECEIVED_MESSAGE`,

  UPDATE_RECEIVED_MESSAGE: `${actionTypePrefix}UPDATE_RECEIVED_MESSAGE`,
  UPDATE_ACTIVE_CONVERSATION: `${actionTypePrefix}UPDATE_ACTIVE_CONVERSATION`,

  MESSAGE_READ_RECEIPT: `${actionTypePrefix}MESSAGE_READ_RECEIPT`,
  MESSAGE_READ_RECEIPT_SUCCESS: `${actionTypePrefix}MESSAGE_READ_RECEIPT_SUCCESS`,

  UPDATE_LOAD_EARLIER_MESSAGES_FLAG: `${actionTypePrefix}UPDATE_LOAD_EARLIER_MESSAGES_FLAG`,

  WEB_CHAT_STARTED: `${actionTypePrefix}WEB_CHAT_STARTED`,
  WEB_CHAT_STOPPED: `${actionTypePrefix}WEB_CHAT_STOPPED`,

  CLEAR_CHAT_CONVERSATIONS: `${actionTypePrefix}CLEAR_CHAT_CONVERSATIONS`,

  START_NEW_CHAT: `${actionTypePrefix}START_NEW_CHAT`,
  CLEAR_NEW_CHAT_PROFILE_ID: `${actionTypePrefix}CLEAR_NEW_CHAT_PROFILE_ID`,

  START_NEW_CONVO: `${actionTypePrefix}START_NEW_CONVO`,
  START_NEW_CONVO_SUCCESS: `${actionTypePrefix}START_NEW_CONVO_SUCCESS`,
  START_NEW_CONVO_FAILURE: `${actionTypePrefix}START_NEW_CONVO_FAILURE`,

  ADD_CONVO_ON_TOP: `${actionTypePrefix}ADD_CONVO_ON_TOP`,

  REFRESH_TOKEN: `${actionTypePrefix}REFRESH_TOKEN`,

  GET_UNREAD_CONVERSATION_COUNT: `${actionTypePrefix}GET_UNREAD_CONVERSATION_COUNT`,
  GET_UNREAD_CONVERSATION_COUNT_SUCCESS: `${actionTypePrefix}GET_UNREAD_CONVERSATION_COUNT_COUNT_SUCCESS`,
  GET_UNREAD_CONVERSATION_COUNT_FAIL: `${actionTypePrefix}GET_UNREAD_CONVERSATION_COUNT_FAIL`,
  DECREMENT_UNREAD_CONVERSATION_COUNT: `${actionTypePrefix}DECREMENT_UNREAD_CONVERSATION_COUNT`,
};

const actionTypePrefixSocket = 'SOCKET/';
export const SocketActionTypes = {
  WEBSOCKET_START: `${actionTypePrefixSocket}START`,
  WEBSOCKET_STOP: `${actionTypePrefixSocket}STOP`,
  WEBSOCKET_SOCKET_ACTIVATION_STATUS: `${actionTypePrefixSocket}SOCKET_ACTIVATION_STATUS`,
  WEBSOCKET_UPDATE_STATUS: `${actionTypePrefixSocket}UPDATE_STATUS`,
};

export const startSocket = (): { type: string } => {
  return {
    type: SocketActionTypes.WEBSOCKET_START,
  };
};

export const stopSocket = (): { type: string } => {
  return {
    type: SocketActionTypes.WEBSOCKET_STOP,
  };
};

export const updateSocketActivationStatus = (
  isSocketActivated: boolean,
): { type: string; payload: { isSocketActivated: boolean } } => {
  return {
    type: SocketActionTypes.WEBSOCKET_SOCKET_ACTIVATION_STATUS,
    payload: {
      isSocketActivated,
    },
  };
};

export const updateSocketStatus = (
  status: string,
  statusData: any = {},
): { type: string; payload: { status: string; statusData: any } } => {
  return {
    type: SocketActionTypes.WEBSOCKET_UPDATE_STATUS,
    payload: {
      status,
      statusData,
    },
  };
};

export const getConversation = (
  userProfileId: string,
  data: IGetConversation,
  inSync?: boolean,
  fetchConvo?: boolean,
): { type: string; payload: any } => {
  return {
    type: ChatActions.GET_CONVERSATION,
    payload: {
      userProfileId,
      data,
      inSync,
      fetchConvo,
    },
  };
};

export const getConversationById = (
    conversationId: string,
    userProfileId: string,
    inSync?: boolean,
    fetchConvo?: boolean,
): { type: string; payload: any } => {
  return {
    type: ChatActions.GET_CONVERSATION_BY_ID,
    payload: {
      conversationId,
      userProfileId,
      inSync,
      fetchConvo,
    },
  };
};

export const getConversationSuccess = (
  conversation: ConversationModel,
): { type: string; payload: ConversationModel } => {
  return {
    type: ChatActions.GET_CONVERSATION_SUCCESS,
    payload: conversation,
  };
};

export const getConversationFailure = (
  error: string,
): { type: string; payload: string } => {
  return {
    type: ChatActions.GET_CONVERSATION_FAILURE,
    payload: error,
  };
};

export const fetchConversations = (page: number, limit: number, reset?: boolean): IFetchConversationAction => {
  return {
    type: ChatActions.FETCH_CONVERSATIONS,
    payload: {
      reset,
      page,
      limit,
    },
  };
};

export const fetchConversationsSuccess = (
  conversations: ConversationModel[], page: number, reset?: boolean,
): { type: string; payload: { reset?: boolean | undefined, conversations: ConversationModel[], page } } => {
  return {
    type: ChatActions.FETCH_CONVERSATIONS_SUCCESS,
    payload: {
      conversations,
      reset,
      page,
    },
  };
};

export const fetchConversationsFailure = (
  error: any,
): { type: string; payload: any } => {
  return {
    type: ChatActions.FETCH_CONVERSATIONS_FAIL,
    payload: error,
  };
};

export const updateActiveConversation = (
  conversation: ConversationModel,
): IUpdateActiveConversation => {
  return {
    type: ChatActions.UPDATE_ACTIVE_CONVERSATION,
    payload: {
      conversation,
    },
  };
};

export const fetchMessages = (
  messagesSpecification: IFetchMessagesSpecification,
  reset = false,
  direction = DIRECTION.before,
): IFetchMessagesAction => {
  return {
    type: ChatActions.FETCH_MESSAGES,
    payload: {
      messagesSpecification,
      reset,
      direction,
    },
  };
};

export const fetchMessagesSuccess = (
  conversationId: string,
  messages: MessageModel[],
  reset = false,
  direction = DIRECTION.before,
): { type: string; payload: any } => {
  return {
    type: ChatActions.FETCH_MESSAGES_SUCCESS,
    payload: {
      messages,
      reset,
      direction,
      conversationId,
    },
  };
};

export const fetchMessagesFailure = (
  error: any,
): { type: string; payload: any } => {
  return {
    type: ChatActions.FETCH_MESSAGES_FAIL,
    payload: error,
  };
};

export const postMessage = (message: MessageModel): IPostMessageAction => {
  return {
    type: ChatActions.POST_MESSAGE,
    payload: {
      message,
    },
  };
};

export const postMessageSuccess = (
  message: MessageModel,
): { type: string; payload: any } => {
  return {
    type: ChatActions.POST_MESSAGE_SUCCESS,
    payload: {
      message,
    },
  };
};

export const postMessageFailure = (
  error: any,
): { type: string; payload: any } => {
  return {
    type: ChatActions.POST_MESSAGE_FAIL,
    payload: error,
  };
};

export const receivedMessage = (message: MessageModel): IReceivedMessage => {
  return {
    type: ChatActions.RECEIVED_MESSAGE,
    payload: {
      message,
    },
  };
};

export const updateReceivedMessage = (message: MessageModel): IReceivedMessage => {
  return {
    type: ChatActions.UPDATE_RECEIVED_MESSAGE,
    payload: {
      message,
    },
  };
};

export const sendReadReceipt = (
  conversationId: string,
  timestamp: string,
  userProfileId: string,
): IReadReceiptAction => {
  return {
    type: ChatActions.MESSAGE_READ_RECEIPT,
    payload: {
      conversationId,
      timestamp,
      profileId: userProfileId,
    },
  };
};

export const sendReadReceiptSuccess = (conversation: ConversationModel): { type: string; payload: ConversationModel } => {
  return {
    type: ChatActions.MESSAGE_READ_RECEIPT_SUCCESS,
    payload: conversation,
  };
};

export const updateLoadEarlieMessagesFlag = (convId: string, loadEarlier: boolean): {
  type: string;
  payload: { convId: string, loadEarlier: boolean }
} => {
  return {
    type: ChatActions.UPDATE_LOAD_EARLIER_MESSAGES_FLAG,
    payload: {
      loadEarlier,
      convId,
    },
  };
};

export const clearConversations = (): { type: string } => {
  return {
    type: ChatActions.CLEAR_CHAT_CONVERSATIONS,
  };
};

export const startNewChat = (profileId: string): {
  type: string;
  payload: { profileId: string }
} => {
  return {
    type: ChatActions.START_NEW_CHAT,
    payload: {
      profileId,
    },
  };
};

export const startNewConvo = (
  userProfileId: string,
  data: IGetConversation,
): { type: string; payload: any } => {
  return {
    type: ChatActions.START_NEW_CONVO,
    payload: {
      userProfileId,
      data,
    },
  };
};

export const startNewConvoSuccess = (): { type: string } => {
  return {
    type: ChatActions.START_NEW_CONVO_SUCCESS,
  };
};

export const clearNewChatProfileId = (): { type: string } => {
  return {
    type: ChatActions.CLEAR_NEW_CHAT_PROFILE_ID,
  };
};

export const addActiveConvoOnTop = (): { type: string } => {
  return {
    type: ChatActions.ADD_CONVO_ON_TOP,
  };
};

export const webChatStart = (): {type: string} => {
  return {
    type: ChatActions.WEB_CHAT_STARTED,
  };
};

export const webChatStop = (): {type: string} => {
  return {
    type: ChatActions.WEB_CHAT_STOPPED,
  };
};

export const getRefreshedToken = () : {type: string} => {
  return {
    type: ChatActions.REFRESH_TOKEN,
  };
};

export const getUnreadConversationCount = (userId: string) => {
  return {
    type: ChatActions.GET_UNREAD_CONVERSATION_COUNT,
    payload: userId
  }
}

export const decrementConversationCount = () => {
  return {
    type: ChatActions.DECREMENT_UNREAD_CONVERSATION_COUNT
  }
}

export const getUnreadConversationCountSuccess = (payload) => {
  return {
    type: ChatActions.GET_UNREAD_CONVERSATION_COUNT_SUCCESS,
    payload
  }
}

export const getUnreadConversationCountFail = (error: string) => {
  return {
    type: ChatActions.GET_UNREAD_CONVERSATION_COUNT_FAIL,
    payload: error
  }
}


