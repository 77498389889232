import React, { useState } from 'react';
import qs from 'qs';

import { getMobileOperatingSystem } from '@utils/DeviceInfoUtils';
import { StorageKeys } from '@utils/LocalStorage';

import Text, { FontFamilyVariant, TextType } from '@components/Text';

import { ANDROID_APP_ID, ANDROID_PLAYSTORE_LINK, IOS_APPSTORE, Platform, UTM_CAMPAIGN, UTM_MEDIUM, UTM_SOURCE } from '@constants/config';

import { WFNudgeLogo, WhiteCross } from '@assets/index';
import { Img } from '@components/Image';
import { useTranslation } from 'react-i18next';
import "./styles.less";

interface IProps {
    onClick: (payload) => void;
}

const Nudges = (props: IProps) => {
    const { onClick } = props;
    const [showAppNudge, setShowAppNudge] = useState(localStorage.getItem(StorageKeys.SHOW_APP_NUDGE));
    const { t } = useTranslation();

    const onClickCross = () => {
        localStorage.setItem(StorageKeys.SHOW_APP_NUDGE, 'false');
        setShowAppNudge("false");
        onClick("false")
    }

    const getApplicationLink = () => {
        const platform = getMobileOperatingSystem();
        switch (platform) {
            case Platform.ANDROID:
                return `${ANDROID_PLAYSTORE_LINK}?id=${ANDROID_APP_ID}&referrer=utm_source%3D${UTM_SOURCE.MOBILE_WEB}%26utm_medium%3D${UTM_MEDIUM.FLOATING_BUTTON}%26utm_campaign%3D${UTM_CAMPAIGN.MOBILE_APP_INSTALL}`;
            case Platform.IOS:
                const iosAppObject = {
                    utm_campaign: UTM_CAMPAIGN.MOBILE_APP_INSTALL,
                    utm_medium: UTM_MEDIUM.FLOATING_BUTTON,
                    utm_source: UTM_SOURCE.MOBILE_WEB,
                }
                return `${IOS_APPSTORE}?${qs.stringify(iosAppObject)}`;
            default:
                return `${ANDROID_PLAYSTORE_LINK}?id=${ANDROID_APP_ID}&referrer=utm_source%3D${UTM_SOURCE.MOBILE_WEB}%26utm_medium%3D${UTM_MEDIUM.FLOATING_BUTTON}%26utm_campaign%3D${UTM_CAMPAIGN.MOBILE_APP_INSTALL}`;
        }
    }

    if (showAppNudge === 'false') {
        return null;
    }

    return (
        <div className="nudges__container">
            <a href={getApplicationLink()}>
                <div className='nudges__logo_container'>
                    <Img src={WFNudgeLogo} alt="Nudge Logo" />
                    <div className="nudges__text_container">
                        <Text
                            textType={TextType.paragraph1}
                            fontFamilyVariant={FontFamilyVariant.light}
                            text={t("general.appName")}
                            className="nudges__app_text"
                        />
                        <Text
                            textType={TextType.paragraph1}
                            fontFamilyVariant={FontFamilyVariant.light}
                            text={t("guestView.labelsAndTitles.OPEN_IN_APP")}
                            className="nudges__sub_text"
                        />
                    </div>
                </div>
            </a>
            <Img className='nudges__cross' src={WhiteCross} onClick={onClickCross} />
        </div>
    )
}

export default Nudges;


