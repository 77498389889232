import { PrepareModel } from '@common/domain/models/Prepare';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';
import { AICoachRepository } from '@common/domain/repositories/AICoachRepository';
import { InterviewRepository } from '@common/domain/repositories/InterviewRepository';
import { getErrorCodes } from '@utils/ErrorMessageUtils';
import { InterviewEvalModel } from '@models/InterviewEvalModel';
import { AzureTokenModel } from '@common/domain/models/AzureTokenModel';
import { IAllJobRolePayload } from '@common/redux/interview/interface';
import { customText } from '@presentation/Interview/config';

import { fetchJobRoleListSuccess,
    fetchFilteredJobRoleListSuccess,
    fetchAllJobRoleListSuccess,
    fetchJobRoleListFail,
    jobRoleActions,
    fetchInterviewQuestionListSuccess,
    fetchInterviewQuestionListFail,
    interviewQuestionsActions,
    aiEvalutionActions,
    aiEvalutionHistoryActions,
    submitEvaluationSuccess,
    submitEvaluationFail,
    fetchEvaluationsSuccess,
    fetchEvaluationsFail,
    IFetchEvaluationAction,
    ISubmitEvalAction,
    fetchAzureTokenActions,
    fetchAzureTokenSuccess,
    fetchAzureTokenFail,
    deleteEvaluationHistoryActions,
    deleteEvaluationSuccess,
    deleteEvaluationFail,
    validateJobRoleSuccess,
    validateJobRoleFail,
    fetchAllJobRoleListFail,
    fetchJobRoleListFromTagsSuccess,
    fetchJobRoleListFromTagsFail } from './action';

export function* fetchInterviewQuestionsSaga(action): Generator<StrictEffect, void, any> {
    try {
        const interviewQuestionsResponse = yield call(AICoachRepository.fetchInterviewQuestions, action.payload);
        yield put(fetchInterviewQuestionListSuccess(interviewQuestionsResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchInterviewQuestionListFail(error));
    }
}

export function* fetchJobRoleSaga(value): Generator<StrictEffect, void, any> {
    try {
        let filteredPayload: IAllJobRolePayload;
        const { payload } = value;
        filteredPayload = {
            search: payload.searchValue,
            status: customText.status,
            language: payload.selectedLanguage,
            country: payload.selectedCountry
        }
        const JobRoleResponse = yield call(InterviewRepository.fetchJobRoleList, filteredPayload);
        const jobRoles = JobRoleResponse.map((eachRole, index) => ({
            value: eachRole,
            key: eachRole + index
        }));
        if (value.payload.searchValue) {
            yield put(fetchFilteredJobRoleListSuccess(jobRoles));
        }
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchJobRoleListFail(error));
    }
}

export function* fetchJobRoleFromTagsSaga(value): Generator<StrictEffect, void, PrepareModel> {
    try {
        const JobRoleResponse = yield call(InterviewRepository.fetchJobRoleListFromTags, value.payload);
        const jobRoles = JobRoleResponse.getTags()[0]?.tags[0]?.values.map((eachRole, index) => ({
            value: eachRole,
            key: eachRole + index
        }));

        yield put(fetchJobRoleListFromTagsSuccess(jobRoles));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchJobRoleListFromTagsFail(error));
    }
}

export function* fetchAllJobRoleSaga(value): Generator<StrictEffect, void, any> {
    try {
        const JobRoleResponse = yield call(InterviewRepository.fetchJobRoleList, { search: '' });
        const jobRoles = JobRoleResponse.map((eachRole, index) => ({
            value: eachRole,
            key: eachRole + index
        }));
        yield put(fetchAllJobRoleListSuccess(jobRoles));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchAllJobRoleListFail(error));
    }
}

export function* validateJobRoleSaga(value): Generator<StrictEffect, void, any> {
    try {
        const validateJobRoleResponse = yield call(InterviewRepository.validateJobRole, value.payload);
        yield put(validateJobRoleSuccess(validateJobRoleResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(validateJobRoleFail(error));
    }
}

export function* submitEvaluationSaga(action:ISubmitEvalAction): Generator<StrictEffect, void, InterviewEvalModel> {
    try {
        const evalResponse:InterviewEvalModel = yield call(InterviewRepository.submitEvaluation, action.payload);
        yield put(submitEvaluationSuccess(evalResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(submitEvaluationFail(error));
    }
}

export function* fetchEvaluationHistorySaga(action:IFetchEvaluationAction): Generator<StrictEffect, void, InterviewEvalModel[]> {
    try {
        const evalHistoryResponse:InterviewEvalModel[] = yield call(InterviewRepository.fetchEvaluationHistory, action.payload);
        yield put(fetchEvaluationsSuccess(evalHistoryResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchEvaluationsFail(error));
    }
}

export function* fetchAzureTokenSaga(): Generator<StrictEffect, void, AzureTokenModel> {
    try {
        const azureTokenResponse:AzureTokenModel = yield call(InterviewRepository.fetchAzureToken);
        yield put(fetchAzureTokenSuccess(azureTokenResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchAzureTokenFail(error));
    }
}

export function* deleteEvaluationSaga(action: any) {
    const id = action.payload;
    try {
        const response = yield call(InterviewRepository.deleteEvaluation, id);
        yield put(deleteEvaluationSuccess(response.conversations));
    } catch (e) {
        yield put(deleteEvaluationFail(e.message));
    }
}

export function* watchPrepare(): Generator<ForkEffect> {
    yield takeLatest(jobRoleActions.FETCH_JOB_ROLE_LIST, fetchJobRoleSaga);
    yield takeLatest(jobRoleActions.FETCH_ALL_JOB_ROLE_LIST, fetchAllJobRoleSaga);
    yield takeLatest(interviewQuestionsActions.FETCH_INTERVIEW_QUESTION_LIST, fetchInterviewQuestionsSaga);
    yield takeLatest(aiEvalutionActions.SUBMIT_EVALUATION, submitEvaluationSaga);
    yield takeLatest(aiEvalutionHistoryActions.FETCH_EVALUATION_LIST, fetchEvaluationHistorySaga);
    yield takeLatest(fetchAzureTokenActions.FETCH_AZURE_TOKEN, fetchAzureTokenSaga);
    yield takeLatest(deleteEvaluationHistoryActions.DELETE_EVALUATION, deleteEvaluationSaga);
    yield takeLatest(jobRoleActions.VALIDATE_JOB_ROLE, validateJobRoleSaga);
    yield takeLatest(jobRoleActions.FETCH_JOB_ROLE_LIST_FROM_TAGS, fetchJobRoleFromTagsSaga);
}
