
import { LOCAL_STORAGE_KEYS } from '@utils/localStorageConstants.js';

export const isValidUser = () => {
    const userData = {};
    const keys = Object.values(LOCAL_STORAGE_KEYS);
    let i = keys.length;
    if (i === 0) return false;
    while (i--) {
        userData[keys[i]] = localStorage.getItem(keys[i]);
    }
    for (const key in userData) {
        if ((userData[key] === null || userData[key] === '') && (key !== 'email' && key !== 'mobile')) {
            return false;
        }
        if ((userData.email === null || userData.email === '') && (userData.mobile === null || userData.mobile === '')) {
            return false;
		 }
    }
    return true;
};

export const logout = {
    clearStorage() {
        return Promise.resolve().then(() => {
            localStorage.clear();
        });
    }
};

export const getLocalStorageItem = (itemKey) => localStorage.getItem(itemKey);

export const setLocalStorageItem = (key, value) => localStorage.setItem(key, value);
