import { createTransform } from "redux-persist";

import { IAuthState } from "@common/redux/auth/interface";
import { initialState as authInitialState} from "@common/redux/auth/reducer";

const authWhiteList = createTransform(
    (inboundState: IAuthState, key) => {
        if (key === "auth") {
            // This is saving to storage
            return inboundState;
        }
    },
    (outBoundState, key) => {
        if (key === "auth") {
            const { login, isAuthenticated } = outBoundState;
            // Receiving data from storage
            return { ...authInitialState, login, isAuthenticated };
        }
    }
);

export default authWhiteList;
