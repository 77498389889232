import React from "react";

import { ArrowLeft } from "@assets/index";

import { WFImg } from "@components/index";
import Text, { FontFamilyVariant, TextType } from "@components/Text";

import Navigator from '@navigator/index';

import './styles.less'

interface IProps {
text?: string;
}

export default function GobackArrow(props: IProps) {
    const { text } = props;
    return (
        <div className="top_back_section">
            <WFImg alt="Go back" className="left_icon" src={ArrowLeft} onClick={() => Navigator.goBack()}/>
            <Text
                className="back"
                fontFamilyVariant={FontFamilyVariant.bold2}
                text={text}
                textType={TextType.display2}
            />
        </div>
    )
}
