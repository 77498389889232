import { InterviewEvalModel } from '@models/InterviewEvalModel';
import { AzureTokenModel } from '@common/domain/models/AzureTokenModel';
import { IFluxStandardAction } from '../../../store/webInterfaces';
import { IJobRolePayload, IValidateJobRolePayload, IJobRoleTagsPayload } from '@common/redux/interview/interface';

const actionTypePrefix = 'INTERVIEW';

export const jobRoleActions = {
    FETCH_JOB_ROLE_LIST: `${actionTypePrefix}/FETCH_JOB_ROLE_LIST`,
    FETCH_JOB_ROLE_LIST_SUCCESS: `${actionTypePrefix}/FETCH_JOB_ROLE_LIST_SUCCESS`,
    FETCH_JOB_ROLE_LIST_FAIL: `${actionTypePrefix}/FETCH_JOB_ROLE_LIST_FAIL`,
    FETCH_JOB_ROLE_LIST_FROM_TAGS: `${actionTypePrefix}/FETCH_JOB_ROLE_LIST_FROM_TAGS`,
    FETCH_JOB_ROLE_LIST_FROM_TAGS_SUCCESS: `${actionTypePrefix}/FETCH_JOB_ROLE_LIST_FROM_TAGS_SUCCESS`,
    FETCH_JOB_ROLE_LIST_FROM_TAGS_FAIL: `${actionTypePrefix}/FETCH_JOB_ROLE_LIST_FROM_TAGS_FAIL`,
    FETCH_FILTERED_JOB_ROLE_LIST_SUCCESS: `${actionTypePrefix}/FETCH_FILTERED_JOB_ROLE_LIST_SUCCESS`,
    FETCH_ALL_JOB_ROLE_LIST: `${actionTypePrefix}/FETCH_ALL_JOB_ROLE_LIST`,
    FETCH_ALL_JOB_ROLE_LIST_SUCCESS: `${actionTypePrefix}/FETCH_ALL_JOB_ROLE_LIST_SUCCESS`,
    FETCH_ALL_JOB_ROLE_LIST_FAIL: `${actionTypePrefix}/FETCH_ALL_JOB_ROLE_LIST_FAIL`,
    VALIDATE_JOB_ROLE: `${actionTypePrefix}/VALIDATE_JOB_ROLE`,
    VALIDATE_JOB_ROLE_SUCCESS: `${actionTypePrefix}/VALIDATE_JOB_ROLE_SUCCESS`,
    VALIDATE_JOB_ROLE_FAIL: `${actionTypePrefix}/VALIDATE_JOB_ROLE_FAIL`,
    RESET_VALIDATE_JOB_ROLE: `${actionTypePrefix}/RESET_VALIDATE_JOB_ROLE`
};

export const interviewQuestionsActions = {
    FETCH_INTERVIEW_QUESTION_LIST: `${actionTypePrefix}/FETCH_INTERVIEW_QUESTION_LIST`,
    FETCH_INTERVIEW_QUESTION_LIST_SUCCESS: `${actionTypePrefix}/FETCH_INTERVIEW_QUESTION_LIST_SUCCESS`,
    FETCH_INTERVIEW_QUESTION_LIST_FAIL: `${actionTypePrefix}/FETCH_INTERVIEW_QUESTION_LIST_FAIL`
};

export const aiEvalutionActions = {
    SUBMIT_EVALUATION: `${actionTypePrefix}/SUBMIT_EVALUATION`,
    SUBMIT_EVALUATION_SUCCESS: `${actionTypePrefix}/SUBMIT_EVALUATION_SUCCESS`,
    SUBMIT_EVALUATION_FAIL: `${actionTypePrefix}/SUBMIT_EVALUATION_FAIL`,
    RESET_SUBMIT_EVALUATION: `${actionTypePrefix}/RESET_SUBMIT_EVALUATION`
};

export const aiEvalutionHistoryActions = {
    FETCH_EVALUATION_LIST: `${actionTypePrefix}/FETCH_EVALUATION_LIST_EVALUATION`,
    FETCH_EVALUATION_LIST_SUCCESS: `${actionTypePrefix}/FETCH_EVALUATION_LIST_SUCCESS`,
    FETCH_EVALUATION_LIST_FAIL: `${actionTypePrefix}/FETCH_EVALUATION_LIST_FAIL`
};

export const deleteEvaluationHistoryActions = {
    DELETE_EVALUATION: `${actionTypePrefix}/DELETE_EVALUATION`,
    DELETE_EVALUATION_SUCCESS: `${actionTypePrefix}/DELETE_EVALUATION_SUCCESS`,
    DELETE_EVALUATION_FAIL: `${actionTypePrefix}/DELETE_EVALUATION_FAIL`,
    RESET_DELETE_EVALUATION: `${actionTypePrefix}/RESET_DELETE_EVALUATION`
};

export const fetchAzureTokenActions = {
    FETCH_AZURE_TOKEN: `${actionTypePrefix}/FETCH_AZURE_TOKEN`,
    FETCH_AZURE_TOKEN_SUCCESS: `${actionTypePrefix}/FETCH_AZURE_TOKEN_SUCCESS`,
    FETCH_AZURE_TOKEN_FAIL: `${actionTypePrefix}/FETCH_AZURE_TOKEN_FAIL`
};

export interface SubmitEvaluationPayload {
  jobRole: string;
  question: string;
  userTextResponse: string;
  userId: string;
}

export interface ISubmitEvalAction {
  type: string;
  payload: SubmitEvaluationPayload;
}

export interface IFetchEvaluationAction {
  type: string;
  payload: string;
}

export const fetchJobRoleList = (payload: IJobRolePayload): IFluxStandardAction<any> => ({
    type: jobRoleActions.FETCH_JOB_ROLE_LIST,
    payload
});

export const fetchJobRoleListFromTags = (payload: IJobRoleTagsPayload): IFluxStandardAction<any> => ({
    type: jobRoleActions.FETCH_JOB_ROLE_LIST_FROM_TAGS,
    payload
});

export const fetchJobRoleListFromTagsSuccess = (payload: Array<Object>): IFluxStandardAction<any> => ({
    type: jobRoleActions.FETCH_JOB_ROLE_LIST_FROM_TAGS_SUCCESS,
    payload
});

export const fetchJobRoleListFromTagsFail = (error: string): IFluxStandardAction<string> => ({
    type: jobRoleActions.FETCH_JOB_ROLE_LIST_FROM_TAGS_FAIL,
    payload: error
});

export const fetchAllJobRoleList = (payload: IJobRolePayload): IFluxStandardAction<any> => ({
    type: jobRoleActions.FETCH_ALL_JOB_ROLE_LIST,
    payload
});

export const fetchJobRoleListSuccess = (payload: Array<Object>): IFluxStandardAction<any> => ({
    type: jobRoleActions.FETCH_JOB_ROLE_LIST_SUCCESS,
    payload
});

export const fetchFilteredJobRoleListSuccess = (payload: Array<Object>): IFluxStandardAction<any> => ({
    type: jobRoleActions.FETCH_FILTERED_JOB_ROLE_LIST_SUCCESS,
    payload
});

export const fetchAllJobRoleListSuccess = (payload: Array<Object>): IFluxStandardAction<any> => ({
    type: jobRoleActions.FETCH_ALL_JOB_ROLE_LIST_SUCCESS,
    payload
});

export const fetchJobRoleListFail = (error: string): IFluxStandardAction<string> => ({
    type: jobRoleActions.FETCH_JOB_ROLE_LIST_FAIL,
    payload: error
});

export const fetchAllJobRoleListFail = (error: string): IFluxStandardAction<string> => ({
    type: jobRoleActions.FETCH_ALL_JOB_ROLE_LIST_FAIL,
    payload: error
});

export const validateJobRole = (payload: IValidateJobRolePayload): IFluxStandardAction<any> => ({
    type: jobRoleActions.VALIDATE_JOB_ROLE,
    payload
});

export const validateJobRoleSuccess = (payload: string): IFluxStandardAction<any> => ({
    type: jobRoleActions.VALIDATE_JOB_ROLE_SUCCESS,
    payload
});

export const validateJobRoleFail = (error: string): IFluxStandardAction<string> => ({
    type: jobRoleActions.VALIDATE_JOB_ROLE_FAIL,
    payload: error
});

export const resetValidateJobRole = () => ({
    type: jobRoleActions.RESET_VALIDATE_JOB_ROLE,
});

export const fetchInterviewQuestionList = (payload: string): IFluxStandardAction<any> => ({
    type: interviewQuestionsActions.FETCH_INTERVIEW_QUESTION_LIST,
    payload
});

export const fetchInterviewQuestionListSuccess = (payload: any): IFluxStandardAction<any> => ({
    type: interviewQuestionsActions.FETCH_INTERVIEW_QUESTION_LIST_SUCCESS,
    payload
});

export const fetchInterviewQuestionListFail = (payload: string): IFluxStandardAction<any> => ({
    type: interviewQuestionsActions.FETCH_INTERVIEW_QUESTION_LIST_FAIL,
    payload
});

export const submitEvaluation = (payload: SubmitEvaluationPayload): ISubmitEvalAction => ({
    type: aiEvalutionActions.SUBMIT_EVALUATION,
    payload,
});

export const submitEvaluationSuccess = (evalModel: InterviewEvalModel) => ({
    type: aiEvalutionActions.SUBMIT_EVALUATION_SUCCESS,
    payload: evalModel,
});

export const submitEvaluationFail = (error: string) => ({
    type: aiEvalutionActions.SUBMIT_EVALUATION_FAIL,
    payload: error,
});

export const resetSubmitEvaluation = () => ({
    type: aiEvalutionActions.RESET_SUBMIT_EVALUATION,
});

export const fetchEvalautions = (
    userId: string,
): IFetchEvaluationAction => ({
    type: aiEvalutionHistoryActions.FETCH_EVALUATION_LIST,
    payload: userId,
});

export const fetchEvaluationsSuccess = (evalhistory: InterviewEvalModel[]) => ({
    type: aiEvalutionHistoryActions.FETCH_EVALUATION_LIST_SUCCESS,
    payload: evalhistory,
});

export const fetchEvaluationsFail = (error: string) => ({
    type: aiEvalutionHistoryActions.FETCH_EVALUATION_LIST_FAIL,
    payload: error,
});

export const fetchAzureToken = () => ({
    type: fetchAzureTokenActions.FETCH_AZURE_TOKEN
});

export const fetchAzureTokenSuccess = (azureToken: AzureTokenModel) => ({
    type: fetchAzureTokenActions.FETCH_AZURE_TOKEN_SUCCESS,
    payload: azureToken,
});

export const fetchAzureTokenFail = (error: string) => ({
    type: fetchAzureTokenActions.FETCH_AZURE_TOKEN_FAIL,
    payload: error,
});

export const deleteEvaluation = (id: string): IFluxStandardAction<any> => ({
    type: deleteEvaluationHistoryActions.DELETE_EVALUATION,
    payload: id
});

export const deleteEvaluationSuccess = (succuss: any): IFluxStandardAction<any> => ({
    type: deleteEvaluationHistoryActions.DELETE_EVALUATION_SUCCESS,
    payload: succuss
});

export const deleteEvaluationFail = (error: string): IFluxStandardAction<string> => ({
    type: deleteEvaluationHistoryActions.DELETE_EVALUATION_FAIL,
    payload: error
});

export const resetDeleteEvaluation = () => ({
    type: deleteEvaluationHistoryActions.RESET_DELETE_EVALUATION,
});

