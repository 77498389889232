import jwtDecode from 'jwt-decode';
import { StorageKeys } from '@utils/LocalStorage';

export const generateObjectFromQueryString = (query) => {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params = {};
    let match;
    // eslint-disable-next-line no-cond-assign
    while (match = regex.exec(query)) {
        // eslint-disable-next-line prefer-destructuring
        params[match[1]] = match[2];
    }
    return params;
};

export const decodeBase64URLToken = (accessToken) => {
    const token = accessToken;
    const decoded = jwtDecode(token);
    return decoded;
};

export const getArticleURLFromSlug = (url) => {
    if (url) {
        const slugArray = url.split('-');
        return slugArray[slugArray.length - 1];
    }
};

export const getInitials = (name) => {
    if (!name) {
        return '';
    }
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const hasCharacters = (inputString) => {
    // Create a regular expression to match any character
    const regex = /[^\s]/; // Matches any non-whitespace character
    // Test if the input string contains any characters
    return regex.test(inputString);
};

export const getIntersectionArray = (firstArray, secondArray) => {
    const intersectonArray = [];
    if (firstArray && firstArray.length > 0 && secondArray && secondArray.length > 0) {
        firstArray.forEach((eachFirstArrayValue) => {
            secondArray.forEach((eachSecondArrayValue) => {
                if (eachFirstArrayValue === eachSecondArrayValue) {
                    intersectonArray.push(eachFirstArrayValue);
                }
            });
        });
    }
    return intersectonArray;
};

export async function downloadFile(url, params) {
    try {
        const token = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
        const headerPayload = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`
            }
        };
        const queryString = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
        const response = await fetch(`${url}?${queryString}`, headerPayload);

        if (!response.ok) {
            throw new Error('error');
        }

        // Extract filename from header
        const filename = response.headers.get('content-disposition')
            .split(';')
            .find((n) => n.includes('filename='))
            .replace('filename=', '')
            .trim();
        const blob = await response.blob();

        // Download the file
        const blobUrl = window.URL.createObjectURL(new Blob([blob]));

        // Create an anchor element to trigger the download
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename; // Set the desired file name and extension
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        throw new Error(error);
    }
}

