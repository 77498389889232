import { deserializeJsonObjCollection } from "@utils/ObjectMapperUtil";

import { APIv1, APIv2 } from "@common/network/constants";

import { Institute } from "@models/Institute";

import { ApiClient } from "@network/client";
import { ProfileModel } from "../models/Profile";

const ENDPOINTS = {
  fetchInstitutes: () => `${APIv1}/organizationservice/users/institutes`,
  fetchMultipleInstituteDetails: () => `${APIv2}/organizationservice/institutes`,
  fetchFacultyCertifiedCourses: (instituteId: string, facultyId: string) => `${APIv1}/courseservice/institutes/${instituteId}/faculty/${facultyId}/certifiedCourses`,
  fetchInstituteFaculties: (instituteId: string, courseId: string) => `${APIv1}/organizationservice/institutes/${instituteId}/courses/${courseId}/faculties`,
  fetchUserInstitutes: () => `${APIv1}/organizationservice/institute/students`
};

class InstituteRepository {
  private apiClient: any;

  constructor(apiClient: any) {
    this.apiClient = apiClient;
  }

  public fetchInstitutes = async () => {
    const response = await this.apiClient.get(ENDPOINTS.fetchInstitutes());
    return response;
  };

  public fetchFacultyCertifiedCourses = async (instituteId: string, facultyId: string) => {
    const response = await this.apiClient.get(
      ENDPOINTS.fetchFacultyCertifiedCourses(instituteId, facultyId)
    );
    return response.courseRootIds;
  };

  public fetchMultipleInstituteDetails = async (instituteIds: number[]) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchMultipleInstituteDetails(), { instituteIds }, true);
    return deserializeJsonObjCollection(response.institutes, Institute, 'fetchMultipleInstituteDetails') as Institute[];
  };

  public fetchInstituteFaculties = async (instituteId: string, courseId: string) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchInstituteFaculties(instituteId, courseId));
    const faculties = deserializeJsonObjCollection(response, ProfileModel, 'fetchInstituteFaculties') as ProfileModel[];
    return faculties;
  };

  public fetchUserInstitutes = async(payload) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchUserInstitutes(), payload, true);
    return response.studentInstitutes;
  }
}

const instituteRepository = new InstituteRepository(ApiClient);

export { instituteRepository as InstituteRepository };
