import React from 'react';
import { Typography } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
const { Paragraph } = Typography;

interface IProps extends ParagraphProps{
  children?: React.ReactNode;
  className?: any;
  code?: boolean;
  copyable?: boolean | { text: string; onCopy: () => void };
  delete?: boolean;
  disabled?: boolean;
  editable?: boolean;
  rows?: number;
  expandable?: boolean;
  suffix?: string;
  level?: number;
  mark?: boolean;
  underline?: boolean;
  onChange?: () => void;
  type?: 'secondary' | 'warning' | 'danger';
}

export default function(props: IProps) {
    const { children, ...rest } = props;
  return <Paragraph {...rest} >{children}</Paragraph>
};
