import { CacheKey, Deserializer, Serializer } from 'json-object-mapper';

@CacheKey('DateSerializerDeserializer')
export class DateSerializerDeserializer implements Serializer, Deserializer {
  public deserialize = (value: string): Date => {
    return new Date(value);
  }

  public serialize = (value: Date): string => {
    return value?.toISOString();
  }
}
