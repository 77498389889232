import { UploadRepository } from "@repositories/UploadRepository";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {

  IUploadFileAction,
  IUploadImageAction,
  uploadActions,
  uploadFileFailure,
  uploadFileSuccess,
  uploadImageFailure,
  uploadImageSuccess,
} from "./actions";

export function* uploadFileSaga(action: IUploadFileAction): any {

  const {
    file,
    fileId,
    bucket,
    include,
    onUploadSuccess,
    onUploadFail
  } = action.payload;

  try {
    const result = yield call(UploadRepository.uploadFile, file, bucket, include);

    onUploadSuccess({
      fileId,
      fileName: result.fileName,
      publicUrl: result.publicUrl,
      sasUrl: result.sasUrl
    });

    yield put(uploadFileSuccess({fileId}));
  } catch (error) {
    onUploadFail(error.message);
    yield put(uploadFileFailure(error.message));
  }
}


export function* uploadImageSaga(action: IUploadImageAction): any {
  const {
    image,
    imageId,
    bucket,
    thumbnailAspectRatio,
    include,
    onUploadSuccess,
    onUploadFail
  } = action.payload;

  try {
    const result: any = yield call(UploadRepository.uploadImage, image, bucket, thumbnailAspectRatio, include);

    onUploadSuccess({
      fileId: imageId,
      fileName: result.fileName,
      publicUrl: result.publicUrl,
    });
    yield put(uploadImageSuccess({imageId}));
  } catch (error) {
    onUploadFail(error.message);
    yield put(uploadImageFailure(error.message));
  }
}


export function* watchUploadFiles(): any {
  yield takeLatest(uploadActions.UPLOAD_FILE, uploadFileSaga);
  yield takeLatest(uploadActions.UPLOAD_IMAGE, uploadImageSaga);
}
