export const profileMessages = {
    profileInfoTitle: {
        key: 'profile.labelsAndTitles.PROFILE_INFORMATION'
    },
    firstName: {
        key: 'profile.labelsAndTitles.FIRST_NAME'
    },
    lastName: {
        key: 'profile.labelsAndTitles.LAST_NAME'
    },
    location: {
        key: 'profile.labelsAndTitles.LOCATION'
    },
    bio: {
        key: 'profile.labelsAndTitles.BIO'
    },
    linkedIn: {
        key: 'profile.labelsAndTitles.LINKEDIN_PROFILE'
    },
    facebook: {
        key: 'profile.labelsAndTitles.FACEBOOK_PROFILE'
    },
    twitter: {
        key: 'profile.labelsAndTitles.TWITTER_PROFILE'
    },
    myCalendly: {
        key: 'profile.labelsAndTitles.MY_CALENDLY_URL'
    },
    calendlyLink: {
        key: 'profile.labelsAndTitles.CALENDLY_LINK'
    },
    calendlyPlaceholder: {
        key: 'profile.labelsAndTitles.CALENDLY_LINK_PLACEHOLDER'
    },
    typeHere: {
        key: 'profile.labelsAndTitles.TYPE_HERE'
    },
    bioPlaceholder: {
        key: 'profile.labelsAndTitles.BIO_PLACEHOLDER'
    },
    linedInPlaceholder: {
        key: 'profile.labelsAndTitles.LINKEDIN_PLACEHOLDER'
    },
    facebookPlaceholder: {
        key: 'profile.labelsAndTitles.FACEBOOK_PLACEHOLDER'
    },
    twitterPlaceholder: {
        key: 'profile.labelsAndTitles.TWITTER_PLACEHOLDER'
    },
    chat: {
        key: 'global.actions.CHAT'
    },
    bookAppointment: {
        key: 'profile.actions.BOOK_APPOINTMENT'
    },
    uploadPicture: {
        key: 'profile.actions.UPLOAD_PICTURE'
    },
    cancel: {
        key: 'global.actions.CANCEL'
    },
    save: {
        key: 'global.actions.SAVE'
    },
    linkedInProfile: {
        key: 'profile.actions.LINKEDIN_PROFILE'
    },
    profileSubtext: {
        key: 'profile.messages.PROFILE_SUBTEXT'
    },
    calendlyDesc: {
        key: 'profile.messages.CALENDLY_DESC'
    },
    somethingWrong: {
        key: 'API_ERRORS.ERR',
    },
    profileUpdated: {
        key: 'profile.messages.PROFILE_UPDATE_SUCCESS'
    },
    imageUploadFailed: {
        key: 'global.messages.IMAGE_UPLOAD_FAILED'
    },
    linkedInUrlError: {
        key: 'profile.errors.LINKED_IN_URL_ERROR'
    },
    facebookUrlError: {
        key: 'profile.errors.FACEBOOK_URL_ERROR'
    },
    twitterUrlError: {
        key: 'profile.errors.TWITTER_URL_ERROR'
    },
    central: {
        key: 'global.labelsAndTitles.CENTRAL'
    },
    state: {
        key: 'global.labelsAndTitles.STATE'
    },
    profilePicUpdate: {
        key: 'profile.messages.PROFILE_PIC_UPDATE_SUCCESS'
    },
      profileCompleteModalTitle: {
        key: 'profile.labelsAndTitles.PROFILE_COMPLETE_MODAL_TITLE'
    },
    calendlyUrlError: {
        key: "profile.errors.CALENDLY_URL_ERROR"
    },
    organisationId: {
        key: 'profile.labelsAndTitles.ORGANISATION_ID'
    },
    organisationIdPlaceholder: {
        key: 'profile.labelsAndTitles.ORGANISATION_ID_PLACEHOLDER'
    },
    facultyRole: {
        key: 'profile.labelsAndTitles.FACULTY_ROLE'
    },
    rmRole: {
        key: 'profile.labelsAndTitles.RM_ROLE'
    },
    studentRole: {
        key: 'profile.labelsAndTitles.STUDENT_ROLE'
    },
    changePicture: {
        key: 'profile.actions.CHANGE_PICTURE'
    },
    languageSpoken: {
        key: 'profile.labelsAndTitles.LANGUAGE_SPOKEN'
    },
    startTyping: {
        key: 'global.labelsAndTitles.SEARCH_PLACEHOLDER'
    },  
    professionalSummary: {
        key: 'profile.labelsAndTitles.PROFESSIONAL_SUMMARY'
    },
    professionalSummaryPlaceholder: {
        key: 'profile.labelsAndTitles.PROFESSIONAL_SUMMARY_PLACEHOLDER'
    },
    headlineCharError: {
        key: 'profile.errors.HEADLINE_CHAR_ERROR'
    },
    spokenLanguagePlaceholder: {
        key: 'profile.labelsAndTitles.SPOKEN_LANGUAGE_PLACEHOLDER'
    },
    expertType: {
        key: 'profile.labelsAndTitles.EXPERT_TYPE'
    },
    expertTypePlaceholder: {
        key: 'profile.labelsAndTitles.EXPERT_TYPE_PLACEHOLDER'
    },
    expertSector: {
        key: 'profile.labelsAndTitles.EXPERT_SECTOR'
    },
    expertSectorPlaceholder: {
        key: 'profile.labelsAndTitles.EXPERT_SECTOR_PLACEHOLDER'
    },
    expertJobrolePlaceholder: {
        key: 'profile.labelsAndTitles.EXPERT_JOBROLE_PLACEHOLDER'
    },
    expertJobrole: {
        key: 'profile.labelsAndTitles.EXPERT_JOBROLE'
    },
    overallExperience: {
        key: 'profile.labelsAndTitles.OVERALL_EXPERIENCE'
    },
    overallExperiencePlaceholder: {
        key: 'profile.labelsAndTitles.OVERALL_EXPERIENCE_PLACEHOLDER'
    },
    workExperience: {
        key: 'profile.labelsAndTitles.WORK_EXPERIENCE'
    },
    addNewWorkExperience: {
        key: 'profile.labelsAndTitles.ADD_NEW_WORK_EXPERIENCE'
    },
};
