import React, { memo } from 'react';

import { ConfigProvider, DatePicker } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { BrowserScrollService } from '@utils/BrowserScrollService';
import { DATE_FORMAT_6 } from '@utils/DateHelper';
import { getCalendarLanguage } from '@utils/UserUtils';

import './style.less';

interface IProps {
  id: string;
  className?: string;
  placeholder?: string;
  textClassName?: string;
  mode?: string;
  size?: SizeType;
  bordered?: boolean;
  allowClear?: boolean;
  labelText?: string;
  onButtonClick: (value: any, dateString: string) => void;
  disabled?: boolean;
  value?: any;
  format?: string;
  disabledDate?: (currentDate: any) => boolean;
  required?: boolean;
  showNow?: boolean;
  showTime?: {};
  picker?: string;
}

export default memo((props: IProps) => {
    const {
        className = '',
        id,
        placeholder,
        onButtonClick,
        value,
        disabled,
        labelText,
        bordered = false,
        format = DATE_FORMAT_6,
        allowClear = false,
        size = 'large',
        disabledDate,
        required,
        showNow,
        showTime,
        picker
    } = props;

    return (
        <div className={`componentWrapper ${className}`}>
            <div className="componentTitle">
                {labelText} {required && <span className="required-field">*</span>}
            </div>

            <div className="componentContent">
                <ConfigProvider locale={getCalendarLanguage()}>
                    <DatePicker
                        allowClear={allowClear}
                        bordered={bordered}
                        className="pickerStyle"
                        disabled={disabled}
                        disabledDate={disabledDate}
                        format={format}
                        getPopupContainer={BrowserScrollService.getScrollParent}
                        id={id}
                        onChange={onButtonClick}
                        placeholder={placeholder || ''}
                        size={size}
                        value={value}
                        showNow={showNow}
                        showTime={showTime}
                        //@ts-ignore
                        picker={picker}
                    />
                </ConfigProvider>
            </div>
        </div>
    );
});
