export const SOCKETIO_CONNECT = 'connect';
export const SOCKETIO_CONNECT_ERROR = 'connect_error';
export const SOCKETIO_DISCONNECT = 'disconnect';
export const SOCKETIO_ERROR = 'error';
export const SOCKETIO_CONNECT_TIMEOUT = 'connect_timeout';
export const SOCKETIO_RECONNECT = 'reconnect';
export const SOCKETIO_RECONNECT_ATTEMPT = 'reconnect_attempt';
export const SOCKETIO_RECONNECTING = 'reconnecting';
export const SOCKETIO_RECONNECT_ERROR = 'reconnect_error';
export const SOCKETIO_RECONNECT_FAILED = 'reconnect_failed';
export const KILL_WEBSOCKET = 'Action::KILL_WEBSOCKET';
export const SOCKETIO_RECEIVED_MESSAGE = 'receivedMessage';
export const SOCKETIO_INVALID_TOKEN = 'Invalid Authtoken';

export default {
    SOCKETIO_CONNECT,
    SOCKETIO_CONNECT_ERROR,
    SOCKETIO_DISCONNECT,
    SOCKETIO_ERROR,
    SOCKETIO_CONNECT_TIMEOUT,
    SOCKETIO_RECONNECT,
    SOCKETIO_RECONNECT_ATTEMPT,
    SOCKETIO_RECONNECTING,
    SOCKETIO_RECONNECT_ERROR,
    SOCKETIO_RECONNECT_FAILED,
    SOCKETIO_RECEIVED_MESSAGE,
    KILL_WEBSOCKET,
    SOCKETIO_INVALID_TOKEN,
};
