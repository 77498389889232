import React, { ReactNode } from "react";
import "./styles.less";
import { FontFamilyVariant } from "../../utils"

interface IProps {
  variant: FontFamilyVariant;
  text: string | ReactNode;
  className?: string;
}

const BodyText = (props: IProps) => {
  const { text, variant, className } = props;

  const customStyle = className ? `${variant} ${className}` : variant;

  switch (variant) {
    case FontFamilyVariant.body3XLarge:
      return <span className={`typography-text ${customStyle}`}>{text}</span>;
    case FontFamilyVariant.body2XLarge:
      return <span className={`typography-text ${customStyle}`}>{text}</span>;
    case FontFamilyVariant.bodyXLarge:
      return <span className={`typography-text ${customStyle}`}>{text}</span>;
    case FontFamilyVariant.bodyLarge:
      return <span className={`typography-text ${customStyle}`}>{text}</span>;
    case FontFamilyVariant.bodyMedium:
      return <span className={`typography-text ${customStyle}`}>{text}</span>;
    case FontFamilyVariant.bodyRegular:
      return <span className={`typography-text ${customStyle}`}>{text}</span>;
    case FontFamilyVariant.bodySmall:
      return <span className={`typography-text ${customStyle}`}>{text}</span>;
    case FontFamilyVariant.bodyXSmall:
      return <span className={`typography-text ${customStyle}`}>{text}</span>;
    case FontFamilyVariant.body2XSmall:
      return <span className={`typography-text ${customStyle}`}>{text}</span>;
    case FontFamilyVariant.body3XSmall:
      return <span className={`typography-text ${customStyle}`}>{text}</span>;

  }
}

export default BodyText;
