import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DateHelper, DEFAULT_DATE_TIME_FORMAT } from '@utils/DateHelper';

import { NotificationModal } from '@common/domain/models/Notification';
import { readNotificationById } from '@common/redux/notifications/actions';
import { getReadNotificationError, getReadNotificationLoader } from '@common/redux/notifications/selectors';

import { WFTextNormal } from '@components/index';

import './styles.less';

interface IProps {
    data :NotificationModal;
}

const NotificationListItem = (props:IProps) => {
    const dispatch = useDispatch();
    const { data } = props;
    const [isRead, setIsRead] = useState(data.getReadAt() === '');
    const [isDispatched,setIsDispatched] = useState(false);
    const readNotificationLoader = useSelector(getReadNotificationLoader);
    const readNotificationError = useSelector(getReadNotificationError)
    const onClickItem = () => {
        setIsRead(false);
        dispatch(readNotificationById(data.getId && data.getId()));
        setIsDispatched(true);
    };
    
    useEffect (function redirectUser(){
        if(isDispatched&&!readNotificationError&&!readNotificationLoader&&data.getDeepLink&&data.getDeepLink()){
         window.location.replace(data.getDeepLink());
        }
    },[readNotificationLoader]);

    const containerClassName = isRead ? 'notification_item__container' : 'notification_item__container  active';
    const showFormattedDate = (date: string) => DateHelper.getDateTime(date as unknown as Date, DEFAULT_DATE_TIME_FORMAT);
    return (
        <div
            className={containerClassName}
            onClick={onClickItem}
        >
            <WFTextNormal className="notification_item__title">{data.getTitle()}</WFTextNormal>
            <WFTextNormal className="notification_item__body">{data.getBody()}</WFTextNormal>
            <WFTextNormal className="notification_item__date">{showFormattedDate(data.getCreatedAt())}</WFTextNormal>
        </div>
    );
};

export default NotificationListItem;