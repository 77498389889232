import React, { ReactNode } from "react";
import "./styles.less";
import { FontFamilyVariant } from "../../utils"

interface IProps {
  variant: FontFamilyVariant;
  text: string | ReactNode;
  className?: string;
}

const TagText = (props: IProps) => {
  const { text, variant, className } = props;

  const customStyle = className ? `${variant} ${className}` : variant;

  switch (variant) {
    case FontFamilyVariant.tagLarge:
      return <h2 className={`typography-text ${customStyle}`}>{text}</h2>;
    case FontFamilyVariant.tagMedium:
      return <h3 className={`typography-text ${customStyle}`}>{text}</h3>;
    case FontFamilyVariant.tagRegular:
      return <h5 className={`typography-text ${customStyle}`}>{text}</h5>;
    case FontFamilyVariant.tagSmall:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.tagXSmall:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;

  }
}

export default TagText;
