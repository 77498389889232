
import { APIv1, APIv2 } from "@common/network/constants";
import { IFetchRecommendedForumListPayload } from "@common/redux/forum/interface";

import { ApiClient, wfcommonApiClient } from "@network/client";

const ENDPOINTS = {
    fetchForumListByUserId: (userId: string) => `${APIv1}/organizationservice/discourse/users/${userId}`,
    fetchForumDetailsByForumId: () => `${APIv1}/organizationservice/discourse/categories`,
    fetchRecommendedForumList: () => `${APIv1}/organizationservice/forum`
};

class ForumRepository {
    private apiClient: any;

    constructor(apiClient: any) {
        this.apiClient = apiClient;
    }

    public fetchForumListByUserId = async (userId, role?: string) => {
        const params = {
            ...(role && { role })
        }
        const response = await this.apiClient.get(ENDPOINTS.fetchForumListByUserId(userId), params);
        return response;
    }

    public fetchForumDetailsByForumId = async (forumId) => {
        const params = {
            forumKey: forumId
        }
        const response = await this.apiClient.get(ENDPOINTS.fetchRecommendedForumList(), params);
        return response?.forums;
    }

    public fetchRecommendedForumList = async (payload: IFetchRecommendedForumListPayload) => {
        const params = {
            tags: JSON.stringify(payload)
        }
        const response = await this.apiClient.get(ENDPOINTS.fetchRecommendedForumList(), params);
        return response;
    }
}

const forumRepository = new ForumRepository(ApiClient);

export { forumRepository as ForumRepository };
