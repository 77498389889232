import { skillScoresActions } from "./actions";

export interface ISkillScoresState {
  skills: any[];
  loaders: {
    fetchSkillsData: boolean;
  };
  errors: {
    fetchSkillsData: string;
  };
}

export const initialState = {
  skills: [],
  loaders: {
    fetchSkillsData: false,
  },
  errors: {
    fetchSkillsData: "",
  },
};

export const skillsScoreReducer = (
  state: ISkillScoresState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case skillScoresActions.FETCH_SKILLS_SCORES:
      return {
        ...state,
        loaders: { ...state.loaders, fetchSkillsData: true },
        errors: { ...state.errors, fetchSkillsData: "" },
      };
    case skillScoresActions.FETCH_SKILLS_SCORES_SUCCESS:
      return {
        ...state,
        skills: action.payload,
        loaders: { ...state.loaders, fetchSkillsData: false },
        errors: { fetchSkillsData: "" },
      };
    case skillScoresActions.FETCH_SKILLS_SCORES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchSkillsData: false },
        errors: { ...state.errors, fetchSkillsData: action.payload },
      };

    default:
      return state;
  }
};
