import React from "react";
import WFButton from "./DefaultButton";

interface IProps {
    text: string;
    labelText: string;
    onButtonClick: (userResponse:any, isLast?: boolean) => void;
    className?: any;
    isDisable?: boolean;
    isLoading?: boolean;
}

export default function (props: IProps) {
    const {text, labelText, onButtonClick, className, isDisable, isLoading} = props;
    const style = ['wf_bordered_label_button', className].join(' ');
    return (
        <div className={style}>
            <div className="button_holder">
                <WFButton
                    type={'primary'}
                    htmlType={'button'}
                    className="button_style text-center muli_bold_white_16"
                    onClick={onButtonClick}
                    disabled={isDisable && isDisable}
                    loading={isLoading}
                >
                    {text}
                </WFButton>
                <div className="instruction_holder">
                    <div className="instruction muli_regular_14">
                        {labelText}
                    </div>
                </div>
            </div>
        </div>
    )
}
