import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Tabs } from "antd";

import Navigator from "@navigator/index";

import { IAppMenuItem } from "@layout/Dashboard";
import "./styles.less";

const { TabPane } = Tabs;

interface IProps {
  tabItems: IAppMenuItem[];
  className?: string;
  activeKey: string;
  onChange: (key: string) => void;
  tabPosition?: string;
  size?: any;
}

export const TabView = (props: IProps) => {
  const { activeKey, size, className, tabItems, onChange } = props;
  const { t } = useTranslation();

  const style = ["tab_container", className].join(" ");

  const tabItemClicked = (item: string) => {
    props.onChange(item);
  };

  return (
    <Tabs
      className={style}
      onChange={tabItemClicked}
      activeKey={activeKey}
      size={size}
      centered
    >
      {tabItems.map((tabItem: IAppMenuItem) => {
        const onClick = () => {
          const route = tabItem.url;
          Navigator.push(route);
          onChange(tabItem.key);
        };

        return (
          <TabPane
            key={tabItem.key}
            tab={
              <div className="tab_menu" onClick={onClick} id={`mobile_${tabItem.name.toString().split(" ")[0]}`}>
                {tabItem.iconImage}
                {/* {`${tabItem.name}`} */}
                <p>{tabItem.name.toString().split(" ")[0]}</p>
                <p>{tabItem.name.toString().split(" ")[1]}</p>
              </div>
            }
          />
        );
      })}
    </Tabs>
  );
};

export default memo(TabView);
