export const enum userRoles {
    student = 'STUDENT',
    faculty = 'FACULTY',
    regionalManager = 'REGIONAL_MANAGER',
  instituteAdmin = 'INSTITUTE_ADMIN',
  expert = "EXPERT"
};

export const FEATURE_NAME = {
         AICOACH: "ai-coach",
         INTERVIEW_PREP: "interview-prep",
         FORUM: "forum",
         CAREERS: "careers",
         LIBRARY: "library",
         FRESHDESK: "freshdesk",
         NEWUITOUR: "new_ui_tour",
         MENTORS: "mentor",
       };

export const supportedFrontEndLanguageCodes = ['en', 'es', 'pt', 'en-US', 'hi', 'ar'];
