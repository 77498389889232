import { LocationModel } from "@common/domain/models/LocationModel";
import { locationActions } from "./actions";

export interface ILocationState {
  regions: any[];
  countries: any[];
  states: any[];
  cities: any[];
  locationList:LocationModel[];
  locationListCount:number;
  loaders: {
    regions: boolean;
    countires: boolean;
    states: boolean;
    cities: boolean;
    locationList:boolean;
  };
  errors: {
    regions: string;
    countires: string;
    states: string;
    cities: string;
    locationList:string;
  };
}

export const initialState = {
  regions: [],
  countries: [],
  states: [],
  cities: [],
  locationList:[],
  locationListCount:0,
  loaders: {
    regions: false,
    countires: false,
    states: false,
    cities: false,
    locationList:false,
  },
  errors: {
    regions: '',
    countires: '',
    states: '',
    cities: '',
    locationList:""
  },
};

export const locationReducer = (
  state: ILocationState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {

    case locationActions.FETCH_REGIONS:
      return {
        ...state,
        countries: [],
        states: [],
        cities: [],
        loaders: { ...state.loaders, regions: true },
        errors: { ...state.errors, regions: '' },
      };
    case locationActions.FETCH_REGIONS_SUCCESS:

      return {
        ...state,
        regions: action.payload,
        loaders: { ...state.loaders, regions: false },
      };
    case locationActions.FETCH_REGIONS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, regions: false },
        errors: { ...state.errors, regions: action.payload },
      };


    case locationActions.FETCH_COUNTRIES:
      return {
        ...state,
        loaders: { ...state.loaders, countries: true },
        errors: { ...state.errors, countries: '' },
      };
    case locationActions.FETCH_COUNTRIES_SUCCESS:

      return {
        ...state,
        countries: action.payload,
        loaders: { ...state.loaders, countries: false },
      };
    case locationActions.FETCH_COUNTRIES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, countries: false },
        errors: { ...state.errors, countries: action.payload },
      };


    case locationActions.FETCH_STATES:
      return {
        ...state,
        cities: [],
        loaders: { ...state.loaders, states: true },
        errors: { ...state.errors, states: '' },
      };
    case locationActions.FETCH_STATES_SUCCESS:

      return {
        ...state,
        states: action.payload,
        loaders: { ...state.loaders, states: false },
      };
    case locationActions.FETCH_STATES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, states: false },
        errors: { ...state.errors, states: action.payload },
      };



    case locationActions.FETCH_CITIES:
      return {
        ...state,
        loaders: { ...state.loaders, cities: true },
        errors: { ...state.errors, cities: '' },
      };
    case locationActions.FETCH_CITIES_SUCCESS:

      return {
        ...state,
        cities: action.payload,
        loaders: { ...state.loaders, cities: false },
      };
    case locationActions.FETCH_CITIES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, cities: false },
        errors: { ...state.errors, cities: action.payload },
      };

    case locationActions.FETCH_LOCATION:
      return {
        ...state,
        loaders: { ...state.loaders, locationList: true },
        errors: { ...state.errors, locationList: '' },
      };
    case locationActions.FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        locationListCount:action.payload.totalCount,
        locationList: action.payload.pageNumber === 1 ? action.payload?.locationList : [...state?.locationList, ...action.payload.locationList],
        loaders: { ...state.loaders, locationList: false },
      };
    case locationActions.FETCH_LOCATION_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, locationList: false },
        errors: { ...state.errors, locationList: action.payload },
      };
    case locationActions.CLEAR_LOCATION_LIST:
      return {
        ...state,
        locationList: []
      };
    case locationActions.CLEAR_COUNTRIES_LIST:
      return {
        ...state,
        countries: []
      };
    default:
      return state;
  }
};

