// import { ApiClient } from "@network/client";
// import { CountryModel } from "@models/CountryModel";
// import { deserializeJsonObj } from "@utils/ObjectMapperUtil";
// import { IApiClient } from "@common/network/interfaces";

// const ENDPOINTS = {
//   fetchCountries: () => `${APIv1}/organizationservice/admin/countries`,
// };

// class LocationRepository {
//   private apiClient: IApiClient;

//   constructor(apiClient: IApiClient) {
//     this.apiClient = apiClient;
//   }

//   public fetchCountries = async (regionId?: string) => {
//       const params = {
//         regionId: regionId
//       }
//       const response = await this.apiClient.get(ENDPOINTS.fetchCountries(), params);
//       const countriesModalData = deserializeJsonObj(response, CountryModel, 'fetchCountries');
//        return countriesModalData;
//   };
// }

// const locationRepository = new LocationRepository(ApiClient);

// export { locationRepository as LocationRepository };


import { APIv1, APIv2 } from "@common/network/constants";
import { IFetchCountryPayload, IFtechLocationPayload } from "@common/redux/location/actions";
import { City } from '@models/City';
import { ApiClient, wfcommonApiClient } from "@network/client";
import { deserializeJsonObjCollection } from '@utils/ObjectMapperUtil';
import { LocationModel } from "../models/LocationModel";

const ENDPOINTS = {
  fetchRegions: () => `${APIv1}/organizationservice/regions`,
  fetchCountries: () => `${APIv1}/locationservice/location/countries`,
  fetchStates: () => `${APIv1}/organizationservice/states`,
  fetchCities: () => `${APIv2}/organizationservice/cities`,
  fetchLocation: () => `${APIv1}/locationservice/location/get`
};

class LocationRepository {
  private apiClient: any;
  private commonApiClient:any

  constructor(apiClient: any,commonApiClient:any) {
    this.apiClient = apiClient;
    this.commonApiClient = commonApiClient
  }

  public fetchRegions = async () => {
    try {
      const response = await this.apiClient.get(ENDPOINTS.fetchRegions());
      return response;
    } catch (e) {
      throw e.message;
    }
  };


  public fetchCountries = async (payload:IFetchCountryPayload) => {
    try {
      const {search} = payload;
      const params = {
        search
      }
      const response = await this.commonApiClient.get(ENDPOINTS.fetchCountries(), params);
      return response?.countries;
    } catch (e) {
      throw e.message;
    }
  };

  public fetchStates = async (countryId: string) => {
    try {
      const params = {
        countryId: countryId
      }
      const response = await this.apiClient.get(ENDPOINTS.fetchStates(), params);
      return response;
    } catch (e) {
      throw e.message;
    }
  };


  public fetchCities = async (searchKey: string) => {
    try {
      const params = {
        search: searchKey
      }
      const response = await this.apiClient.get(ENDPOINTS.fetchCities(), params);
      return deserializeJsonObjCollection(response, City, 'fetchCities') as City[];
    } catch (e) {
      throw e.message;
    }
  };

  public fetchLocation = async (payload: IFtechLocationPayload) => {
    try {
      const params = payload
      const response = await this.commonApiClient.get(ENDPOINTS.fetchLocation(), params);
      const locationList: LocationModel[] = deserializeJsonObjCollection(response.data, LocationModel, 'fetchLocation') as LocationModel[];
      const responseData = {
        locationList,
        total: response.totalCount
      }
      return responseData;
    } catch (e) {
      throw e.message
    }
  }
}

const locationRepository = new LocationRepository(ApiClient,wfcommonApiClient);

export { locationRepository as LocationRepository };
