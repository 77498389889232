import { deserializeJsonObj } from '@utils/ObjectMapperUtil';

import { EventModel } from '@common/domain/models/Event';
import { eventActions } from '@common/redux/event/actions';
import { IEventState } from '@common/redux/event/interface';

import { EventStatus } from '@constants/config';

export const initialState = {
    byId: {},
    eventDetail: null,
    recommendedEvents: [],
    upcomingEvents: [],
    eventParticipantList: {},
    eventParticipantListCount: {},
    loaders: {
        eventDetail: false,
        recommendedEvents: false,
        registerEvents: false,
        upcomingEvents: false,
        eventParticipantList: false,
    },
    errors: {
        eventDetail: '',
        recommendedEvents: '',
        registerEvents: '',
        eventParticipantList: "",
        upcomingEvents: '',
    }
}

export const eventReducer = (state: IEventState = initialState,
    action: { type: string; payload: any }) => {
    switch (action.type) {

        // event detail
        case eventActions.FETCH_EVENT_DETAIL:
            return {
                ...state,
                loaders: { ...state.loaders, eventDetail: true },
                errors: { ...state.errors, eventDetail: '' },
            }
        case eventActions.FETCH_EVENT_DETAIL_SUCCESS:

            return {
                ...state,
                byId: {...state.byId, [action.payload.id]: action.payload },
                eventDetail: action.payload,
                loaders: { ...state.loaders, eventDetail: false },
            }
        case eventActions.FETCH_EVENT_DETAIL_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, eventDetail: false },
                errors: { ...state.errors, eventDetail: action.payload },
            }
        // recommended events
        case eventActions.FETCH_RECOMMENDED_EVENT:
            return {
                ...state,
                loaders: { ...state.loaders, recommendedEvents: true },
                errors: { ...state.errors, recommendedEvents: '' },
            }
        case eventActions.FETCH_RECOMMENDED_EVENT_SUCCESS:
            return {
                ...state,
                recommendedEvents: action.payload,
                loaders: { ...state.loaders, recommendedEvents: false },
            }
        case eventActions.FETCH_RECOMMENDED_EVENT_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, recommendedEvents: false },
                errors: { ...state.errors, recommendedEvents: action.payload },
            }
        // upcoming events
        case eventActions.FETCH_UPCOMING_USER_EVENTS:
            return {
                ...state,
                loaders: { ...state.loaders, upcomingEvents: true },
                errors: { ...state.errors, upcomingEvents: '' },
            }
        case eventActions.FETCH_UPCOMING_USER_EVENTS_SUCCESS:
            return {
                ...state,
                upcomingEvents: action.payload,
                loaders: { ...state.loaders, upcomingEvents: false },
            }
        case eventActions.FETCH_UPCOMING_USER_EVENTS_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, upcomingEvents: false },
                errors: { ...state.errors, upcomingEvents: action.payload },
            }
        // register events
        case eventActions.REGISTER_EVENT:
            return {
                ...state,
                loaders: { ...state.loaders, registerEvents: true },
                errors: { ...state.errors, registerEvents: '' },
            }
        case eventActions.REGISTER_EVENT_SUCCESS:
            const eventDetail = state.byId[action.payload]
            const updatedEventResponse = {...eventDetail, registrationStatus: EventStatus.REGISTERED};
            const serialisedResponse = deserializeJsonObj(updatedEventResponse, EventModel, 'registerEvent');
            return {
                ...state,
                byId: {...state.byId, [action.payload]: serialisedResponse },
                loaders: { ...state.loaders, registerEvents: false },
            }
        case eventActions.REGISTER_EVENT_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, registerEvents: false },
                errors: { ...state.errors, registerEvents: action.payload },
            }
        // events  participant list
        case eventActions.EVENT_PARTICIPANT_LIST:
            return {
                ...state,
                loaders: { ...state.loaders, eventParticipantList: true },
                errors: { ...state.errors, eventParticipantList: '' },
            }
        case eventActions.EVENT_PARTICIPANT_LIST_SUCCESS:
            return {
                ...state,
                eventParticipantList: {
                    ...state.eventParticipantList,
                    [action.payload.status]: action.payload.page === 1 ? action.payload.eventParticipantList : [...state.eventParticipantList[action.payload.status], ...action.payload.eventParticipantList]
                },
                eventParticipantListCount: {
                    ...state.eventParticipantListCount,
                    [action.payload.status]: action.payload.total
                },
                loaders: { ...state.loaders, eventParticipantList: false },
            }
        case eventActions.EVENT_PARTICIPANT_LIST_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, eventParticipantList: false },
                errors: { ...state.errors, eventParticipantList: action.payload },
            }
        default:
            return state;
    }
}




