import axios from 'axios';
import { GUEST_USER_DETAILS_BY_IP_API } from '@constants/config';

/**
 * Response structure of https://ipapi.co/json
 *
 * {
    "ip": "106.51.106.68",
    "version": "IPv4",
    "city": "Bengaluru",
    "region": "Karnataka",
    "region_code": "KA",
    "country": "IN",
    "country_name": "India",
    "country_code": "IN",
    "country_code_iso3": "IND",
    "country_capital": "New Delhi",
    "country_tld": ".in",
    "continent_code": "AS",
    "in_eu": false,
    "postal": "560002",
    "latitude": 12.9634,
    "longitude": 77.5855,
    "timezone": "Asia/Kolkata",
    "utc_offset": "+0530",
    "country_calling_code": "+91",
    "currency": "INR",
    "currency_name": "Rupee",
    "languages": "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
    "country_area": 3287590.0,
    "country_population": 1352617328.0,
    "asn": "AS24309",
    "org": "Atria Convergence Technologies Pvt. Ltd. Broadband Internet Service Provider INDIA"
}
 */

export interface IDetailsByIP {
    ip: string;
    city: string;
    region: string;
    region_code: string;
    country: string;
    country_code: string;
    country_code_iso3: string;
    country_capital: string;
    country_tld: string;
    country_name: string;
    continent_code: string;
    in_eu: boolean;
    postal: string;
    latitude: number;
    longitude: number;
    timezone: string;
    timezoneName: string;
    utc_offset: string;
    country_calling_code: string;
    currency: string;
    currency_name: string;
    languages: string;
    country_area: number;
    country_population: number;
    asn: string;
    org: string;
}

export const DEFAULT_USER_LOCAL = {
    country: "IN",
    country_name: "India",
    country_code: "IN",
    country_code_iso: "IND",
    country_calling_code: "+91",
    currency: "INR",
    languages: "en-IN",
}
export class UserLocalDetailsByIpService {
    private userDetails = null;
    static userLocalDetailsByIpService = null;

    static async getInstance(): Promise<UserLocalDetailsByIpService> {
        if(UserLocalDetailsByIpService.userLocalDetailsByIpService === null){
            UserLocalDetailsByIpService.userLocalDetailsByIpService  = new UserLocalDetailsByIpService();
            await UserLocalDetailsByIpService.userLocalDetailsByIpService.callApi();
        }
        return UserLocalDetailsByIpService.userLocalDetailsByIpService;
    }

    public getCountryCallingCode() {
        if(this.userDetails){
            return this.userDetails.country_calling_code;
        }
        return '';
    }

    public getLanguage() {
        if(this.userDetails) {
            return this.userDetails.languages;
        }
        return '';
    }

    public getCurrency() {
        if(this.userDetails) {
            return this.userDetails.currency;
        }
        return '';
    }

    public getCountry() {
        if(this.userDetails) {
            return this.userDetails?.country;
        }
        return '';
    }

    private async callApi() {
        try {
            const response: { data: IDetailsByIP } = await axios.get(
                GUEST_USER_DETAILS_BY_IP_API,
            );
            this.userDetails = response.data;
        } catch (error) {
            // default language
            this.userDetails = DEFAULT_USER_LOCAL;
        }
    }
}

