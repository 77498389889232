import { AppLanguage } from '@translations/AppLanguage';
import { i18n } from '@translations/i18n';
import { i18n as placementPrepI18n } from '@translations/placementPrepi18n';
import { i18n as courseI18n } from '@translations/coursei18n';
import { JsonProperty } from 'json-object-mapper';

export interface ILoginModel {
  user: {
    email: string;
    password: string;
  };
}

export interface IChangePasswordModel {
  details: {
    id: number;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  };
}

export interface IPrivacyPolicy {
  isTermsAccepted?: boolean;
  dateTime?: string;
  timeZone?: string;
  isThirdPartyTermsAccepted?: boolean;
}

export interface IUserAcceptance {
  isAccepted: boolean;
  lou: {
    id: string;
    version: number;
    countryCode: string;
  },
  data: {
    dateTime?: string;
    timeZone?: string;
    ipAddress?: string;
    osInfo?: any;
  }
}

export interface IResetPasswordModel {
  newPassword: string;
  confirmPassword: string;
  userAcceptance?: IUserAcceptance;
}

export interface IUserModel {
  id: number;
  uuid: string;
  isVerified: boolean;
  token: IUserToken;
  role: IUserRole[];
  isRequirePasswordChange: boolean;
  isPrivacyPolicyAccepted: boolean;
  isUserAcceptanceAccepted: boolean;
  email: string;
}

export interface IUserToken {
  authToken: string;
  refreshToken: string;
}

export interface IUserRole {
  id: number;
  name: string;
  profileId: string;
  isTM: boolean;
  ecosystem: {
    id: number;
    name: string;
  }
}

export interface ILogoutModel {
  device: {
    installationId: string;
  };
}

export interface IRegisterModel {
  device: {
    token: string;
    installationId: string;
  };
}

export interface IDeviceInfoModel {
  deviceType: string;
  appVersion: string;
  timezoneName: string;
  bundleId: string;
  buildId: string;
  userAgent: string;
  deviceId: string;
  deviceName: string;
  systemVersion: string;
  platform: string;
  appBuildNumber: string;
  manufacturer: string;
  systemName: string;
  brand: string;
  fontScale: number;
  uniqueId: string;
  landscape: boolean;
  deviceLocale: string;
}

export interface IRole {
  id: number;
  name: string;
}

export class UserByEmailModel {
  @JsonProperty({name: 'id', required: true})
  private id: number = 0;

  @JsonProperty({name: 'email', required: true})
  private email: string = '';

  @JsonProperty({name: 'role', required: false})
  private role: IRole[] = []

  public getId = (): number => {
    return this.id;
  }

  public getEmail = (): string => {
    return this.email;
  }

  public getRole = (): IRole[] => {
    return this.role;
  }
}

export const isPlatformLanguageArabic = () => {
  return i18n.language === AppLanguage.Arabic;
};

export const isInterviewPrepArabic = () => {
  return placementPrepI18n.language === AppLanguage.Arabic;
};

export const isCourseAiCoachArabic = () => {
  return courseI18n.language === AppLanguage.Arabic;
};

