
import { JsonProperty } from 'json-object-mapper';
import { DateSerializerDeserializer } from '@utils/transformers/DateSerializerDeserializer';

export interface IMessageContent {
  text?: string;
  file?: string;
  thumbnailImageUrl?: string;
  thumbnailImageLowResolutionUrl?: string;
  url?: string;
}

export interface IMessage {
    id: string;
    type: number;
    content: IMessageContent;
    thumbnailImageUrl?: string;
    thumbnailImageLowResolutionUrl?: string;
    url?: string;
    pending: boolean;
    sent: boolean;
    conversationId: string;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
    replyOf: IMessage;
}

export class MessageModel {

  @JsonProperty({ name: 'pending', required: false })
  private pending: boolean = false;

  @JsonProperty({ name: 'sent', required: false })
  private sent: boolean = true;

  @JsonProperty({ name: 'deleted', required: true })
  private deleted: boolean = false;

  @JsonProperty({ name: 'id', required: true })
  private id: string = '';

  @JsonProperty({ name: 'clientMessageId', required: true })
  private clientMessageId: string = '';

  @JsonProperty({ name: 'type', required: true })
  private type: number = 1;

  @JsonProperty({ name: 'content', required: false })
  public content: IMessageContent = {
    text: '',
    file: '',
    thumbnailImageLowResolutionUrl: '',
    thumbnailImageUrl: '',
  };

  @JsonProperty({ name: 'conversationId', required: true })
  private conversationId: string = '';

  @JsonProperty({ name: 'createdBy', required: true })
  public createdBy: string = '';

  @JsonProperty({
    type: Date,
    serializer: DateSerializerDeserializer,
    deserializer: DateSerializerDeserializer,
  })
  private createdAt?: Date = undefined;

  @JsonProperty({
    type: Date,
    serializer: DateSerializerDeserializer,
    deserializer: DateSerializerDeserializer,
  })
  @JsonProperty({ name: 'updatedAt', required: true })
  private updatedAt?: Date = undefined;

  @JsonProperty({name: 'replyOf', required: false})
  private replyOf?: MessageModel = undefined;

  public getId = (): string => {
    return this.id;
  }

  public getReplyOf = () :  MessageModel|undefined => {
    return this.replyOf;
  }

  public getConversationId = (): string => {
    return this.conversationId;
  }

  public getMessageText = (): string => {
    if ( this.content && this.content.text ) {
      return this.content.text;
    }
    return '';
  }

  public getCreatedBy = (): string => {
    return this.createdBy;
  }

  public getCreatedAt = (): Date | undefined => {
    return this.createdAt;
  }

  public isPending = (): boolean => {
    return this.pending;
  }

  public setPending = (status: boolean) => {
    this.pending = status;
  }

  public setSent = (status: boolean) => {
    this.sent = status;
  }

  public isSent = (): boolean => {
    return this.sent;
  }

  public getClientMessageId = (): string => {
    return this.clientMessageId;
  }

  public getType = (): number => {
    return this.type;
  }

  public getMessageFile = (): string => {
    if ( this.content && this.content.file ) {
      return this.content.file;
    }
    return '';
  }

  public getMessageFileUrl = (): string => {
    if ( this.content && this.content.url ) {
      return this.content.url;
    }
    return '';
  }
  public getThumbnailImageUrl = (): string => {
    if ( this.content && this.content.thumbnailImageUrl ) {
      return this.content.thumbnailImageUrl;
    }
    return '';
  }
  public getThumbnailImageLowResolutionUrl = (): string => {
    if ( this.content && this.content.thumbnailImageLowResolutionUrl ) {
      return this.content.thumbnailImageLowResolutionUrl;
    }
    return '';
  }
}
