import { Article, IArticle, IContentTag, IGetArticleDetailsParam, IGetArticlesApiParam, ILikeArticlePayload, ITagPayload } from '@common/domain/models/Article';

import { IFluxStandardAction } from '@store/webInterfaces';

const actionTypePrefix = 'ARTICLE';

export const contentActions = {
    FETCH_ARTICLES: `${actionTypePrefix}/FETCH_ARTICLES`,
    FETCH_ARTICLES_SUCCESS: `${actionTypePrefix}/FETCH_ARTICLES_SUCCESS`,
    FETCH_ARTICLES_FAIL: `${actionTypePrefix}/FETCH_ARTICLES_FAIL`,

    FETCH_ARTICLE_DETAILS: `${actionTypePrefix}/FETCH_ARTICLE_DETAILS`,
    FETCH_ARTICLE_DETAILS_SUCCESS: `${actionTypePrefix}/FETCH_ARTICLE_DETAILS_SUCCESS`,
    FETCH_ARTICLE_DETAILS_FAIL: `${actionTypePrefix}/FETCH_ARTICLE_DETAILS_FAIL`,

    FETCH_TAGS: `${actionTypePrefix}/FETCH_TAGS`,
    FETCH_TAGS_SUCCESS: `${actionTypePrefix}/FETCH_TAGS_SUCCESS`,
    FETCH_TAGS_FAIL: `${actionTypePrefix}/FETCH_TAGS_FAIL`,

    LIKE_ARTICLE: `${actionTypePrefix}/LIKE_ARTICLE`,
    LIKE_ARTICLE_SUCCESS: `${actionTypePrefix}/LIKE_ARTICLE_SUCCESS`,
    LIKE_ARTICLE_FAIL: `${actionTypePrefix}/LIKE_ARTICLE_FAIL`,

    UNLIKE_ARTICLE: `${actionTypePrefix}/UNLIKE_ARTICLE`,
    UNLIKE_ARTICLE_SUCCESS: `${actionTypePrefix}/UNLIKE_ARTICLE_SUCCESS`,
    UNLIKE_ARTICLE_FAIL: `${actionTypePrefix}/UNLIKE_ARTICLE_FAIL`,

    FETCH_SUGGESTED_ARTICLES: `${actionTypePrefix}/FETCH_SUGGESTED_ARTICLES`,
    FETCH_SUGGESTED_ARTICLES_SUCCESS: `${actionTypePrefix}/FETCH_SUGGESTED_ARTICLES_SUCCESS`,
    FETCH_SUGGESTED_ARTICLES_FAIL: `${actionTypePrefix}/FETCH_SUGGESTED_ARTICLES_FAIL`,

    SET_SEARCH_PAGE_LOCATION: `${actionTypePrefix}/SET_SEARCH_PAGE_LOCATION`,
};

export interface IFetchArticleAction {
  type: string;
  payload: IGetArticlesApiParam;
}

export interface IFetchTagsAction {
    type: string;
    payload: ITagPayload;
}

export interface IArticleSuccessPayload {
    articles: Article[],
    count: number,
    type: string,
}

export interface IFetchArticleByIdAction {
  type: string;
  payload: IGetArticleDetailsParam;
}

export interface ILikeArticleAction{
    type:string;
    payload: ILikeArticlePayload;
}

export interface ISetSearchPageLocation{
    fromHomePage?:boolean;
    fromListingPage?:boolean;
}

export interface ISetSearchPageLocationAction{
    type:string,
    payload:ISetSearchPageLocation
}

export const fetchArticles = (apiParams: IGetArticlesApiParam): IFetchArticleAction => ({
    type: contentActions.FETCH_ARTICLES,
    payload: apiParams,
});

export const fetchArticlesSuccess = (
    articles: IArticleSuccessPayload
) => ({
    type: contentActions.FETCH_ARTICLES_SUCCESS,
    payload: articles
});

export const fetchArticlesFail = (
    error: string
): IFluxStandardAction<string> => ({
    type: contentActions.FETCH_ARTICLES_FAIL,
    payload: error,
});

export const fetchArticlesById = (
    payload:IGetArticleDetailsParam
): IFetchArticleByIdAction => ({
    type: contentActions.FETCH_ARTICLE_DETAILS,
    payload,
});

export const fetchArticlesByIdSuccess = (
    articles: Article
): IFluxStandardAction<Article> => ({
    type: contentActions.FETCH_ARTICLE_DETAILS_SUCCESS,
    payload: articles,
});

export const fetchArticlesByIdFail = (
    error: string
): IFluxStandardAction<string> => ({
    type: contentActions.FETCH_ARTICLE_DETAILS_FAIL,
    payload: error,
});

export const fetchTags = (payload: ITagPayload): IFetchTagsAction => ({
    type: contentActions.FETCH_TAGS,
    payload,
});

export const fetchTagsSuccess = (tags: IContentTag[]) => ({
    type: contentActions.FETCH_TAGS_SUCCESS,
    payload: tags
});

export const fetchTagsFail = (error: string): IFluxStandardAction<string> => ({
    type: contentActions.FETCH_TAGS_FAIL,
    payload: error,
});

export const likeArticle = (payload:ILikeArticlePayload):ILikeArticleAction => ({
    type: contentActions.LIKE_ARTICLE,
    payload
});

export const likeArticleSuccess = (payload:any) => ({
    type: contentActions.LIKE_ARTICLE_SUCCESS,
    payload
});
export const likeArticleFailure = (error: string): IFluxStandardAction<string> => ({
    type: contentActions.LIKE_ARTICLE_FAIL,
    payload: error
});

export const unLikeArticle = (payload:ILikeArticlePayload):ILikeArticleAction => ({
    type: contentActions.UNLIKE_ARTICLE,
    payload
});

export const unLikeArticleSuccess = (payload:any) => ({
    type: contentActions.UNLIKE_ARTICLE_SUCCESS,
    payload
});
export const unLikeArticleFailure = (error: string): IFluxStandardAction<string> => ({
    type: contentActions.UNLIKE_ARTICLE_FAIL,
    payload: error
});

export const fetchSuggestedArticles = (apiParams: IGetArticlesApiParam): IFetchArticleAction => ({
    type: contentActions.FETCH_SUGGESTED_ARTICLES,
    payload: apiParams,
});

export const fetchSuggestedArticlesSuccess = (
    articles: IArticleSuccessPayload
) => ({
    type: contentActions.FETCH_SUGGESTED_ARTICLES_SUCCESS,
    payload: articles
});

export const fetchSuggestedArticlesFail = (
    error: string
): IFluxStandardAction<string> => ({
    type: contentActions.FETCH_SUGGESTED_ARTICLES_FAIL,
    payload: error,
});

export const setSearchPageLocation = (payload:ISetSearchPageLocation):ISetSearchPageLocationAction => ({
    type: contentActions.SET_SEARCH_PAGE_LOCATION,
    payload
});
