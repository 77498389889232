import React, { ReactNode } from "react";
import "./styles.less";
import { FontFamilyVariant } from "../../utils"

interface IProps {
  variant: FontFamilyVariant;
  text: string | ReactNode;
  className?: string;
}

const MiscellaneousText = (props: IProps) => {
  const { text, variant, className } = props;

  const customStyle = className ? `${variant} ${className}` : variant;

  switch (variant) {
    case FontFamilyVariant.miscellaneousXLarge:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.miscellaneousLarge:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.miscellaneousMedium:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.miscellaneousSmall:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.miscellaneousXSmall:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;

  }
}

export default MiscellaneousText;
