import { JsonProperty } from 'json-object-mapper';

export interface INotificationAlertsCount {
    meetings: number;
    connects: number;
    content: number;
}

export interface INotification {
    id: number;
    title: string;
    body: string;
    deepLink: string;
    createdAt: string;
    updatedAt: string;
    seenAt: string;
    readAt: string;
    deleted: boolean;
}

export class NotificationModal {

    @JsonProperty({ name: 'id', required: true })
    private id: string = '';

    @JsonProperty({ name: 'title', required: true })
    private title: string = '';

    @JsonProperty({ name: 'body', required: true })
    private body: string = '';

    @JsonProperty({ name: 'deepLink', required: true })
    private deepLink: string = '';

    @JsonProperty({ name: 'createdAt', required: true })
    private createdAt: string = '';

    @JsonProperty({ name: 'updatedAt', required: true })
    private updatedAt: string = '';

    @JsonProperty({ name: 'seenAt', required: false })
    private seenAt: string = '';

    @JsonProperty({ name: 'readAt', required: false })
    private readAt: string = '';

    @JsonProperty({ name: 'deleted', required: true })
    private deleted: boolean = false;

    public getId = (): string => {
        if (this.id) {
            return this.id;
        }
        return '';
    }

    public getTitle = () => {
        if (this.title) {
            return this.title;
        }
        return '';
    }

    public getBody = () => {
        if (this.body) {
            return this.body;
        }
        return '';
    }

    public getDeepLink = () => {
        if (this.deepLink) {
            return this.deepLink;
        }
        return '';
    }

    public getCreatedAt = () => {
        if (this.createdAt) {
            return this.createdAt;
        }
        return '';
    }

    public getUpdatedAt = () => {
        if (this.updatedAt) {
            return this.updatedAt;
        }
        return '';
    }

    public getSeenAt = () => {
        if (this.seenAt) {
            return this.seenAt;
        }
        return '';
    }

    public getReadAt = () => {
        if (this.readAt) {
            return this.readAt;
        }
        return '';
    }
    public setReadAt = (date: string) => {
        if (date && date.length > 0) {
            this.readAt = date;
        }
    }

    public getDeleted = () => {
        if (this.deleted) {
            return this.deleted;
        }
        return '';
    }

    public isValid = (): boolean => {
        if (this.id && this.id.length > 0) {
            return true;
        }
        return false;
    }

}
