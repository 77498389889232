import { BatchStudent } from '@common/domain/models/BatchStudent';

const searchActionPrefix = 'SEARCH/';

export const SearchActions = {
  FETCH_STUDENT_FACULTY_ON_BATCH_ID: `${searchActionPrefix}FETCH_STUDENT_FACULTY_ON_BATCH_ID`,
  FETCH_STUDENT_FACULTY_ON_BATCH_ID_SUCCESS: `${searchActionPrefix}FETCH_STUDENT_FACULTY_ON_BATCH_ID_SUCCESS`,
  FETCH_STUDENT_FACULTY_ON_BATCH_ID_FAILURE: `${searchActionPrefix}FETCH_STUDENT_FACULTY_ON_BATCH_ID_FAILURE`,
};

export const fetchStudentFacultyOnBatchId = (search: string, batchIds: number[], type?: string, isVentureAddMember?: boolean) => {
  return {
    type: SearchActions.FETCH_STUDENT_FACULTY_ON_BATCH_ID,
    payload: {
      search,
      batchIds,
      type,
      isVentureAddMember
    },
  };
};
  
export const fetchStudentFacultyOnBatchIdSuccess = (connections: BatchStudent[]) => {
  return {
    type: SearchActions.FETCH_STUDENT_FACULTY_ON_BATCH_ID_SUCCESS,
    payload: connections,
  };
};
  
export const fetchStudentFacultyOnBatchIdFailure = (error: string) => {
  return {
    type: SearchActions.FETCH_STUDENT_FACULTY_ON_BATCH_ID_SUCCESS,
    payload: error,
  };
};

