import { MeetingModel } from '@common/domain/models/Meeting';
import { meetingActions } from '@common/redux/meeting/actions';
import { IMeetingState } from '@common/redux/meeting/interface';
import { MeetingEnrollStatus } from '@constants/config';

export const initialState = {
    createEvent: '',
    fetchEventList: [],
    eventDetail: null,
    deletedEvent: {},
    updateMeeting: '',
    meetingDetailDraft: {},
    courseMeetingList: [],
    slotBooking: null,
    attendeeMeetingEnroll: null,
    lessonMeetingList: [],

    loaders: {
        createEvent: false,
        fetchEventList: false,
        eventDetail: false,
        slotBooking: false,
        deletedEvent: false,
        updateMeeting: false,
        courseMeetingList: false,
        attendeeMeetingEnroll: false,
        lessonMeetingList: false
    },
    errors: {
        createEvent: '',
        fetchEventList: '',
        eventDetail: '',
        deletedEvent: '',
        updateMeeting: '',
        slotBooking: '',
        courseMeetingList: '',
        attendeeMeetingEnroll: '',
        lessonMeetingList: ''
    }
}

export const meetingReducer = (state: IMeetingState = initialState,
    action: { type: string; payload: any }) => {
    switch (action.type) {
        case meetingActions.CREATE_EVENT:
            return {
                ...state,
                loaders: { ...state.loaders, createEvent: true },
                errors: { ...state.errors, createEvent: '' },
            }
        case meetingActions.CREATE_EVENT_SUCCESS:
            return {
                ...state,
                createEvent: action.payload,
                loaders: { ...state.loaders, createEvent: false },
            }
        case meetingActions.CREATE_EVENT_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, createEvent: false },
                errors: { ...state.errors, createEvent: action.payload },
            }

        // event list
        case meetingActions.FETCH_EVENT_LIST:
            return {
                ...state,
                loaders: { ...state.loaders, fetchEventList: true },
                errors: { ...state.errors, fetchEventList: '' },
            }
        case meetingActions.FETCH_EVENT_LIST_SUCCESS:
            return {
                ...state,
                fetchEventList: action.payload,
                loaders: { ...state.loaders, fetchEventList: false },
            }
        case meetingActions.FETCH_EVENT_LIST_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, fetchEventList: false },
                errors: { ...state.errors, fetchEventList: action.payload },
            }

        // event detail
        case meetingActions.FETCH_EVENT_DETAIL:
            return {
                ...state,
                loaders: { ...state.loaders, eventDetail: true },
                errors: { ...state.errors, eventDetail: '' },
            }
        case meetingActions.FETCH_EVENT_DETAIL_SUCCESS:
            return {
                ...state,
                eventDetail: action.payload,
                loaders: { ...state.loaders, eventDetail: false },
            }
        case meetingActions.FETCH_EVENT_DETAIL_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, eventDetail: false },
                errors: { ...state.errors, eventDetail: action.payload },
            }

        // delete event
        case meetingActions.DELETE_EVENT:
            return {
                ...state,
                loaders: { ...state.loaders, deletedEvent: true },
                errors: { ...state.errors, deletedEvent: '' },
            }
        case meetingActions.DELETE_EVENT_SUCCESS:
            return {
                ...state,
                fetchEventList: state.fetchEventList.filter((event: MeetingModel) => event?.getId() !== action?.payload),
                deletedEvent: action.payload,
                loaders: { ...state.loaders, deletedEvent: false },
            }
        case meetingActions.DELETE_EVENT_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, deletedEvent: false },
                errors: { ...state.errors, deletedEvent: action.payload },
            }
        case meetingActions.CLEAR_EVENT:
            return {
                ...state,
                eventDetail: null,
                loaders: { ...state.loaders, eventDetail: false },
                errors: { ...state.errors, eventDetail: "" },
            }
        case meetingActions.UPDATE_MEETING_DETAIL:
            return {
                ...state,
                loaders: { ...state.loaders, updateMeeting: true },
                errors: { ...state.errors, updateMeeting: '' },
            }
        case meetingActions.UPDATE_MEETING_DETAIL_SUCCESS:
            return {
                ...state,
                updateMeeting: action.payload,
                loaders: { ...state.loaders, updateMeeting: false },
            }
        case meetingActions.UPDATE_MEETING_DETAIL_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, updateMeeting: false },
                errors: { ...state.errors, updateMeeting: action.payload },
            }
        case meetingActions.CREATE_MEETING_DRAFT_DATA:
            return {
                ...state,
                meetingDetailDraft: action.payload
            }
        // course meeting list
        case meetingActions.FETCH_COURSE_MEETING_LIST:
            return {
                ...state,
                loaders: { ...state.loaders, courseMeetingList: true },
                errors: { ...state.errors, courseMeetingList: '' },
            }
        case meetingActions.FETCH_COURSE_MEETING_LIST_SUCCESS:
            return {
                ...state,
                courseMeetingList: action.payload,
                loaders: { ...state.loaders, courseMeetingList: false },
            }
        case meetingActions.FETCH_COURSE_MEETING_LIST_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, courseMeetingList: false },
                errors: { ...state.errors, courseMeetingList: action.payload },
            }

        // lesson meeting list
        case meetingActions.FETCH_LESSON_MEETING_LIST:
            return {
                ...state,
                loaders: { ...state.loaders, lessonMeetingList: true },
                errors: { ...state.errors, lessonMeetingList: '' },
            }
        case meetingActions.FETCH_LESSON_MEETING_LIST_SUCCESS:
            return {
                ...state,
                lessonMeetingList: action.payload,
                loaders: { ...state.loaders, lessonMeetingList: false },
            }
        case meetingActions.FETCH_LESSON_MEETING_LIST_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, lessonMeetingList: false },
                errors: { ...state.errors, lessonMeetingList: action.payload },
            }

        // attendee meeting enroll
        case meetingActions.ATTENDEE_MEETING_ENROLL:
            return {
                ...state,
                loaders: { ...state.loaders, attendeeMeetingEnroll: true },
                errors: { ...state.errors, attendeeMeetingEnroll: '' },
            }
        case meetingActions.ATTENDEE_MEETING_ENROLL_SUCCESS:
            const courseMeeting = updateMeetingListWithStatus(state.courseMeetingList, action.payload);
            const lessonMeeting = updateMeetingListWithStatus(state.lessonMeetingList, action.payload);
            return {
                ...state,
                courseMeetingList: courseMeeting,
                lessonMeetingList: lessonMeeting,
                attendeeMeetingEnroll: action.payload,
                eventDetail: action.payload,
                loaders: { ...state.loaders, attendeeMeetingEnroll: false },
            }
        case meetingActions.ATTENDEE_MEETING_ENROLL_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, attendeeMeetingEnroll: false },
                errors: { ...state.errors, attendeeMeetingEnroll: action.payload },
            }
        case meetingActions.CLEAR_MEETINGS_FOR_COURSE:
            return {
                ...state,
                courseMeetingList: [],
                lessonMeetingList: [],
            }

        // slot booking
        case meetingActions.SLOT_BOOKING:
            return {
                ...state,
                loaders: { ...state.loaders, slotBooking: true },
                errors: { ...state.errors, slotBooking: '' },
            }
        case meetingActions.SLOT_BOOKING_SUCCESS:
            return {
                ...state,
                slotBooking: action.payload,
                loaders: { ...state.loaders, slotBooking: false },
                errors: { ...state.errors, slotBooking: '' },
            }
        case meetingActions.SLOT_BOOKING_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, slotBooking: false },
                errors: { ...state.errors, slotBooking: action.payload },
            }
        default:
            return state;
    }
}


const updateMeetingListWithStatus = (meetingList, payload) => {
    const list = [...meetingList];
    const findMeetingIndex = list?.findIndex(meeting => meeting?.id === payload?.id);
    if (findMeetingIndex > -1) {
        const meeting = list?.find(meeting => meeting.id === payload.id);
        list[findMeetingIndex] = { ...meeting, status: MeetingEnrollStatus.ENROLLED };
    }
    return list;
}