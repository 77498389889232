import { IInstituteAdminState } from '@redux/instituteAdmin/interface';
import { instituteAdminActions } from '@redux/instituteAdmin/action';

export const initialState = {
    instituteId: '',
    instituteData: {},
    courseIds: [],
    coursesData: [],
    openCoursesData: [],
    instituteAdminDashboardData: [],
    instituteAdminDashboardOpenCourseData: [],
    facultyIds: [],
    facultyData: [],
    lastRefreshedDate: '',
    loaders: {
        instituteId: false,
        instituteData: false,
        courseIds: false,
        coursesData: false,
        openCoursesData: false,
        instituteAdminDashboardData: false,
        instituteAdminDashboardOpenCourseData: false,
        facultyIds: false,
        facultyData: false,
        lastRefreshedDate: false
    },
    errors: {
        instituteId: '',
        instituteData: '',
        courseIds: '',
        coursesData: '',
        openCoursesData: '',
        instituteAdminDashboardData: '',
        instituteAdminDashboardOpenCourseData: '',
        facultyIds: '',
        facultyData: '',
        lastRefreshedDate: ''
    },
};


export const instituteAdminReducer = (
    state: IInstituteAdminState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_ID:
            return {
                ...state,
                loaders: { ...state.loaders, instituteId: true },
                errors: { ...state.errors, instituteId: '' },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_ID_SUCCESS:
            return {
                ...state,
                instituteId: action.payload,
                loaders: { ...state.loaders, instituteId: false },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_ID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, instituteId: false },
                errors: { ...state.errors, instituteId: action.payload },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, instituteData: true },
                errors: { ...state.errors, instituteData: '' },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DATA_SUCCESS:
            return {
                ...state,
                instituteData: action.payload,
                loaders: { ...state.loaders, instituteData: false },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, instituteData: false },
                errors: { ...state.errors, instituteData: action.payload },
            };
        case instituteAdminActions.FETCH_COURSES_IDS:
            return {
                ...state,
                loaders: { ...state.loaders, courseIds: true },
                errors: { ...state.errors, courseIds: '' },
            };
        case instituteAdminActions.FETCH_COURSES_IDS_SUCCESS:
            return {
                ...state,
                courseIds: action.payload,
                loaders: { ...state.loaders, courseIds: false },
            };
        case instituteAdminActions.FETCH_COURSES_IDS_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, courseIds: false },
                errors: { ...state.errors, courseIds: action.payload },
            };
        case instituteAdminActions.FETCH_COURSES_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, coursesData: true },
                errors: { ...state.errors, coursesData: '' },
            };
        case instituteAdminActions.FETCH_COURSES_DATA_SUCCESS:
            return {
                ...state,
                coursesData: action.payload,
                loaders: { ...state.loaders, coursesData: false },
            };
        case instituteAdminActions.FETCH_COURSES_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, coursesData: false },
                errors: { ...state.errors, coursesData: action.payload },
            };   
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, instituteAdminDashboardData: true },
                errors: { ...state.errors, instituteAdminDashboardData: '' },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                instituteAdminDashboardData: action.payload,
                loaders: { ...state.loaders, instituteAdminDashboardData: false },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, instituteAdminDashboardData: false },
                errors: { ...state.errors, instituteAdminDashboardData: action.payload },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_OPEN_COURSE_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, instituteAdminDashboardOpenCourseData: true },
                errors: { ...state.errors, instituteAdminDashboardOpenCourseData: '' },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_OPEN_COURSE_DATA_SUCCESS:
            return {
                ...state,
                instituteAdminDashboardOpenCourseData: action.payload,
                loaders: { ...state.loaders, instituteAdminDashboardOpenCourseData: false },
            };
        case instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_OPEN_COURSE_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, instituteAdminDashboardOpenCourseData: false },
                errors: { ...state.errors, instituteAdminDashboardOpenCourseData: action.payload },
            };   
        case instituteAdminActions.FETCH_FACULTY_IDS:
            return {
                ...state,
                loaders: { ...state.loaders, facultyIds: true },
                errors: { ...state.errors, facultyIds: '' },
            };
        case instituteAdminActions.FETCH_FACULTY_IDS_SUCCESS:
            return {
                ...state,
                facultyIds: action.payload,
                loaders: { ...state.loaders, facultyIds: false },
            };
        case instituteAdminActions.FETCH_FACULTY_IDS_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, facultyIds: false },
                errors: { ...state.errors, facultyIds: action.payload },
            };
        case instituteAdminActions.FETCH_FACULTY_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, facultyData: true },
                errors: { ...state.errors, facultyData: '' },
            };
        case instituteAdminActions.FETCH_FACULTY_DATA_SUCCESS:
            return {
                ...state,
                facultyData: action.payload,
                loaders: { ...state.loaders, facultyData: false },
            };
        case instituteAdminActions.FETCH_FACULTY_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, facultyData: false },
                errors: { ...state.errors, facultyData: action.payload },
            }; 
            
        case instituteAdminActions.FETCH_LAST_REFRESHED_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, lastRefreshedDate: true },
                errors: { ...state.errors, lastRefreshedDate: '' },
            };
        case instituteAdminActions.FETCH_LAST_REFRESHED_DATA_SUCCESS:
            return {
                ...state,
                lastRefreshedDate: action.payload,
                loaders: { ...state.loaders, lastRefreshedDate: false },
            };
        case instituteAdminActions.FETCH_LAST_REFRESHED_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, lastRefreshedDate: false },
                errors: { ...state.errors, lastRefreshedDate: action.payload },
            };  
        case instituteAdminActions.FETCH_OPEN_COURSES_DATA:
            return {
                ...state,
                loaders: { ...state.loaders, openCoursesData: true },
                errors: { ...state.errors, openCoursesData: '' },
            };
        case instituteAdminActions.FETCH_OPEN_COURSES_DATA_SUCCESS:
            return {
                ...state,
                openCoursesData: action.payload,
                loaders: { ...state.loaders, openCoursesData: false },
            };
        case instituteAdminActions.FETCH_OPEN_COURSES_DATA_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, openCoursesData: false },
                errors: { ...state.errors, openCoursesData: action.payload },
            };       
        default:
            return state;
    }
};
