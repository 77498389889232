import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { getLoggedInUserId } from "@utils/UserUtils";

import { NavigationUrl, URLs } from "@navigator/NavigationUrl";

import AuthLayout from "@layout/AuthLayout";

interface IProps {
  component: React.ComponentType<any>;
  location: any;
  isAuthenticated: boolean;
  exact?: boolean;
  path: string;
  to?: string;
  errorInFetchAppData?: string;
}

export const mapStateToProps = (state: any) => {
  return {};
};

export const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export class RoutePublic extends React.Component<IProps, any> {
  private defaultPrivateRoute = NavigationUrl.generate(URLs.home); 

  private renderComponent = () => {
    return <AuthLayout {...this.props} />;
  };

  private renderRedirect = (routeProps: any) => {
    const {
      component: Component,
      isAuthenticated,
      to = this.defaultPrivateRoute, 
      ...rest
    } = routeProps;
    return (
      <Redirect
        to={{
          pathname: to,
          state: {
            redirect: to,
            isAuthenticated: true,
          },
        }}
      />
    );
  };

  render() {
    const {
      component: Component,
      isAuthenticated,
      errorInFetchAppData,
      to,
      path,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={isAuthenticated && getLoggedInUserId() ? this.renderRedirect : this.renderComponent}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutePublic);
