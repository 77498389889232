import React, { Component } from 'react';

import { Menu } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';

import { WF_Logo_Color } from '@assets/index';
import { i18n } from '@translations/i18n';

import { PrimaryButton, LinkButton } from '@components/Button';
import ContentWrapper from '@components/LayoutContainers/ContentWrapper';

import Navigator from "@navigator/index";
import { NavigationUrl, URLs } from "@navigator/NavigationUrl";
import { platform } from '@constants/config';

interface IProps {
  component: React.ComponentType;
  location: any;
}

interface IState {
  current: string;
  selectedKey: string;
}


export default class AuthLayout extends React.Component<IProps, IState> {

  private getInitialState = (): IState => {
    let menuKey = "";
    if (this.props.location) {
      menuKey = this.props.location.pathname.split('/').pop();
    }
    return {
      selectedKey: menuKey,
      current: menuKey
    };

  }
  state = this.getInitialState();
  private guestHeaderLogo = () => {
    return (
      <div className="guest-logo">
        <img className="witp-logo" alt="Logo color" src={WF_Logo_Color} onClick={() => Navigator.push(NavigationUrl.generate(URLs.guest))} />
      </div>
    )
  }

  private renderGuestMenu = () => {
    const onClick = (e) => {
      const key = e.key
      Navigator.push(NavigationUrl.generate(key));
      this.setState({ selectedKey: e.key })
    };
    return (
      <div className='guest-menu'>
        <Menu onClick={onClick} selectedKeys={[this.state.selectedKey]} mode="horizontal" >
          <Menu.Item key={'guest'}>{i18n.t('layout.labelsAndTitles.DASHBOARD_MENU')}</Menu.Item>
          <Menu.Item key={'library'}>{i18n.t('guestView.labelsAndTitles.OPEN_LIBRARY')}</Menu.Item>
        </Menu>
      </div>
    )
  }
  private goToLogin = () => {
    Navigator.push(NavigationUrl.generate(URLs.login));
  }

  private renderHeader = () => {
    return (
      <div className='guest-header-section'>
        {this.guestHeaderLogo()}
        {this.renderGuestMenu()}
        <LinkButton
          className="guest-started-btn"
          onClick={this.goToLogin}
          value={i18n.t('register.signInText')}
        />
      </div>
    );
  };

  private showHeader = (selectedKey) => {
    switch (selectedKey) {
      case 'guest':
        return true;
      case 'library':
        return true;
      default:
        return false;
    }
  }

  render() {
    return (
      <ContentWrapper className="layout_wrapper">
        <div className="app__layout__auth">
          {/* @ts-ignore */}
          {platform.WITP && this.showHeader(this.state.selectedKey) && <Header className="auth_layout_header">{this.renderHeader()}</Header>}
          <Content className="app__layout_auth__inner_content">
            {this.renderContent()}
          </Content>
        </div>
      </ContentWrapper>
    );
  }

  private renderContent = () => {
    const Component = this.props.component;
    return <Component />;
  };

}
