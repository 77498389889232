import { BatchModel, IInvitedStudent } from "@common/domain/models/Batch";
import { ProfileModel } from "@common/domain/models/Profile";
import { RMBatchModel } from "@common/domain/models/RMBatchModel";

import { BatchStatus } from "@constants/config";

import { userRoles } from "../constants";
import { batchActions, IBulkUploadResponse } from "./actions";
import { IBatchState, IJoinBatchMessage, IUserBatchPermissions } from "./interface";

export const initialState = {
  batches: [],
  batchDeliveryModels: [],
  byId: {},
  batchStatus: "",
  competenciesList: [],
  setCompetencyDate: "",
  batchCreate: null,
  joinBatchData: null,
  userBatches: [],
  activeBatches: [],
  activeBatchCount: 0,
  inactiveBatches: [],
  inactiveBatchCount: 0,
  studentFacultyBatch: [],
  allBatches: [],
  allBatchesCount: 0,
  rmAssignedBatches: [],
  rmAssignedBatchesById: {},
  rmAssignedBatchesCount: 0,
  rmAssignedBatchesList: [],
  allBatchesList: [],
  postFacultyAdd: {},
  fetchUserPermissionsByBatchId: {},
  invitedStudentsList: [],
  invitedStudentsCount: 0,
  bulkUploadUser:{} as IBulkUploadResponse,
  resendOTP: {},
  inviteSingleStudent: null,
  loaders: {
    fetchBatchesUserOwned: false,
    closeBatch: false,
    fetchBatchCompetencies: false,
    competencyDateUpate: false,
    fetchBatchDeliveryModels: false,
    createBatch: false,
    joinBatch: false,
    fetchUserBatch: false,
    fetchStudentFacultyBatch: false,
    fetchBatchesMembers: false,
    fetchBatchesDetails: false,
    allBatches: false,
    rmAssignedBatches: false,
    updateBatch: false,
    isFetchingBatchesMembersCount: false,
    fetchUserPermissionsByBatchId: false,
    addFaculty: false,
    removeFaculty: false,
    fetchBatchFaculties: false,
    fetchBatchStudents: false,
    fetchBatchRMs: false,
    invitedStudents: false,
    uploadUsers: false,
    resendOTP: false,
    deleteBatchStudent: false,
    inviteSingleStudent: false,
  },
  errors: {
    fetchBatchesUserOwned: "",
    closeBatch: "",
    fetchBatchCompetencies: "",
    competencyDateUpate: "",
    fetchBatchDeliveryModels: "",
    createBatch: "",
    joinBatch: "",
    fetchUserBatch: "",
    fetchStudentFacultyBatch: "",
    fetchBatchesMembers: "",
    fetchBatchesDetails: "",
    allBatches: "",
    rmAssignedBatches: "",
    updateBatch: "",
    isFetchingBatchesMembersCount: "",
    addFaculty: "",
    removeFaculty: "",
    fetchUserPermissionsByBatchId: "",
    invitedStudents: "",
    uploadUsers: "",
    resendOTP: "",
    deleteBatchStudent: "",
    inviteSingleStudent: "",
  },
};

export const batchReducer = (
  state: IBatchState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case batchActions.FETCH_BATCHES_USER_OWNED:
      return {
        ...state,
        loaders: { ...state.loaders, fetchBatchesUserOwned: true },
        errors: { ...state.errors, fetchBatchesUserOwned: "" },
      };
    case batchActions.FETCH_BATCHES_USER_OWNED_SUCCESS:
      const batches = action.payload.batches;
      updateBatchMap(batches, state.byId);
      const batchIds = batches.map((batch) => batch.id);
      return {
        ...state,
        batches: batchIds,
        ...(action.payload.status === BatchStatus.ACTIVE && {
          activeBatches: action.payload.batches,
          activeBatchCount: action.payload.total
        }),
        ...(action.payload.status === BatchStatus.INACTIVE && {
          inactiveBatches: action.payload.batches,
          inactiveBatchCount: action.payload.total
        }),
        loaders: { ...state.loaders, fetchBatchesUserOwned: false },
      };
    case batchActions.FETCH_BATCHES_USER_OWNED_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchBatchesUserOwned: false },
        errors: { ...state.errors, fetchBatchesUserOwned: action.payload },
      };
    case batchActions.CLOSE_BATCH:
      return {
        ...state,
        loaders: { ...state.loaders, closeBatch: true },
        errors: { ...state.errors, closeBatch: "" },
      };
    case batchActions.CLOSE_BATCH_SUCCESS:
      return {
        ...state,
        batchStatus: action.payload,
        loaders: { ...state.loaders, closeBatch: false },
      };
    case batchActions.CLOSE_BATCH_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, closeBatch: false },
        errors: { ...state.errors, closeBatch: action.payload },
      };

    case batchActions.FETCH_BATCH_COMPETENCIES:
      return {
        ...state,
        competenciesList: [],
        loaders: { ...state.loaders, fetchBatchCompetencies: true },
        errors: { ...state.errors, fetchBatchCompetencies: "" },
      };
    case batchActions.FETCH_BATCH_COMPETENCIES_SUCCESS:
      return {
        ...state,
        competenciesList: action.payload,
        loaders: { ...state.loaders, fetchBatchCompetencies: false },
      };
    case batchActions.FETCH_BATCH_COMPETENCIES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchBatchCompetencies: false },
        errors: { ...state.errors, fetchBatchCompetencies: action.payload },
      };

    case batchActions.ADD_COMPETENCY_DATE:
      return {
        ...state,
        setCompetencyDate: "",
        loaders: { ...state.loaders, competencyDateUpate: true },
        errors: { ...state.errors, competencyDateUpate: "" },
      };
    case batchActions.ADD_COMPETENCY_DATE_SUCCESS:
      return {
        ...state,
        setCompetencyDate: action.payload,
        loaders: { ...state.loaders, competencyDateUpate: false },
      };
    case batchActions.ADD_COMPETENCY_DATE_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, competencyDateUpate: false },
        errors: { ...state.errors, competencyDateUpate: action.payload },
      };
    case batchActions.FETCH_BATCH_DELIVERY_MODELS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchBatchDeliveryModels: true },
        errors: { ...state.errors, fetchBatchDeliveryModels: "" },
      };
    case batchActions.FETCH_BATCH_DELIVERY_MODELS_SUCCESS:
      return {
        ...state,
        batchDeliveryModels: action.payload,
        loaders: { ...state.loaders, fetchBatchDeliveryModels: false },
      };
    case batchActions.FETCH_BATCH_DELIVERY_MODELS_FAILURE:
      return {
        ...state,
        loaders: { ...state.loaders, fetchBatchDeliveryModels: false },
        errors: { ...state.errors, fetchBatchDeliveryModels: action.payload },
      };
    case batchActions.CREATE_BATCH:
      return {
        ...state,
        loaders: { ...state.loaders, createBatch: true },
        errors: { ...state.loaders, createBatch: "" },
      };
    case batchActions.CREATE_BATCH_SUCCESS:
      return {
        ...state,
        batchCreate: action.payload,
        loaders: { ...state.loaders, createBatch: false },
      };
    case batchActions.CREATE_BATCH_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, createBatch: false },
        errors: { ...state.errors, createBatch: action.payload },
      };

    case batchActions.FETCH_BATCHES_DETAILS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchBatchesDetails: true },
        errors: { ...state.errors, fetchBatchesDetails: "" },
      };
    case batchActions.FETCH_BATCHES_DETAILS_SUCCESS:
      updateBatchMap(action.payload, state.byId);
      return {
        ...state,
        loaders: { ...state.loaders, fetchBatchesDetails: false },
      };
    case batchActions.FETCH_BATCHES_DETAILS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchBatchesDetails: false },
        errors: { ...state.errors, fetchBatchesDetails: action.payload },
      };

    case batchActions.JOIN_BATCH:
      return {
        ...state,
        joinBatchData: null,
        loaders: { ...state.loaders, joinBatch: true },
        errors: { ...state.errors, joinBatch: "" },
      };
    case batchActions.JOIN_BATCH_SUCCESS:
      return {
        ...state,
        joinBatchData: action.payload,
        loaders: { ...state.loaders, joinBatch: false },
      };
    case batchActions.JOIN_BATCH_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, joinBatch: false },
        errors: { ...state.errors, joinBatch: action.payload },
      };

    case batchActions.FETCH_USER_BATCHES:
      return {
        ...state,
        loaders: { ...state.loaders, fetchUserBatch: true },
        errors: { ...state.loaders, fetchUserBatch: "" },
      };
    case batchActions.FETCH_USER_BATCHES_SUCCESS:
      return {
        ...state,
        userBatches: action.payload,
        loaders: { ...state.loaders, fetchUserBatch: false },
      };
    case batchActions.FETCH_USER_BATCHES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchUserBatch: false },
        errors: { ...state.errors, fetchUserBatch: action.payload },
      };
    case batchActions.FETCH_STUDENT_FACULTY_BATCHES:
      return {
        ...state,
        loaders: { ...state.loaders, fetchStudentFacultyBatch: true },
        errors: { ...state.errors, fetchStudentFacultyBatch: "" },
      };
    case batchActions.FETCH_STUDENT_FACULTY_BATCHES_SUCCESS:
      return {
        ...state,
        studentFacultyBatch: action.payload,
        loaders: { ...state.loaders, fetchStudentFacultyBatch: false },
      };
    case batchActions.FETCH_STUDENT_FACULTY_BATCHES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchStudentFacultyBatch: false },
        errors: { ...state.errors, fetchStudentFacultyBatch: action.payload },
      };
    case batchActions.FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE:
      return {
        ...state,
        loaders: {
          ...state.loaders, fetchBatchesMembers: true,
          ...(action.payload.role === userRoles.faculty && { fetchBatchFaculties: true }),
          ...(action.payload.role === userRoles.student && { fetchBatchStudents: true }),
          ...(action.payload.role === userRoles.regionalManager && { fetchBatchRMs: true }),
        },
        errors: { ...state.errors, fetchBatchesMembers: "" }
      };
    case batchActions.FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE_SUCCESS:
      const { batchId, role, batchMembers } = action.payload;
      updateBatchesMembersMap(batchId, role, batchMembers, state.byId)
      return {
        ...state,
        loaders: {
          ...state.loaders,
          fetchBatchesMembers: false,
          ...(action.payload.role === userRoles.faculty && { fetchBatchFaculties: false }),
          ...(action.payload.role === userRoles.student && { fetchBatchStudents: false }),
          ...(action.payload.role === userRoles.regionalManager && { fetchBatchRMs: false })
        }
      };
    case batchActions.FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchBatchesMembers: false },
        errors: { ...state.errors, fetchBatchesMembers: action.payload }
      }

    // All Batches
    case batchActions.FETCH_ALL_BATCHES:
      return {
        ...state,
        loaders: { ...state.loaders, allBatches: true },
        errors: { ...state.errors, allBatches: "" },
      }
    case batchActions.FETCH_ALL_BATCHES_SUCCESS:
      const allBatches = action.payload.batches;
      updateBatchMap(allBatches, state.byId);
      const allBatchIds = allBatches.map((batch: BatchModel) => batch.getBatchId());
      return {
        ...state,
        allBatchesList: allBatchIds,
        allBatchesCount: action.payload.total,
        loaders: { ...state.loaders, allBatches: false },
      }
    case batchActions.FETCH_ALL_BATCHES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, allBatches: false },
        errors: { ...state.errors, allBatches: action.payload },
      }

    case batchActions.RM_FETCH_ASSIGNED_BATCHES:
      return {
        ...state,
        loaders: { ...state.loaders, rmAssignedBatches: true },
        errors: { ...state.errors, rmAssignedBatches: "" },
      }
    case batchActions.RM_FETCH_ASSIGNED_BATCHES_SUCCESS:
      const rmAssignedBatches = action.payload.batches;
      updateRMBatchMap(rmAssignedBatches, state.rmAssignedBatchesById);
      const rmBatchIds = rmAssignedBatches.map((batch: RMBatchModel) => batch.getBatchId());
      return {
        ...state,
        rmAssignedBatchesList: rmBatchIds,
        rmAssignedBatches: action.payload.batches,
        rmAssignedBatchesCount: action.payload.total,
        loaders: { ...state.loaders, rmAssignedBatches: false },
      }
    case batchActions.RM_FETCH_ASSIGNED_BATCHES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, rmAssignedBatches: false },
        errors: { ...state.errors, rmAssignedBatches: action.payload },
      }
    case batchActions.RM_FETCH_ASSIGNED_BATCHES_ID_LIST_SUCCESS:
      const rmBatchIdsList = action.payload;
      return {
        ...state,
        rmAssignedBatchesList: rmBatchIdsList,
      }
    // update batch
    case batchActions.UPDATE_BATCH:
      return {
        ...state,
        loaders: { ...state.loaders, updateBatch: true },
        errors: { ...state.errors, updateBatch: "" },
      };
    case batchActions.UPDATE_BATCH_SUCCESS:
      updateBatchMap([action.payload], state.byId);
      return {
        ...state,
        loaders: { ...state.loaders, updateBatch: false },
      };
    case batchActions.UPDATE_BATCH_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, updateBatch: false },
        errors: { ...state.errors, updateBatch: action.payload },
      };
    case batchActions.ADD_FACULTY:
      return {
        ...state,
        loaders: { ...state.loaders, addFaculty: true },
        errors: { ...state.errors, addFaculty: "" },
      }
    case batchActions.ADD_FACULTY_SUCCESS:
      return {
        ...state,
        postFacultyAdd: action.payload,
        loaders: { ...state.loaders, addFaculty: false },
      }
    case batchActions.ADD_FACULTY_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, addFaculty: false },
        errors: { ...state.errors, addFaculty: action.payload },
      }
    case batchActions.REMOVE_BATCH_FACULTY:
      return {
        ...state,
        loaders: { ...state.loaders, removeFaculty: true },
        errors: { ...state.errors, removeFaculty: "" },
      }
    case batchActions.REMOVE_BATCH_FACULTY_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, removeFaculty: false },
      }
    case batchActions.REMOVE_BATCH_FACULTY_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, removeFaculty: false },
        errors: { ...state.errors, removeFaculty: action.payload },
      }

    // fetch user batch permissions
    case batchActions.FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID:
      return {
        ...state,
        loaders: { ...state.loaders, fetchUserPermissionsByBatchId: true },
        errors: { ...state.errors, fetchUserPermissionsByBatchId: "" },
      }
    case batchActions.FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID_SUCCESS:
      const permissions = action.payload.batchPermissions;
      const id = action.payload.batchId;
      userBatchPermissionMap(permissions, id, state.fetchUserPermissionsByBatchId);
      return {
        ...state,
        loaders: { ...state.loaders, fetchUserPermissionsByBatchId: false },
      }
    case batchActions.SET_USER_BATCH_PERMISSIONS_BY_BATCH_ID:
      const batchPermission = action.payload.batchPermissions;
      userBatchPermissionMap(batchPermission, action.payload.batchId, state.fetchUserPermissionsByBatchId);
      return {
        ...state,
        loaders: { ...state.loaders, fetchUserPermissionsByBatchId: false },
      }
    case batchActions.FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchUserPermissionsByBatchId: false },
        errors: { ...state.errors, fetchUserPermissionsByBatchId: action.payload },
      }
    case batchActions.FETCH_BATCH_MEMBERS_COUNT:
      return {
        ...state,
        loaders: { ...state.loaders, isFetchingBatchesMembersCount: true },
        errors: { ...state.errors, isFetchingBatchesMembersCount: "" }
      };
    case batchActions.FETCH_BATCH_MEMBERS_COUNT_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, isFetchingBatchesMembersCount: false },
      };
    case batchActions.FETCH_BATCH_MEMBERS_COUNT_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, isFetchingBatchesMembersCount: false },
        errors: { ...state.errors, isFetchingBatchesMembersCount: action.payload }
      }
    case batchActions.FETCH_INVITED_STUDENTS:
      return {
        ...state,
        loaders: {...state.loaders, invitedStudents: true}
      }
    case batchActions.FETCH_INVITED_STUDENTS_SUCCESS:
      const invitedStudents = action.payload.students.users.map((user) => {
        return {
        key: user.userEmail,
        batchKey: user.batchKey,
        userEmail: user.userEmail,
        status: user.status,
        }
      })
      return {
        ...state,
        invitedStudentsList: action.payload.pageNumber === 1 ? invitedStudents : [...state.invitedStudentsList, ...invitedStudents],
        invitedStudentsCount: action.payload.students.total,
        loaders: {...state.loaders, invitedStudents: false}
      }
    case batchActions.FETCH_INVITED_STUDENTS_FAIL:
      return {
        ...state,
        loaders: {...state.loaders, invitedStudents: false},
        errors: {...state.errors, invitedStudents: action.payload}
      }
    case batchActions.RESEND_OPT:
      return {
        ...state,
        loaders: {...state.loaders, resendOTP: true}
      }
    case batchActions.RESEND_OPT_SUCCESS:
      return {
        ...state,
        resendOTP: action.payload,
        loaders: {...state.loaders, resendOTP: false}
      }
    case batchActions.RESEND_OPT_FAIL:
      return {
        ...state,
        loaders: {...state.loaders, resendOTP: false},
        errors: {...state.errors, resendOTP: action.payload}
      }
    case batchActions.UPLOAD_USERS:
      return {
        ...state,
        loaders: {...state.loaders, uploadUsers: true}
      }
    case batchActions.UPLOAD_USERS_SUCCESS:
      return {
        ...state,
        bulkUploadUser: action.payload.bulkUploadHistory,
        loaders: {...state.loaders, uploadUsers: false},
        errors: { ...state.errors, uploadUsers: ""}
      }
    case batchActions.UPLOAD_USERS_FAIL:
      return {
        ...state,
        loaders: {...state.loaders, uploadUsers: false},
        errors: { ...state.errors, uploadUsers: action.payload}
      }
    // delete batch student
    case batchActions.DELETE_BATCH_STUDENT:
      return {
        ...state,
        loaders: { ...state.loaders, deleteBatchStudent: true },
        errors: { ...state.errors, deleteBatchStudent: "" },
      }
    case batchActions.DELETE_BATCH_STUDENT_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, deleteBatchStudent: false },
        errors: { ...state.errors, deleteBatchStudent: "" },
      }
    case batchActions.DELETE_BATCH_STUDENT_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, deleteBatchStudent: false },
        errors: { ...state.errors, deleteBatchStudent: action.payload },
      }
    case batchActions.INVITE_SINGLE_STUDENT:
      return {
        ...state,
        loaders: {...state.loaders, inviteSingleStudent: true}
      }
    case batchActions.INVITE_SINGLE_STUDENT_SUCCESS:
      return {
        ...state,
        inviteSingleStudent: action.payload,
        loaders: {...state.loaders, inviteSingleStudent: false},
        errors: { ...state.errors, inviteSingleStudent: ""}
      }
    case batchActions.INVITE_SINGLE_STUDENT_FAIL:
      return {
        ...state,
        loaders: {...state.loaders, inviteSingleStudent: false},
        errors: { ...state.errors, inviteSingleStudent: action.payload}
      }
    case batchActions.GET_STATUS_OF_BULK_UPLOADING_BYID:
      return {
        ...state,
        loaders: { ...state.loaders, uploadUsers: true }
      }
    case batchActions.GET_STATUS_OF_BULK_UPLOADING_BYID_SUCCESS:
      return {
        ...state,
        bulkUploadUser: action.payload.bulkUploadHistory,
        loaders: { ...state.loaders, uploadUsers: false },
        errors: { ...state.errors, uploadUsers: "" }
      }
    case batchActions.GET_STATUS_OF_BULK_UPLOADING_BYID_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, uploadUsers: false },
        errors: { ...state.errors, uploadUsers: action.payload }
      }
    default:
      return state;
  }
};

export const updateRMBatchMap = (rmAssignedBatches: RMBatchModel[], existingMap: any) => {
  rmAssignedBatches.forEach((batch: RMBatchModel) => {
    existingMap[batch.getId()] = batch;
  });
};

export const updateBatchMap = (
  newBatches: BatchModel[],
  existingMap: any
) => {
  newBatches.forEach((batch: BatchModel | any) => {

    try {

      const existingBatch = existingMap[batch.getId()];
      if (existingBatch) {

        try {
          if (existingBatch.getBatchMembers()?.length > 0) {
            batch.setBatchMembers(existingBatch.getBatchMembers(userRoles.student), userRoles.student);
            batch.setBatchMembers(existingBatch.getBatchMembers(userRoles.faculty), userRoles.faculty);
          }
          if (existingBatch.getBatchMembersCount() > 0) {
            batch.setBatchMembersCount(existingBatch.getBatchMembersCount(userRoles.student), userRoles.student);
            batch.setBatchMembersCount(existingBatch.getBatchMembersCount(userRoles.faculty), userRoles.faculty);
          }
          existingMap[batch.getId()] = batch;

          return;
        } catch (e) { }

      }

      if (batch instanceof BatchModel)
        existingMap[batch.getId()] = batch;
      else
        existingMap[batch.id] = batch;
    } catch (error) {
    }
  });
};

export const userBatchPermissionMap = (permissions: IUserBatchPermissions, id: number, existingMap: any) => {
  existingMap[id] = permissions;
}

export const updateBatchesMembersMap = (batchId, role, newBatchesMembers, existingMap) => {
  const batch: BatchModel = existingMap[batchId];
  if (batch && batch instanceof BatchModel) {
    batch.setBatchMembers(newBatchesMembers, role)
  }
}

