import { CommentEntitiesKeys, CommentModel, IComment } from "@common/domain/models/Comment";

const actionTypePrefix = "COMMENTS/";

export const commentsActions = {
  FETCH_COMMENTS: `${actionTypePrefix}FETCH_COMMENTS`,
  FETCH_COMMENTS_SUCCESS: `${actionTypePrefix}FETCH_COMMENTS_SUCCESS`,
  FETCH_COMMENTS_FAIL: `${actionTypePrefix}FETCH_COMMENTS_FAIL`,

  CREATE_COMMENT: `${actionTypePrefix}CREATE_COMMENT`,
  CREATE_COMMENT_SUCCESS: `${actionTypePrefix}CREATE_COMMENT_SUCCESS`,
  CREATE_COMMENT_FAIL: `${actionTypePrefix}CREATE_COMMENT_FAIL`,

  DELETE_COMMENTS: `${actionTypePrefix}DELETE_COMMENTS`,
  DELETE_COMMENTS_SUCCESS: `${actionTypePrefix}DELETE_COMMENTS_SUCCESS`,
  DELETE_COMMENTS_FAIL: `${actionTypePrefix}DELETE_COMMENTS_FAIL`,
};


export interface IFetchCommentsActionPayload {
  entityKey?: CommentEntitiesKeys;
  entityValue?: string;
  creatorId?: string;
  commentId?: string;
  page?: number;
  limit?: number;
}
export interface IFetchCommentsAction {
  type: string;
  payload: IFetchCommentsActionPayload;
}

export const fetchComments = (paylaod: IFetchCommentsActionPayload): IFetchCommentsAction => {
  return {
    type: commentsActions.FETCH_COMMENTS,
    payload: {
      ...paylaod
    }
  }
};

export interface IFetchCommentsSuccessAction {
  type: string;
  payload: {
    comments: CommentModel[],
    total: number,
    page?: number,
  }
}

export const fetchCommentsSuccess = (comments: CommentModel[], total: number, page?: number): IFetchCommentsSuccessAction => {
  return {
    type: commentsActions.FETCH_COMMENTS_SUCCESS,
    payload: {
      comments,
      total,
      page
    }
  };
};

export const fetchCommentsFailure = (error: string) => {
  return {
    type: commentsActions.FETCH_COMMENTS_FAIL,
    payload: error,
  };
};

export interface ICreateCommentsAction {
  type: string;
  payload: {
    comment: IComment;
    entity: CommentEntitiesKeys;
    entityId: string;
  };
}

export const createComment = (comment: IComment, entity: CommentEntitiesKeys, entityId: string): ICreateCommentsAction => {
  return {
    type: commentsActions.CREATE_COMMENT,
    payload: {
      comment,
      entity,
      entityId
    }
  };
};

export interface ICreateCommentsSuccessAction {
  type: string;
  payload: CommentModel;
}

export const createCommentSuccess = (comment: CommentModel): ICreateCommentsSuccessAction => {
  return {
    type: commentsActions.CREATE_COMMENT_SUCCESS,
    payload: comment
  };
};

export const createCommentFailure = (error: string) => {
  return {
    type: commentsActions.CREATE_COMMENT_FAIL,
    payload: error,
  };
};

export interface IDeleteCommentsAction {
  type: string;
  payload: {
    commentId: string;
    entity: CommentEntitiesKeys;
    entityId: string;
  }
}

export const deleteComment = (entity: CommentEntitiesKeys, entityId: string, commentId: string): IDeleteCommentsAction => {
  return {
    type: commentsActions.DELETE_COMMENTS,
    payload: {
      entity,
      commentId,
      entityId
    }
  };
};

export interface IDeleteCommentsSuccessAction {
  type: string;
  payload: {
    commentId: string;
  }
}

export const deleteCommentSuccess = (commentId: string): IDeleteCommentsSuccessAction => {
  return {
    type: commentsActions.DELETE_COMMENTS_SUCCESS,
    payload: {
      commentId
    }
  };
};

export const deleteCommentFailure = (error: string) => {
  return {
    type: commentsActions.DELETE_COMMENTS_FAIL,
    payload: error,
  };
};
