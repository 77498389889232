import React from "react";
import Spinner from "@components/Spinner/index";

interface IProps {
    text: string;
    icon?: any;
    className?: any;
    onClick?: (e?: any) => void;
    loader?: boolean;
}

export default function (props: IProps) {
    const { text, icon, className, onClick, loader } = props;
    const style = ['wf_bordered_button', className].join(' ');
    return (
            <div className={style} onClick={!!onClick && onClick}>
                {
                    <>
                        {loader && <Spinner />}  {icon && <img alt="icon for outline button" src={icon} />} <span className="text">{text}</span>
                    </>
                }
            </div>
    );
}
