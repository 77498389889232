import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { ZoomRepository } from '@common/domain/repositories/ZoomRepository';
import { IUpdateSettingPayload } from '@common/domain/models/SettingsConfig';

import { CLIENT_NAME } from '@constants/config';

import { updateUserSettings } from '../settings/actions';
import {getZoomMeetingDetailsFailure,
    getZoomMeetingDetailsSuccess, IGetZoomMeetingDetailsAction,
    IZoomAccessTokenAction,
    zoomAccessTokenFailure,
    zoomAccessTokenSuccess,
    ZoomActions} from './actions';

    export function* zoomAccessTokenSaga(action: IZoomAccessTokenAction): any {
        const { userId, code, updateSettingData, isCreateSettingData } = action.payload;
        const updateSettingPayload: IUpdateSettingPayload = {
            isCreateSettingData,
            settingsData: updateSettingData
        }
        try {
            const result: any = yield call(ZoomRepository.zoomAccessToken, userId, code, CLIENT_NAME);
            yield put(updateUserSettings(updateSettingPayload));
            yield put(zoomAccessTokenSuccess(result));
        } catch (e) {
            yield put(zoomAccessTokenFailure(e.message));
        }
    }

export function* getZoomMeetingDetailsSaga(action:IGetZoomMeetingDetailsAction):any {
    const { meetingId } = action.payload;
    try {
        const result:any = yield call(ZoomRepository.getZoomDetails, meetingId);
        yield put(getZoomMeetingDetailsSuccess(result));
    } catch (e) {
        yield put(getZoomMeetingDetailsFailure(e.message));
    }
}

export function* watchZoomSaga() :Generator<ForkEffect> {
    yield takeLatest(ZoomActions.ZOOM_ACCESS_TOKEN, zoomAccessTokenSaga);
    yield takeLatest(ZoomActions.GET_ZOOM_MEETING_DETAILS, getZoomMeetingDetailsSaga);
}
