import { connect } from 'react-redux';

import { getNotifications, setAllNotificationSeen } from '@common/redux/notifications/actions';
import { getNotificationList, getNotificationsCount, getTotalNotificationCount } from '@common/redux/notifications/selectors';

import Notifications from '@presentation/Notifications';

const mapStateToProps = (state:any) => ({
    notificationCount: getNotificationsCount(state),
    listOfNotification: getNotificationList(state),
    totalNotificationCount: getTotalNotificationCount(state)
});

const mapDispatchToProps = (dispatch:any) => ({
    getNotifications: (userId: string, page: number, limit: number) => dispatch(getNotifications(userId, page, limit)),
    setAllNotificationSeen: (userId:string) => dispatch(setAllNotificationSeen(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);