import { IState } from "@store/webInterfaces";

export const getSettingsConfigSelector = (state: IState): {} => {
    return state?.settings?.settingsConfig || {};
};

export const getSettingsConfigLoaderSelector = (state: IState): boolean => {
    return state?.settings?.loaders?.settingsConfig || false;
};

export const getSettingsConfigErrorSelector = (state: IState): string => {
    return state?.settings?.errors?.userSettings || "";
};

// fetch users
export const getSettingsSelector = (state: IState, userId?: string): any => {
    return state?.settings?.userSettings || {}
};

export const getSettingsLoaderSelector = (state: IState): boolean => {
    return state.settings.loaders.userSettings || false;
};

export const getSettingsErrorSelector = (state: IState): string => {
    return state?.settings?.errors?.userSettings || "";
};

// Register User
export const createProfileSelector = (state: any) => {
    return state?.profile?.createUser || {};
};

export const createProfileErrorSelector = (state: any): string => {
    return state.profile.errors.createUser || "";
};

export const createProfileLoaderSelector = (state: any): boolean => {
    return state.profile.loaders.createUser || false;
};


// update settings
export const updateSettingsErrorSelector = (state: IState): string => {

    return state.settings.errors.updateSettings || "";
}

export const updateSettingsLoaderSelector = (state: IState): boolean => {

    return state.settings.loaders.updateSettings || false;
};

export const getIsFromMeetingModal = (state: IState): boolean => {
    return state?.settings?.isFromMeetingModal || false;
}
