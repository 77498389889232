import { JsonProperty } from 'json-object-mapper';

export class BatchCompetenciesModal {

    @JsonProperty({ name: 'id', required: true })
    private id: number = 0;

    @JsonProperty({ name: 'batchId', required: true })
    private batchId: number = 0;

    @JsonProperty({ name: 'competencyId', required: true })
    private competencyId: number = 0;

    @JsonProperty({ name: 'competencyName', required: true })
    private competencyName: string = "";

    @JsonProperty({ name: 'startDate', required: false })
    private startDate: string = null;

    @JsonProperty({ name: 'endDate', required: false })
    private endDate: string = null;

    @JsonProperty({ name: 'createdAt', required: false })
    private createdAt: string = "";

    @JsonProperty({ name: 'updatedAt', required: false })
    private updatedAt: string = "";

    @JsonProperty({ name: 'deletedAt', required: false })
    private deletedAt?: string = "";

    public getId(): number {
        return this.id;
    }

    public getBatchId(): number {
        return this.batchId;
    }

    public getCompetencyId(): number {
        return this.competencyId;
    }

    public getCompetencyName(): string {
        return this.competencyName;
    }

    public getStartDate(): string {
        return this.startDate;
    }

    public setStartDate(startDate: string): void {
        this.startDate = startDate;
    }

    public getEndDate(): string {
        return this.endDate;
    }

    public setEndDate(endDate: string): void {
        this.endDate = endDate;
    }

    public getCreatedAt(): string {
        return this.createdAt;
    }

    public getUpdatedAt(): string {
        return this.updatedAt;
    }

    public getDeletedAt(): string {
        return this.deletedAt;
    }
}
