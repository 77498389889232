import { facultyReportActions } from "./actions";

export interface IReportState {
  report: any[];
  quizReport: any[];
  byId: {};
  studentsReport: any; //TODO: Create model for students
  engagementReport: any[];
  batchReports: any[];
  loaders: {
    fetchReportData: boolean;
    fetchQuizData: boolean;
    fetchStudentsList: boolean;
    fetchEngagementReport: boolean;
    fetchMultipleBatchReports: boolean;
  };
  errors: {
    fetchReportData: string;
    fetchQuizData: string;
    fetchStudentsList: string;
    fetchEngagementReport: string;
    fetchMultipleBatchReports: string;
  };
}

export const initialState = {
  report: [],
  quizReport: [],
  byId: {},
  studentsReport: {},
  engagementReport: [],
  batchReports: [],
  loaders: {
    fetchReportData: false,
    fetchQuizData: false,
    fetchStudentsList: false,
    fetchEngagementReport: false,
    fetchMultipleBatchReports: false,
  },
  errors: {
    fetchReportData: "",
    fetchQuizData: "",
    fetchStudentsList: "",
    fetchEngagementReport: "",
    fetchMultipleBatchReports: "",
  },
};

export const facultyReportReducer = (
  state: IReportState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case facultyReportActions.FETCH_REPORTS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchReportData: true },
        errors: { ...state.errors, fetchReportData: "" },
      };
    case facultyReportActions.FETCH_REPORTS_SUCCESS:
      const batchData: any[] = action.payload;
      updateReportMap(batchData, state.byId);
      const reportIds = batchData.map((report) => report.batchName);
      return {
        ...state,
        report: reportIds,
        loaders: { ...state.loaders, fetchReportData: false },
      };
    case facultyReportActions.FETCH_REPORTS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchReportData: false },
        errors: { ...state.errors, fetchReportData: action.payload },
      };

    case facultyReportActions.FETCH_QUIZ_REPORTS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchQuizData: true },
        errors: { ...state.errors, fetchQuizData: "" },
      };
    case facultyReportActions.FETCH_QUIZ_REPORTS_SUCCESS:
      return {
        ...state,
        quizReport: action.payload,
        loaders: { ...state.loaders, fetchQuizData: false },
      };
    case facultyReportActions.FETCH_QUIZ_REPORTS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchQuizData: false },
        errors: { ...state.errors, fetchQuizData: action.payload },
      };

    case facultyReportActions.FETCH_STUDENTS_LIST:
      return {
        ...state,
        loaders: { ...state.loaders, fetchStudentsList: true },
        errors: { ...state.errors, fetchStudentsList: "" },
      };
    case facultyReportActions.FETCH_STUDENTS_LIST_SUCCESS:
      return {
        ...state,
        studentsReport: action.payload,
        loaders: { ...state.loaders, fetchStudentsList: false },
      };
    case facultyReportActions.FETCH_STUDENTS_LIST_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchStudentsList: false },
        errors: { ...state.errors, fetchStudentsList: action.payload },
      };

    case facultyReportActions.FETCH_ENGAGEMENT_REPORTS:
      return {
        ...state,
        engagementReport: [],
        loaders: { ...state.loaders, fetchEngagementReport: true },
        errors: { ...state.errors, fetchEngagementReport: "" },
      };
    case facultyReportActions.FETCH_ENGAGEMENT_REPORTS_SUCCESS:
      return {
        ...state,
        engagementReport: action.payload,
        loaders: { ...state.loaders, fetchEngagementReport: false },
      };
    case facultyReportActions.FETCH_ENGAGEMENT_REPORTS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchEngagementReport: false },
        errors: { ...state.errors, fetchEngagementReport: action.payload },
      };
    case facultyReportActions.FETCH_MULTIPLE_BATCHES_REPORT:
      return {
        ...state,
        loaders: { ...state.loaders, fetchMultipleBatchReports: true },
        errors: { ...state.errors, fetchMultipleBatchReports: "" },
      };
    case facultyReportActions.FETCH_MULTIPLE_BATCHES_REPORT_SUCCESS:
      
      return {
        ...state,
        batchReports: action.payload,
        loaders: { ...state.loaders, fetchMultipleBatchReports: false },
      };
    case facultyReportActions.FETCH_MULTIPLE_BATCHES_REPORT_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchMultipleBatchReports: false },
        errors: { ...state.errors, fetchMultipleBatchReports: action.payload },
      };
    default:
      return state;
  }
};

export const updateReportMap = (newReports: any[], existingMap: any) => {
  newReports.forEach((report) => {
    existingMap[report.batchName] = report;
  });
};
