import { EventModel } from "@common/domain/models/Event";

import { IFluxStandardAction } from "@store/webInterfaces";

const actionTypePrefix = "EVENT";

export const eventActions = {
    FETCH_EVENT_DETAIL: `${actionTypePrefix}/FETCH_EVENT_DETAIL`,
    FETCH_EVENT_DETAIL_SUCCESS: `${actionTypePrefix}/FETCH_EVENT_DETAIL_SUCCESS`,
    FETCH_EVENT_DETAIL_FAIL: `${actionTypePrefix}/FETCH_EVENT_DETAIL_FAIL`,

    FETCH_RECOMMENDED_EVENT: `${actionTypePrefix}/FETCH_RECOMMENDED_EVENT`,
    FETCH_RECOMMENDED_EVENT_SUCCESS: `${actionTypePrefix}/FETCH_RECOMMENDED_EVENT_SUCCESS`,
    FETCH_RECOMMENDED_EVENT_FAIL: `${actionTypePrefix}/FETCH_RECOMMENDED_EVENT_FAIL`,

    FETCH_UPCOMING_USER_EVENTS: `${actionTypePrefix}/FETCH_UPCOMING_USER_EVENTS`,
    FETCH_UPCOMING_USER_EVENTS_SUCCESS: `${actionTypePrefix}/FETCH_UPCOMING_USER_EVENTS_SUCCESS`,
    FETCH_UPCOMING_USER_EVENTS_FAIL: `${actionTypePrefix}/FETCH_UPCOMING_USER_EVENTS_FAIL`,

    REGISTER_EVENT: `${actionTypePrefix}/REGISTER_EVENT`,
    REGISTER_EVENT_SUCCESS: `${actionTypePrefix}/REGISTER_EVENT_SUCCESS`,
    REGISTER_EVENT_FAIL: `${actionTypePrefix}/REGISTER_EVENT_FAIL`,

    EVENT_PARTICIPANT_LIST: `${actionTypePrefix}/EVENT_PARTICIPANT_LIST`,
    EVENT_PARTICIPANT_LIST_SUCCESS: `${actionTypePrefix}/EVENT_PARTICIPANT_LIST_SUCCESS`,
    EVENT_PARTICIPANT_LIST_FAIL: `${actionTypePrefix}/EVENT_PARTICIPANT_LIST_FAIL`,
}

// event detail
export const fetchEventDetail = (id: string): IFluxStandardAction<string> => {
    return {
        type: eventActions.FETCH_EVENT_DETAIL,
        payload: id
    }
}

export const fetchEventDetailSuccess = (payload: EventModel): IFluxStandardAction<EventModel> => {
    return {
        type: eventActions.FETCH_EVENT_DETAIL_SUCCESS,
        payload
    }
}

export const fetchEventDetailFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: eventActions.FETCH_EVENT_DETAIL_FAIL,
        payload: error
    }
}

// recommended events
export const fetchRecommendedEvents = (payload): IFluxStandardAction<string> => {
    return {
        type: eventActions.FETCH_RECOMMENDED_EVENT,
        payload
    }
}

export const fetchRecommendedEventsSuccess = (payload: EventModel[]): IFluxStandardAction<EventModel[]> => {
    return {
        type: eventActions.FETCH_RECOMMENDED_EVENT_SUCCESS,
        payload
    }
}

export const fetchRecommendedEventsFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: eventActions.FETCH_RECOMMENDED_EVENT_FAIL,
        payload: error
    }
}

// upcoming events
export const fetchUpcomingUserEvents = (payload): IFluxStandardAction<string> => {
    return {
        type: eventActions.FETCH_UPCOMING_USER_EVENTS,
        payload
    }
}

export const fetchUpcomingUserEventsSuccess = (payload): IFluxStandardAction<EventModel[]> => {
    return {
        type: eventActions.FETCH_UPCOMING_USER_EVENTS_SUCCESS,
        payload
    }
}

export const fetchUpcomingUserEventsFail = (error: string): IFluxStandardAction<string> => {
    return {
        type: eventActions.FETCH_UPCOMING_USER_EVENTS_FAIL,
        payload: error
    }
}

// register event
export const registerEvent = (payload): IFluxStandardAction<string> => {
    return {
        type: eventActions.REGISTER_EVENT,
        payload
    }
}

export const registerEventSuccess = (payload): IFluxStandardAction<string> => {
    return {
        type: eventActions.REGISTER_EVENT_SUCCESS,
        payload
    }
}

export const registerEventFail = (error): IFluxStandardAction<string> => {
    return {
        type: eventActions.REGISTER_EVENT_FAIL,
        payload: error
    }
}

// participant list
export const eventParticipantList = (payload): IFluxStandardAction<string> => {
    return {
        type: eventActions.EVENT_PARTICIPANT_LIST,
        payload
    }
}

export const eventParticipantListSuccess = (payload): IFluxStandardAction<string> => {
    return {
        type: eventActions.EVENT_PARTICIPANT_LIST_SUCCESS,
        payload
    }
}

export const eventParticipantListFail = (error): IFluxStandardAction<string> => {
    return {
        type: eventActions.EVENT_PARTICIPANT_LIST_FAIL,
        payload: error
    }
}