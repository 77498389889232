import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import './index.less';

interface IProps extends InputProps{
  className?: string;
}

export default function(props: IProps) {

  const { className, ...rest } = props;
  const style = ["normal_input", className].join(' ');
  return <Input {...rest} className={style} />;
};

