export const GENERAL_MENTOR_TAG = ["Broad Career Guidance", "Interview Basics"]
export const INDUSTRY_MENTOR_TAG = ["Sector Specific Insights", "Market Trends Info"]
export const JOB_ROLE_MENTOR_TAG = ["Job Role Focused", "Discover Progression Path"]

export const JOB_ROLE_MENTOR_DES = ["Choose a Job Role Mentor if you already know the specific job role you want.",
  "If no Job Role Mentor is available for your role, consider an Industry Mentor or General Mentor."]

export const GENERAL_MENTOR_DES = ["If you’re unsure about which sector or job role to pursue.",
  "No Industry Mentor available for your sector."]

export const INDUSTRY_MENTOR_DES = ["Choose an Industry Mentor if you know the sector you're interested in (like Manufacturing or Healthcare).",
  "No Job Role Mentor available for your sector."];

export const MENTOR_TYPE = {
  GENERAL_MENTOR: "GENERAL MENTORS",
  INDUSTRY_MENTOR: "INDUSTRY MENTORS",
  JOB_ROLE_MENTOR: "JOB-ROLE MENTORS"
}

export const Visibility = {
  PUBLIC: "PUBLIC",
};

export const MENTOR_TYPE_KEY = {
  EXPLORE: "Explore",
  GENERAL_MENTOR: "General Mentors",
  INDUSTRY_MENTOR: "Industry Mentors",
  JOB_ROLE_MENTOR: "Job-role Mentors"
}

export const PAGE_LIMIT = 10;


