import { ConversationModel} from '@models/Conversation';
import {
  SOCKETIO_CONNECT,
  SOCKETIO_CONNECT_ERROR,
  SOCKETIO_DISCONNECT,
  SOCKETIO_RECONNECT,
  SOCKETIO_RECONNECTING,
} from '@redux/chat/SocketConstants';
import { MessageModel } from '@models/Message';
import { SocketService } from '@services/SocketService';
import { DataUtility } from '@utils/DataUtility';
import { getProfileByUserIdSelector } from "@redux/profile/selectors";
import { IState } from '@store/webInterfaces';

const isChatUndefined = (state: any) => state.chat === undefined;

const isLoaderUndefined = (state: any) => state.chat.loaders === undefined;

const isErrorUndefined = (state: any) => state.chat.errors === undefined;

export const getActiveConversation = (state: any): ConversationModel => {
  if (isChatUndefined(state)
    || state.chat.activeConversation === undefined || state.chat.activeConversation === null) {
    return {} as ConversationModel;
  }
  const activeConversation = state.chat.activeConversation;
  return activeConversation;
};

export const isGetConversationLoading = (state: any): boolean => {
  if (
    isChatUndefined(state) ||
    isLoaderUndefined(state) ||
    state.chat.loaders.getConversation === undefined
  ) {
    return false;
  }
  return state.chat.loaders.getConversation;
};

export const getConversationError = (state: any): string => {
  if (
    isChatUndefined(state) ||
    isErrorUndefined(state) ||
    state.chat.errors.getConversation === undefined
  ) {
    return '';
  }
  return state.chat.errors.getConversation;
};

export const getConversationsSelector = (state: any): ConversationModel[] => {
  if (isChatUndefined(state) || state.chat.conversations === undefined) {
    return [] as ConversationModel[];
  }
  let conversations = state.chat.conversations;

 /* // remove conversation with empty message
 if (!state.chat.webChatStarted) {
    conversations = state.chat.conversations.filter((conversation: ConversationModel) =>
        !DataUtility.isEmpty(conversation.getLastMessage()));
  }
  */

  //todo: identify group/one on one using metadata
  // set profile for conversation
  conversations = conversations.map((conversation: ConversationModel) => {
    if (conversation?.getProfileIds().length > 0) {
      conversation.setProfile(getProfileByUserIdSelector(state, conversation?.getProfileIds()[0]));
    }
    return conversation;
  });
  return conversations;
};

export const isSocketConnectedSelector = (state: any): boolean => {
  if (
    isChatUndefined(state) ||
    state.chat.isSocketActivated === undefined ||
    state.chat.socketStatus.status === undefined
  ) {
    return false;
  }
  return (
    state.chat.isSocketActivated &&
    (state.chat.socketStatus.status === SOCKETIO_CONNECT || state.chat.socketStatus.status === SOCKETIO_RECONNECT)
  );
};

// loading for reconnecting 1
export const isSocketLoadingSelector = (state: any): boolean => {
  if (
    isChatUndefined(state) ||
    state.chat.isSocketActivated === undefined ||
    state.chat.socketStatus.status === undefined
  ) {
    return false;
  }

  return (
    state.chat.isSocketActivated &&
    state.chat.socketStatus.status === SOCKETIO_RECONNECTING &&
    state.chat.socketStatus.statusData == 1  // reconnect attempt
  );
};

// Error Message
export const isSocketErrorMessageSelector = (state: any): boolean => {
  if (
    isChatUndefined(state) ||
    state.chat.isSocketActivated === undefined ||
    state.chat.socketStatus.status === undefined
  ) {
    return false;
  }
  if (!state.chat.isSocketActivated) {
    return (
      state.chat.socketStatus.status === SOCKETIO_CONNECT_ERROR ||
      state.chat.socketStatus.status === SOCKETIO_DISCONNECT
    );
  }

  if (state.chat.isSocketActivated) {
    return (
      state.chat.socketStatus.status !== SOCKETIO_CONNECT &&
      state.chat.socketStatus.status !== SOCKETIO_RECONNECT
    );
  }
  return false;
};

// retry button
export const isSocketRetrySelector = (state: any): boolean => {
  if (
    isChatUndefined(state) ||
    state.chat.isSocketActivated === undefined ||
    state.chat.socketStatus.status === undefined
  ) {
    return false;
  }

  return (
    !state.chat.isSocketActivated &&
    (state.chat.socketStatus.status === '' ||
      state.chat.socketStatus.status === SOCKETIO_CONNECT_ERROR ||
      state.chat.socketStatus.status === SOCKETIO_DISCONNECT)
  );
};

export const isConversationsLoadingSelector = (state: any): boolean => {
  if (
    isChatUndefined(state) ||
    isLoaderUndefined(state) ||
    state.chat.loaders.fetchConversations === undefined
  ) {
    return false;
  }
  return state.chat.loaders.fetchConversations;
};

export const getMessagesOfConversation = (state: any, conversationId: string): MessageModel[] => {
  if (isChatUndefined(state)) {
    return [];
  }
  return state.chat.messages.get(conversationId);
}

export const getConversationMessagesByProfileId = (state: any, conversationId: string): any => {
  const activeConversation = getConversationById(state, conversationId);
  if (DataUtility.isEmpty(activeConversation)) {
    return [];
  }
  const messages = getMessagesOfConversation(state, activeConversation.getId());

  if (messages && messages.length !== 0) {
    let profile = activeConversation.getProfile();
    // if profile is undefined, means not set
    if (!profile) {
      const ids = DataUtility.without(activeConversation.getProfileIds(), SocketService.userProfileId);
      if (ids && ids.length > 0) {
        profile = getProfileByUserIdSelector(state, ids[0]);
        activeConversation.setProfile(profile);
      }
    }
    const giftedChatMessages = messages.map((message: MessageModel, index: number) => {
      return {
        _id: index,
        text: message.getMessageText(),
        file: message.getMessageFile(),
        createdAt: message.getCreatedAt(),
        url: message.getMessageFileUrl(),
        thumbnailImageUrl: message.getThumbnailImageUrl(),
        thumbnailImageLowResolutionUrl: message.getThumbnailImageLowResolutionUrl(),
        messageId: message.getId(),
        pending: message.isPending(),
        sent: message.isSent(),
        user: {
          _id: message.getCreatedBy(),
          //TODO: update after profile API integrated
          avatar: profile ? 'https://placeimg.com/140/140/any' : '',
          name: profile ? profile.getFirstName() : '',
        },
        replyOf: message.getReplyOf()
      };
    });
    return DataUtility.uniqBy(giftedChatMessages, 'messageId');
  }
  return [];
};

export const getConversationMessagesByConversationId = (state: any, conversationId: string): any => {
  const activeConversation = getConversationById(state, conversationId);
  if (DataUtility.isEmpty(activeConversation)) {
    return [];
  }
  const messages = getMessagesOfConversation(state, activeConversation.getId());
  if (messages && messages.length !== 0) {
    return DataUtility.uniqBy(messages.map((message: MessageModel, index: number) => {
      return getGiftChatMessage(state, message, index);
    }), 'messageId');
  }
  return [];
};

export const getGiftChatMessage = (state: any, message: MessageModel, index: number) => {
  const profile = getProfileByUserIdSelector(state, message.getCreatedBy());
  let replyOf = undefined;
  const reply = message.getReplyOf();
  if(reply){
    const profile = getProfileByUserIdSelector(state, reply.createdBy);
    replyOf = {
      text: (reply.content && reply.content.text) ? reply.content.text : '',
      name: profile ? profile.getName() : '',
    }
  }
  return {
    _id: index,
    text: message.getMessageText(),
    file: message.getMessageFile(),
    createdAt: message.getCreatedAt(),
    url: message.getMessageFileUrl(),
    thumbnailImageUrl: message.getThumbnailImageUrl(),
    thumbnailImageLowResolutionUrl: message.getThumbnailImageLowResolutionUrl(),
    messageId: message.getId(),
    pending: message.isPending(),
    sent: message.isSent(),
    clientMessageId: message.getClientMessageId(),
    user: {
      _id: message.getCreatedBy(),
      //TODO: update after profile API integrated
      avatar: profile ? '' : '',
      name: profile ? profile.getName(): '',
    },
    replyOf,
  };
}


export const isFetchMessagesLoading = (state: any): boolean => {
  if (
    isChatUndefined(state) ||
    isLoaderUndefined(state) ||
    state.chat.loaders.fetchMessages === undefined
  ) {
    return false;
  }
  return state.chat.loaders.fetchMessages;
};

export const getChatData = (state: any) => state.chat;

export const isAllConversationsLoaded = (state: any): boolean => {
  if (
    isChatUndefined(state) ||
    state.chat.isLastPage === undefined
  ) {
    return false;
  }
  return state.chat.isLastPage;
};

export const conversationsListPageNumber = (state: any): number => {
  if (
    isChatUndefined(state) ||
    state.chat.pageNumber === undefined
  ) {
    return 1;
  }
  return state.chat.pageNumber;
};

export const getConversationByProfile = (state: any, conversationId: string): ConversationModel => {
  if (isChatUndefined(state) || state.chat.conversations === undefined) {
    return {} as ConversationModel;
  }
  const conversation = state.chat.conversations.find((item: ConversationModel) => {
    if (item.getProfileIds().length > 0) {
      return item.getId() === conversationId;
    }
    return false;
  });
  return conversation || {} as ConversationModel;
};

export const getConversationById = (state: any, conversationId: string): ConversationModel => {
  if (isChatUndefined(state) || state.chat.conversations === undefined) {
    return {} as ConversationModel;
  }
  const conversation = state.chat.conversations.find((item: ConversationModel) => {
    return item?.getId() === conversationId;
  });
  return conversation || {} as ConversationModel;
};

export const isLoadEarlierMessagesSelector = (state: any, conversationId: string): boolean => {
  const activeConversation = getConversationById(state, conversationId);
  if (DataUtility.isEmpty(activeConversation) || state.chat.loadEarlierMessages === undefined) {
    return false;
  }
  return state.chat.loadEarlierMessages.get(activeConversation.getId());
};

export const isLoadEarlierConvMessagesSelector = (state: any, conversationId: string): boolean => {
  if (state.chat.loadEarlierMessages === undefined) {
    return false;
  }
  return state.chat.loadEarlierMessages.get(conversationId);
};

export const getPostMessageError = (state: any): string => {
  if (
    isChatUndefined(state) ||
    isErrorUndefined(state) ||
    state.chat.errors.postMessageError === undefined
  ) {
    return '';
  }
  return state.chat.errors.postMessageError;
};

export const getNewChatProfileId = (state: any): string => {
  if (
    isChatUndefined(state) ||
    state.chat.newChatProfileId === undefined
  ) {
    return '';
  }
  return state.chat.newChatProfileId;
};

export const isNewConvoLoading = (state: any): boolean => {
  if (
    isChatUndefined(state) ||
    isLoaderUndefined(state) ||
    state.chat.loaders.newChatProfileLoader === undefined
  ) {
    return false;
  }
  return state.chat.loaders.newChatProfileLoader;
};

export const isNewConvoStarted = (state: any): boolean => {
  if (
    isChatUndefined(state) ||
    state.chat.newConvoStarted === undefined
  ) {
    return false;
  }
  return state.chat.newConvoStarted;
};

export const getUnreadConversationCountSelector = (state: IState): number => {
  return state?.chat?.unreadConversationCount || 0;
}

export const getUnreadConversationCountLoaderSelector = (state: IState): boolean => {
  return state?.chat?.loaders?.unreadConversationCount || false;
}

export const getUnreadConversationCountErrorSelector = (state: IState): string => {
  return state?.chat?.errors?.unreadConversationCount || "";
}
