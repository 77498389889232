import {
  call,
  delay,
  ForkEffect,
  put,
  select,
  StrictEffect,
  takeEvery,
  takeLatest
} from "redux-saga/effects";

import { getErrorCodes } from "@utils/ErrorMessageUtils";

import { BatchModel, IInviteSingleResponse, IResendOTPResponse } from "@common/domain/models/Batch";
import { BatchDeliveryModel } from "@common/domain/models/BatchDeliveryModel";
import { CourseModel } from "@common/domain/models/Course";
import { Institute } from "@common/domain/models/Institute";
import { ProfileModel } from "@common/domain/models/Profile";
import { RMBatchModel } from "@common/domain/models/RMBatchModel";

import { IFluxStandardAction } from "@store/webInterfaces";
import { BatchRepository } from "../../domain/repositories/BatchRepository";
import { userRoles } from "../constants";
import { fetchMultipleCourseDetails } from "../course/actions";
import { fetchMultipleCoursesSaga } from "../course/sagas";
import { getCourseByIdSelector } from "../course/selectors";
import { fetchMultipleInstitutes } from "../institutes/actions";
import { fetchMultipleInstituteDetailSaga } from "../institutes/sagas";
import { getInstituteByIdSelector } from "../institutes/selectors";
import { fetchProfilesByUserIds } from "../profile/actions";
import { fetchProfilesByUserIdsSaga } from "../profile/sagas";
import {
  addCompetencyDateFailure,
  addCompetencyDateSuccess,
  addFacultyFail,
  addFacultySuccess,
  batchActions,
  closeBatchFailure,
  closeBatchSuccess,
  createBatchFail,
  createBatchSuccess,
  deleteBatchStudentFail,
  deleteBatchStudentSuccess,
  fetchAllBatchesFail,
  fetchAllBatchesSuccess,
  fetchBatchCompetenciesFailure,
  fetchBatchCompetenciesSuccess,
  fetchBatchDeliveryModelsFailure,
  fetchBatchDeliveryModelsSuccess,
  fetchBatchesDetails,
  fetchBatchesDetailsFailure,
  fetchBatchesDetailsSuccess,
  fetchBatchesUserOwnedFailure,
  fetchBatchesUserOwnedSuccess,
  fetchBatchMembersCount,
  fetchBatchMembersCountFail,
  fetchBatchMembersCountSuccess,
  fetchInvitedStudentsFail,
  fetchInvitedStudentsSuccess,
  fetchMultipleBatchesMembersByRoleFail,
  fetchMultipleBatchesMembersByRoleSuccess,
  fetchStatusOfBulkUploadingByIdFail,
  fetchStatusOfBulkUploadingByIdSuccess,
  fetchStudentFacultyBatchesFailure,
  fetchStudentFacultyBatchesSuccess,
  fetchUserBatchesFailure,
  fetchUserBatchesSuccess,
  fetchUserPermissionsByBatchIdFail,
  fetchUserPermissionsByBatchIdSuccess,
  IBulkUploadResponse,
  ICloseBatchAction,
  IFetchBatchesDetailsAction,
  IFetchBatchesUserOwnedAction,
  IFetchBatchMembersCountAction,
  IFetchBulkUploadStatusAction,
  IFetchInvitedStudentsAction,
  IInviteSingleStudentAction,
  IJoinBatchAction,
  inviteSingleStudentFail,
  inviteSingleStudentSuccess,
  IUploadUsersAction,
  joinBatchFailure,
  joinBatchSuccess,
  removeBatchFacultyFail,
  removeBatchFacultySuccess,
  resendOTPFail,
  resendOTPSuccess,
  rMFetchAssignedBatchesFail,
  rMFetchAssignedBatchesListSuccess,
  rMFetchAssignedBatchesSuccess,
  updateBatchFail,
  updateBatchSuccess,
  uploadUsersError,
  uploadUsersSuccess
} from "./actions";
import { ICreateBatchPayload, IDeleteBatchStudentAction, IFetchUserBatchPayload, IResendOTPAction, IUserBatchPermissions } from "./interface";
import { getBatchByIdSelector, getBatchMapSelector } from "./selectors";

export function* fetchBatchesUserOwnedSaga(action: IFetchBatchesUserOwnedAction): any {
  try {
    const { userId, status, page, limit } = action.payload;
    const response: {
      batches: BatchModel[],
      total: number
    } = yield call(BatchRepository.fetchBatchesUserOwned, userId, status, page, limit);

    yield put(fetchBatchesUserOwnedSuccess(response.batches, status, page, limit, response.total));
  } catch (e) {
    yield put(fetchBatchesUserOwnedFailure(e.message));
  }
}

export function* fetchBatchStudentsCountSaga(action: IFetchBatchMembersCountAction) {
  try {
    const {
      batchIds
    } = action;
    const batchStudents = yield call(BatchRepository.fetchBatchStudentsCount, batchIds);
    const batchStudentObj = {};
    batchStudents.forEach(element => {
      batchStudentObj[element.batchId] = Number(element.count);
    });
    const batchMap = yield select(getBatchMapSelector);
    const batches: BatchModel[] = batchIds.map((batchId) => {
      return (
        batchMap && batchMap[batchId]
      );
    });
    batches.forEach((batch: BatchModel) => {
      batch.setBatchMembersCount(batchStudentObj[batch.getId()], userRoles.student);
    });
    yield put(fetchBatchMembersCountSuccess());
  } catch (e) {
    yield put(fetchBatchMembersCountFail(e.message));
  }
}

export function* closeBatchSaga(action: ICloseBatchAction): any {
  try {
    const { batchId } = action.payload;
    const batches: any = yield call(BatchRepository.closeBatch, batchId);
    yield put(closeBatchSuccess(batches));
  } catch (e) {
    yield put(closeBatchFailure(e.message));
  }
}

export function* fetchBatchCompetenciesListSaga(
  action: IFluxStandardAction
): Generator<StrictEffect, void, any> {
  try {
    const id = action.payload;
    const competenciesList: any[] = yield call(
      BatchRepository.fetchCompetenciesList,
      id
    );
    yield put(fetchBatchCompetenciesSuccess(competenciesList));
  } catch (e) {
    const error = getErrorCodes(e);
    yield put(fetchBatchCompetenciesFailure(error));
  }
}

export function* updateCompetencyDateSaga(
  action
): Generator<StrictEffect, void, any> {
  try {
    const { batchId, competencyId, endDate, startDate } = action.payload;
    const updateCompetencyDateStatus: string = yield call(
      BatchRepository.updateCompetencyDate,
      batchId,
      competencyId,
      endDate,
      startDate,
    );
    yield put(addCompetencyDateSuccess(updateCompetencyDateStatus));
  } catch (e) {
    yield put(addCompetencyDateFailure(e.message));
  }
}

export function* fetchBatchDeliveryModelsSaga(): Generator<
  StrictEffect,
  void,
  BatchDeliveryModel[]
> {
  try {
    const batchDeliveryModels: BatchDeliveryModel[] = yield call(
      BatchRepository.getBatchDeliveryModels
    );
    yield put(fetchBatchDeliveryModelsSuccess(batchDeliveryModels));
  } catch (e) {
    yield put(fetchBatchDeliveryModelsFailure(e.message));
  }
}

export function* createBatchSaga(
  action: IFluxStandardAction<ICreateBatchPayload>
): Generator<StrictEffect, void, BatchModel> {
  try {
    const createBatchResponse: BatchModel = yield call(
      BatchRepository.createBatch,
      action.payload
    );
    yield put(createBatchSuccess(createBatchResponse));
  } catch (e) {
    yield put(createBatchFail(e.message));
  }
}

export function* fetchBatchesDetailsSaga(action: IFetchBatchesDetailsAction): any {
  try {
    const { batchIds, shouldFetchBatchStudentProfiles } = action.payload;
    const batches: BatchModel[] = yield call(
      BatchRepository.fetchBatchesDetails,
      batchIds
    );
    
    if (shouldFetchBatchStudentProfiles) {
      const batchStudents = yield call(BatchRepository.fetchBatchStudentsCount, batchIds);
      const batchStudentObj = {};
      batchStudents.forEach(element => {
        batchStudentObj[element.batchId] = Number(element.count);
      });
      batches.forEach((batch: BatchModel) => {
        batch.setBatchMembersCount(batchStudentObj[batch.getId()], userRoles.student);
      });

      // TODO: fetchBatchMemebrs Action is required to be triggered

      yield put(fetchBatchesDetailsSuccess(batches));
    } else {
      yield put(fetchBatchesDetailsSuccess(batches));
    }
  } catch (e) {
    yield put(fetchBatchesDetailsFailure(e.message));
  }
}

export function* joinBatchSaga(action: IJoinBatchAction) {
  const {
    batchKey
  } = action.payload
  try {
    const result = yield call(BatchRepository.joinBatch, batchKey);
    yield put(joinBatchSuccess(result));
  } catch (e) {
    yield put(joinBatchFailure(e.message));
  }
}


export function* fetchUserBatchesSaga(
  action: IFluxStandardAction<IFetchUserBatchPayload>
) {
  try {
    const userBatches = yield call(
      BatchRepository.fetchUserBatch,
      action.payload
    );
    yield put(fetchUserBatchesSuccess(userBatches));
  } catch (error) {
    yield put(fetchUserBatchesFailure(error.message));
  }
}

export function* fetchStudentAndFacultyCombinedBatchSaga(
  action
): Generator<StrictEffect, void, BatchModel[]> {
  try {
    const studentFacultyBatches = yield call(
      BatchRepository.fetchStudentAndFacultyCombinedBatch,
      action.payload
    );
    yield put(fetchStudentFacultyBatchesSuccess(studentFacultyBatches));
  } catch (error) {
    yield put(fetchStudentFacultyBatchesFailure(error.message));
  }
}

export function* fetchMultipleBatchesMembersByRoleSaga(action): Generator<StrictEffect, void, ProfileModel[]> {
  try {
    const { role, batchIds, search } = action.payload;
    const batchesMembers = yield call(BatchRepository.fetchBatchesMembers, batchIds, role, search);
    yield put(fetchMultipleBatchesMembersByRoleSuccess(batchIds[0], role, batchesMembers));
  } catch (error) {
    yield put(fetchMultipleBatchesMembersByRoleFail(error.message));
  }
}

// all batches
export function* fetchAllBatchesSaga(action) {
  // debounce of 0.5 second.
  yield delay(500);
  const { page, limit, search, status, courseId, batchIds } = action.payload;
  try {
    const allBatches: { batches: BatchModel[], total: number } = yield call(BatchRepository.fetchAllBatches, page, limit, search, status, courseId, batchIds);
    yield put(fetchAllBatchesSuccess(allBatches.batches, allBatches.total, page));
  } catch (e) {
    yield put(fetchAllBatchesFail(e.message));
  }
}

export function* setBatchesDetailSaga(batch: RMBatchModel[]) {
  let batchDetail: BatchModel = null;
  const courseIds: string[] = [];
  const instituteIds: number[] = [];
  for (let i = 0; i < batch.length; i++) {
    batchDetail = yield select(getBatchByIdSelector, batch[i].getBatchId());
    if (batchDetail?.getCourseId()) {
      courseIds.push(batchDetail?.getCourseId());
    }
    if (batchDetail?.getInstituteId()) {
      instituteIds.push(batchDetail?.getInstituteId());
    }
    batch[i].setBatchDetail(batchDetail);
  }
  return { courseIds, instituteIds };
}

export function* setCourseDetailSaga(batch: RMBatchModel[]) {
  for (let i = 0; i < batch.length; i++) {
    const courseDetail: CourseModel = yield select(getCourseByIdSelector, batch[i]?.getBatchDetail()?.getCourseId());
    batch[i]?.setCourseDetail(courseDetail);
  }
}

export function* setInstituteDetailSaga(batch: RMBatchModel[]) {
  for (let i = 0; i < batch.length; i++) {
    const instituteDetail: Institute = yield select(getInstituteByIdSelector, batch[i]?.getBatchDetail()?.getInstituteId());
    batch[i]?.setInstituteDetail(instituteDetail);
  }
}

export function* fetchRMAssignedBatchesSaga(action) {
  try {
    const { regionalManagerId, page, limit, status,
      notFetchBatchInstitutes, notFetchStudentsCount } = action.payload;
    const rmBatches: { batches: RMBatchModel[], total: number } = yield call(BatchRepository.fetchRMAssignedBatches, regionalManagerId, page, limit, status);
    const batchIds = rmBatches.batches.map((batch: RMBatchModel) => batch.getBatchId());
    // fetchBatchIdsOnly flag is to avoid unnecessary extra api calls when we need RM batch ids only.
    if (action.payload.fetchBatchIdsOnly) {
      yield put(rMFetchAssignedBatchesListSuccess(batchIds));
    } else {
      yield call(fetchBatchesDetailsSaga, fetchBatchesDetails(batchIds));
      if (!notFetchStudentsCount) {
        yield call(fetchBatchStudentsCountSaga, fetchBatchMembersCount(batchIds));
      }
      const { courseIds, instituteIds } = yield call(setBatchesDetailSaga, rmBatches.batches);
      yield call(fetchMultipleCoursesSaga, fetchMultipleCourseDetails(courseIds));
      if (!notFetchBatchInstitutes) {
        yield call(fetchMultipleInstituteDetailSaga, fetchMultipleInstitutes(instituteIds));
        yield call(setInstituteDetailSaga, rmBatches.batches);
      }
      yield call(setCourseDetailSaga, rmBatches.batches);
      yield put(rMFetchAssignedBatchesSuccess(rmBatches.batches, rmBatches.total, page));
    }
  } catch (e) {
    yield put(rMFetchAssignedBatchesFail(e.message));
  }
}

// update batch
export function* updateBatchSaga(action: IFluxStandardAction<any>): Generator<StrictEffect, void, BatchModel> {
  try {
    const { batchId, payload } = action.payload;
    const updatedBatch = yield call(BatchRepository.updateBatch, batchId, payload);
    yield put(updateBatchSuccess(updatedBatch));
  } catch (e) {
    const error = getErrorCodes(e);
    yield put(updateBatchFail(error));
  }
}

export function* addFacultySaga(action) {
  try {
    const addFacultyResponse = yield call(BatchRepository.addFaculty, action.payload);
    yield put(addFacultySuccess(addFacultyResponse));
  } catch (e) {
    const error = getErrorCodes(e);
    yield put(addFacultyFail(error));
  }
}

export function* removeFacultySaga(action) {
  try {
    const removeFacultyResponse = yield call(BatchRepository.removeFaculty, action.payload);
    yield put(removeBatchFacultySuccess(removeFacultyResponse));
  } catch (e) {
    const error = getErrorCodes(e);
    yield put(removeBatchFacultyFail(error));
  }
}

export function* fetchUserBatchPermissionByBatchIdSaga(action): Generator<StrictEffect, void, IUserBatchPermissions> {
  try {
    const { userId, batchId } = action.payload;
    const permissions = yield call(BatchRepository.fetchUserBatchPermissionsByBatchId, batchId, userId);
    yield put(fetchUserPermissionsByBatchIdSuccess(permissions, batchId));
  } catch (error) {
    yield put(fetchUserPermissionsByBatchIdFail(error.message));
  }
}

export function* fetchInvitedStudentsSage(action: IFetchInvitedStudentsAction): any {
  const { batchKey, page, limit, search} = action.payload
  try {
    const response: any[] = yield call(BatchRepository.invitedStudents, batchKey, page, limit, search)
    yield put(fetchInvitedStudentsSuccess(response, page));
  } catch (e) {
    yield put(fetchInvitedStudentsFail(e.message))
  }
};

export function* deleteBatchStudentSaga(action: IDeleteBatchStudentAction) {
  try {
    const { batchId, studentId, deleteUserCourseData } = action.payload;
    const deletedBatch = yield call(BatchRepository.deleteBatchStudents, batchId, studentId, deleteUserCourseData);
    yield put(deleteBatchStudentSuccess(studentId));
  } catch (e) {
    yield put(deleteBatchStudentFail(e.message));
  }
}

export function* resendOTPSaga(action: IResendOTPAction): any {
  const { emailIds, ccEmailIds, batchKey, instituteCustomUrl } = action.payload
  try {
    const response: IResendOTPResponse = yield call(BatchRepository.reSendOTP, emailIds, batchKey, ccEmailIds, instituteCustomUrl);
    yield put(resendOTPSuccess(response));
  } catch (e) {
    yield put(resendOTPFail(e.message))
  }
};

export function* uploadUsersSaga(action: IUploadUsersAction): any {
  const {data} = action.payload
  try {
    const bulkUserUploads = yield call(BatchRepository.bulkUploadUsers, data);
    yield put(uploadUsersSuccess(bulkUserUploads));
  } catch (e) {
    yield put(uploadUsersError(e.message))
  }
};

export function* inviteSingleUsersSaga(action: IInviteSingleStudentAction) {
  const {batchKey, studentEmailIds} = action.payload
  try {
    const response: IInviteSingleResponse = yield call(BatchRepository.invitedSingleStudents, batchKey, studentEmailIds);
    yield put(inviteSingleStudentSuccess(response));
  } catch (e) {
    yield put(inviteSingleStudentFail(e.message))
  }
};

export function* fetchStatusOfBulkUploadByIdSaga(action: IFetchBulkUploadStatusAction) {
  const { bulkUploadId } = action.payload;
  try {
    const response: IBulkUploadResponse = yield call(BatchRepository.fetchBulkUploadStatusById, bulkUploadId);
    yield put(fetchStatusOfBulkUploadingByIdSuccess(response));
  } catch (e) {
    yield put(fetchStatusOfBulkUploadingByIdFail(e.message));
  }
};

export function* watchBatches(): Generator<ForkEffect> {
  yield takeEvery(batchActions.FETCH_BATCHES_USER_OWNED, fetchBatchesUserOwnedSaga);
  yield takeEvery(batchActions.FETCH_BATCH_MEMBERS_COUNT, fetchBatchStudentsCountSaga);
  yield takeLatest(batchActions.CLOSE_BATCH, closeBatchSaga);
  yield takeLatest(
    batchActions.FETCH_BATCH_COMPETENCIES,
    fetchBatchCompetenciesListSaga
  );
  yield takeLatest(batchActions.ADD_COMPETENCY_DATE, updateCompetencyDateSaga);
  yield takeLatest(
    batchActions.FETCH_BATCH_DELIVERY_MODELS,
    fetchBatchDeliveryModelsSaga
  );
  yield takeLatest(batchActions.CREATE_BATCH, createBatchSaga);
  yield takeLatest(batchActions.FETCH_BATCHES_DETAILS, fetchBatchesDetailsSaga);
  yield takeLatest(batchActions.JOIN_BATCH, joinBatchSaga);
  yield takeLatest(batchActions.FETCH_USER_BATCHES, fetchUserBatchesSaga);
  yield takeLatest(batchActions.FETCH_STUDENT_FACULTY_BATCHES, fetchStudentAndFacultyCombinedBatchSaga);
  yield takeEvery(batchActions.FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE, fetchMultipleBatchesMembersByRoleSaga);
  yield takeLatest(batchActions.FETCH_ALL_BATCHES, fetchAllBatchesSaga);
  yield takeLatest(batchActions.RM_FETCH_ASSIGNED_BATCHES, fetchRMAssignedBatchesSaga);
  yield takeLatest(batchActions.UPDATE_BATCH, updateBatchSaga);
  yield takeLatest(batchActions.ADD_FACULTY, addFacultySaga);
  yield takeLatest(batchActions.REMOVE_BATCH_FACULTY, removeFacultySaga);
  yield takeLatest(batchActions.FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID, fetchUserBatchPermissionByBatchIdSaga);
  yield takeLatest(batchActions.FETCH_INVITED_STUDENTS, fetchInvitedStudentsSage);
  yield takeLatest(batchActions.RESEND_OPT, resendOTPSaga);
  yield takeLatest(batchActions.DELETE_BATCH_STUDENT, deleteBatchStudentSaga);
  yield takeLatest(batchActions.UPLOAD_USERS, uploadUsersSaga);
  yield takeLatest(batchActions.INVITE_SINGLE_STUDENT, inviteSingleUsersSaga);
  yield takeLatest(batchActions.GET_STATUS_OF_BULK_UPLOADING_BYID, fetchStatusOfBulkUploadByIdSaga);
}
