import { CourseModel } from "@common/domain/models/Course";
import { Institute } from "@common/domain/models/Institute";
import { ProfileModel } from "@common/domain/models/Profile";

import { institutesActions } from "./actions";

export interface IInstituteState {
  institutes: any;
  instituteIdsList: number[];
  instituteById: {},
  faculties: ProfileModel[];
  byCourseRootId: {}
  userInstitute: Institute[];
  loaders: {
    fetchInstitutes: boolean;
    fetchMutipleInstituteDetail: boolean;
    fetchInstituteFaculties: boolean;
    userInstitute: boolean;
  };
  errors: {
    fetchInstitutes: string;
    fetchMutipleInstituteDetail: string;
    fetchInstituteFaculties: string;
    userInstitute: string;
  };
}

export const initialState = {
  institutes: [],
  instituteById: {},
  instituteIdsList: [],
  faculties: [],
  byCourseRootId: {},
  userInstitute: [],
  loaders: {
    fetchInstitutes: false,
    fetchMutipleInstituteDetail: false,
    fetchInstituteFaculties: false,
    userInstitute: false
  },
  errors: {
    fetchInstitutes: "",
    fetchMutipleInstituteDetail: "",
    fetchInstituteFaculties: "",
    userInstitute: ""
  },
};

export const instituteReducer = (
  state: IInstituteState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case institutesActions.FETCH_INSTITUTES:
      return {
        ...state,
        loaders: { ...state.loaders, fetchInstitutes: true },
        errors: { ...state.errors, fetchInstitutes: "" },
      };
    case institutesActions.FETCH_INSTITUTES_SUCCESS:
      const institutes: any[] = action.payload;
      return {
        ...state,
        institutes: institutes,
        loaders: { ...state.loaders, fetchInstitutes: false },
        errors: { ...state.errors, fetchInstitutes: "" },
      };
    case institutesActions.FETCH_INSTITUTES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchInstitutes: false },
        errors: { ...state.errors, fetchInstitutes: action.payload },
      };
    case institutesActions.FETCH_CERTIFIED_COURSES:
      return {
        ...state,
        loaders: { ...state.loaders, fetchCourses: true },
        errors: { ...state.errors, fetchCourses: "" },
      };
    case institutesActions.FETCH_CERTIFIED_COURSES_SUCCESS:
      const courses: CourseModel[] = action.payload;
      updateCourseRootMap(action.payload.courses, state.byCourseRootId);

      return {
        ...state,
        courses: courses,
        loaders: { ...state.loaders, fetchCourses: false },
        errors: { ...state.errors, fetchCourses: "" },
      };
    case institutesActions.FETCH_CERTIFIED_COURSES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchCourses: false },
        errors: { ...state.errors, fetchCourses: action.payload },
      };
    // fetch multiple institute
    case institutesActions.FETCH_MULTIPLE_INSTITUTES_DETAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchMutipleInstituteDetail: true }
      }
    case institutesActions.FETCH_MULTIPLE_INSTITUTES_DETAIL_SUCCESS:
      const institutesList: Institute[] = action.payload;
      updateInstituteMap(institutesList, state.instituteById);
      const instituteIdsList = institutesList.map((institute: Institute) => institute.getId());
      return {
        ...state,
        instituteIdsList,
        loaders: { ...state.loaders, fetchMutipleInstituteDetail: false }
      }
    case institutesActions.FETCH_MULTIPLE_INSTITUTES_DETAIL_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchMutipleInstituteDetail: false },
        errors: { ...state.errors, allBatches: action.payload },
      }

    case institutesActions.FETCH_INSTITUTE_FACULTIES:
      return {
        ...state,
        loaders: { ...state.loaders, fetchInstituteFaculties: true },
        errors: { ...state.errors, fetchInstituteFaculties: "" },
      };
    case institutesActions.FETCH_INSTITUTE_FACULTIES_SUCCESS:
      return {
        ...state,
        faculties: action.payload,
        loaders: { ...state.loaders, fetchInstituteFaculties: false },
      };
    case institutesActions.FETCH_INSTITUTE_FACULTIES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchInstituteFaculties: false },
        errors: { ...state.errors, fetchInstituteFaculties: action.payload },
      };
    // fetch user institute
    case institutesActions.FETCH_USER_INSTITUTE:
      return {
        ...state,
        loaders: { ...state.loaders, userInstitute: true },
        errors: { ...state.errors, userInstitute: "" }
      }
    case institutesActions.FETCH_USER_INSTITUTE_SUCCESS:
      return {
        ...state,
        userInstitute: action.payload,
        loaders: { ...state.loaders, userInstitute: false },
        errors: { ...state.errors, userInstitute: "" }
      }
    case institutesActions.FETCH_USER_INSTITUTE_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, userInstitute: false },
        errors: { ...state.errors, userInstitute: action.payload }
      }

    default:
      return state;
  }
};

export const updateInstituteMap = (institutes: Institute[], existingMap: any) => {
  institutes.forEach((institute: Institute) => {
    existingMap[institute.getId()] = institute;
  });
};

export const updateCourseRootMap = (courses, existingMap: {}) => {
  if (courses.length > 0) {
    courses.forEach((course) => {
      existingMap[course.courseRootId] = course;
    });
  }
};
