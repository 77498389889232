import { JsonProperty } from "json-object-mapper";

export interface ILocation {
    iso2: string,
    country: string,
    district: string,
    primaryPostalCode: string,
    dialCode: string,
    locationId: string,
    city: string,
    state: string,
    latitude: string,
    longitude: string,
    region: string,
    timezoneName: string
}
export class LocationModel {
    @JsonProperty({ name: "iso2", required: true })
    public iso2: string = "";

    @JsonProperty({ name: "country", required: true })
    public country: string = "";

    @JsonProperty({ name: "district", required: false })
    public district: string = "";

    @JsonProperty({ name: "primaryPostalCode", required: false })
    public primaryPostalCode: string = "";

    @JsonProperty({ name: "dialCode", required: true })
    public dialCode: string = "";

    @JsonProperty({ name: "locationId", required: true })
    public locationId: string = "";

    @JsonProperty({ name: "city", required: true })
    public city: string = "";

    @JsonProperty({ name: "state", required: true })
    public state: string = "";

    @JsonProperty({ name: "deletedAt", required: false })
    public deletedAt: string = "";

    @JsonProperty({ name: "timezone", required: false })
    public timezoneName: string = "";

    @JsonProperty({ name: "latitude", required: true })
    public latitude: string = "";

    @JsonProperty({ name: "longitude", required: true })
    public longitude: string = "";

    @JsonProperty({ name: "region", required: false })
    public region: string = "";

    public getIso2(): string {
        return this.iso2;
    }

    public getCountry(): string {
        return this.country;
    }

    public getDistrict(): string {
        return this.district;
    }

    public getPrimaryPostalCode(): string {
        return this.primaryPostalCode;
    }

    public getDialCode(): string {
        return this.dialCode;
    }

    public getLocationId(): string {
        return this.locationId;
    }

    public getCity(): string {
        return this.city;
    }

    public getState(): string {
        return this.state;
    }

    public getDeletedAt(): string {
        return this.deletedAt;
    }

    public getTimezone(): string {
        return this.timezoneName;
    }

    public getLatitude(): string {
        return this.latitude;
    }

    public getLongitude(): string {
        return this.longitude;
    }

    public getRegion(): string {
        return this.region;
    }

    public getTimezoneName(): string {
        return this.timezoneName;
    }
}
