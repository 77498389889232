import { deserializeJsonObj } from "@utils/ObjectMapperUtil";

import { APIBaseServices, clearCacheForService } from "@common/network/clientCacheHandler";
import { APIv1, APIv2 } from "@common/network/constants";
import { IApiClient } from "@common/network/interfaces";

import { ApiClient } from "@network/client";
import { SettingsModel } from "../models/Settings";
import {IUpdateSettingPayload, SettingsConfigModel } from "../models/SettingsConfig";

const ENDPOINTS = {
  getUserSettingsConfig: () => `${APIv1}/iamservice/userSettingsConfig`,
  userSettingsUrl: (userId: string) =>
    `${APIv1}/iamservice/users/${userId}/settings`,
  fetchInstitutes: () => `${APIv1}/organizationservice/users/institutes`,
  fetchInstitutesDetails: () => `${APIv2}/organizationservice/institutes`,
  fetchUserRestoreId:() => `${APIv1}/iamservice/restoreId`,
  storeUserRestoreId:() => `${APIv1}/iamservice/restoreId`,

};

class SettingsRepository {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  public getUserSettingsConfig = async () => {
    const params = {};
    const response = await this.apiClient.get(
      ENDPOINTS.getUserSettingsConfig(),
      params,
      true
    );
    const userSettingsInfo = deserializeJsonObj(
      response.userSettingsConfig,
      SettingsConfigModel,
      "getUserSettingsConfig"
    ) as SettingsConfigModel;

    return userSettingsInfo;
  };

  public getUserSettings = async (userId: string) => {
    try {
      const response = await this.apiClient.get(
        ENDPOINTS.userSettingsUrl(userId), ""
      );

      const userSetting = deserializeJsonObj(
        response.userSettings,
        SettingsModel,
        "updateUserSettings"
      ) as SettingsModel;

      return userSetting;

    } catch (e) {
      throw e.message;
    }
  };

  public updateUserSettings = async (userSettingsPayload: IUpdateSettingPayload) => {
    const { settingsData, isCreateSettingData } = userSettingsPayload;
    const response = isCreateSettingData ? await this.apiClient.post(
      ENDPOINTS.userSettingsUrl(settingsData?.userId),
      settingsData
    ) : await this.apiClient.put(
      ENDPOINTS.userSettingsUrl(settingsData?.userId),
      settingsData
    );
    clearCacheForService(APIBaseServices.IAM_SERVICE);

    const userSetting = deserializeJsonObj(
      response.userSettings,
      SettingsModel,
      "updateUserSettings"
    ) as SettingsModel;

    return userSetting;
  };
}



const settingsRepository = new SettingsRepository(ApiClient);

export { settingsRepository as SettingsRepository };
