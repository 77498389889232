import { JsonProperty } from "json-object-mapper";

import { ICourseModule } from "./CourseModule";

export class StudentCourseActivity {

  @JsonProperty({ name: "totalActivities", required: true })
  private totalActivities: number = 0;

  @JsonProperty({ name: "completedActivities", required: true })
  private completedActivities: number = 0;

  @JsonProperty({ name: "attemptedActivities", required: true })
  private attemptedActivities: number = 0;

  @JsonProperty({ name: "userScore", required: true })
  private userScore: number = 0;

  @JsonProperty({ name: "totalScore", required: true })
  private totalScore: number = 0;
  
  @JsonProperty({ name: "modules", required: true })
  private modules: ICourseModule[] = [];

  public getTotalActivities(): number {
    return this.totalActivities;
  }

  public getCompletedActivities(): number {
    return this.completedActivities;
  }

  public getAttemptedActivities(): number {
    return this.attemptedActivities;
  }

  public getUserScore(): number {
    return this.userScore;
  }

  public getTotalScore(): number {
    return this.totalScore;
  }

  public getModules(): ICourseModule[] {
    return this.modules;
  }
}




