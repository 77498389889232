export const userMessages = {
    subscribe: {
        key: 'notification.actions.SUBSCRIBE',
    },
    notificationInfoTitle: {
        key: 'notification.messages.NOTIFICATION_INFO_CARD_TITLE',
    },
    notificationInfoMessage: {
        key: 'notification.messages.NOTIFICATION_INFO_CARD_BODY',
    },
    noResultSearch: {
        key: "global.messages.NO_RESULT_SEARCH"
    }
}