
const actionTypePrefix = 'ZOOM/';
import { SettingsModel } from "@common/domain/models/Settings";

export const ZoomActions = {

    ZOOM_ACCESS_TOKEN: `${actionTypePrefix}ZOOM_ACCESS_TOKEN`,
    ZOOM_ACCESS_TOKEN_SUCCESS: `${actionTypePrefix}ZOOM_ACCESS_TOKEN_SUCCESS`,
    ZOOM_ACCESS_TOKEN_FAILURE: `${actionTypePrefix}ZOOM_ACCESS_TOKEN_FAILURE`,

    GET_ZOOM_MEETING_DETAILS: `${actionTypePrefix}GET_ZOOM_MEETING_DETAILS`,
    GET_ZOOM_MEETING_DETAILS_SUCCESS: `${actionTypePrefix}GET_ZOOM_MEETING_DETAILS_SUCCESS`,
    GET_ZOOM_MEETING_DETAILS_FAILURE: `${actionTypePrefix}GET_ZOOM_MEETING_DETAILS_FAILURE`
};

export interface IZoomAccessTokenParams {
    code: string;
    userId: string;
    updateSettingData?: SettingsModel;
    isCreateSettingData: boolean;
}

export interface IZoomAccessTokenAction {
    type: string,
    payload: IZoomAccessTokenParams
}

export interface IRefreshZoomAccessTokenAction {
    type: string,
    payload: {
        userId: string
    }
}
export interface IUnauthorizeZoomIntegrationPayload {
    userId: string;
    updateSettingData?: SettingsModel;
}

export interface IUnauthorizeZoomIntegrationAction {
    type: string,
    payload: IUnauthorizeZoomIntegrationPayload
}

export interface IGetZoomMeetingDetailsAction {
    type: string,
    payload: {
        meetingId: string
    }
}

export const zoomAccessToken = (payload: IZoomAccessTokenParams): IZoomAccessTokenAction => ({
    type: ZoomActions.ZOOM_ACCESS_TOKEN,
    payload
});

export const zoomAccessTokenSuccess = (payload: any) => ({
    type: ZoomActions.ZOOM_ACCESS_TOKEN_SUCCESS,
    payload
});

export const zoomAccessTokenFailure = (error: string) => ({
    type: ZoomActions.ZOOM_ACCESS_TOKEN_FAILURE,
    payload: error,
});

export const getZoomMeetingDetails = (payload: { meetingId: string }): IGetZoomMeetingDetailsAction => ({
    type: ZoomActions.GET_ZOOM_MEETING_DETAILS,
    payload
});

export const getZoomMeetingDetailsSuccess = (payload: any) => ({
    type: ZoomActions.GET_ZOOM_MEETING_DETAILS_SUCCESS,
    payload
});

export const getZoomMeetingDetailsFailure = (error: string) => ({
    type: ZoomActions.GET_ZOOM_MEETING_DETAILS_FAILURE,
    payload: error,
});
