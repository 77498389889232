import React from "react";
import { Select, SelectProps } from "antd";
const { Option } = Select;

interface IItem {
  value: string;
  name: string;
}

interface IProps extends SelectProps<any> {
  className?: any;
  children?: any;
  items?: IItem[];
  sectionWrapperId: string;
  label?: string;
  isRequired?: boolean;
  placeholder?: string;
}

export default function(props: IProps) {
  const {
    children,
    sectionWrapperId,
    className,
    items,
    label,
    isRequired,
    placeholder,
    ...rest
  } = props;
  const style = ["wf_normal_select", className].join(" ");

  return (
    <div id={sectionWrapperId}>
        {label && <h4>{label}{isRequired && <span>*</span>}</h4>}
      <Select
        getPopupContainer={() => document.getElementById(sectionWrapperId)}
        {...rest}
        placeholder={placeholder}
        className={style}
      >
        {items.map((item: IItem) => (
          <Option value={item.value}>{item.name}</Option>
        ))}
      </Select>
    </div>
  );
}
