import React from 'react';
import { useTranslation } from 'react-i18next';

import { Space } from 'antd';

import { translate } from '@translations/Translater';

import { authMessages } from '@containers/Auth/message';

import './styles.less';

export default function LegalLinks() {
    const { t } = useTranslation();

    return (
        <div className="terms_container">
            <Space direction="vertical">
                <span>
                    {translate(t, authMessages.termsAndConditionText)}
                    <a href={translate(t, authMessages.termsUrl)} rel="noreferrer" target="_blank">
                        {translate(t, authMessages.terms)}
                    </a>
                    {translate(t, authMessages.and)}
                    <a href={translate(t, authMessages.privacyPolicyUrl)} rel="noreferrer" target="_blank">
                        {translate(t, authMessages.privacyPolicy)}
                    </a>
                </span>
            </Space>
        </div>
    );
}
