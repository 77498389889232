import { Language } from '@models/Language';
import { getLangaugesSelector } from '@redux/common/selectors';
import { Logger } from '@utils/Logger';
import { LocalStorage, StorageKeys } from '@utils/LocalStorage';
import { DataUtility } from '@utils/DataUtility';
import { i18n } from '@translations/i18n';
import { UserLocalDetailsByIpService } from '@webServices/UserLocalDetailsByIpService';
import { WebStoreProviderService } from '@store/WebStoreProviderService';

const store = WebStoreProviderService.getStore();
// default language id as 1, for english
export const DEFAULT_LANG: {id: string | number, code: string} = { id: 1, code: 'en' };

class LanguageService {
  private extractPrimeLanguage = (languages: string): string => {
    let primeLang = DEFAULT_LANG.code;
    const arrLang = languages.split(',');
    if (arrLang.length > 0) {
      primeLang = arrLang[0].split('-')[0];
    }
    return primeLang;
  };

  public storeGuestUserLanguage = async () => {
    let lang = DEFAULT_LANG;
    try {
      const localeDetails = await UserLocalDetailsByIpService.getInstance();
      const languages =  localeDetails.getLanguage();
      const nenLangs: Language[] = getLangaugesSelector(
        store.getState(),
      );
      const guestUserLangCode = this.extractPrimeLanguage(languages);
      Logger.log(
        '[LanguageService - storeGuestUserLanguage()]',
        {
            languages,
            nenLangs,
            guestUserLangCode,
        }
      );
      if (!DataUtility.isEmpty(nenLangs)) {
        const found = nenLangs.find(
          (item: Language) => item.getLanguageCode() === guestUserLangCode,
        );
        if (found) {
          lang = { id: found.getId(), code: found.getLanguageCode() };
        }
      }
    } catch (e) {
      Logger.error('[LanguageUtils - storeGuestUserLanguage()] Error',{ error: e.message });
    }
    // store guest user lang id
    Logger.info(
      '[LanguageUtils - storeGuestUserLanguage()] storing guest language:' + lang
    );
    await LocalStorage.set(StorageKeys.GUEST_USER_LANG, lang);
    this.changeLanguage(lang.code);
  };

  public setDefaultLanguage = async () => {
    Logger.log(
      '[LanguageUtils - setDefaultLanguage()] Setting default language' + DEFAULT_LANG
    );
      this.changeLanguage(DEFAULT_LANG.code);
      await LocalStorage.set(StorageKeys.GUEST_USER_LANG, DEFAULT_LANG);
  };

  public changeLanguage(langCode: string) {
    if(i18n)
      i18n.changeLanguage(langCode);
  }


}

const languageService = new LanguageService();
export { languageService as LanguageService };
