import { JsonProperty } from "json-object-mapper";
export interface ILoginRequestModel {
    userName: string;
    password: string;
}

export interface IPreferences {
    isProfileCompleted: boolean | string;
    isEmailVerified: string;
    isPhoneVerified: string;
    tempPasswordGenerated: string;
    cookieConsents: string;
    preferredLanguage: string;
}

export interface IUserModel {
    accessToken: string;
    refreshToken: string;
    preferences: IPreferences;
    role: string[];
    isTermsAndConditionsAccepted: boolean;
}
export class LoginModel {
    @JsonProperty({ name: 'accessToken', required: true })
    public accessToken: string = ""

    @JsonProperty({ name: 'preferences', required: true })
    public preferences: any = {}

    @JsonProperty({ name: 'refreshToken', required: true })
    public refreshToken: string = ""

    @JsonProperty({ name: 'role', required: true })
    public role: string[] = []

    public getAccessToken(): string {
        return this.accessToken;
    }

    public setAccessToken(accessToken: string){
        this.accessToken = accessToken;
    }


    public getPreferences(): any {
        return this.preferences;
    }

    public getRefreshToken(): string {
        return this.refreshToken;
    }

    public setRefreshToken(refreshToken: string){
        this.refreshToken = refreshToken;
    }

    public getRole = (): string => {
        if ( this.role && this.role.length > 0 ) {
            return this.role[0];
        }
        return '';
    }
}
