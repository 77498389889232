import { JsonProperty } from "json-object-mapper";

import { ITags, IInterviewQuestions } from "@common/redux/interview/interface";

export class PrepareModel {

  @JsonProperty({ name: "tags", required: false })
  private tags: ITags[] = [];

  @JsonProperty({ name: "interviewQuestions", required: false })
  private interviewQuestions: IInterviewQuestions = null;

  public getTags(): ITags[] {
    return this.tags;
  }

  public getInterviewQuestions(): IInterviewQuestions {
    return this.interviewQuestions;
  }
}
