import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";

import { getErrorCodes } from "@utils/ErrorMessageUtils";

import { CommonRepository } from "@repositories/CommonRepository";

import { Language } from "@common/domain/models/Language";
import { LanguageName, IFetchLanguageNamesAction } from "@common/domain/models/LanguageName";

import { supportedFrontEndLanguageCodes } from "../constants";
import {
  commonActions,
  fetchLanguagesFailure,
  fetchLanguagesSuccess,
  fetchTagsFail,
  fetchTagsSuccess,
  fetchTagsLanguagesSuccess,
  fetchTagsLanguagesFailure,
  fetchLanguageNamesSuccess,
  fetchLanguageNamesFailure,
  fetchTagsInterviewPrepLanguagesSuccess, 
  fetchTagsInterviewPrepLanguagesFailure,
  IFetchAssessmentCompetencyAction,
  fetchAssessmentCompetencySuccess,
  fetchAssessmentCompetencyFail,
  fetchProductToursSuccess,
  fetchProductToursFail,
  IFetchProductTourAction,
  fetchSectorListSuccess,
  fetchSectorListFail,
  fetchDegreeListSuccess,
  fetchDegreeListFail
} from "./actions";
import { IFetchTagsAction, ITag } from "./interface";

export function* fetchLanguagesSaga() {
  try {
    const languages: Language[] = yield call(CommonRepository.fetchLanguages);
    const updatedLanguages: Language[] = languages.filter((language: Language) => supportedFrontEndLanguageCodes.includes(language.languageCode));
    yield put(fetchLanguagesSuccess(updatedLanguages));
  } catch (e) {
    const error = getErrorCodes(e);
    yield put(fetchLanguagesFailure(error));
  }
}

export function* fetchTagsSaga(action: IFetchTagsAction) {
  try {
      const tags = yield call(CommonRepository.fetchTags, action.payload);
      let tagValues: ITag[] = [];
      tags?.map((tag) => {
          tag?.tags?.map((contentTag) => {
              if (contentTag?.isGrouped) {
                  const groupObj = contentTag?.groups?.map((group) => {
                      return {
                          ...group,
                          key: contentTag?.key,
                          isGrouped: contentTag?.isGrouped
                      }
                  });
                  tagValues = [...tagValues, ...groupObj];
              } else {
                  const groupObj = contentTag?.values?.map((value) => {
                      return {
                          groupName: value,
                          groupValues: [value],
                          key: contentTag?.key,
                          isGrouped: contentTag?.isGrouped
                      }
                  });
                  tagValues = [...groupObj, ...tagValues];
              }
          });
      });
      yield put(fetchTagsSuccess(tagValues));
  } catch (e) {
      const error = getErrorCodes(e);
      yield put(fetchTagsFail(error));
  }
}

export function* fetchTagsLanguagesSaga(action: IFetchTagsAction) {
  try {
    const response = yield call(CommonRepository.fetchTags, action.payload);
    yield put(fetchTagsLanguagesSuccess(response[0].tags[0].groups));
  } catch (e) {
    yield put(fetchTagsLanguagesFailure(e.message));
  }
};

export function* fetchLanguageNamesSaga(action: IFetchLanguageNamesAction) {
  try {
    const languageNames: LanguageName[] = yield call(CommonRepository.fetchLanguageNames, action.payload);
    yield put(fetchLanguageNamesSuccess(languageNames));
  } catch (e) {
    const error = getErrorCodes(e);
    yield put(fetchLanguageNamesFailure(error));
  }
}

export function* fetchTagsInterviewPrepLanguagesSaga(action: IFetchTagsAction) {
  try {
    const response = yield call(CommonRepository.fetchTags, action.payload);
    yield put(fetchTagsInterviewPrepLanguagesSuccess(response[0].tags[0].values));
  } catch (e) {
    yield put(fetchTagsInterviewPrepLanguagesFailure(e.message));
  }
};


export function* fetchAssessmentCompetencyListSaga(action: IFetchAssessmentCompetencyAction) {
  try {
    const result = yield call(CommonRepository.fetchAssessmentCompetency, action.payload);
    yield put(fetchAssessmentCompetencySuccess(result));
  } catch (error) {
    yield put(fetchAssessmentCompetencyFail(error.message));
  }
}

export function* fetchProductTourSaga(action: IFetchProductTourAction) {
  try {
    const result = yield call(CommonRepository.fetchTags, action.payload);
    const values = result[0].tags[0].values;  
    yield put(fetchProductToursSuccess(values));
  } catch (error) {
    yield put(fetchProductToursFail(error.message));
  }
}
export function* fetchSectorListSaga(action: IFetchTagsAction) {
  try {
    const result = yield call(CommonRepository.fetchTags, action.payload);
    yield put(fetchSectorListSuccess(result));
  } catch (error) {
    yield put(fetchSectorListFail(error.message));
  }
}

export function* fetchDegreeListSaga(action: IFetchTagsAction) {
  try {
    const result = yield call(CommonRepository.fetchTags, action.payload);
    yield put(fetchDegreeListSuccess(result));
  } catch (error) {
    yield put(fetchDegreeListFail(error.message));
  }
}

export function* watchCommons(): Generator<ForkEffect> {
  yield takeLatest(commonActions.FETCH_LANGUAGES, fetchLanguagesSaga);
  yield takeLatest(commonActions.FETCH_TAGS, fetchTagsSaga);
  yield takeLatest(commonActions.FETCH_TAGS_LANGUAGES, fetchTagsLanguagesSaga);
  yield takeLatest(commonActions.FETCH_LANGUAGES_NAMES, fetchLanguageNamesSaga);
  yield takeLatest(commonActions.FETCH_TAGS_INTERVIEW_PREP_LANGUAGES, fetchTagsInterviewPrepLanguagesSaga);
  yield takeLatest(commonActions.FETCH_ASSESSMENT_COMPETENCYLIST, fetchAssessmentCompetencyListSaga);
  yield takeLatest(commonActions.FETCH_PRODUCT_TOURS, fetchProductTourSaga);
  yield takeLatest(commonActions.FETCH_SECTOR_LIST, fetchSectorListSaga);
  yield takeLatest(commonActions.FETCH_DEGREE_LIST, fetchDegreeListSaga);
}
