import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
    NotificationActions,
    getNotificationsSuccess,
    getNotificationsFailure,
    getNotificationsCountSuccess,
    getNotificationsCountFailure,
    setAllNotificationSeenSuccess,
    setAllNotificationSeenFailure,
    readNotificationByIdSuccess,
    readNotificationByIdFailure,
    setTotalNotificationCount,
} from '@redux/notifications/actions';
import { NotificationRepository } from '@repositories/NotificationRepository';

interface IAction {
    type: string;
    payload: { userId: number, page: number, limit: number, userEcosystemId: number };
}

interface INCAction {
    type: string;
    payload: { userId: number };
}

interface IRNAction {
    type: string;
    payload: { notifId: string };
}

export function* fetchNotificationsSaga(action: IAction): any {
    try {
        const { userId, page, limit } = action.payload;
        const notificationInfo = yield call(NotificationRepository.getNotifications, userId, page, limit);
        yield put(getNotificationsSuccess(page, limit, notificationInfo.notificationData));
        yield put(setTotalNotificationCount(notificationInfo.totalCount));
    } catch (e) {
        yield put(getNotificationsFailure(e.message));
    }
}

export function* getNotificationCountSaga(action: INCAction): any {
    try {
        const { userId } = action.payload;
        const notificationCount = yield call(NotificationRepository.getNotificationsCount, userId);
        yield put(getNotificationsCountSuccess(notificationCount));
    } catch (e) {
        yield put(getNotificationsCountFailure(e.message));
    }
}

export function* setAllNotificationSeenSaga(action: INCAction): any {
    try {
        const { userId } = action.payload;
        yield call(NotificationRepository.seenAllNotifications, userId);
        yield put(setAllNotificationSeenSuccess(true));
    } catch (e) {
        yield put(setAllNotificationSeenFailure(e.message));
    }
}

export function* readNotificationByIdSaga(action: IRNAction): any {
    try {
        const { notifId } = action.payload;
        yield call(NotificationRepository.readNotificationById, notifId);
        yield put(readNotificationByIdSuccess(notifId));
    } catch (e) {
        yield put(readNotificationByIdFailure(e.message));
    }
}

export function* watchNotifications(): any {
    yield takeLatest(NotificationActions.FETCH_NOTIFICATION, fetchNotificationsSaga);
    yield takeLatest(NotificationActions.GET_NOTIFICATION_COUNT, getNotificationCountSaga);
    yield takeLatest(NotificationActions.SEEN_NOTIFICATION_ALL, setAllNotificationSeenSaga);
    yield takeEvery(NotificationActions.READ_NOTIFICATION_BY_ID, readNotificationByIdSaga);
}
