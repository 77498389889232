import { GUEST_USER_DETAILS_BY_IP_API, Platform } from '@constants/config';

import axios from 'axios';
import dayjs from 'dayjs';
import { detect } from 'detect-browser';
import { v1 as uuidv1 } from 'uuid';
import packageFile from '../../package.json';

export interface IDetailsByIP {
  ip: string;
  city: string;
  region: string;
  region_code: string;
  country: string;
  country_code: string;
  country_code_iso3: string;
  country_capital: string;
  country_tld: string;
  country_name: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezoneName: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  country_area: number;
  country_population: number;
  asn: string;
  org: string;
}

export const getAppVersion = (): string => packageFile.version;

export const getDevicePlatform = (): string => 'web';

export const getAppName = (): string => packageFile.name;

export const getTimeZone = (): string => {
    const offset = new Date().getTimezoneOffset();
    const formatted = -(offset / 60);
    return formatted.toString();
};

export const getBrowserName = () => detect()?.name || '';

export const getOS = () => detect()?.os || '';

export const getBrowserVersion = () => detect()?.version || '';

export const getIPAddress = async () => {
    try {
        const response: { data: IDetailsByIP } = await axios.get(
            GUEST_USER_DETAILS_BY_IP_API,
        );
        return response.data.ip || '';
    } catch (e) {
        return '';
    }
};

export const getDeviceScreenType = () => {
  // width Specification
  // * xs (for phones - screens less than 768px wide)
  // * sm (for tablets - screens equal to or greater than 768px wide)
  // * md (for small laptops - screens equal to or greater than 992px wide)
  // * lg (for laptops and desktops - screens equal to or greater than 1200px wide)
  // Reference - https://www.w3schools.com/bootstrap/bootstrap_grid_small.asp
  const deviceWidth = window.innerWidth;
  if (deviceWidth < 768) return 'xs';
  else if (deviceWidth >= 768 && deviceWidth < 992) return 'sm';
  else if (deviceWidth >= 992 && deviceWidth < 1200) return 'md';
  else if (deviceWidth >= 1200) return 'lg';
};

export const getClientTraceId = () => {
  return uuidv1();
};

export const getClientSessionId = () => {

  const clientSessonData: {
    clientSessonId: string;
    clientSessonIdExpiryTime: string;
  } = null;

  if (
    !clientSessonData ||
    dayjs().diff(dayjs(clientSessonData.clientSessonIdExpiryTime)) >= 0
  ) {
    let clientSessonId = uuidv1();
    let clientSessonIdExpiryTime = dayjs()
      .add(24, 'hours')
      ?.toISOString();
    // SessionStorage.set(SessionStorageKeys.CLIENT_SESSION_DATA, {
    //   clientSessonId: clientSessonId,
    //   clientSessonIdExpiryTime: clientSessonIdExpiryTime,
    // });

    return clientSessonId;
  }

  let { clientSessonId } = clientSessonData;
  return clientSessonId;
};

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
      return Platform.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
      return Platform.IOS;
  }

  return Platform.ANDROID;
}
