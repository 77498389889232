import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

interface IProps {
  children?: React.ReactNode;
  className?: any;
  code?: boolean;
  copyable?: boolean | { text: string; onCopy: () => void };
  delete?: boolean;
  disabled?: boolean;
  editable?: boolean;
  ellipsis?: boolean | { rows: number, expandable: boolean, onExpand?: (event: any) => void, onEllipsis?: (ellipsis: any) => void };
  rows?: number;
  expandable?: boolean;
  suffix?: string;
  level?: number;
  mark?: boolean;
  underline?: boolean;
  onChange?: () => void;
  type?: 'secondary' | 'warning' | 'danger';
}

export default function(props: IProps) {
  const { children, className, ...rest } = props;
  const style = ["wf_card_title", className].join(' ');
  return <Title {...rest} className={style} level={1}>{children}</Title>;
};
