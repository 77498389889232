import { BatchStudent } from '@common/domain/models/BatchStudent';

import { SearchActions } from '@redux/connections/actions';
import { IConnectionState } from '@redux/connections/interface';

export const initialState: IConnectionState = {
    fetchStudentFacultyOnBatchId: [],
    loaders: {
        fetchStudentFacultyOnBatchId: true,
    },
    error: {
        fetchStudentFacultyOnBatchId: null,
    }
}

export const connectionReducer = (state: IConnectionState = initialState, action: { type: string, payload: BatchStudent[] }) => {
    switch (action.type) {
        case SearchActions.FETCH_STUDENT_FACULTY_ON_BATCH_ID:
            return {
                ...state,
                loaders: { ...state.loaders, fetchStudentFacultyOnBatchId: true },
                error: { ...state.error, fetchStudentFacultyOnBatchId: "" },
            }
        case SearchActions.FETCH_STUDENT_FACULTY_ON_BATCH_ID_SUCCESS:
            return {
                ...state,
                fetchStudentFacultyOnBatchId: action.payload,
                loaders: { ...state.loaders, fetchStudentFacultyOnBatchId: false },
            }
        case SearchActions.FETCH_STUDENT_FACULTY_ON_BATCH_ID_FAILURE:
            return {
                ...state,
                loaders: { ...state.loaders, fetchStudentFacultyOnBatchId: false },
                error: { ...state.error, fetchStudentFacultyOnBatchId: action.payload },
            }
        default:
            return state;
    }
}
