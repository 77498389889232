export enum FontFamilyVariant {
  light = 'light',
  regular = 'regular',
  medium = 'medium',
  bold = 'bold',
  semibold = 'semibold',
  black = 'black',
  regular1 = 'regular1',
  medium1 = 'medium1',
  bold1 = 'bold1',
  bold2 = 'bold2',
  bold3 = 'bold3',
  medium2 = 'medium2',
  bold4 = 'bold4',

  pageTitle = 'page-title',
  subtitle1 = 'subtitle1',
  subtitle2 = 'subtitle2',
  subtitle3 = 'subtitle3',
  subtitle4 = 'subtitle4',
  subtitle5 = 'subtitle5',

  cardTitleXLarge = "card-title-x-large",
  cardTitleLarge = "card-title-large",
  cardTitleMedium = "card-title-medium",
  cardTitleSmall = "card-title-small",
  cardTitleXSmall = "card-title-x-small",

  tabLarge = "tab-large",
  tabMedium = "tab-medium",
  tabRegular = "tab-regular",
  tabSmall = "tab-small",
  tabXSmall = "tab-x-small",

  tagLarge = "tag-large",
  tagMedium = "tag-medium",
  tagRegular = "tag-regular",
  tagSmall = "tag-small",
  tagXSmall = "tag-x-small",

  body3XLarge = "body-3x-large",
  body2XLarge = "body-2x-large",
  bodyXLarge = "body-x-large",
  bodyLarge = "body-large",
  bodyMedium = "body-medium",
  bodyRegular = "body-regular",
  bodySmall = "body-small",
  bodyXSmall = "body-x-small",
  body2XSmall = "body-2x-small",
  body3XSmall = "body-3x-small",

  banner3XLarge = "banner-3x-large",
  banner2XLarge = "banner-2x-large",
  bannerXLarge = "banner-x-large",
  bannerMedium = "banner-medium",
  bannerRegular = "banner-regular",
  bannerSmall = "banner-small",
  bannerXSmall = "banner-x-small",
  banner2XSmall = "banner-2x-small",
  banner3XSmall = "banner-3x-small",

  label3XLarge = "label-3x-large",
  label2XLarge = "label-2x-large",
  labelXLarge = "label-x-large",
  labelLarge = "label-large",
  labelMedium = "label-medium",
  labelRegular = "label-regular",
  labelXSmall = "label-x-small",
  label2XSmall = "label-2x-small",
  label3XSmall = "label-3x-small",

  miscellaneousXLarge = "miscellaneous-x-large",
  miscellaneousLarge = "miscellaneous-large",
  miscellaneousMedium = "miscellaneous-medium",
  miscellaneousSmall = "miscellaneous-small",
  miscellaneousXSmall = "miscellaneous-x-small",
}
