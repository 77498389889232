import { LocalStorage, StorageKeys } from "@utils/LocalStorage.ts";

import { AuthRepository } from "@repositories/AuthRepository";
import { IIamRepository } from "@repositories/interfaces";

import { LoginModel } from "@models/Login";

import { IAuthHelper } from '@network/interfaces';
import { ApiClient } from "@common/network/client";


class AuthenticationService implements IAuthHelper {
  private userRepository: IIamRepository;

  constructor(userRepository: IIamRepository) {
    this.userRepository = userRepository;
  }

  public getUser = async (): Promise<LoginModel | null> => {
    const user = await LocalStorage.get<LoginModel>(StorageKeys.USER);
    return user;
  };

  public setUser = async (user: LoginModel) => {
    await LocalStorage.set<LoginModel>(StorageKeys.USER, user);
  };

  public getAccessToken = async (): Promise<string> => {
    const userToken = await localStorage.getItem(StorageKeys.ACCESS_TOKEN);
    return userToken ? userToken : '';
  };

  public setAccessToken = async (userToken: string) => {
    await localStorage.setItem(StorageKeys.ACCESS_TOKEN, userToken);
  };

  public getRefreshToken = async (): Promise<string> => {
    const userRefreshToken = await localStorage.getItem(StorageKeys.REFRESH_TOKEN);
    return userRefreshToken ? userRefreshToken : '';
  };

  public setRefreshToken = async (userRefreshToken: string) => {
    await localStorage.setItem(StorageKeys.REFRESH_TOKEN, userRefreshToken);
  };

  public refreshTokens = async (): Promise<void> => {
   //todo refresh token
   const userRefreshToken = await this.getRefreshToken();
   let user: any  = await this.getUser();
   user = JSON.parse(user);
   if ( !userRefreshToken || !user) {
     return;
   }

    const userTokens = await AuthRepository.refreshToken(userRefreshToken);
    await this.setAccessToken(userTokens.accessToken);
    await this.setRefreshToken(userTokens.refreshToken);

    if(user instanceof LoginModel) {
      user.setAccessToken(userTokens.accessToken);
      user.setRefreshToken(userTokens.refreshToken);
    } else {
      //@ts-ignore
      user.accessToken = userTokens.accessToken;
      //@ts-ignore
      user.refreshToken = userTokens.refreshToken;
    }
    await this.setUser(user);

  };

}

const authenticationService = new AuthenticationService(AuthRepository);
ApiClient.setAuthHelper(authenticationService);
export { authenticationService as AuthenticationService };
