import { Article, IArticle, IContentTag } from '@common/domain/models/Article';

import { contentActions, ISetSearchPageLocation } from './actions';

export interface IContentState {
    byId: {},
    contentTypeMap: {},
    tags: IContentTag[],
    count: {},
    tagsMap: {},
    searchPageLocation:ISetSearchPageLocation,
    isLastPage:{},
    suggestedArticle:Article[],
    loaders: {
        articles: boolean;
        articleDetails: boolean;
        tags: boolean;
        like:boolean;
        unLike:boolean;
        suggestedArticle:boolean;
    };

    errors: {
        articles: string;
        articleDetails: string;
        tags: string;
        like:string;
        unLike:string;
        suggestedArticle:string;
    };
}

export const initialState = {
    byId: {},
    contentTypeMap: {},
    tags: [],
    count: {},
    tagsMap: {},
    searchPageLocation: {},
    isLastPage: {},
    suggestedArticle:[],
    loaders: {
        articles: false,
        articleDetails: false,
        tags: false,
        like: false,
        unLike: false,
        suggestedArticle: false,
    },
    errors: {
        articles: '',
        articleDetails: '',
        tags: '',
        like: '',
        unLike: '',
        suggestedArticle: ''
    },
};

export const contentReducer = (
    state: IContentState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
    case contentActions.FETCH_ARTICLES:
        return {
            ...state,
            loaders: { ...state.loaders, [`${action.payload.type}_articles`]: true },
        };

    case contentActions.FETCH_ARTICLES_SUCCESS:
        createMap(action.payload.content, state.byId);
        if (action.payload.page === 1) {
            return {
                ...state,
                contentTypeMap: {
                    ...state.contentTypeMap,
                    [action.payload.type]: action.payload.content,
                },
                count: {
                    ...state.count,
                    [action.payload.type]: action.payload.total,
                },
                isLastPage: { ...state.isLastPage, [action.payload.type]: action.payload.isLastpage },
                loaders: { ...state.loaders, [`${action.payload.type}_articles`]: false },
                errors: { ...state.errors, [`${action.payload.type}_articles`]: '' },
            };
        } if (action.payload.page !== 1) {
            return {
                ...state,
                contentTypeMap: {
                    ...state.contentTypeMap,
                    [action.payload.type]: [...state.contentTypeMap[action.payload.type], ...action.payload.content],
                },
                count: {
                    ...state.count,
                    [action.payload.type]: action.payload.total,
                },
                isLastPage: { ...state.isLastPage, [action.payload.type]: action.payload.isLastpage },
                loaders: { ...state.loaders, [`${action.payload.type}_articles`]: false },
                errors: { ...state.errors, [`${action.payload.type}_articles`]: '' },
            };
        }
    case contentActions.FETCH_ARTICLES_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, [`${action.payload.type}_articles`]: false },
            errors: { ...state.errors, [`${action.payload.type}_articles`]: action.payload },
        };

        // fetch articles by ID
    case contentActions.FETCH_ARTICLE_DETAILS:
        return {
            ...state,
            loaders: { ...state.loaders, articleDetails: true },
            errors: { ...state.errors, articleDetails: '' },
        };
    case contentActions.FETCH_ARTICLE_DETAILS_SUCCESS:
        createMap([action.payload], state.byId);
        return {
            ...state,
            loaders: { ...state.loaders, articleDetails: false },
            errors: { ...state.errors, articleDetails: '' },
        };
    case contentActions.FETCH_ARTICLE_DETAILS_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, articleDetails: false },
            errors: { ...state.errors, articleDetails: action.payload },
        };

        // fetch tags
    case contentActions.FETCH_TAGS:
        return {
            ...state,
            loaders: { ...state.loaders, tags: true },
        };
    case contentActions.FETCH_TAGS_SUCCESS:
        createTagMap(action.payload, state.tagsMap);
        return {
            ...state,
            tags: action.payload,
            loaders: { ...state.loaders, tags: false },
            errors: { ...state.errors, tags: '' },
        };
    case contentActions.FETCH_TAGS_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, tags: false },
            errors: { ...state.errors, tags: action.payload },
        };

    case contentActions.LIKE_ARTICLE:
        return {
            ...state,
            loaders: { ...state.loaders, like: true },
        };
    case contentActions.LIKE_ARTICLE_SUCCESS:
        updateLikeInMap(action.payload, state.byId);
        return {
            ...state,
            loaders: { ...state.loaders, like: false },
            errors: { ...state.errors, like: '' },
        };
    case contentActions.LIKE_ARTICLE_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, like: false },
            errors: { ...state.errors, like: action.payload },
        };

    case contentActions.UNLIKE_ARTICLE:
        return {
            ...state,
            loaders: { ...state.loaders, unLike: true },
        };
    case contentActions.UNLIKE_ARTICLE_SUCCESS:
        updateUnLikeInMap(action.payload, state.byId);
        return {
            ...state,
            loaders: { ...state.loaders, unLike: false },
            errors: { ...state.errors, unLike: '' },
        };
    case contentActions.UNLIKE_ARTICLE_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, unLike: false },
            errors: { ...state.errors, unLike: action.payload },
        };
    case contentActions.FETCH_SUGGESTED_ARTICLES:
        return {
            ...state,
            loaders: { ...state.loaders, suggestedArticle: true },
        };
    case contentActions.FETCH_SUGGESTED_ARTICLES_SUCCESS:
        return {
            ...state,
            suggestedArticle:action.payload.content,
            loaders: { ...state.loaders, suggestedArticle: false },
        };
    case contentActions.FETCH_SUGGESTED_ARTICLES_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, suggestedArticle: false },
            errors: { ...state.errors, suggestedArticle: action.payload }
        };
    case contentActions.SET_SEARCH_PAGE_LOCATION:
        return {
            ...state,
            searchPageLocation: action.payload
        };
    default:
        return state;
    }
};

const createMap = (articles: Article[], existingMap: {}) => {
    articles.forEach((article: Article) => {
        if (!existingMap[article.getShortId()]) {
            existingMap[article.getShortId()] = article;
        }
    });
};

const createTagMap = (tags: IContentTag[], existingMap: {}) => {
    tags.forEach((tag: IContentTag) => {
        if (!existingMap[tag?.groupName] && tag?.isGrouped) {
            existingMap[tag?.groupName] = tag.groupValues;
        }
    });
};

const updateLikeInMap = (slugId:string, existingMap: {}) => {
    existingMap[slugId].isLiked = true;
    existingMap[slugId].likesCount += 1;
};

const updateUnLikeInMap = (slugId:string, existingMap: {}) => {
    existingMap[slugId].isLiked = false;
    existingMap[slugId].likesCount -= 1;
};
