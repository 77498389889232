import { call, put, takeEvery } from "redux-saga/effects";
import { SkillsScoreRepository } from "../../domain/repositories/SkillsScoreRepository";
import {
  skillScoresActions,
  fetchSkillsScoresSuccess,
  fetchSkillsScoresFailure,
  IFetchSkillsScoreAction,
} from "./actions";

export function* fetchSkillsScoresSaga(action: IFetchSkillsScoreAction): any {
  try {
    const { userId, courseId, courseLmsId } = action.payload;
    const states: any[] = yield call(
      SkillsScoreRepository.fetchSkillScores,
      userId,
      courseLmsId
    );
    yield put(fetchSkillsScoresSuccess(states));
  } catch (e) {
    yield put(fetchSkillsScoresFailure(e.message));
  }
}

export function* watchSkillsScores(): any {
  yield takeEvery(
    skillScoresActions.FETCH_SKILLS_SCORES,
    fetchSkillsScoresSaga
  );
}
