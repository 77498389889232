import { IFluxStandardAction } from '@store/webInterfaces';

const actionTypePrefix = 'root/';

export const rootActions = {
    FETCH_APP_DATA: `${actionTypePrefix}FETCH_APP_DATA`,
    FETCH_APP_DATA_SUCCESS: `${actionTypePrefix}FETCH_APP_DATA_SUCCESS`,
    FETCH_APP_DATA_FAIL: `${actionTypePrefix}FETCH_APP_DATA_FAIL`
};

export const fetchAppData = (): IFluxStandardAction => ({
    type: rootActions.FETCH_APP_DATA,
});

export const fetchAppDataSuccess = (): { type: string } => ({
    type: rootActions.FETCH_APP_DATA_SUCCESS
});

export const fetchAppDataFailed = (error: string): IFluxStandardAction<string> => ({
    type: rootActions.FETCH_APP_DATA_FAIL,
    payload: error
});
