import { JsonProperty } from "json-object-mapper";

export interface ILanguageNamePayload {
    languageCodes: string;
}


export interface IFetchLanguageNamesAction {
    type?: string;
    payload?: ILanguageNamePayload;
}

export interface ILanguageName {
  id: string;
  languageCode: string;
  languageName: string;
  displayName: string;
  languageScript: string;
  countriesSupported: string[];
  isTranslatorNeeded: boolean;
  evaluationHeaders: string[];
}

export class LanguageName {
  @JsonProperty({ name: "_id", required: true })
  public id: string = "";

  @JsonProperty({ name: "languageName", required: true })
  public languageName: string = "";

  @JsonProperty({ name: "languageCode", required: true })
  public languageCode: string = "";

  @JsonProperty({ name: "displayName", required: true })
  public displayName: string = "";

  @JsonProperty({ name: "languageScript", required: true })
  public languageScript: string = "";

  @JsonProperty({ name: "countriesSupported", required: true })
  public countriesSupported: string[] = [];

  @JsonProperty({ name: "isTranslatorNeeded", required: false })
  public deletedAt: boolean;

  @JsonProperty({ name: "evaluationHeaders", required: true })
  public evaluationHeaders: string[] = [];

  public getId(): string {
    return this.id;
  }

  public getLanguageCode(): string {
    return this.languageCode;
  }

  public getLanguageName(): string {
    return this.languageName;
  }
}
