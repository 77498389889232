import React, { ReactNode } from "react";
import "./styles.less";
import { FontFamilyVariant } from "../../utils"

interface IProps {
  variant: FontFamilyVariant;
  text: string | ReactNode;
  className?: string;
}

const LabelText = (props: IProps) => {
  const { text, variant, className } = props;

  const customStyle = className ? `${variant} ${className}` : variant;

  switch (variant) {
    case FontFamilyVariant.label3XLarge:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.label2XLarge:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.labelXLarge:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.labelLarge:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.labelMedium:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.labelRegular:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.labelXSmall:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.label2XSmall:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;
    case FontFamilyVariant.label3XSmall:
      return <p className={`typography-text ${customStyle}`}>{text}</p>;

  }
}

export default LabelText;
