import { JsonProperty } from "json-object-mapper";

export interface ICity {
  id: number;
  countryId: number;
  name: string;
  otherName: string;
  isPopular: boolean;
  displayName: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  timezoneId: number;
}
export class City {
  @JsonProperty({ name: "id", required: true })
  public id: number = 0;

  @JsonProperty({ name: "countryId", required: true })
  public countryId: number = 0;

  @JsonProperty({ name: "name", required: true })
  public name: string = "";

  @JsonProperty({ name: "otherName", required: false })
  public otherName: string = "";

  @JsonProperty({ name: "isPopular", required: true })
  public isPopular: boolean = false;

  @JsonProperty({ name: "displayName", required: true })
  public displayName: string = "";

  @JsonProperty({ name: "createdAt", required: true })
  public createdAt: string = "";

  @JsonProperty({ name: "updatedAt", required: true })
  public updatedAt: string = "";

  @JsonProperty({ name: "deletedAt", required: false })
  public deletedAt: string = "";

  @JsonProperty({ name: "timezoneId", required: true })
  public timezoneId: number = 0;

  public getId(): number {
    return this.id;
  }

  public getCountryId(): number {
    return this.countryId;
  }

  public getName(): string {
    return this.name;
  }

  public getDisplayName(): string {
    return this.displayName;
  }

  public getCreatedAt(): string {
    return this.createdAt;
  }

  public getUpdatedAt(): string {
    return this.updatedAt;
  }

  public getDeletedAt(): string {
    return this.deletedAt;
  }
}
