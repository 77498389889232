import Config from '@config/index';
import { platform } from '@constants/config';
import { DataUtility } from '@utils/DataUtility';

export class CRMService {
  public static init() {
    // @ts-ignore
    if (window.$crisp === undefined) {
      // Must be call before any other $crisp method
      // https://help.crisp.chat/en/article/how-to-use-dollarcrisp-javascript-sdk-10ud15y/#1-disable-warnings-amp-errors
      // @ts-ignore
      window.$crisp = [['safe', false]];
    }
    const scriptUrl = 'https://client.crisp.chat/l.js';
    const scripts = document.querySelector(`script[src='${scriptUrl}']`);
    if (scripts === null) {
      // CRISP_WEBSITE_ID, CRISP_TOKEN_ID and CRISP_RUNTIME_CONFIG
      // must be declared before inserting the script
      // https://help.crisp.chat/en/article/how-to-restore-chat-sessions-with-a-token-c32v4t/
      // https://help.crisp.chat/en/article/how-to-use-crisp-with-reactjs-fe0eyz/

      // We are good start Crisp
      // @ts-ignore
      window.CRISP_COOKIE_DOMAIN = platform.WITP ? '*.witp.wfglobal.org' : "*.opportunity.wfglobal.org'";
      // @ts-ignore
      window.CRISP_WEBSITE_ID = Config.CRISP_WEB_CONFIG.websiteId;
      // @ts-ignore
      window.CRISP_RUNTIME_CONFIG = {
        session_merge : true
      };

      const script = document.createElement('script');
      script.src = scriptUrl;
      script.async = true;
      document.head.appendChild(script);
    }
  }

  public static setAttributes = (method: string, propsDict: object) => {
    if (!DataUtility.isEmpty(propsDict)) {
      Object.entries(propsDict).forEach(([key, value]) => {
        // @ts-ignore
        return window?.$crisp?.push([method, key, value]);
      });
    }
  };

  public static doOperation = (method: string, arg: string) => {
    // @ts-ignore
    return window?.$crisp?.push([method, arg]);
  };
}