import { rootActions } from './actions';
import { IRootState } from '@redux/root/interfaces';

export const initialState: IRootState = {
    loaders: {
        appData: false,
    },
    errors: {
        appData: '',
    },
};

export const rootReducer = (
    state: IRootState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
    case rootActions.FETCH_APP_DATA:
        return {
            ...state,
            loaders: { ...state.loaders, appData: true },
            errors: { ...state.errors, appData: '' },
        };
    case rootActions.FETCH_APP_DATA_SUCCESS:
        return {
            ...state,
            loaders: { ...state.loaders, appData: false },
        };
    case rootActions.FETCH_APP_DATA_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, appData: false },
            errors: { ...state.errors, appData: action.payload },
        };

    default:
        return state;
    }
};
