import { fetchForumListByUserIdPayload, fetchForumListByUserIdAction, fetchForumDetailsByForumIdPayload, fetchForumDetailsByForumIdAction } from "./interface";
import {
    ProfileModel,
    ISetFilterData,
    IProfileLocation
} from "@common/domain/models/Profile";

const actionTypePrefix = "MENTOR";

export const mentorActions = {
    FETCH_MENTOR_LIST_START: `${actionTypePrefix}/FETCH_MENTOR_LIST_START`,
    FETCH_MENTOR_LIST_SUCCESS: `${actionTypePrefix}/FETCH_MENTOR_LIST_SUCCESS`,
    FETCH_MENTOR_LIST_FAIL: `${actionTypePrefix}/FETCH_MENTOR_LIST_FAIL`,

    FETCH_FILTERED_MENTOR_LIST: `${actionTypePrefix}/FETCH_FILTERED_MENTOR_LIST`,
    FETCH_FILTERED_MENTOR_LIST_SUCCESS: `${actionTypePrefix}/FETCH_FILTERED_MENTOR_LIST_SUCCESS`,
    FETCH_FILTERED_MENTOR_LIST_FAIL: `${actionTypePrefix}/FETCH_FILTERED_MENTOR_LIST_FAIL`,

    FETCH_MENTEES_LIST_START: `${actionTypePrefix}/FETCH_MENTEES_LIST_START`,
    FETCH_MENTEES_LIST_SUCCESS: `${actionTypePrefix}/FETCH_MENTEES_LIST_SUCCESS`,
    FETCH_MENTEES_LIST_FAIL: `${actionTypePrefix}/FETCH_MENTEES_LIST_FAIL`,

    SET_FILTER_DATA: `${actionTypePrefix}/SET_FILTER_DATA`,
    CLEAR_FILTER_DATA: `${actionTypePrefix}/CLEAR_FILTER_DATA`,

    FETCH_LOCATION: `${actionTypePrefix}/FETCH_LOCATION`,
    FETCH_LOCATION_SUCCESS: `${actionTypePrefix}/FETCH_LOCATION_SUCCESS`,
    FETCH_LOCATION_FAIL: `${actionTypePrefix}/FETCH_LOCATION_FAIL`,
};

export interface IMentorPayload {
    expertType?: string,
    countries?: string,
    visibility?: string,
    page?: number,
    limit?: number
}

export interface IMentorListPayload {
    res?: any,
    type?: string,
    page?: number
}


export interface IFetchLocation {
    role?: string;
    includeCities?: boolean;
}

export interface IFetchLocationAction {
    type: string,
    payload: IFetchLocation
}

export interface IFetchLocationFilterData {
    countries?: IProfileLocation[];
    cities?: IProfileLocation[];
}

export const fetchMentorListStart = (payload: IMentorPayload) => {
    return {
        type: mentorActions.FETCH_MENTOR_LIST_START,
        payload
    }
}

export const fetchMentorListSuccess = (payload: IMentorListPayload) => {
    return {
        type: mentorActions.FETCH_MENTOR_LIST_SUCCESS,
        payload
    }
}

export const fetchMentorListFail = (payload: string) => {
    return {
        type: mentorActions.FETCH_MENTOR_LIST_FAIL,
        payload
    }
}

export const setFilterData = (payload: ISetFilterData) => {
    return {
        type: mentorActions.SET_FILTER_DATA,
        payload
    }
}

export const fetchFilteredMentorList = (payload: IMentorPayload) => {
    return {
        type: mentorActions.FETCH_FILTERED_MENTOR_LIST,
        payload
    }
}

export const fetchFilteredMentorListSuccess = (payload: IMentorListPayload) => {
    return {
        type: mentorActions.FETCH_FILTERED_MENTOR_LIST_SUCCESS,
        payload
    }
}

export const fetchLocation = (payload: IFetchLocation): IFetchLocationAction => {
    return {
        type: mentorActions.FETCH_LOCATION,
        payload
    }
}

export const fetchLocationSuccess = (data: IFetchLocationFilterData) => {
    return {
        type: mentorActions.FETCH_LOCATION_SUCCESS,
        payload: data
    }
}

export const fetchLocationFail = (error: string) => {
    return {
        type: mentorActions?.FETCH_LOCATION_FAIL,
        payload: error
    }
}

export const clearFilterData = () => {
    return {
        type: mentorActions.CLEAR_FILTER_DATA,
    }
}

export const fetchMenteesListStart = (payload:string) => {
    return {
        type: mentorActions.FETCH_MENTEES_LIST_START,
        payload
    }
}

export const fetchMenteesListSuccess = (payload:ProfileModel[]) => {
    return {
        type: mentorActions.FETCH_MENTEES_LIST_SUCCESS,
        payload
    }
}

export const fetchMenteesListError = (error:string) => {
    return {
        type: mentorActions?.FETCH_MENTEES_LIST_FAIL,
        payload: error
    }
}

