import { deserializeJsonObjCollection } from "@utils/ObjectMapperUtil";

import { APIv1 } from "@common/network/constants";
import { ITagPayload } from "@common/redux/common/interface";
import { IFetchAssessmentCompetency } from "@common/redux/common/actions";

import { Language } from "@models/Language";
import { LanguageName, ILanguageNamePayload } from "@models//LanguageName";

import { ApiClient,wfcommonApiClient } from "@network/client";

const ENDPOINTS = {
  fetchLanguages: () => `${APIv1}/organizationservice/languages`,
  fetchTags: () => `${APIv1}/tagsservice/tags`,
  fetchLanguageNames: () => `${APIv1}/transformerservice/languages`
};

class CommonRepository {
  private apiClient: any;
  private commonApiClient: any;

  constructor(apiClient: any,commonApiClient:any) {
    this.apiClient = apiClient;
    this.commonApiClient = commonApiClient;
  }

  public fetchLanguages = async () => {
    const response = await this.apiClient.get(ENDPOINTS.fetchLanguages());
    return deserializeJsonObjCollection(
      response,
      Language,
      "fetchLanguages"
    ) as Language[];
  };

  public fetchLanguageNames = async (payload: ILanguageNamePayload) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchLanguageNames(), payload);
    return deserializeJsonObjCollection(
      response.languages,
      LanguageName,
      "fetchLanguageNames"
    ) as LanguageName[];
  };

  public fetchTags = async(payload: ITagPayload) => {
    const response = await this.commonApiClient.get(ENDPOINTS.fetchTags(), payload, true);
    return response.tags;
  }

  public fetchAssessmentCompetency = async (payload: IFetchAssessmentCompetency) => {
    const response = await this.commonApiClient.get(ENDPOINTS.fetchTags(), payload);
    return response.tags[0].tags;
  }
}

const commonRepository = new CommonRepository(ApiClient,wfcommonApiClient);

export { commonRepository as CommonRepository };

