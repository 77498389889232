import React from 'react';

interface IProps {
    children: any;
    className?: string;
}

export default function (props: IProps) {
    const { className, children } = props;
    return (
        <div className={`content_wrapper ${className}`}>
            {children}
        </div>
    )
}
