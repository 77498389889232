import { APIv1 } from "@common/network/constants";
import { ApiClient } from "@network/client";

const ENDPOINTS = {
  fetchSkillScores: (userId: string) =>
    `${APIv1}/organizationservice/reports/skill/student/${userId}`,
};

class SkillsScoreRepository {
  private apiClient: any;

  constructor(apiClient: any) {
    this.apiClient = apiClient;
  }

  public fetchSkillScores = async (userId: string, courseId: number) => {
    const requestPayload = {
      courseId: courseId,
    };
    const response = await this.apiClient.get(
      ENDPOINTS.fetchSkillScores(userId),
      requestPayload
    );
    return response;
  };
}

const skillsScoreRepository = new SkillsScoreRepository(ApiClient);

export { skillsScoreRepository as SkillsScoreRepository };
