import { ZoomActions } from './actions';

export interface IZoomState {
  zoomAccessToken:any;
  zoomMeetinDetails:any;
  loaders: {
    zoomAccessToken:boolean;
    zoomMeetinDetails:boolean;
  };
  errors: {
    zoomAccessToken:string;
    zoomMeetinDetails:string;
  };
}

export const initialState = {
    zoomAccessToken: '',
    zoomMeetinDetails: '',
    loaders: {
        zoomAccessToken: false,
        zoomMeetinDetails: false,
    },
    errors: {
        zoomAccessToken: '',
        zoomMeetinDetails: ''
    },
};

export const zoomReducer = (
    state: IZoomState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
  
    case ZoomActions.ZOOM_ACCESS_TOKEN:
        return {
            ...state,
            loaders: { ...state.loaders, zoomAccessToken: true },
            errors: { ...state.errors, zoomAccessToken: '' },
        };
    case ZoomActions.ZOOM_ACCESS_TOKEN_SUCCESS:
        return {
            ...state,
            zoomAccessToken: action.payload.accessToken,
            loaders: { ...state.loaders, zoomAccessToken: false },
            errors: { ...state.errors, zoomAccessToken: '' },
        };
    case ZoomActions.ZOOM_ACCESS_TOKEN_FAILURE:
        return {
            ...state,
            loaders: { ...state.loaders, zoomAccessToken: false },
            errors: { ...state.errors, zoomAccessToken: action.payload },
        };
   
    case ZoomActions.GET_ZOOM_MEETING_DETAILS:
        return {
            ...state,
            loaders: { ...state.loaders, zoomMeetinDetails: true },
            errors: { ...state.errors, zoomMeetinDetails: '' },
        };
    case ZoomActions.GET_ZOOM_MEETING_DETAILS_SUCCESS:
        return {
            ...state,
            zoomMeetinDetails: action.payload,
            loaders: { ...state.loaders, zoomMeetinDetails: false },
            errors: { ...state.errors, zoomMeetinDetails: '' },
        };
    case ZoomActions.GET_ZOOM_MEETING_DETAILS_FAILURE:
        return {
            ...state,
            loaders: { ...state.loaders, zoomMeetinDetails: false },
            errors: { ...state.errors, zoomMeetinDetails: action.payload },
        };
    default:
        return state;
    }
};
