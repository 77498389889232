import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";

import { getErrorCodes } from "@utils/ErrorMessageUtils";

import { SettingsModel } from "@common/domain/models/Settings";

import { ISettingsConfig, IUpdateSettingsPayload } from "@common/domain/models/SettingsConfig";
import { SettingsRepository } from "@common/domain/repositories/SettingsRepository";

import {
    getUserSettingsConfigFailure,
    getUserSettingsConfigSuccess,
    getUserSettingsFailure,
    getUserSettingsSuccess,
    settingsActions,
    updateUserSettingsFailure,
    updateUserSettingsSuccess
} from "./actions";

export function* fetchSettingsConfigSaga() {
    try {
        const settings: ISettingsConfig = yield call(SettingsRepository.getUserSettingsConfig);
        yield put(getUserSettingsConfigSuccess(settings));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(getUserSettingsConfigFailure(error));
    }
}

export function* fetchSettingsSaga(action: any) {
    try {
        const settings: SettingsModel = yield call(SettingsRepository.getUserSettings, action.payload.userId);
        yield put(getUserSettingsSuccess(settings));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(getUserSettingsFailure(error));
    }
}

export function* updateSettingsSaga(action: IUpdateSettingsPayload) {
    try {
        const settings: SettingsModel = yield call(SettingsRepository.updateUserSettings, action.payload);
        yield put(updateUserSettingsSuccess(settings));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(updateUserSettingsFailure(error));
    }
}

export function* watchSettings(): Generator<ForkEffect> {
    yield takeLatest(settingsActions.GET_USER_SETTINGS_CONFIG, fetchSettingsConfigSaga);
    yield takeLatest(settingsActions.GET_USER_SETTINGS, fetchSettingsSaga);
    yield takeLatest(settingsActions.UPDATE_USER_SETTINGS, updateSettingsSaga);
}
