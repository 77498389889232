import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { getErrorCodes } from '@utils/ErrorMessageUtils';

import { Article, IContentTag } from '@common/domain/models/Article';
import { ContentRepository } from '@common/domain/repositories/ContentRepository';
import {
    contentActions,
    fetchArticlesByIdFail,
    fetchArticlesByIdSuccess,
    fetchArticlesFail,
    fetchArticlesSuccess,
    fetchSuggestedArticlesFail,
    fetchSuggestedArticlesSuccess,
    fetchTagsFail,
    fetchTagsSuccess,
    IArticleSuccessPayload,
    IFetchArticleAction,
    IFetchArticleByIdAction,
    IFetchTagsAction,
    ILikeArticleAction,
    likeArticleFailure,
    likeArticleSuccess,
    unLikeArticleFailure,
    unLikeArticleSuccess,

} from '@common/redux/content/actions';

export function* fetchArticlesSaga(action: IFetchArticleAction) {
    try {
        const articles = yield call(
            ContentRepository.fetchArticles,
            action.payload
        );
        const lastPage = articles.total <= action.payload.page * action.payload.limit;

        const payload: IArticleSuccessPayload = {
            ...articles,
            type: action.payload.type,
            page: action.payload.page,
            isLastpage: lastPage
        };
        yield put(fetchArticlesSuccess(payload));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchArticlesFail(error));
    }
}

export function* fetchArticleDetailsSaga(action: IFetchArticleByIdAction) {
    try {
        const articles: Article = yield call(
            ContentRepository.fetchArticlesById,
            action.payload
        );
        yield put(fetchArticlesByIdSuccess(articles));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchArticlesByIdFail(e.message));
    }
}

export function* fetchTagsSaga(action: IFetchTagsAction) {
    try {
        const tags = yield call(ContentRepository.fetchTags, action.payload);
        let tagValues: IContentTag[] = [];
        tags?.map((tag) => {
            tag?.tags?.map((contentTag) => {
                if (contentTag?.isGrouped) {
                    const groupObj = contentTag?.groups?.map((group) => {
                        return {
                            ...group,
                            key: contentTag?.key,
                            isGrouped: contentTag?.isGrouped
                        }
                    });
                    tagValues = [...tagValues, ...groupObj];
                } else {
                    const groupObj = contentTag?.values?.map((value) => {
                        return {
                            groupName: value,
                            groupValues: [value],
                            key: contentTag?.key,
                            isGrouped: contentTag?.isGrouped
                        }
                    });
                    tagValues = [...groupObj, ...tagValues];
                }
            });
        });
        yield put(fetchTagsSuccess(tagValues));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchTagsFail(error));
    }
}

export function* likeArticleSaga(action: ILikeArticleAction) {
    try {
        const response = yield call(ContentRepository.likeArticle, action.payload);
        yield put(likeArticleSuccess(action.payload.shortId));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(likeArticleFailure(error));
    }
}

export function* unLikeArticleSaga(action: ILikeArticleAction) {
    try {
        const response = yield call(ContentRepository.unLikeArticle, action.payload);
        yield put(unLikeArticleSuccess(action.payload.shortId));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(unLikeArticleFailure(error));
    }
}

export function* fetchSuggestedArticlesSaga(action: IFetchArticleAction) {
    try {
        const articles = yield call(
            ContentRepository.fetchArticles,
            action.payload
        );
        const payload: IArticleSuccessPayload = {
            ...articles,
            shortId: action.payload.articleShortId
        };
        yield put(fetchSuggestedArticlesSuccess(payload));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchSuggestedArticlesFail(error));
    }
}
export function* watchContent(): any {
    yield takeEvery(contentActions.FETCH_ARTICLES, fetchArticlesSaga);
    yield takeLatest(contentActions.FETCH_ARTICLE_DETAILS, fetchArticleDetailsSaga);
    yield takeLatest(contentActions.FETCH_TAGS, fetchTagsSaga);
    yield takeLatest(contentActions.LIKE_ARTICLE, likeArticleSaga);
    yield takeLatest(contentActions.UNLIKE_ARTICLE, unLikeArticleSaga);
    yield takeEvery(contentActions.FETCH_SUGGESTED_ARTICLES, fetchSuggestedArticlesSaga);
}
