export const STATUS = {
    LessThanForty: "0% - 40%",
    FourtyToSixty: "41% - 60%",
    SixtyToSeventyFive: "61% - 75%",
    SeventyFiveToEighty: "76% - 80%",
    GreaterThanEighty: "81% - 100%"
};

export const STACK_GRAPH = "bar-stacked";

export const BAR_WIDTH = 2;

export const GRAPH_SIZE = {
    HEIGHT: 300,
    WIDTH: 300
};

export const LEGEND_POSITION = "bottom";

export const TITLE = {
    PADDING: 30,
    FONT: 20
};

export const Y_AXIS = {
    PRECISION: 0,
    FONT_SIZE: 20,
    PADDING_TOP: 30,
    PADDING_RIGHT: 10,
    PADDING_BOTTOM: 0,
    PADDING_LEFT: 0
}

export enum QuizLabels {
    Pending = "Pending",
    LessThanForty = "<40%",
    FourtyToSeventy = "40-70%",
    SeventyToEighty = "70-80%",
    GreaterThanEighty = ">80%"
}

export enum ModuleLevelActivityStatus {
    NotAttemptedLessons = "Not Attempted Lessons",
    AttemptedLessons = "Attempted Lessons",
    CompletedLessons = "Completed Lessons",
}

