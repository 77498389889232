import { JsonProperty } from "json-object-mapper";

export interface ITags {
  public: {
    industries: string[];
    skills: string[];
    category: string[];
    topic?:string[];
    keywords?: string[];
    sector?:string[]
  };
  private: {
    type?: string[];
  };
}

export interface IArticleLanguage {
  name: string;
  languageCode: string;
}

export interface IAuthor {
  name: string;
  title: string;
}

export interface IGetArticlesApiParam {
  referer?: string;
  query?: string;
  page?: number;
  limit?: number;
  languageCodes?: string;
  tagValues?: string;
  tags?: {};
  searchTerm?: string;
  type?: string;
  include?: string;
  articleShortId?:string;
}

export interface IGetArticleDetailsParam{
  slugId:string;
  include?:string;
}

export interface IArticle {
  title: string;
  body: string;
  thumbnailUrl: string;
  videoUrl: string;
  coverImageUrl: string;
  tags: ITags;
  articleLanguage: IArticleLanguage;
  author: IAuthor;
  likesCount: number;
  commentsCount: number;
  viewsCount: number;
  sharesCount: number;
  duration: number;
  slug: number;
  shortId: number;
  shareUrl: number;
  id: number;
}

export interface ITagPayload {
  products?: string;
  scopes?: string;
  keys?: string;
  languageCodes?: string;
}

export interface ILikeArticlePayload{
  articleId:string;
  shortId:string;
}

export interface IOgData{
  title:string;
  description:string;
  image:string;
  updatedAt:string;
}

export interface IDuration{
  value: number;
  type: string;
}

export interface IContentTag {
  groupName?: string;
  groupValues?: string[];
  key?: string;
  id?: string;
  searchTerm?: string;
  isGrouped?: boolean;
}

export interface IAttachment {
  type: string;
  value: string;
}

export interface IHighlights {
  path: string;
  score: number;
  texts: IAttachment[]
}

export class Article {

    @JsonProperty({ name: "title", required: true })
    private title: string = "";

    @JsonProperty({ name: "attachments", required: false })
    private attachments: IAttachment[] = [];

    @JsonProperty({ name: "body", required: true })
    private body: string = "";

    @JsonProperty({ name: "thumbnailUrl", required: true })
    private thumbnailUrl: string = "";

    @JsonProperty({ name: "videoUrl", required: true })
    private videoUrl: string = "";

    @JsonProperty({ name: "articleLanguage", required: true })
    private articleLanguage: IArticleLanguage = {} as IArticleLanguage;

    @JsonProperty({ name: "coverImageUrl", required: true })
    private coverImageUrl: string = "";

    @JsonProperty({ name: "tags", required: true })
    private tags: ITags = {} as ITags;

    @JsonProperty({ name: "author", required: true })
    private author: IAuthor = {} as IAuthor;

    @JsonProperty({ name: "likesCount", required: true })
    private likesCount: number = 0;

    @JsonProperty({ name: "commentsCount", required: true })
    private commentsCount: number = 0;

    @JsonProperty({ name: "viewsCount", required: true })
    private viewsCount: number = 0;

    @JsonProperty({ name: "sharesCount", required: true })
    private sharesCount: number = 0;

    @JsonProperty({ name: "shortId", required: true })
    private shortId: string = "";

    @JsonProperty({ name: "createdAt", required: false })
    private createdAt: string = "";

    @JsonProperty({name:'duration', required:false})
    private duration: IDuration={} as IDuration;

    @JsonProperty({name:'type', required:false})
    private type: string="";

    @JsonProperty({ name: "id", required: true })
    private id: string = "";

    @JsonProperty({ name: "isLiked", required: false })
    private isLiked: boolean = false;

    @JsonProperty({name:'ogData',required:false})
    private ogData: IOgData = {} as IOgData;

    @JsonProperty({name:'suggestedArticle', required:false})
    private suggestedArticle:Article[] = [];

    @JsonProperty({ name: "slug", required: true })
    private slug: string = "";

    @JsonProperty({ name: 'highlights', required: false })
    private highlights: IHighlights[] = [];

    public getTitle() {
        return this.title;
    }

    public getAttachments() {
      return this.attachments;
    }

    public getBody() {
        return this.body;
    }
    public getThumbnailUrl() {
        return this.thumbnailUrl;
    }

    public getVideoUrl(){
      return this.videoUrl;
    }

    public getArticleLanguage() {
      return this.articleLanguage;
    }

    public getCoverImageUrl(){
      return this.coverImageUrl;
    }

    public getTags(){
      return this.tags;
    }

    public getAuthor(){
      return this.author;
    }

    public getLikesCount(){
      return this.likesCount;
    }

    public getCommentsCount(){
      return this.commentsCount;
    }

    public getViewsCount(){
      return this.viewsCount;
    }

    public getSharesCount(){
      return this.sharesCount;
    }

    public getShortId(): string {
      return this.shortId;
    }

    public getSlug(): string {
      return this.slug;
    }

    public getCreatedAt(): string {
      return this.createdAt;
    }

    public getDuration(): IDuration{
      return this.duration
    }

    public getType():string{
      return this.type
    }

    public getId(): string {
      return this.id;
    }

    public getIsLiked(): boolean {
      return this.isLiked;
    }

    public getOgData():IOgData{
      return this.ogData;
    }

    public getSuggestedArticle():Article[]{
      return this.suggestedArticle
    }

    public getHighlights(): IHighlights[] {
      return this.highlights
    }
}

