import { JsonProperty } from 'json-object-mapper';

import { BatchModel } from './Batch';
import { CourseModel } from './Course';
import { Institute } from './Institute';

export class RMBatchModel {
    @JsonProperty({name: 'id', required: true})
    private id: number = 0;

    @JsonProperty({name: 'batchId', required: true})
    private batchId: number = 0;

    @JsonProperty({name: 'regionalManagerId', required: true})
    private regionalManagerId: string = "";

    @JsonProperty({name: 'status', required: true})
    private status: string = "";

    @JsonProperty({name: 'createdAt', required: true})
    private createdAt: string = "";

    @JsonProperty({name: 'updatedAt', required: true})
    private updatedAt: string = "";

    @JsonProperty({name: 'deletedAt', required: false})
    private deletedAt: string = "";

    @JsonProperty({ name: 'batchDetail', required: false})
    private batchDetail: any = "";

    @JsonProperty({ name: 'courseDetail', required: false})
    private courseDetail: CourseModel = null;

    @JsonProperty({ name: 'instituteDetail', required: false})
    private instituteDetail: Institute = null;

    @JsonProperty({name: 'numberOfStudents', required: false})
    private numberOfStudents: number = 0;

    public setInstituteDetail(institute: Institute) {
        this.instituteDetail = institute;
    }

    public getInstituteDetail(): Institute {
        return this.instituteDetail;
    }

    public setNumberOfStudents(count: number) {
        this.numberOfStudents = count;
    }

    public getNumberOfStudents() {
        return this.numberOfStudents;
    }
    
    public setBatchDetail(batch: BatchModel) {
        this.batchDetail = batch;
    }

    public getBatchDetail(): BatchModel {
        return this.batchDetail;
    }

    public setCourseDetail(course: CourseModel) {
        this.courseDetail = course;
    }

    public getCourseDetail(): CourseModel {
        return this.courseDetail;
    }

    public getId(): number {
        return this.id;
    }

    public getBatchId(): number {
        return this.batchId;
    }

    public getRegionalManagerId(): string {
        return this.regionalManagerId;
    }

    public getStatus(): string {
        return this.status;
    }

    public getCreatedAt(): string {
        return this.createdAt;
    }

    public getUpdatedAt(): string {
        return this.updatedAt;
    }

    public getDeletedAt(): string {
        return this.deletedAt;
    }
}
