import { ObjectMapper } from "json-object-mapper";

import { APIv1 } from "@common/network/constants";
import { IApiClient } from "@common/network/interfaces";

import { Article, IGetArticleDetailsParam, IGetArticlesApiParam, ILikeArticlePayload, ITagPayload } from "@models/Article";

import { wfcommonApiClient } from "@network/client";

const ENDPOINTS = {
    fetchArticles: () => `${APIv1}/contentservice/articles`,
    fetchArticlesById: (articleId: string) => `${APIv1}/contentservice/articles/${articleId}`,
    fetchTags: () => `${APIv1}/tagsservice/tags`,
    likeArticle: (articleId: string) => `${APIv1}/contentservice/articles/${articleId}/likes`,
}

class ContentRepository{
    private apiClient: IApiClient;
    constructor(apiClient: IApiClient) {
        this.apiClient = apiClient;
    }

    public fetchArticles = async(apiParams: IGetArticlesApiParam) => {
        const {tagValues, tags, limit, page ,include, searchTerm, query } = apiParams;
        const params = {tagValues, tags, limit, page, include, searchTerm, query };
        const response = await this.apiClient.get(ENDPOINTS.fetchArticles(), params);
        return { content: response?.articles?.map((article) => ObjectMapper.deserialize(Article, article)), total: response?.articlesCount };
    }

    public fetchArticlesById = async(payload:IGetArticleDetailsParam) => {
        const params = {include:payload.include}
        const response = await this.apiClient.get(ENDPOINTS.fetchArticlesById(payload.slugId),params);
        return ObjectMapper.deserialize(Article, response.article);
    }

    public fetchTags = async(payload: ITagPayload) => {
        const response = await this.apiClient.get(ENDPOINTS.fetchTags(), payload);
        return response.tags;
    }

    public likeArticle = async(payload: ILikeArticlePayload) => {
        const response = await this.apiClient.put(ENDPOINTS.likeArticle(payload.articleId));
        return response;
    }

    public unLikeArticle = async(payload: ILikeArticlePayload) => {
        const response = await this.apiClient.delete(ENDPOINTS.likeArticle(payload.articleId));
        return response;
    }
}

const contentRepository = new ContentRepository(wfcommonApiClient);

export { contentRepository as ContentRepository };

