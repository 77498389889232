import { JsonProperty } from "json-object-mapper";

export class AzureTokenModel {
  @JsonProperty({ name: 'token', required: true })
  private token: string = "";

  @JsonProperty({ name: 'region', required: true })
  private region: string = "";


  public getRegion = (): string | undefined => {
    return !!this.region ? this.region : undefined;
  };

  public getToken = (): string | undefined => {
    return !!this.token ? this.token : "";
  };
}

