import { MessageModel } from '@models/Message';
import { ConversationModel } from '@models/Conversation';
import { SocketService } from '@services/SocketService';
import { DataUtility } from '@utils/DataUtility';

const updateChatMessage = (
  messages: MessageModel[] = [],
  newMessages: MessageModel[] = [],
  reorder: boolean = false,
  reset: boolean = false): MessageModel[] => {
  let updatedMessages = newMessages || [];
  if (newMessages && messages && !reset) {
    updatedMessages = [...messages, ...newMessages];
  }
  if (reorder) {
    // Sorting of messages array based on the createdAt
    updatedMessages = DataUtility.orderBy(updatedMessages, (item: MessageModel) => item.getCreatedAt(), ['desc']);
  }
  return updatedMessages;
};

const updateConversations = (
  conversations: ConversationModel[],
  newConversations: ConversationModel[],
  reorder: boolean = false,
  reset: boolean = false,
): ConversationModel[] => {
  let updatedConversations = newConversations;

  if (!conversations) {
    if (reorder) {
      // Sorting of messages array based on the createdAt
      updatedConversations = DataUtility.orderBy(updatedConversations, (item) => item.getLastMessage()?.getCreatedAt(), ['desc']);
      ;
    }
  }

  if (conversations && !reset) {
    let sortedConversations = updatedConversations;
    if (reorder) {
      // Sorting of messages array based on the createdAt
      sortedConversations = DataUtility.orderBy(updatedConversations, (item) => item.getLastMessage()?.getCreatedAt(), ['desc']);
      ;
    }
    updatedConversations = [...conversations, ...sortedConversations];
  }
  return updatedConversations;
}

const addNewConversation = (conversations: ConversationModel[],
  newConversation: ConversationModel, force?: boolean): ConversationModel[] => {
  let conversationsUpdate = [newConversation];

  if (DataUtility.isEmpty(conversations)) {
    return conversationsUpdate;
  }
  // adding conversation to retain con list in store, if conversation screen not opened.
  const convFound = conversations.find(
    (item: ConversationModel) => item?.getId() === newConversation?.getId());
  // if found update existing one
  if (convFound && !force) {
    conversationsUpdate = conversations.map((item: ConversationModel) => {
      return item?.getId() === newConversation?.getId() ? newConversation : item;
    });
  } else {
    if (force) {
      conversations = conversations.filter((convo) => convo?.getId() !== newConversation?.getId());
    }
    conversationsUpdate = [newConversation, ...conversations];
  }
  return conversationsUpdate;
};

const removeUserProfileId = (profileId: string[]): string[] => {
  return DataUtility.without(
    profileId,
    SocketService.userProfileId,
  );
}

/**
 * removes duplicate messages and concat to original array
 * @param messages sorted desc order
 * @param sliceLength
 */
const removeLatestDuplicateMessages = (messages: MessageModel[] = [], sliceLength: number): MessageModel[] => {
  if (DataUtility.isEmpty(messages)) {
    return [];
  }
  let firstChunk = messages;
  let concatWith: MessageModel[] = [];
  // if > 0, then split and take, else use full array
  if (sliceLength > 0) {
    firstChunk = DataUtility.take(messages, sliceLength);
    if (messages.length > sliceLength) {
      concatWith = DataUtility.takeRight(messages, messages.length - sliceLength);
    }
  }
  firstChunk = DataUtility.uniqBy(firstChunk, 'id');
  return [...firstChunk, ...concatWith];
}

export default {
  updateChatMessage,
  updateConversations,
  addNewConversation,
  removeUserProfileId,
  removeLatestDuplicateMessages,
};
